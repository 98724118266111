import RequestHelper, { CONTENT_TYPE } from "../../RequestHelper";
import InternationalisationService from "../../InternationalisationService";
import {serverAddress} from "../config/EnvConfig";

const translationMgmtApiUrl = serverAddress + '/translations/mgmt'

export const getLanguages = async () => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/language`
    );
    return result;
}

export const searchTranslations = async (langCode, queryString) => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/lang/${langCode}?query=${queryString?.toLowerCase()?.trim()}&maxResults=100`
    );
    return result;
}

export const getTranslationForLanguageAndDefinition = async (langCode, questionnaireCode, searchTerm) => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/questionnairedefcode/${questionnaireCode}/lang/${langCode}`,
        { "Accept-Language": InternationalisationService.getLanguage() },
        "GET",
        {
            translationQuery: searchTerm ? searchTerm : ""
        }
    );
    return result;
}

export const getTranslationsForTranslationCode = async (translationCode) => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/translationCode/${translationCode}`,
        { "Accept-Language": InternationalisationService.getLanguage() },
        "GET"
    );
    return result;
}

export const getTranslationsForQuestionnaireDefCode = async (questionnaireDefinitionCode, translationType) => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/questionnairedefcode/${questionnaireDefinitionCode}/type/${translationType}`,
        { "Accept-Language": InternationalisationService.getLanguage() },
        "GET"
    );
    return result;
}

export const saveTranslation = async (langCode, client, code, translation) => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/update`,
        {},
        'POST',
        null,
        {
            language: langCode,
            client: client,
            code: code,
            translation: translation
        }

    );
    return result;
}

export const updateLanguage = async (languageCode, languageName, copyTranslations, purposes) => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/language`,
        { 'Accept': 'text/plain' },
        'POST',
        null,
        {
            languageCode: languageCode,
            languageName: languageName,
            purposes: purposes,
            copyData: copyTranslations
        }

    );
    return result;
}

export const downloadForTranslation = (language) => {
    return RequestHelper.send(
        `${translationMgmtApiUrl}/data/download-separate-files/${language}?defaultLanguage=en_GB&forTranslation=true`,
        {
            Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
            "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "GET"
    );
}

export const downloadForTranslationNoDuplicatesSingleFile = (language, filters) => {
    return RequestHelper.send(
        `${translationMgmtApiUrl}/data/download-for-translation/${language}?defaultLanguage=en_GB&forTranslation=true&filters=${filters.trim()}`,
        {
            Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
            "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "GET"
    );
}
export const downloadForMigration = (language) => {
    return RequestHelper.send(
        `${translationMgmtApiUrl}/data/download-separate-files/${language}?defaultLanguage=en_GB&forTranslation=false`,
        {
            Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
            "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "GET"
    );
}

export const downloadDiffsFromRefLanguage = (language, refLanguage) => {
    return RequestHelper.send(
        `${translationMgmtApiUrl}/data/reference-diffs/${language}?defaultLanguage=${refLanguage}`,
        {
            Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
            "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "GET"
    );
}

export const downloadQuestionnaireDefinitionsTranslatableReport = (language) => {
    return RequestHelper.send(
        `${translationMgmtApiUrl}/data/unrenderable-questionnaires/${language}`,
        {
            Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
            "Keep-Alive": "Keep-Alive: timeout=600, max=1000"
        },
        "GET"
    );
}

export const getBackgroundJobs = async () => {
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/jobs`
    );
    return result;
}



export const uploadTranslationCsv = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const headers = {
        "Content-Type": CONTENT_TYPE.MULTIPART_FORMDATA,
        "Content-Length": file.size,
    };
    const result = RequestHelper.send(
        `${translationMgmtApiUrl}/data/upload`,
        headers,
        'POST',
        null,
        formData
    );
    return result;
}
