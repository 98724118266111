import React, {forwardRef, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {createPortal} from "react-dom";
import RegionSelectorControls from "./RegionSelectorControls";
import useCalculatedFPS from "./hooks/useCalculatedFPS";
import DateTimeService from "../../../services/DateTimeService";
import {saveAs} from "file-saver";
import useKey from '../../../hooks/useKey';
import KeyboardHotkeyMappings from "../../../utility/KeyboardHotkeyMappings";
import AttachmentService, {MEDIA_STATE} from "../../../services/AttachmentService"
import {withTranslation} from "react-i18next";
import AppStateService from "../../../services/AppStateService";
import {DataChannel} from "atom5-data-analysis/src/utils/DataChannel";
import Box from "./regionselector/Box";
import {ChannelList} from "atom5-data-analysis";
import trackObjects from "./Tracking";
import {loadExtractedHeadRects, loadRegionSelectorData} from "./regionselector/AttachmentParser";
import RegionSelectorAnnotations from "./RegionSelectorAnnotations";
import _ from "lodash";
import {ANNOTATION_TYPE} from "./VideoPlayer";


const RegionSelector = forwardRef((props, ref) => {

  const {t, videoPlayerRef, video, portalRegionSelectorToolsTarget, portalRegionSelectorGraphsTarget, videoSizeState, question,changeAnswerMapValue, getAnswerMapValue, videoData, selectedAnnotation, handleSetVideoPlayerAnnotations} = props
  const canvas = useRef(null);
  const boxRef = useRef(null);
  const selectedBoxRef = useRef(null);
  const regionSelectorAnnotations = useRef(null);

  const [boxesVisible, setBoxesVisible] = useState(true);
  const [boxToolActive, setBoxToolActive] = useState(true);
  const [boxSelectActive, setBoxSelectActive] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleBox = useRef(null);
  const handleSelectBox = useRef(null);
  const videoNativeSize = [video?.videoWidth, video?.videoHeight];
  const videoRect = video?.getBoundingClientRect() || new DOMRect();
  const [editorMode] = useState('BLUR_CORRECTION_DATA');  //
  const [filtered, setFiltered] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const questionCode = question?.code;
  const attachmentsConfig = question?.config?.regionSelectorAttachmentsConfig;
  const configForEditorMode = attachmentsConfig?.filter(conf => conf?.attachmentType === editorMode);
  const [entries, setEntries] = useState([])
  const [fps, setFPS] = useState(null);
  const [boxIdEntry, setBoxIdEntry] = useState("0");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [initialContent,setInitialContent] = useState(true);
  const [regionError,setRegionError] = useState(null);
  const [mousePoint, setMousePoint] = useState({x:0,y:0})
  const [mouseDown, setMouseDown] = useState({x:0,y:0})
  //May be a wrapped answer due to answer format required for submission of files, unwraps answer value if needed.
  let attachmentReference = getAnswerMapValue?configForEditorMode?unWrapAnswer(getAnswerMapValue(configForEditorMode[0]?.fileUploader)):"":"";
  const [frameData, setFrameData] = useState(new DataChannel("FrameData",30,100));
  const [frameDataHistory, setFrameDataHistory] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [boxTypes, setBoxTypes] = useState([])
  const [updateUpload, setUpdateUpload] = useState(false);

  const [channelList] = useState(new ChannelList(30,100));

  const [trackingSettings, setTrackingSettings] = useState({ initFrame: 0, endFrame: 0, distanceThreshold: 100, maxId: 10, maxFramesLost: 10, clearLostObjects: false, fromId: 0, toId: 1 })
  const [showGraphs, setShowGraphs] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (updateUpload) {
        prepareDataForFileUpload();
        setUpdateUpload(false);
      }
    }, 1000);

    return () => clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUpload]); // Dependency array includes updateUpload


  function unWrapAnswer(answerValue) {
    let attachmentReference = answerValue ;
    const isWrappedFileuploadAnswer = typeof attachmentReference === "object" && attachmentReference !== null;
    return isWrappedFileuploadAnswer ? attachmentReference.answer : attachmentReference;
  }

  const loadContent = async () => {
    const questionnaireCtx = AppStateService.getLocalStateForNamespace("CURRENT_QUESTIONNAIRE");
    const subjectId = questionnaireCtx.subjectId

    await loadAttachmentVariants(subjectId)

    if(attachmentReference !== null){

      const attachmentResponse = await AttachmentService.getAttachment(
          subjectId,
          attachmentReference,
          false
      );
      if(attachmentResponse /*&& AttachmentService.getMediaStateFromString(attachmentResponse.state) === MEDIA_STATE.COMPLETED*/){
        return new Promise((resolve, reject) => {
          AttachmentService.getAttachmentInline(
              subjectId,
              attachmentReference,
              false,
              (progress) => {
                //console.log(progress.percentage);
              },
              (handDataUrl, blob) => {
                // Read blob as text
                const reader = new FileReader();
                reader.onload = function (event) {
                  const csvData = event.target.result;
                  resolve(loadRegionSelectorData(csvData));
                };
                reader.onerror = function (event) {
                  console.error("File could not be read! Code " + event.target.error.code);
                  reject(event.target.error);
                };
                reader.readAsText(blob);
              },
              (err) => {
                console.error('Error getting cvs data attachment', err)
                reject(err);
              },
              attachmentResponse.variantReference,
          )
        })
      }
    }
  };

  const loadAttachmentVariants= async (subjectId) => {

    const videoReference = getAnswerMapValue(question.code)

    const attachmentVariants = await AttachmentService.getAttachmentVariants(subjectId,videoReference,false);
    const headrectvariant = attachmentVariants.find(item => item.mediaType === "csv/headrect");

    if(AttachmentService.getMediaStateFromString(headrectvariant.state) === MEDIA_STATE.COMPLETED){
      AttachmentService.getAttachmentInline(
          subjectId,
          videoReference,
          false,
          (progress) => {
            //console.log(progress.percentage);
          },
          (variantDataUrl, blob) => {
            const reader = new FileReader();
            reader.onload = function(event) {

              handleImportedFrames(loadExtractedHeadRects(event.target.result));
              handleAddItem("Tracking")
              handleAddItem("_")
              setInitialContent(false);
            }
            reader.readAsText(blob);
          },
          (err) => {
            console.error('Error getAttachmentInline', err)
          },
          headrectvariant.variantReference,
      )
    }
  }

  // This is a bit of a hack
  // cycle1 - isExpandedView set to true
  // cycle2 - this component and video rerender, this value changes
  // cycle3 - this component rerenders getting new video size
  const [localVideoSizeState, setLocalVideoSizeState] = useState(videoSizeState);
  useEffect(()=>{
    if(videoSizeState !== localVideoSizeState) {
      setLocalVideoSizeState(videoSizeState);
    }
  }, [localVideoSizeState, videoSizeState])

  /// Initialize end of frame according to video duration
  useEffect(() => {
    if (video && fps) {
      setTrackingSettings(t => ({ ...t, endFrame: Math.ceil(video.duration * fps) }));
    }
  }, [video, fps]);

  /// Initialise removing keyboard shortcuts
  useEffect(()=>{
    KeyboardHotkeyMappings.unAssignKeys();
    return () => {
      KeyboardHotkeyMappings.assignKeys();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // calculate video padding
  // assumption is made here that if horizontalLeadScaling is true there will be vertical padding
  // and the opposite if horizontalLeadScaling is false
  const isHorizontalLeadScaling = false;//videoNativeSize[0] > videoNativeSize[1];
  const scaleFactor = isHorizontalLeadScaling ?
      videoRect.width / videoNativeSize[0] :
      videoRect.height / videoNativeSize[1];
  const halfXDeadzone = isHorizontalLeadScaling ?
      0 :
      (videoRect.width - (videoNativeSize[0] * scaleFactor)) / 2 ;
  const halfYDeadzone = isHorizontalLeadScaling ?
      (videoRect.height - (videoNativeSize[1] * scaleFactor)) / 2 :
      0 ;


  const getSelectedBox = useCallback((frameNumber, x, y) => {
    x = (x - halfXDeadzone) / scaleFactor
    y = (y - halfYDeadzone) / scaleFactor

    const entries = frameData.getDataPos(frameNumber);
    if(Array.isArray(entries)){
      let selected = null;
      entries.forEach((entry) => {
        if(x > entry.coords.x1 && x < entry.coords.x2  && y > entry.coords.y1 && y < entry.coords.y2) {
          selected = entry;
        }
      });
      return selected;
    }else {
      return null;
    }
  },[frameData, halfXDeadzone, halfYDeadzone, scaleFactor])

  useEffect(() => {
    if(frameData === null)
      return;

    if(channelList.numChannels() === 0){
      channelList.addDataChannel(frameData);
    }else{
      channelList.channels[0].dataChannel=frameData;
    }

      if(regionSelectorAnnotations.current)
        regionSelectorAnnotations.current.handleFrameDataSet(channelList,frameData)

    },
    [channelList, frameData]);


  const handleMouseDown = (e) => {

    if(e.clientY < 0 || e.clientY > (videoRect.y + videoRect.height))
      return;

    const x = e.clientX - videoRect.x
    const y = e.clientY - videoRect.y
    setMouseDown({ x: x, y: y});

    if(boxSelectActive){
      const _selectedEntry = getSelectedBox(currentFrame,x,y)
      if(_selectedEntry) {
        setSelectedEntry(_selectedEntry);
        setSelectedRows([_selectedEntry.id]);
        setBoxIdEntry(_selectedEntry.boxId)

        selectedBoxRef.current._setBoxRect({
          visible: true,
          boxId: _selectedEntry.boxId,
          x: _selectedEntry.coords.x1 * scaleFactor + halfXDeadzone,
          y: _selectedEntry.coords.y1 * scaleFactor + halfYDeadzone,
          width: (_selectedEntry.coords.x2 - _selectedEntry.coords.x1) * scaleFactor,
          height: (_selectedEntry.coords.y2 - _selectedEntry.coords.y1) * scaleFactor,
          unblurred: _selectedEntry.unblurred
        })
      }else {
        setSelectedEntry(null);
        setSelectedRows([]);
        selectedBoxRef.current.hide();
      }
    }
  }

  const handleToggleBoxTool = () => {
    setBoxSelectActive(false);
    if(selectedBoxRef.current !== null)
      selectedBoxRef.current.hide();
    setBoxToolActive(!boxToolActive);
  }

  const handleToggleBoxSelect = () => {
    setBoxToolActive(false);
    setBoxSelectActive(!boxSelectActive);
  }

  const handleDeselectTools = () => {
    setBoxToolActive(false);
    setBoxSelectActive(false);
    if(selectedBoxRef.current !== null)
      selectedBoxRef.current.hide();
  }

  /// When boxSelectActive is changed and sis set to false clear selectedEntry and selectedRows
  useEffect(()=>{
    if(!boxSelectActive){
      setSelectedEntry(null);
      setSelectedRows([]);

    }
    if(selectedBoxRef.current !== null)
      selectedBoxRef.current.hide();
  },[boxSelectActive]);

  /// When boxTollActive is set to false clear selectedEntry
  useEffect(() => {
    if(!boxToolActive){
      setSelectedEntry(null);
    }
  }, [boxToolActive]);


  // handle temporal information
  const calculatedFPSData = useCalculatedFPS(video);

  const currentFrame = useMemo(()=>{
    if(
        typeof calculatedFPSData.currentTime !== "number" ||
        typeof fps !== "number"
    ) {
      return null;
    }
    return Math.round(calculatedFPSData.currentTime * fps);
  }, [calculatedFPSData, fps]);

  // handle drawing on the canvas
  useEffect(()=>{

    const ctx = canvas.current.getContext("2d");
    ctx.clearRect(0,0, 10000, 10000)
    ctx.lineWidth = 1
    ctx.font = "20px Arial"; // Set font size and family

    if(!boxesVisible)
      return;
    if(frameData == null)
      return;
    const currentFrameEntries = frameData.getDataPos(currentFrame);
    if(currentFrameEntries === 0)
      return;
    currentFrameEntries.forEach((entry)=> {
      /// If entry is selected then dont draw
      if(selectedEntry?.id === entry.id){
        return;
      }

      const x = entry.coords.x1 * scaleFactor + halfXDeadzone;
      const y = entry.coords.y1 * scaleFactor + halfYDeadzone;
      const width = (entry.coords.x2 - entry.coords.x1) * scaleFactor;
      const height = (entry.coords.y2 - entry.coords.y1) * scaleFactor;

      const unblurred = (entry.unblurred?entry.unblurred:false)
      const text = entry.boxId+(unblurred?" *":"")
      ctx.fillStyle = unblurred?"#fff2":"#666666BB";
      ctx.fillRect(x, y, width, height);

      ctx.strokeStyle = "#ffcccc";
      ctx.strokeRect(x, y, width, height);

      ctx.fillStyle = "#FFF"; // Set text color
      ctx.fillText(text, x + 2, y + 20);
      ctx.fillStyle = "#000"; // Set text color
      ctx.fillText(text, x + 3, y + 20); // Add some padding for better visibility
    })
  }, [boxesVisible, entries, frameData, currentFrame, halfXDeadzone, halfYDeadzone, scaleFactor,selectedEntry, boxSelectActive])

  useEffect(()=>{
    if ((fps == null || fps === 0) && calculatedFPSData?.fps !== null){
      setFPS(calculatedFPSData.fps);
    }
  },[fps,calculatedFPSData])

  useEffect(()=>{
    if (fps !== null && fps !== 0){
      frameData.freq = fps
    }
  },[fps, frameData])

  useEffect(() => {
    setBoxTypes(Array.from(new Set(entries.map(item => item.type))));
    setSelectedTypes(prevSelectedTypes => prevSelectedTypes.filter(type => boxTypes.includes(type)));
    setUpdateUpload(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[entries])

  useEffect(() => {
    loadContent().then((loadedEntries) => {

      handleImportedFrames(loadedEntries);
      handleAddItem("Stored")


      //addFrameDataEntries(loadedEntries)
      //setEntries(loadedEntries);
      //setInitialContent(false)
    }).catch(error => {
      console.log("Error fetching previous region data.")
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Controls
  async function nextFrame() {
    if (fps === null) return;
    const calculatedTime = video.currentTime  + (1 / fps);

    video.currentTime = Math.ceil(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }

  async function previousFrame() {
    if (fps === null) return;
    const calculatedTime = calculatedFPSData.currentTime - (1 / fps)
    video.currentTime = Math.ceil(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }

  async function handleGoToFrame(frameNumber) {
      if(frameNumber < 500) // Ugly fix to make sure we set the right frame
        frameNumber++;
    const calculatedTime = ((frameNumber)/ fps)
    video.currentTime = Math.round(calculatedTime * 1000000) / 1000000;//round up number to 6 decimal places
    await video.pause();
  }



  const addFrameDataEntry = useCallback((frameNumber,entry,notify) => {

    let cframeData = frameData.getDataPos(frameNumber);
    frameData.setWritePos(frameNumber, false);
    frameData.empty = false;
    if(cframeData === 0){
      if(entry.boxId === -1)
        entry.boxId = 0;
      frameData.setDataPos(
          [entry],
          frameNumber)
    }else{
      if(entry.boxId === -1)
        entry.boxId = cframeData.length;
      frameData.setDataPos(
          [
            ...cframeData,
            entry, // default box id can be set on selector
          ],
          frameNumber)
    }

    if(notify === null || notify)
      channelList.emitDataChangedEvent();
  },[channelList, frameData]);

  const updateDataFrameEntry = (frameNumber,updatedEntry) => {
    let cframeData = frameData.getDataPos(frameNumber);
    frameData.setDataPos(cframeData.map(item => item.id === updatedEntry.id ? updatedEntry : item),frameNumber);
    setEntries(entries.map(item => item.id === updatedEntry.id ? updatedEntry : item ));
    channelList.emitDataChangedEvent();
  }

  const clearFrameDataEntries = () => {
    frameData.clearData();
    channelList.clearData()

  }

  const addFrameDataEntries = (entries) => {
   entries.map( (entry) =>
       addFrameDataEntry(entry.frame,entry,false)
   );
    channelList.emitDataChangedEvent();
  }

  const handleSetBoxIdSelected = () => {
    selectedRows.forEach(id => handleUpdateBoxId(id, getFirstBoxId(boxIdEntry),entries.find(entry => entry.id === id)),false);
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
    channelList.emitDataChangedEvent();
  };

  const handleBlurSelected = (blurFlag) => {
    selectedRows.forEach(id => handleBlur(entries.find(entry => entry.id === id), blurFlag, false));
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
    channelList.emitDataChangedEvent();
  }

  function getFirstBoxId(boxIdEntry) {
    if (String(boxIdEntry).indexOf(",") !== -1) {
      // Parse as array, return first instance
      const parts = boxIdEntry.split(',').map(part => part.trim());
      const firstPart = parts[0];
      if (!isNaN(firstPart)) {
        return parseInt(firstPart, 10);
      } else {
        throw new Error(`Invalid number: ${firstPart}`);
      }
    } else {
      return parseInt(boxIdEntry, 10);
    }
  }

  function logFrame(type){
    if (fps === null) return;

    if(!boxToolActive) return;
    const coords = boxRef.current.coords();

    addFrameDataEntry(currentFrame, { frame: currentFrame, coords, id: crypto.randomUUID(), boxId: getFirstBoxId(boxIdEntry), type: type },true)
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
  }

  async function logInit() {
    if (fps === null) return;

    const coords = boxRef.current.coords();
    addFrameDataEntry(currentFrame, { frame: currentFrame, coords, id: crypto.randomUUID(), boxId: getFirstBoxId(boxIdEntry), type: "init" },false)
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
  }

  // Function to find the last entry with boxId and type
  function findLastEntry(boxId, type, frameNumber) {

    for(let i= frameNumber;i >= 0; i--){
      const frameEntries = frameData.getDataPos(i);
      if(frameEntries === 0)
        continue;
      const filteredEntries = frameEntries.filter(entry => entry.boxId === boxId && entry.type === type);
      if(filteredEntries.length > 0){
        return filteredEntries[filteredEntries.length - 1]
      }
    }
  }

  function handleTrackIds() {
    trackObjects(frameData, trackingSettings);
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
    channelList.emitDataChangedEvent();
  }


// Function to interpolate between two values
  function interpolate(start, end, fraction) {
    return start + (end - start) * fraction;
  }

// Function to create interpolated entries
  function createInterpolatedEntries(initEntry, endEntry) {

    if (!initEntry || !endEntry) {
      console.error('Both init and end entries are required.');
      return [];
    }

    const { frame: startFrame, coords: startCoords } = initEntry;
    const { frame: endFrame, coords: endCoords } = endEntry;

    const interpolatedEntries = [];
    for (let frame = startFrame + 1; frame < endFrame; frame++) {
      const fraction = (frame - startFrame) / (endFrame - startFrame);
      const interpolatedCoords = {
        x1: Math.round(interpolate(startCoords.x1, endCoords.x1, fraction)),
        y1: Math.round(interpolate(startCoords.y1, endCoords.y1, fraction)),
        x2: Math.round(interpolate(startCoords.x2, endCoords.x2, fraction)),
        y2: Math.round(interpolate(startCoords.y2, endCoords.y2, fraction)),
      };
      interpolatedEntries.push({
        frame,
        coords: interpolatedCoords,
        id: crypto.randomUUID(),
        boxId: initEntry.boxId,
        type: 'calculated'
      });
    }

    return interpolatedEntries;
  }


  async function logEnd() {
    if (fps === null) return;

    // Search init of current entry
    const initEntry = findLastEntry(getFirstBoxId(boxIdEntry),"init", currentFrame)
    const coords = boxRef.current.coords();
    const endEntry = { frame: currentFrame, coords, id: crypto.randomUUID(), boxId: getFirstBoxId(boxIdEntry), type: "end" }

    const newEntries = createInterpolatedEntries(initEntry,endEntry);

    addFrameDataEntries(newEntries);
    addFrameDataEntry(currentFrame, endEntry);
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
  }

  async function handleInterpolateFrames(initEntry,endEntry){
    const newEntries = createInterpolatedEntries(initEntry,endEntry);
    addFrameDataEntries(newEntries);

    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
  }

  function buildBlobData(){

    let dataArray = []

    if(frameData !== null){
      for (let p = frameData.initPos; p <= frameData.endPos; p += 1) {
        let fd = frameData.getDataPos(p);
        if(fd instanceof Array){
          dataArray = [...dataArray, ...fd]
        }
      }
    }

    const titles = "id,frame,top_left_x,top_left_y,bottom_right_x,bottom_right_y,boxId,frameType,blurred\n"
    const csvEntries = dataArray.map(e=>`${e.id},${e.frame},${e.coords.x1},${e.coords.y1},${e.coords.x2},${e.coords.y2},${e.boxId},${e.type},${(e.unblurred === undefined?true:!e.unblurred)}\n`)
    return new Blob([titles,...csvEntries], {
      type: "text/csv;charset=utf-8;",
    })
  }

  function prepareDataForFileUpload () {
    const csvBlobData = buildBlobData();

    if (attachmentsConfig) {
      if (changeAnswerMapValue) {
        const configForEditorMode = attachmentsConfig.filter(conf => conf?.attachmentType === editorMode);
        if (configForEditorMode.length === 1) {
          try {
            AttachmentService.injectAttachmentForUpload(t,
                changeAnswerMapValue,
                configForEditorMode[0]?.fileUploader,
                csvBlobData,
                configForEditorMode[0]?.mimeType,
                'csv',
                attachmentReference,
                videoData.attachmentVariantRef,
                initialContent ? 'ORIGINAL' : 'CSV',
                !initialContent)
          }catch(error){
            setRegionError(error)
          }
        } else {
          console.error("Attachment configuration of: " + questionCode + " is expecting one element of regionSelectorAttachmentsConfig.attachmentType: " + editorMode + " but got " + configForEditorMode.size);
        }
      } else {
        console.error("changeAnswerMapValue function is not defined, is the region selection tool being used from readonly questionnaire view? No data will be prepared for upload.");
      }
    } else {
      console.warn("No attachment configuration defined for Region Selector, will not attempt to upload generated data from Region Selector tool on submission");
    }
  }
  async function download(){
    const csvBlobData = buildBlobData();
    const dateTimeWithMillis = DateTimeService.now.asString();
    const filename = `video-region-selector-${questionCode}-${dateTimeWithMillis}.csv`;
    saveAs(csvBlobData, filename);
  }
  async function remove(entryId) {
    for(let i =frameData.initPos; i <= frameData.endPos; i++){
      let fd = frameData.getDataPos(i)
      if(fd !== 0) {
        const index = fd.findIndex(e=>e.id === entryId);
        if ( index >= 0){
          fd.splice(index,1)
          //frameData.setDataPos(fd,i);
        }
      }
    }

    setEntries(prev => {
      const index = prev.findIndex(e=>e.id === entryId)
      let newEntries = [...prev];
      newEntries.splice(index, 1)
      return newEntries;
    })
    channelList.emitDataChangedEvent();
    setUpdateUpload(true);
  }
  function updateEntries() {
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
    channelList.emitDataChangedEvent();
    setUpdateUpload(true);
  }

  async function handleUpdateSelectRect(boxRect){

    if(selectedEntry){
      const coords = selectedBoxRef.current.coords();
      if(selectedEntry.type.includes("op_") && !selectedEntry.type.includes("fix")){
        setSelectedEntry(prev=>{
          return {...prev, type: prev.type + "_fix"}
        })
      }
      updateDataFrameEntry(selectedEntry.frame, { ...selectedEntry, coords: coords})
      setEntries(getEntriesFromFrameData(filtered, selectedTypes));
    }
  }

  function handleChangeBoxIdsToRange() {


      const initPos = trackingSettings.initFrame
      const endPos = trackingSettings.endFrame
      const newBoxId = trackingSettings.toId
      for(let p = initPos; p <= endPos; p++){
        let frame = frameData.getDataPos(p);
        if (frame instanceof Array) {
          for (let r = 0; r < frame.length; r++) {
            let rect = frame[r];
            if(rect.boxId === trackingSettings.fromId){
              rect.boxId = newBoxId
            }
          }
        }
      }

      setEntries(getEntriesFromFrameData(filtered, selectedTypes));
      channelList.emitDataChangedEvent();

  }

  function handleSwitchBoxIdsToRange() {

    const initPos = trackingSettings.initFrame
    const endPos = trackingSettings.endFrame

    for(let p = initPos; p <= endPos; p++){
      let frame = frameData.getDataPos(p);
      if (frame instanceof Array) {
        for (let r = 0; r < frame.length; r++) {
          let rect = frame[r];
          if(rect.boxId === trackingSettings.fromId){
            rect.boxId = trackingSettings.toId
          }else if(rect.boxId === trackingSettings.toId){
            rect.boxId = trackingSettings.fromId
          }
        }
      }
    }

    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
    channelList.emitDataChangedEvent();

  }

  async function handleBlur(entry, blurFlag, refreshEntries = true){
    let fd = frameData.getDataPos(entry.frame)

    if(fd !== 0) {
      const index = fd.findIndex(e=>e.id === entry.id);
      if ( index >= 0){
        let frameEntries = fd.map((e) =>
            e.id === entry.id ? { ...e, unblurred: !blurFlag } : e
        )
        frameData.setDataPos(frameEntries, entry.frame);
      }else {
        console.log("Error, could not find entry");
      }
      if(refreshEntries)
        channelList.emitDataChangedEvent();
    }

    if(refreshEntries)
      setEntries(getEntriesFromFrameData(filtered, selectedTypes));

    if(selectedEntry && entry.id === selectedEntry.id){
      setSelectedEntry((prev) => ({
        ...prev,
        unblurred: !blurFlag
      }));
      selectedBoxRef.current._setBoxRect({
        visible: true,
        boxId: selectedEntry.boxId,
        unblurred: !blurFlag,
        x: selectedEntry.coords.x1 * scaleFactor + halfXDeadzone,
        y: selectedEntry.coords.y1 * scaleFactor + halfYDeadzone,
        width: (selectedEntry.coords.x2 - selectedEntry.coords.x1) * scaleFactor,
        height: (selectedEntry.coords.y2 - selectedEntry.coords.y1) * scaleFactor
      })
    }
  }
  ///
  async function handleUpdateBoxId(id, newBoxId, entry, refreshEntries = true){

    let fd = frameData.getDataPos(entry.frame)
    if(fd !== 0) {
      const index = fd.findIndex(e=>e.id === id);
      if ( index >= 0){
        frameData.setDataPos(fd.map((e) =>
            e.id === id ? { ...e, boxId: newBoxId } : e
        ), entry.frame);
      }else {
        console.log("Error, could not find entry");
      }
      if(refreshEntries)
        channelList.emitDataChangedEvent();
    }

    if(refreshEntries)
      setEntries(getEntriesFromFrameData(filtered, selectedTypes));

    if(selectedEntry && id === selectedEntry.id){
      setSelectedEntry((prev) => ({
        ...prev,
        boxId: newBoxId
      }));
      selectedBoxRef.current._setBoxRect({
        visible: true,
        boxId: newBoxId,
        x: selectedEntry.coords.x1 * scaleFactor + halfXDeadzone,
        y: selectedEntry.coords.y1 * scaleFactor + halfYDeadzone,
        width: (selectedEntry.coords.x2 - selectedEntry.coords.x1) * scaleFactor,
        height: (selectedEntry.coords.y2 - selectedEntry.coords.y1) * scaleFactor,
        unblurred: selectedEntry.unblurred
      })
    }
  }

  const handleBoxInteraction = (e) => {
    setMousePoint({ x: e.clientX, y: e.clientY});
  }

  function parseInput(input) {
    // Trim the input to remove any surrounding whitespace
    input = String(input).trim();

    // Check if the input is a single number
    if (!isNaN(input)) {
      return [parseFloat(input)]; // Return as an array with a single number
    }

    // Check if the input is a list of numbers separated by commas
    if (input.includes(',')) {
      const parts = input.split(',').map(part => part.trim());
      const numbers = [];

      for (let part of parts) {
        if (isNaN(part)) {
          throw new Error(`Invalid number: ${part}`);
        }
        if(!isNaN(parseFloat(part)))
          numbers.push(parseFloat(part));
      }

      return numbers;
    }

    // If the input is neither a single number nor a valid list of numbers
    throw new Error('Invalid input format');
  }

  const getEntriesFromFrameData = useCallback((filterEntries, selectedTypes) =>{
    if(frameData === null)
      return [];
    let dataArray = []

    let filteredEntries = filterEntries?parseInput(boxIdEntry):[];

    for (let p = frameData.initPos; p <= frameData.endPos; p += 1) {
      let fd = frameData.getDataPos(p);
      if(fd instanceof Array){
        if (filterEntries) {
          if(selectedTypes.length === 0){
            fd = fd.filter(entry =>   filteredEntries.includes(entry?.boxId));
          }else{
            fd = fd.filter(entry =>  selectedTypes.includes(entry?.type));
            fd = fd.filter(entry =>  filteredEntries.includes(entry?.boxId));
          }
        }
        dataArray = [...dataArray, ...fd]
      }
    }
    return dataArray

  },[boxIdEntry, frameData]);


  useEffect(() => {
    setEntries(getEntriesFromFrameData(filtered,selectedTypes))
    // eslint-disable-next-line
  }, [filtered, getEntriesFromFrameData]);


  const handleSetBoxIdSelectedAnnotation = () => {
    if(selectedAnnotation){
      const boxId = JSON.parse(selectedAnnotation.notes).boxId;
      const initPos = frameData.timeToPos(selectedAnnotation.timeStart);
      const newBoxId = getFirstBoxId(boxIdEntry);
      if(selectedAnnotation.category.type === ANNOTATION_TYPE.INSTANT.value){
        let frame = frameData.getDataPos(initPos);
        if (frame instanceof Array) {
          for (let r = 0; r < frame.length; r++) {
            let rect = frame[r];
            if(rect.boxId === boxId){
              rect.boxId = newBoxId
            }
          }
        }
      }else{
        const endPos = frameData.timeToPos(selectedAnnotation.timeEnd);
        for(let p = initPos; p <= endPos; p++){
          let frame = frameData.getDataPos(p);
          if (frame instanceof Array) {
            for (let r = 0; r < frame.length; r++) {
              let rect = frame[r];
              if(rect.boxId === boxId){
                rect.boxId = newBoxId
              }
            }
          }
        }
      }


      setEntries(getEntriesFromFrameData(filtered, selectedTypes));
      channelList.emitDataChangedEvent();
    }
  }

  const createCloneFrameData = (frameData) => {
    const clonedFrameData = new DataChannel("FrameData",frameData.freq,frameData.samplesPerFrame)
    for(let p = frameData.initPos; p <= frameData.endPos; p++) {
      let frame = frameData.getDataPos(p);
      if(!frameData.isZeroValue(frame)){
        clonedFrameData.setWritePos(p,!clonedFrameData.empty)
        clonedFrameData.setDataPos(_.cloneDeep(frame),p)
      }
    }
    clonedFrameData.empty = frameData.empty;
    return clonedFrameData;
  }

  const handleAddItem = (newItemName, newFrameData) => {
    if (newItemName.trim()) {

      if(newFrameData === undefined){
        const prev = frameDataHistory.find(f => f.value === selectedValue)
        if(prev){
          newFrameData = createCloneFrameData(prev.frameData)
        }else{
          newFrameData = createCloneFrameData(frameData)
        }
      }

      const newValue = crypto.randomUUID();
      const newItemObject = { listName: newItemName, value: newValue, frameData: newFrameData };
      setFrameDataHistory(prevFrameDataHistory =>[...prevFrameDataHistory, newItemObject]);
      setSelectedValue(newValue);
      setFrameData(newFrameData)

    }
  };

  const handleImportedFrames = (frames) => {
    const newEntries = []
    for(const frame of frames){

      newEntries.push({
        frame: frame.frame_id,
        coords: { x1: frame.top_left_x, x2: frame.bottom_right_x, y1: frame.top_left_y, y2: frame.bottom_right_y },
        id: (frame.id !== undefined)?frame.id:crypto.randomUUID(),
        boxId: (frame.boxId !== undefined)?frame.boxId:-1,
        type: frame.type,
        unblurred: frame.unblurred
      })
    }

    clearFrameDataEntries();
    addFrameDataEntries(newEntries);
    setEntries(getEntriesFromFrameData(filtered, selectedTypes));
  }

  const handleTypesChange = (e, { value }) => {
    setSelectedTypes(value);
  }

  const handleDeleteSelected = () => {

    for(let i =frameData.initPos; i <= frameData.endPos; i++){
      let fd = frameData.getDataPos(i)
      if(fd !== 0) {
        fd = fd.filter(e => !selectedRows.includes(e.id));
        frameData.setDataPos(fd, i);
      }
    }
    setSelectedRows([]);
    updateEntries();
  };


  function playPause() {
    if (video?.paused) {
      video.play();
    } else {
      video.pause();
    }
  }

  const withFocusCheck = (callback, isInputFocused) => (event) => {
    if (!isInputFocused) {
      callback(event);
    }
  };

  const handleSetAnnotationRange = () => {
    if(selectedAnnotation){
      const initPos = frameData.timeToPos(selectedAnnotation.timeStart);
      const endPos = frameData.timeToPos(selectedAnnotation.timeEnd);

      setTrackingSettings(t => ({ ...t, initFrame: initPos, endFrame: endPos?endPos:initPos }));
    }
  }

  const handleUpdateGraphs = useCallback(() => {
    if(regionSelectorAnnotations.current !== null)
      regionSelectorAnnotations.current.updateData(frameData)
  },[frameData,regionSelectorAnnotations])

  // Note that the instructions should be shown in the translations in RegionSelectorControls
  useKey("KeyQ", withFocusCheck(() => previousFrame(), isInputFocused));
  useKey("KeyW", withFocusCheck(() => nextFrame(), isInputFocused));
  useKey("KeyE", withFocusCheck(() => logFrame("keyframe"), isInputFocused));
  useKey("KeyT", withFocusCheck(() => logFrame("track"), isInputFocused));
  //useKey("KeyI", withFocusCheck(() => logInit(), isInputFocused));
  //useKey("KeyO", withFocusCheck(() => logEnd(), isInputFocused));
  useKey("Space", withFocusCheck(() => playPause(), isInputFocused));
  useKey("KeyB", withFocusCheck(() => handleToggleBoxTool(), isInputFocused));
  useKey("KeyS", withFocusCheck(() => handleToggleBoxSelect(), isInputFocused));
  useKey( "Escape", withFocusCheck(()=> handleDeselectTools(), isInputFocused))

  useKey("Digit1", withFocusCheck(() => setBoxIdEntry(1), isInputFocused));
  useKey("Digit2", withFocusCheck(() => setBoxIdEntry(2), isInputFocused));
  useKey("Digit3", withFocusCheck(() => setBoxIdEntry(3), isInputFocused));
  useKey("Digit4", withFocusCheck(() => setBoxIdEntry(4), isInputFocused));
  useKey("Digit5", withFocusCheck(() => setBoxIdEntry(5), isInputFocused));
  useKey("Digit6", withFocusCheck(() => setBoxIdEntry(6), isInputFocused));
  useKey("Digit7", withFocusCheck(() => setBoxIdEntry(7), isInputFocused));
  useKey("Digit8", withFocusCheck(() => setBoxIdEntry(8), isInputFocused));
  useKey("Digit9", withFocusCheck(() => setBoxIdEntry(9), isInputFocused));
  useKey("Digit0", withFocusCheck(() => setBoxIdEntry(0), isInputFocused));

  // This is portaled into the parent video component
  const portaledSelectorControls = <RegionSelectorControls
      video={video}
      entries={entries}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      fps={fps}
      setFPS={setFPS}
      boxIdEntry={boxIdEntry}
      setBoxIdEntry={setBoxIdEntry}
      setIsInputFocused={setIsInputFocused}
      calculatedFPSData={calculatedFPSData}
      currentFrame={currentFrame}
      frameDataHistory={frameDataHistory}
      setFrameDataHistory={setFrameDataHistory}
      setFrameData={setFrameData}
      frameData={frameData}
      selectedValue={selectedValue}
      setSelectedValue={setSelectedValue}
      selectedAnnotation={selectedAnnotation}
      updateEntries={updateEntries}
      filtered={filtered}
      error={regionError}
      trackingSettings={trackingSettings}
      setTrackingSettings={setTrackingSettings}
      selectedTypes={selectedTypes}
      boxTypes={boxTypes}
      boxesVisible={boxesVisible}
      setBoxesVisible={setBoxesVisible}
      boxToolActive={boxToolActive}
      boxSelectActive={boxSelectActive}
      handleAddItem={handleAddItem}
      handlePrevFrame={previousFrame}
      handleNextFrame={nextFrame}
      handleLogSingle={ () => {logFrame("keyframe")}}
      handleLogTrack={() => {logFrame("track")}}
      handleLogInit={logInit}
      handleLogEnd={logEnd}
      handleDownload={download}
      handleRemove={remove}
      handleUpdateBoxId={handleUpdateBoxId}
      handleInterpolateFrames={handleInterpolateFrames}
      handleGoToFrame={handleGoToFrame}
      handleFilteredToggle={() => setFiltered(!filtered)}
      handleToggleBoxTool={handleToggleBoxTool}
      handleToggleBoxSelect={handleToggleBoxSelect}
      handleImportedFrames={handleImportedFrames}
      handleTrackIds={handleTrackIds}
      handleSetBoxIdSelectedAnnotation={handleSetBoxIdSelectedAnnotation}
      handleSetBoxIdSelected={handleSetBoxIdSelected}
      handleBlurSelected={handleBlurSelected}
      handleDeleteSelected={handleDeleteSelected}
      handleToggleShowGraphs={ ()=> setShowGraphs(!showGraphs)}
      handleSwitchBoxIdsToRange={handleSwitchBoxIdsToRange}
      handleChangeBoxIdsToRange={handleChangeBoxIdsToRange}
      handleUpdateGraphs={handleUpdateGraphs}
      handleTypesChange={handleTypesChange}
      handleUpload={prepareDataForFileUpload}
      handleSetAnnotationRange={handleSetAnnotationRange}
  />

  const portaledTrackingGraphs = <RegionSelectorAnnotations
      t={t}
      ref={regionSelectorAnnotations}
      videoPlayerRef={videoPlayerRef}
      video={video}
      showGraphs={showGraphs}
      handleSetVideoPlayerAnnotations={handleSetVideoPlayerAnnotations}
    />

  return (
    <div
        className={"frame-area-selector"}
        onMouseMove={handleBoxInteraction}
        onMouseDown={handleMouseDown}
        style={{
          position:"absolute",
          left: 0,
          top: 0,
          width: videoRect.width,
          height: videoRect.height,
          zIndex: 999,
          pointerEvents: boxToolActive || boxSelectActive ? 'auto' : 'none'
        }}
    >
      {portalRegionSelectorToolsTarget.current && createPortal(portaledSelectorControls, portalRegionSelectorToolsTarget.current)}
      {portalRegionSelectorGraphsTarget.current && createPortal(portaledTrackingGraphs, portalRegionSelectorGraphsTarget.current)}
      <canvas
          ref={canvas}
          width={`${videoRect.width}px`}
          height={`${videoRect.height}px`}
          style={{position: 'absolute', left: 0, top: 0, zIndex:999}} />
      { boxToolActive  && (        <Box
          ref={boxRef}
          boxId={getFirstBoxId(boxIdEntry)}
          boxSelectActive={boxSelectActive}
          handle={handleBox}
          halfYDeadzone={halfYDeadzone}
          halfXDeadzone={halfXDeadzone}
          scaleFactor={scaleFactor}
          videoRect={videoRect}
          mousePoint={mousePoint}
          mouseDown={mouseDown}
      />)}
      { boxSelectActive && (        <Box
          ref={selectedBoxRef}
          boxSelectActive={boxSelectActive}
          handle={handleSelectBox}
          halfYDeadzone={halfYDeadzone}
          halfXDeadzone={halfXDeadzone}
          scaleFactor={scaleFactor}
          videoRect={videoRect}
          mousePoint={mousePoint}
          mouseDown={mouseDown}
          updateRect={handleUpdateSelectRect}
      />)}
    </div>
  )
});

export default withTranslation()(RegionSelector);
