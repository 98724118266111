import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";

class SubjectAllergyCreatePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      drugOptions: [],
      error: false,

      name: undefined,
    };
  }

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = () => {
    const payload = {
      name: this.state.name,
    };
    SubjectService.createAllergy(this.props.match.params.subjectId, payload)
      .then(this.onCancel)
      .catch((error) => {
        console.error(error);
        this.setState({ error: { ...error, submitted: this.state.name } });
      });
  };

  onCancel = () => {
    window.location.href =
      window.location.origin +
      "/app/subject/" +
      this.props.match.params.subjectId +
      "/tabs/allergies";
  };

  render() {
    const { t } = this.props;
    let errorMessage = undefined;
    if (this.state.error) {
      if (
        this.state.error.message.includes(
          "constraint [allergies_subjectid_name_unique]"
        )
      ) {
        errorMessage = t("ALLERGY_DUPLICATE_MESSAGE", {
          allergy: this.state.error.submitted,
        });
      } else {
        errorMessage = t("GLOBAL_ERROR_GENERIC_MESSAGE");
      }
    }

    const submitDisabled = !this.state.name || this.state.name.trim().length === 0;

    return (
      <Page
        name="ALLERGY_CREATE"
        header={t("ALLERGIES_CREATE_HEADER")}
        subheader={t("ALLERGIES_CREATE_SUBHEADER")}
      >
        <Form
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          error={this.state.error}
        >
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={errorMessage}
          />

          <Grid columns={"equal"}>
            <Grid.Column>
              <Segment>
                <Header>{t("ALLERGIES_CREATE_ALLERGY_SECTION_HEADER")}</Header>

                <Form.Field>
                  <Form.Input
                    label={t("ALLERGIES_NAME")}
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.inputChanged}
                    value={this.state.name}
                  />
                </Form.Field>
              </Segment>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  loading={this.state.submitting}
                  disabled={submitDisabled}
                  primary={!submitDisabled}
                >
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                {this.props.onCancel && (
                  <Button color={"black"} basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL")}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Page>
    );
  }
}

export default withTranslation()(SubjectAllergyCreatePage);
