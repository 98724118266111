import React, { useEffect, useState } from "react";
import { Button, Form, Header, Icon, Message, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import withError from "../hocs/withError";
import { compose } from "redux";
import withContainer from "../hocs/withContainer";
import AuthService from "../../../services/AuthService";
import SelectorDisplay from "../../additional/SelectorDisplay";
import withLabel from "../hocs/withLabel";
import SubjectService from "../../../SubjectService";
import LocalDataService from "../../../services/LocalDataService";

const DefaultAuthorise = ({
  question,
  value,
  changeValue,
  isReadOnly,
  t
}) => {

  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [authErrorMsg, setAuthErrorMsg] = useState("");

  const modalHeader = typeof question.config?.confirmPasswordHeader?.label !== 'object' ? question.config?.confirmPasswordHeader?.label : "";
  const controlLabel = typeof question.config?.checkbox?.label !== 'object' ? question.config?.checkbox?.label : "";

  useEffect(() => {
    setChecked(value ? true : false);
  }, [checked, value]);

  const authenticate = async () => {
    try {
      setLoading(true);
      const profile = await AuthService.getMyProfile()
      await AuthService.login(profile.email, password, AuthService.ACCOUNT_TYPE);
      setPassword("");
      if (LocalDataService.getAccountType() === "subject") {
        const subjectCode = await SubjectService.getSubjectCode()
        changeValue(subjectCode);
      } else {
        changeValue(profile.email);
      }
      setChecked(true);
      setOpen(false);
    } catch (e) {
      setChecked(false);
      setAuthErrorMsg(t("LOGIN_FAILED", "Incorrect Credentials"))
    } finally {
      setLoading(false);
    }
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const onCheckboxChange = async () => {
    if (!checked) {
      setOpen(true);
    } else {
      //Checked -> unchecked, clear checked and remove value
      setChecked(false);
      changeValue("");
    }
  }

  function getSelectorDisplay(isReadonly) {
    return (
      <SelectorDisplay
        key={question.code + "_checkbox"}
        onChange={onCheckboxChange}
        isCheckbox={true}
        checked={checked}
        label={controlLabel}
        disabled={isReadonly} />
    )
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >

        <Header content={modalHeader} />
        <Modal.Content>
          <Form onSubmit={authenticate}>
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              name="password"
              placeholder={t("GLOBAL_LABEL_PASSWORD", "Password")}
              type="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete={"off"}
            />
          </Form>
          {authErrorMsg && (
            <Message error textAlign="left">
              <Message.Content>
                {authErrorMsg}
              </Message.Content>
            </Message>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button color='grey' onClick={() => {
            setOpen(false);
            setAuthErrorMsg("")
          }}>
            <Icon name='remove' /> Cancel
          </Button>
          <Button loading={loading} primary onClick={authenticate}>
            <Icon name='checkmark' /> Submit
          </Button>
        </Modal.Actions>
      </Modal>
      <Form.Group inline>
        {!isReadOnly && (
          getSelectorDisplay(isReadOnly))}
        {isReadOnly && LocalDataService.getAccountType() === "staff" && (
          <Form.Input
            data-question-answer-value={value}
            type="text"
            name={question.code}
            id={question.code}
            value={value != null ? value : ""}
            disabled={true}
          />)}
        {isReadOnly && LocalDataService.getAccountType() === "subject" && (
          getSelectorDisplay(isReadOnly))}
      </Form.Group>

    </>
  );
};

const enhance = compose(withContainer, withTranslation(), withLabel, withError);

export default enhance(DefaultAuthorise);
