import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";

const AdminDataSegregationService = {
  reconcile: (entityName, entityId) => {
    const requestBody = { entityName, entityId };
    return RequestHelper.send(
      serverAddress + "/data-segregation/admin/reconcile",
      {},
      "POST",
      null,
      requestBody,
    );
  }
}

export default AdminDataSegregationService;