import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

const ToolButton = ({ onClick, primary, disabled, iconName, tooltip, buttonText, width, iconSize = 'large'}) => (
    <Popup
        content={tooltip}
        trigger={
            <Button
                fluid
                onClick={onClick}
                primary={primary}
                disabled={disabled}
                icon
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',maxWidth: width ? width : '80px' }}
            >
                <Icon name={iconName} size={iconSize} />
                <span style={{ marginLeft: '0.5em', whiteSpace: 'nowrap' }}>{buttonText}</span>
            </Button>
        }
    />
);

export default ToolButton;
