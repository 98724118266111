import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Dropdown, Form, Input, Loader, Popup, Label, Icon } from "semantic-ui-react";
import ConfigContext from "../../context/ConfigContext";
import StaffService from "../../StaffService";
import ConfirmButton from "../dashboard/ConfirmButton";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import MandatoryTraining from "../../pages/roles/MandatoryTraining";
import { PERMISSION_DEFAULT_TRANSLATION } from "../../GroupPermission";


function RoleEditComponent({ t, trial, onSubmit, roleToEdit }) {

  const config = useContext(ConfigContext);
  let showFeedbackOnSubmit = config.audit?.captureGroupAlternationFeedback;

  const [formRole, setFormRole] = useState(roleToEdit);
  const [permissionOptions, setPermissionOptions] = useState([{ key: 1, text: 'Empty', value: '', hoverText: 'Empty' }]);
  const [roleOptions, setRoleOptions] = useState([{ key: 1, text: 'Empty', value: '' }]);
  const [usedRoleCodes, setUsedRoleCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dropdownSearch, setDropdownSearch] = useState('');


  useEffect(() => {
    const fetchData = async () => {
      const getRoleCodes = async () => {
        await StaffService.getRolesForSuperAdminStaff()
          .then(staffRoles => {
            //Generate list of codes used for validation
            let roleCodes = staffRoles.map(role => {
              return role.code
            }).filter(role => {
              //remove the currently editing role code from 'used codes' validation list
              //as the current roleToEdit.code would be a valid code when editing a role.
              return role !== roleToEdit.code
            });
            setUsedRoleCodes(roleCodes);

            //Generate list of codes used in override Dropdown
            setRoleOptions(
              staffRoles.map(role => {
                return { key: role.code, text: role.label, value: role.code }
              })
            );

          });
      }
      await getRoleCodes();

      const permissionsToHide = config.role?.permissionsToHide || [];

      //Replaced server call
      const permissionsMap = Object.keys(PERMISSION_DEFAULT_TRANSLATION)
        .filter(p => !permissionsToHide?.includes(p))
        .map(p => {
          const permissions = PERMISSION_DEFAULT_TRANSLATION[p];
          const textLabel = t(
            permissions.labelTranslation ? permissions.labelTranslation.translationKey : 'Not Found',
            permissions.labelTranslation ? permissions.labelTranslation.label : 'Not Found'
          )
          const textHover = t(
            permissions.hoverDescription ? permissions.hoverDescription.translationKey : 'Not Found',
            permissions.hoverDescription ? permissions.hoverDescription.hover : 'Not Found'
          )
          return {
            key: p,
            text: textLabel,
            value: p,
            hovertext: textHover
          };
        });

      setPermissionOptions(permissionsMap);

      setFormRole(roleToEdit);
      setLoading(false);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleToEdit, t]);

  const handleSubmit = async (feedback) => {

    let mandatoryTraining = [];
    if (formRole.mandatoryTraining != null) {
      mandatoryTraining = formRole.mandatoryTraining.filter(value => !Number.isNaN(value));
    }

    const newRole = {
      ...formRole,
      trialId: trial.id,
      code: formRole.code.trim(),
      label: formRole.label.trim(),
      createRolesOverride: formRole.createRolesOverride,
      permissions: formRole.permissions,
      mandatoryTraining: mandatoryTraining
    };
    await onSubmit(newRole, feedback);
  }

  function onChange(e, item) {
    e.preventDefault();
    setFormRole((oldRole) => {
      const newRole = { ...oldRole, [e.target.name]: e.target.value };
      return newRole;
    });
  }

  function onDropdownChange(e, item, dropDownField) {
    setFormRole((oldRole) => {
      const newRole = { ...oldRole, [dropDownField]: item.value };
      return newRole;
    });
  }

  //New permissions dropdown selection and search functions/logic
  const addPermission = (permissionCode) => {
    const newAnswer = [...formRole.permissions, permissionCode];
    setFormRole((oldRole) => {
      const newRole = { ...oldRole, permissions: newAnswer };
      return newRole;
    });
  }

  const removePermission = (permissionCode) => {
    const newAnswer = formRole.permissions.filter(code => code !== permissionCode);
    setFormRole((oldRole) => {
      const newRole = { ...oldRole, permissions: newAnswer };
      return newRole;
    });
  }

  const filteredOptions = permissionOptions.filter(option =>
    !formRole.permissions.includes(option.value) &&
    option.text.toLowerCase().includes(dropdownSearch.toLowerCase())
  );

  const handleSearchChange = (e, { value }) => {
    setDropdownSearch(value);
  };
//-------------------------------

  function isRoleCodeValid(role) {
    //should not have been previously used
    if (usedRoleCodes.includes(role.code)) {
      return false;
    }
    //Code should not contain comma
    if (role.code.indexOf(",") !== -1) {
      return false;
    }
    return true;
  }

  function isOverridesValid(role) {
    const maxLen = 300;
    let fieldLen = 0;
    if (role.creatableRolesOverride !== null) {
      role.creatableRolesOverride.forEach((overrideCode) => {
        fieldLen += overrideCode.length;
      });
    }
    if (fieldLen > maxLen) {
      return false;
    }
    return true;
  }

  function isValid() {
    if (!loading) {
      if (formRole.code.trim().length === 0) {
        return false;
      }
      if (!isRoleCodeValid(formRole)) {
        return false;
      }
      if (!isOverridesValid(formRole)) {
        return false;
      }
      if (formRole.label.trim().length === 0) {
        return false;
      }
      if (formRole.permissions.length === 0) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  return (
    <>
      {loading && (<Loader active={loading} />)}
      {!loading && (<Form>
        <Form.Field>
          <label>{t("ROLE_EDIT_CODE", "Code")}</label>
          <Input
            name="code"
            value={formRole.code}
            onChange={onChange}
            error={!isRoleCodeValid(formRole)}
            maxLength="255"
          />
        </Form.Field>
        <Form.Field>
          <label>{t("ROLE_EDIT_LABEL", "Label")}</label>
          <input name="label"
            value={formRole.label}
            onChange={onChange}
            maxLength="10485760" />
        </Form.Field>
        <Form.Field>
          <label>{t("ROLE_EDIT_OVERRIDE", "Roles Override")}</label>
          <Dropdown
            placeholder={t("ROLE_EDIT_OVERRIDE_DROPDOWN_PLACEHOLDER", "Roles")}
            fluid
            multiple
            selection
            error={!isOverridesValid(formRole)}
            options={roleOptions}
            value={formRole.creatableRolesOverride}
            onChange={(e, item) => { onDropdownChange(e, item, 'creatableRolesOverride') }}
          />
        </Form.Field>
        <Form.Field>
          <MandatoryTraining
            formRole={formRole}
            onChange={(e, item) => { onDropdownChange(e, item, 'mandatoryTraining') }}
          />
        </Form.Field>
        <Form.Field>
          <label>{t("ROLE_EDIT_PERMISIONS", "Permissions")}</label>
          <div style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            backgroundColor: 'white'
          }}>
            {formRole.permissions.length > 0 && formRole.permissions.map(pKey => (
              <Label
                key={pKey}
                size='large'
                style={{ margin: '3px', padding: '0.35714286em 0.78571429em' }}>
                <Popup
                  content={permissionOptions.find(p => p.key === pKey)?.hovertext}
                  mouseEnterDelay={500}
                  mouseLeaveDelay={500}
                  on='hover'
                  position='right center'
                  hoverable
                  trigger={<span>{permissionOptions.find(p => p.key === pKey)?.text}</span>}
                />
                <Icon name='delete'
                  onClick={() => { removePermission(pKey) }}
                />
              </Label>
            ))}
          </div>
          <Dropdown
            fluid
            multiple
            selection
            search
            searchInput={
              <Input
                icon='search'
                iconPosition='right'
                placeholder={t("ROLE_EDIT_PERMISSIONS_DROPDOWN_PLACEHOLDER", "Select Permissions")}
                value={dropdownSearch}
                onChange={handleSearchChange}
                style={{ width: '700px' }}
              />
            }
          >
            <Dropdown.Menu>
              {filteredOptions.map(option => (
                <Dropdown.Item
                  onClick={() => { addPermission(option.value) }}
                  key={option.key}
                >
                  <Popup
                    content={option.hovertext}
                    position='right center'
                    hoverable
                    trigger={<span>{option.text}</span>}
                  />
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Field>
        <Form.Field>
          {showFeedbackOnSubmit ? (
            <ConfirmButtonWithFeedback
              buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
              headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
              contentText={t(
                "ROLE_EDIT_DETAILED",
                "Please give a reason why this is being changed and confirm."
              )}
              confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
              cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
              onConfirm={handleSubmit}
              placeholderText={t("ROLE_EDIT_PLACEHOLDER_TEXT", "Reason")}
              mandatoryValidationText={t(
                "ROLE_EDIT_VALIDATION_TEXT",
                "Please supply a reason for the change."
              )}
              disabled={!isValid()}
              color={isValid() ? "orange" : "grey"}
            />
          ) : (
            <ConfirmButton
              buttonText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
              headerText={t("GLOBAL_BUTTON_SUBMIT", "Submit")}
              contentText={t(
                "ROLE_EDIT_DETAILED_NO_FEEDBACK",
                "Please confirm that you want to make this change"
              )}
              confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
              cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
              onConfirm={handleSubmit}
              disabled={!isValid()}
              color={isValid() ? "orange" : "grey"}
            />
          )}
        </Form.Field>
      </Form>)}
    </>
  );
}

export default withTranslation()(RoleEditComponent);
