// IMPORTANT
// These values cannot have underscores in their name
// as they are used as question codes.

const BODY_PARTS = {
  FRONT_HEAD: 'FRONT_HEAD',
  FRONT_UPPERARM: 'FRONT_UPPERARM',
  FRONT_ELBOW: 'FRONT_ELBOW',
  FRONT_LOWERARM: 'FRONT_LOWERARM',
  FRONT_HANDWRIST: 'FRONT_HANDWRIST',
  FRONT_FINGERS: 'FRONT_FINGERS',
  FRONT_UPPERLEG: 'FRONT_UPPERLEG',
  FRONT_KNEE: 'FRONT_KNEE',
  FRONT_LOWERLEG: 'FRONT_LOWERLEG',
  FRONT_ANKLE: 'FRONT_ANKLE',
  FRONT_FOOT: 'FRONT_FOOT',
  FRONTASYM_HEAD: 'FRONTASYM_HEAD',
  FRONTASYM_CHEST: 'FRONTASYM_CHEST',
  FRONTASYM_SPINE: 'FRONTASYM_SPINE',
  FRONTASYM_ABDOMEN_LEFT: 'FRONTASYM_ABDOMEN_LEFT',
  FRONTASYM_ABDOMEN_RIGHT: 'FRONTASYM_ABDOMEN_RIGHT',
  FRONTASYM_SHOULDER_LEFT: 'FRONTASYM_SHOULDER_LEFT',
  FRONTASYM_SHOULDER_RIGHT: 'FRONTASYM_SHOULDER_RIGHT',
  FRONTASYM_UPPERARM_LEFT: 'FRONTASYM_UPPERARM_LEFT',
  FRONTASYM_UPPERARM_RIGHT: 'FRONTASYM_UPPERARM_RIGHT',
  FRONTASYM_ELBOW_LEFT: 'FRONTASYM_ELBOW_LEFT',
  FRONTASYM_ELBOW_RIGHT: 'FRONTASYM_ELBOW_RIGHT',
  FRONTASYM_LOWERARM_LEFT: 'FRONTASYM_LOWERARM_LEFT',
  FRONTASYM_LOWERARM_RIGHT: 'FRONTASYM_LOWERARM_RIGHT',
  FRONTASYM_HANDWRIST_LEFT: 'FRONTASYM_HANDWRIST_LEFT',
  FRONTASYM_HANDWRIST_RIGHT: 'FRONTASYM_HANDWRIST_RIGHT',
  FRONTASYM_FINGERS_LEFT: 'FRONTASYM_FINGERS_LEFT',
  FRONTASYM_FINGERS_RIGHT: 'FRONTASYM_FINGERS_RIGHT',
  FRONTASYM_UPPERLEG_LEFT: 'FRONTASYM_UPPERLEG_LEFT',
  FRONTASYM_UPPERLEG_RIGHT: 'FRONTASYM_UPPERLEG_RIGHT',
  FRONTASYM_KNEE_LEFT: 'FRONTASYM_KNEE_LEFT',
  FRONTASYM_KNEE_RIGHT: 'FRONTASYM_KNEE_RIGHT',
  FRONTASYM_LOWERLEG_LEFT: 'FRONTASYM_LOWERLEG_LEFT',
  FRONTASYM_LOWERLEG_RIGHT: 'FRONTASYM_LOWERLEG_RIGHT',
  FRONTASYM_ANKLE_LEFT: 'FRONTASYM_ANKLE_LEFT',
  FRONTASYM_ANKLE_RIGHT: 'FRONTASYM_ANKLE_RIGHT',
  FRONTASYM_FOOT_LEFT: 'FRONTASYM_FOOT_LEFT',
  FRONTASYM_FOOT_RIGHT: 'FRONTASYM_FOOT_RIGHT'
};

export const convertToStandardAnatomyFormat = unknownKeyFormat => unknownKeyFormat.replaceAll('-','_').toUpperCase()

export default BODY_PARTS;
