import React, { useState } from "react";
import { Accordion, Button, Icon } from "semantic-ui-react";
import useParentQuestionnaireInformation from "../hooks/useParentQuestionnaireInformation";
import DateTimeService from "../services/DateTimeService";
import ReadOnlyQuestionnaire from "./ReadOnlyQuestionnaire";

export default function ParentQuestionnaireDisplay({
  questionnaire,
  subjectId,
  allDefinitions,
  shouldShow,
  shouldUseAltDisplay,
  setShouldUseAltDisplay,
}) {
  const [isLoading, parentQuestionnaireArray, permissions] =
    useParentQuestionnaireInformation(questionnaire, subjectId, allDefinitions);

  const [activeIndex, setActiveIndex] = useState(0);
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  if (!isLoading && parentQuestionnaireArray && shouldShow) {
    return (
      <Accordion styled>
        {parentQuestionnaireArray.map(
          ({ parentQuestionnaire, parentDefinition }, index) => {
            return (
              <>
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={handleClick}
                  style={{ display: "flex", alignItems: "baseline" }}
                >
                  <Icon name="dropdown" />
                  <div>{`${
                    parentDefinition.label
                  } - ${DateTimeService.build.asDate(
                    parentQuestionnaire.completionDate
                  )}`}</div>
                  {index === 0 && (
                    <div
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Button
                        style={{
                          boxShadow: "none",
                          alignSelf: "flex-end",
                          padding: "0.2rem 1rem",
                          backgroundColor: "transparent",
                        }}
                        basic
                        color={"black"}
                        onClick={(e) => {
                          e.stopPropagation();
                          setShouldUseAltDisplay((oldValue) => !oldValue);
                        }}
                      >
                        <Icon
                          style={{
                            margin: 0,
                            fontSize: "1.2rem",
                          }}
                          name={
                            shouldUseAltDisplay
                              ? "angle double right"
                              : "angle double left"
                          }
                        />
                      </Button>
                    </div>
                  )}
                </Accordion.Title>
                <Accordion.Content
                  style={{
                    maxHeight: shouldUseAltDisplay ? undefined : "75vh",
                    overflowY: "scroll",
                  }}
                  active={activeIndex === index}
                >
                  <ReadOnlyQuestionnaire
                    definition={parentDefinition}
                    questionnaire={parentQuestionnaire}
                    subjectId={subjectId}
                    permissions={permissions}
                    isStaff={true}
                  />
                </Accordion.Content>
              </>
            );
          }
        )}
      </Accordion>
    );
  }

  return null;
}
