import { DateTime } from "luxon";
import {
  setQuestionnaires,
  setCompletedQuestionnaires,
  setTimezone,
} from "../redux/questionnaires/subjectQuestionnairesSlice";
import RequestHelper from "../RequestHelper";
import SubjectQuestionnaireService from "./SubjectQuestionnaireService";
import {serverAddress} from "./config/EnvConfig";

const SubjectAppStateService = {
  //TODO: change to questionniares
  getSubjectQuestionnairesFromServer: async (dispatch) => {
    const questionnaires = await Promise.all([
      SubjectQuestionnaireService.getQuestionnaires("pros"),
      SubjectQuestionnaireService.getQuestionnaires("content"),
      SubjectQuestionnaireService.getQuestionnaires("events"),
      SubjectQuestionnaireService.getQuestionnaires("data"),
      SubjectAppStateService.getSubjectTimezone(),
    ]);

    dispatch(setTimezone(questionnaires[4]));
    dispatch(
      setQuestionnaires({
        pros: questionnaires[0],
        contents: questionnaires[1],
        events: questionnaires[2],
        datas: questionnaires[3],
      })
    );
  },

  getSubjectCompletedQuestionnairesFromServer: async (dispatch) => {
    Promise.all([
      SubjectQuestionnaireService.getCompletedQuestionnaires("pro"),
      SubjectQuestionnaireService.getCompletedQuestionnaires("content"),
      SubjectQuestionnaireService.getCompletedQuestionnaires("event"),
      SubjectQuestionnaireService.getCompletedQuestionnaires("data"),
    ]).then( (questionnaires) => {
      dispatch(
          setCompletedQuestionnaires({
            pros: questionnaires[0],
            contents: questionnaires[1],
            events: questionnaires[2],
            datas: questionnaires[3],
          })
      );
    });

  },
  getSubjectTimezone: async () => {
    let timezone;
    try {
      timezone = await RequestHelper.send(
        serverAddress + "/subjects/timezone",
        {},
        "GET",
        null
      );
    } catch {
      console.warn(
        "[SubjectAppStateService][getSubjectTimezone] unable to get sbject timezone database value."
      );
    }
    if (!timezone) {
      timezone = DateTime.now().zoneName;
    }
    return timezone;
  },
};

export default SubjectAppStateService;
