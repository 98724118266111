import React, { useContext, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { Modal } from "semantic-ui-react";
import ConfigContext from "../../context/ConfigContext";
import Questionnaire from "../../questionnaires/Questionnaire";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { getQuestionnaires } from "../../redux/questionnaires/subjectQuestionnairesSlice";
import SubjectAppStateService from "../../services/SubjectAppStateService";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import QuestionnaireEnd from "../questionnaire/QuestionnaireEnd";
import { filterCompletedQuestionnaires } from "../../helpers/completedQuestionnairesHelper";
import SingleQuestionnaireDisplay from "./SingleQuestionnaireDisplay";
import EmptyDisplay from "./EmptyDisplay";
import ReadOnlyQuestionnaire from "../../questionnaires/ReadOnlyQuestionnaire";
import ThemeService from "../../services/ThemeService";

const SubjectQuestionnaireList = (props) => {
  const {
    title,
    questionnaireList,
    definitionList,
    allQuestionnaires,
    allDefinitions,
    sort,
    showEmpty,
  } = props;

  const dispatch = useDispatch();

  const sortFn = sort
    ? sort
    : () => {
        return 0;
      };

  let [questionnaire, setQuestionnaire] = useState(null);
  let [questionnaireDefinition, setQuestionnaireDefinition] = useState(null);
  let [isModalOpen, setIsModalOpen] = useState(false);
  let [isCurrentQuestionnaireCompleted, setIsCurrentQuestionnaireCompleted] =
    useState(false);

  let [linkingInfo, setLinkingInfo] = useState({
    isLinkValid: false,
    questionnaire: null,
    definition: null,
  });

  const config = useContext(ConfigContext);

  let filteredQuestionnaireList = null;
  if (questionnaireList !== null && questionnaireList.length !== undefined) {
    filteredQuestionnaireList = filterCompletedQuestionnaires(
      questionnaireList ? questionnaireList : [],
      definitionList,
      config.data
    );
  }

  const showQuestionnaire = async (questionnaire, definition) => {
    await setQuestionnaire(questionnaire);
    await setQuestionnaireDefinition(definition);
    setLinkingInfo(getLinkingInfo(definition));
    setIsModalOpen(true);
    setIsCurrentQuestionnaireCompleted(false);
  };

  const hideQuestionnaire = () => {
    if (isCurrentQuestionnaireCompleted) {
      SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
      SubjectAppStateService.getSubjectCompletedQuestionnairesFromServer(dispatch);
      setIsCurrentQuestionnaireCompleted(false);
    }

    setIsModalOpen(false);
  };

  const submitQuestionnaire = async (e) => {
    await SubjectQuestionnaireService.submitQuestionnaire(
      questionnaireDefinition,
      questionnaire,
      e
    );

    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
    SubjectAppStateService.getSubjectCompletedQuestionnairesFromServer(dispatch);
    setIsCurrentQuestionnaireCompleted(true);
  };

  const getLinkingInfo = (questionnaireDefinition) => {
    if (questionnaireDefinition?.config?.to) {
      let [type, code] = questionnaireDefinition.config.to.split("://");
      try {
        let foundDefinition = allDefinitions.find((def) => {
          return code === def.code && type.toUpperCase() === def.type;
        });

        let foundQuestionnaire = null;
        if (type !== "event") {
          foundQuestionnaire = allQuestionnaires.find(
            (q) => q.definitionId === foundDefinition.id
          );
        }

        return {
          isLinkValid: true,
          questionnaire: foundQuestionnaire,
          definition: foundDefinition,
        };
      } catch {
        console.error(
          "[SubjectQuestionnaireTable] Linking questionnaire not found"
        );
        return { isLinkValid: false, questionnaire: null, definition: null };
      }
    } else {
      return { isLinkValid: false, questionnaire: null, definition: null };
    }
  };

  // Seems like a costly check for such a simple thing
  const commonType = definitionList.every(
    (def) => def.type === definitionList[0].type
  )
    ? definitionList[0].type
    : null;

  const buildQuestionnaireItems = (questionnaires, definitions, onClick) => {

    const questionnairesWithSequence = questionnaires.map((q) => {
      let def = definitions.find((d) => d.id === q.definitionId);
      return ({
        ...q,
        sequence: def.sequence
      })
    });

    return questionnairesWithSequence.sort(sortFn).map((q) => {
      let def = definitions.find((d) => d.id === q.definitionId);
      if (!def) {
        return null;
      }

      return (
        <SingleQuestionnaireDisplay
          key={q.id}
          questionnaire={q}
          definition={def}
          action={(q, d) => onClick(q, d)}
          link=""
        />
      );
    });
  };

  const buildDefinitionItems = (definitions, onClick) => {
    return definitions
      .sort(sortFn)
      .map((def) => (
        <SingleQuestionnaireDisplay
          key={def.code}
          definition={def}
          action={(q, d) => onClick(null, d)}
          link=""
        />
      ));
  };

  let rows;
  if (filteredQuestionnaireList !== null) {
    rows = buildQuestionnaireItems(
      filteredQuestionnaireList,
      definitionList,
      showQuestionnaire
    );
  } else {
    rows = buildDefinitionItems(definitionList, showQuestionnaire);
  }

  const QuestionnaireComponent =
    questionnaire && questionnaire.completionDate !== null
      ? ReadOnlyQuestionnaire
      : Questionnaire;

  return (
    <div>
      {((filteredQuestionnaireList && filteredQuestionnaireList.length !== 0) ||
        showEmpty) &&
        title && (
          <h2 style={{ paddingTop: "3rem", paddingBottom: "2rem" }}>{title}</h2>
        )}
      {rows.length > 0 && <div>{rows}</div>}
      {showEmpty && rows.length === 0 && <EmptyDisplay type={commonType} />}
      <Modal
        key={"modal_" + questionnaireDefinition?.code}
        size={"small"}
        open={isModalOpen}
        onOpen={() => {
          setIsModalOpen(true);
        }}
        onClose={hideQuestionnaire}
      >
        <Modal.Content scrolling style={{...ThemeService.getStyle()}}>
          <div style={{ padding: "1rem" }}>
            {questionnaireDefinition && !isCurrentQuestionnaireCompleted && (
              <QuestionnaireComponent
                definition={questionnaireDefinition}
                questionnaire={questionnaire}
                onSubmit={submitQuestionnaire}
                isStaff={false}
              />
            )}
            {isCurrentQuestionnaireCompleted && (
              <QuestionnaireEnd
                  definition={questionnaireDefinition}
                  questionnaire={questionnaire}
                  onFinish={hideQuestionnaire}
                  onLink={showQuestionnaire}
                  linkingInfo={linkingInfo}
              />
            )}
          </div>
        </Modal.Content>
      </Modal>
    </div>
  );
};

const mapStatetoProps = (state) => {
  return {
    allQuestionnaires: getQuestionnaires(state),
    allDefinitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStatetoProps));

export default enhance(SubjectQuestionnaireList);
