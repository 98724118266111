import { memo, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Header } from 'semantic-ui-react';
import StaffService from '../../StaffService';
import ConfirmButton from '../dashboard/ConfirmButton';
import GeneralHelpers from '../../helpers/GeneralHelpers';
import { sortByLabel } from '../../helpers/sortFunctions';
import { genericCodeAndLabelToDropDownOption } from '../../helpers/mapFunctions';

const SELECT_ALL = 'selectAll';

const StaffGroupRoleMapping = ({
  t,
  allGroups,
  selectedGroupRoles: passedSelectedGroupRoles,
  onSelectionUpdate
}) => {
  const [groupRoleSelections, setGroupRoleSelections] = useState();

  const [rolesForCreation, setRolesForCreation] = useState();
  const [availableGroups, setAvailableGroups] = useState();
  const [selectedNewGroups, setSelectedNewGroups] = useState();

  const selectionDataTransformers = {
    fromIncoming: (data) => {
      return data?.map(group => {
        return {
          code: group.group,
          label: allGroups?.find(ag => ag.code === group.group).label,
          roles: group.roles.map(roleCode => {
            return {
              code: roleCode,
              label: rolesForCreation?.find(ar => ar.code === roleCode).label,
            }
          }).sort(sortByLabel)
        };
      }).sort(sortByLabel)
    },
    toOutgoing: (data) => {
      return data?.map(group => {
        return {
          group: group.code,
          roles: group.roles.map(r => r.code)
        };
      })
    }
  };

  useEffect(() => {
    if (allGroups == null) {
      return;
    }
    const populateRoles = async () => {
      const groupCodes = allGroups.map(g => g.code);
      const items = await StaffService.getRolesForStaffCreationByGroupCodes(groupCodes);
      setRolesForCreation(items.sort(sortByLabel));
    }
    populateRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups]);

  useEffect(() => {
    if ((allGroups == null) || (rolesForCreation == null)) {
      return;
    }
    const transformed = selectionDataTransformers.fromIncoming(passedSelectedGroupRoles);
    setGroupRoleSelections(transformed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, rolesForCreation]);

  useEffect(() => {
    if ((allGroups == null) || (groupRoleSelections == null)) {
      return;
    }

    const items = [...allGroups]
      .filter(g => !groupRoleSelections.map(group => group.code).includes(g.code));
    if (items.length !== 0) {
      items.unshift({ code: SELECT_ALL, label: t('STAFF_FORM_SELECT_ALL', 'Select All') });
    }
    setAvailableGroups(items);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups, groupRoleSelections]);

  const updateGroupRoleSelections = (modifierFunction) => {
    const updateGroupRoleSelections = [...groupRoleSelections];
    modifierFunction(updateGroupRoleSelections);
    setGroupRoleSelections(updateGroupRoleSelections);

    const selectedGroupRolesForOutput = selectionDataTransformers.toOutgoing(updateGroupRoleSelections);
    onSelectionUpdate && onSelectionUpdate(selectedGroupRolesForOutput);
  }

  const handleRemoveGroupClick = (groupCode) => {
    const indexToRemove = groupRoleSelections.findIndex(group => group.code === groupCode);
    if (indexToRemove === -1) {
      return;
    }
    updateGroupRoleSelections((data) => {
      data.splice(indexToRemove, 1);
    });
  }

  const handleRoleSelectionChange = (_e, groupCode, data) => {
    const selectedRolesValue = data.value?.map(roleCode => {
      return {
        code: roleCode,
        label: rolesForCreation?.find(ar => ar.code === roleCode).label,
      }
    });

    updateGroupRoleSelections((data) => {
      data.find(group => group.code === groupCode).roles = selectedRolesValue
    });
  };

  const handleSelectedNewGroupAdd = (_e, data) => {
    let newSelection = [];

    if (data.value.includes(SELECT_ALL)) {
      newSelection = availableGroups
        .filter(g => g.code !== SELECT_ALL)
        .map(g => g.code);
    } else {
      newSelection = data.value;
    }

    setSelectedNewGroups({ ...selectedNewGroups, groups: newSelection });
  };

  const handleSelectedNewGroupRoleAdd = (_e, data) => {
    setSelectedNewGroups({ ...selectedNewGroups, roles: data.value });
  }

  const handleAddNewClick = (e) => {
    GeneralHelpers.stopEvent(e);

    const newRoles = selectedNewGroups.roles
      .map(roleCode => {
        return {
          code: roleCode,
          label: rolesForCreation?.find(ar => ar.code === roleCode).label
        }
      })
      .sort(sortByLabel);

    const newGroupsWithRoles = selectedNewGroups.groups
      .filter(groupCode => !groupRoleSelections.map(group => group.code).includes(groupCode))
      .map(groupCode => {
        return {
          code: groupCode,
          label: allGroups?.find(ag => ag.code === groupCode).label,
          roles: [...newRoles]
        }
      });

    updateGroupRoleSelections((data) => {
      setSelectedNewGroups();
      newGroupsWithRoles.forEach(element => {
        data.push(element);
      });
      data.sort(sortByLabel);
    });
  }

  const hasAllData = groupRoleSelections != null && availableGroups != null;
  if (!hasAllData) {
    return null;
  }

  return (
    <>
      <Header style={{ marginBottom: 10 }}>{t('STAFF_FORM_GROUP_ROLES_HEADER', 'Site Roles')}</Header>

      <Form.Group key='addNew'>
        <Form.Field width={6}>
          <label>{t('STAFF_FORM_GROUP_DROPDOWN_LABEL', 'Site')}</label>
          <Dropdown
            placeholder={t('STAFF_FORM_GROUP_DROPDOWN_PLACEHOLDER', 'Select site')}
            fluid
            multiple
            selection
            options={availableGroups.map(genericCodeAndLabelToDropDownOption)}
            onChange={handleSelectedNewGroupAdd}
            value={selectedNewGroups?.groups ?? []}
            disabled={!availableGroups?.length}
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>{t('STAFF_FORM_ROLES_DROPDOWN_LABEL', 'Roles')}</label>
          <Dropdown
            placeholder={t('STAFF_FORM_ROLES_DROPDOWN_PLACEHOLDER', 'Select roles')}
            fluid
            multiple
            selection
            options={!selectedNewGroups?.groups?.length ? [] : rolesForCreation.map(genericCodeAndLabelToDropDownOption)}
            onChange={handleSelectedNewGroupRoleAdd}
            value={selectedNewGroups?.roles ?? []}
            disabled={!selectedNewGroups?.groups?.length}
          />
        </Form.Field>
        <Form.Field width={4}>
          <label>&nbsp;</label>
          <Button
            style={{ height: 35 }}
            onClick={handleAddNewClick}
            disabled={!selectedNewGroups?.roles?.length}
            color={!selectedNewGroups?.roles?.length ? 'grey' : 'orange'}
          >
            {t('GLOBAL_BUTTON_ADD', 'Add')}
          </Button>
        </Form.Field>
      </Form.Group>

      {groupRoleSelections.map(group => (
        <Form.Group key={group.code}>
          <Form.Field style={{ width: '100%' }}>
            <ConfirmButton
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              color='red'
              size='mini'
              onConfirm={() => handleRemoveGroupClick(group.code)}
              floated='right'
              buttonText={t('GLOBAL_BUTTON_REMOVE', 'Remove')}
              preventDefault={true}
            />
            <label style={{ marginBottom: 15 }}>{group.label}</label>
            <Dropdown
              placeholder={t('STAFF_FORM_ROLES_DROPDOWN_PLACEHOLDER', 'Select roles')}
              fluid
              multiple
              selection
              options={rolesForCreation.map(genericCodeAndLabelToDropDownOption)}
              onChange={(e, data) => handleRoleSelectionChange(e, group.code, data)}
              value={group.roles.map(role => role.code)}
            />
          </Form.Field>
        </Form.Group>
      ))}
    </>
  );
};

export default memo(withTranslation()(StaffGroupRoleMapping));
