import React, { useState, useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-moment";
import { Line, Scatter, Bar } from "react-chartjs-2";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Button, Dropdown } from "semantic-ui-react";
import SubjectService from "../../../../SubjectService";
import ChartService from "../../../../services/ChartService";
import DateTimeService from "../../../../services/DateTimeService";
import { usePapaParse } from "react-papaparse";
import { saveAs } from "file-saver";

const CHART_TYPES = {
  LINE: "LINE",
  SCATTER: "SCATTER",
  BAR: "BAR",
};

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Tooltip,
  Legend
);

const ActivityChart = (props) => {
  const { t, title, yAxis, subjectId, activityData, color } = props;
  let [chartType, setChartType] = useState(CHART_TYPES.BAR);
  let chartRef = useRef(null);
  const { jsonToCSV } = usePapaParse();
  let subjectData;
  SubjectService.getSubjectData(subjectId).then((data) => {
    subjectData = data;
  });

  let dataSets = [];
  if (activityData) {
    let dataSet = {
      label: title || "",
      borderColor: color,
      backgroundColor: color,
      fill: "none",
      pointRadius: 4,
      pointHoverRadius: 6,
      data: activityData.map((stepObj, index) => {
        return {
          x: stepObj.x,
          y: stepObj.y,
        };
      }),
    };
    dataSets.push(dataSet);
  }

  const downloadData = () => {

    console.log('debug', activityData);
    const asJson = activityData.map(d=> {
      return {
        timestamp: d.x instanceof moment ? d.x.format('YYYY-MM-DDTHH:mm:ss') : d.x,
        value: d.y && d.y!=null ? d.y : 0
      }

    })
    console.log('asJson', asJson);
    let results = jsonToCSV(asJson, { escapeFormulae: true });
    const csvBlobData = new Blob([results], {
      type: "text/csv;charset=utf-8;",
    });

    const theDate = DateTimeService.build.asDisplayDate(new Date());
    const filename = `Chart-${title}-${theDate}.csv`;
    saveAs(csvBlobData, filename);
  };
  const exportFunction = () => {
    ChartService.subjectChartExport(
      document,
      chartRef.current,
      subjectData,
      "activity-"+title
    );
  };

  const chartOptions = [
    {
      key: CHART_TYPES.BAR,
      text: t("CHART_BAR"),
      value: CHART_TYPES.BAR,
    },
    {
      key: CHART_TYPES.LINE,
      text: t("CHART_LINE"),
      value: CHART_TYPES.LINE,
    },
    {
      key: CHART_TYPES.SCATTER,
      text: t("CHART_SCATTER"),
      value: CHART_TYPES.SCATTER,
    },
  ];

  let options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          displayFormats: {
            second: "LTS",
            minute: "LT",
            hour: "LT",
            day: "ll",
            week: "ll",
            month: "ll",
            quarter: "ll",
            year: "YYYY",
          },
        },
      },
      y: {
        ticks: {
          beginAtZero: false,
        },
        title: {
          display: true,
          text: yAxis || '',
        },
      },
    },
    legend: {
      labels: {
        usePointStyle: true,
      },
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          const date = moment(tooltipItem.xLabel).format("LLLL");
          return "[" + date + "]:  " + tooltipItem.yLabel;
        },
      },
    },
  };

  const data = {
    datasets: dataSets,
  };

  return (
    <>
      {chartType === CHART_TYPES.LINE && (
        <Line data={data} options={options} ref={chartRef} />
      )}
      {chartType === CHART_TYPES.SCATTER && (
        <Scatter data={data} options={options} ref={chartRef} />
      )}
      {chartType === CHART_TYPES.BAR && (
        <Bar data={data} options={options} ref={chartRef} />
      )}
      <div
        style={{
          padding: "12px 0",
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Dropdown
          placeholder={t("CHART_BAR")}
          selection
          onChange={(e, obj) => {
            setChartType(obj.value);
          }}
          options={chartOptions}
        />
        <div>
          <Button
            primary
            onClick={() => {
              exportFunction();
            }}
          >
            {t("CHART_EXPORT")}
          </Button>
          <Button
              primary
              onClick={() => {
                downloadData();
              }}
          >
            {t("DOWNLOAD", 'Download')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(ActivityChart);
