import React from 'react';

import AnatomyDiagram from './AnatomyDiagram';
import AnatomySelectors from './AnatomySelectors';
import ANATOMICALDATA_FRONT from "../../../../constants/anatomyData/ANATOMICALDATA_FRONT";
import ANATOMICALDATA_FRONTASYM from "../../../../constants/anatomyData/ANATOMICALDATA_FRONTASYM";

const AnatomyFull = (props) => {
  const { onClick, isSelectedArray, height, width, bodyPartArray, bodyType } = props;

  // Adding opacity to colors
  const selectedColor = '#fdb05f';
  const unselectedColor = '#eeeeee40';

  return (
    <svg
      width={width}
      height={height}
      style={{ position: 'absolute' }}
      viewBox='0 0 600 1200'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {bodyType === 'FRONT' && (
        <AnatomySelectors
          onClick={onClick}
          bodyPartData={ANATOMICALDATA_FRONT}
          bodyPartArray={bodyPartArray}
          isSelectedArray={isSelectedArray}
          selectedColor={selectedColor}
          unselectedColor={unselectedColor}
        />
      )}
      {bodyType === 'FRONTASYM' && (
        <AnatomySelectors
          onClick={onClick}
          bodyPartData={ANATOMICALDATA_FRONTASYM}
          bodyPartArray={bodyPartArray}
          isSelectedArray={isSelectedArray}
          selectedColor={selectedColor}
          unselectedColor={unselectedColor}
        />
      )}

      <svg
        height={height}
        width={width}
        style={{ position: 'absolute' }}
        viewBox='0 0 600 1200'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        {bodyType === 'FRONT' && (
          <AnatomyDiagram bodyPartData={ANATOMICALDATA_FRONT} bodyType={bodyType} bodyPartArray={bodyPartArray} />
        )}
        {bodyType === 'FRONTASYM' && (
          <AnatomyDiagram bodyPartData={ANATOMICALDATA_FRONTASYM} bodyType={bodyType} bodyPartArray={bodyPartArray} />
        )}
      </svg>
    </svg>
  );
};

export default AnatomyFull;
