import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";

class SubjectAllergyEditPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.initialise();
  }

  // This state setting method does not apply to allergies, but was copied from
  // the medication file.
  initialise = () => {
    SubjectService.getAllergy(
      this.props.match.params.subjectId,
      this.props.match.params.allergyId
    )
      .then((allergy) => {
        this.setState({
          name: allergy.name,
          loading: false,
        });
      })
      .catch(this.errorHandler);

    return {
      loading: true,
      error: false,
    };
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId ||
      prevProps.match.params.allergyId !== this.props.match.params.allergyId
    ) {
      this.setState(this.initialise());
    }
  };

  selectChanged = (event, option) => {
    this.setState({ [option.name]: option.value });
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (event) => {
    const payload = {
      name: this.state.name,
    };
    SubjectService.editAllergy(
      this.props.match.params.subjectId,
      this.props.match.params.allergyId,
      payload
    )
      .then(this.onCancel)
      .catch((error) => {
        console.error(error);
        this.setState({ error: true });
      });
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  onCancel = () => {
    window.location.href =
      window.location.origin +
      "/app/subject/" +
      this.props.match.params.subjectId +
      "/tabs/allergies";
  };

  render() {
    const { t } = this.props;
    const errorMessage = this.state.error
      ? t("GLOBAL_ERROR_GENERIC_MESSAGE")
      : undefined;

    return (
      <Page
        name="ALLERGY_EDIT"
        header={t("ALLERGIES_EDIT_HEADER")}
        subheader={t("ALLERGIES_EDIT_SUBHEADER")}
        loading={this.state.loading}
      >
        <Form
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          error={this.state.error}
        >
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={errorMessage}
          />

          <Grid columns={"equal"}>
            <Grid.Column>
              <Segment>
                <Header>{t("ALLERGIES_EDIT_ALLERGY_SECTION_HEADER")}</Header>

                <Form.Field>
                  <Form.Input
                    label={t("ALLERGIES_NAME")}
                    type="text"
                    name="name"
                    id="name"
                    onChange={this.inputChanged}
                    value={this.state.name}
                  />
                </Form.Field>
              </Segment>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button type="submit" loading={this.state.submitting} primary>
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                {this.props.onCancel && (
                  <Button color={"black"} basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL")}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Page>
    );
  }
}

export default withTranslation()(SubjectAllergyEditPage);
