import React, {useState, useContext, useEffect} from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import Page from "../../components/page/Page";
import { getDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {Redirect, useHistory, useParams} from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";
import Questionnaire from "../../questionnaires/Questionnaire";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import QuestionnaireEnd from "../../components/questionnaire/QuestionnaireEnd";
import QuestionnaireContext from "../../context/QuestionnaireContext";
import SubjectService from "../../SubjectService";
import {Button, Header, Icon, Loader, Message} from "semantic-ui-react";
import { cloneDeep } from "lodash";
import UserContext from "../../context/UserContext";
import DashboardContext from "../../context/DashboardContext";
import ParentQuestionnaireDisplay from "../../questionnaires/ParentQuestionnaireDisplay";
import VideoDisplay from "../../components/questionnaire/VideoDisplay";
import GroupPermission from "../../GroupPermission";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const ERROR_TYPE = {
  UNKNOWN: {
    title: "GLOBAL_ERROR_TITLE",
    message: "GLOBAL_ERROR_GENERIC_MESSAGE",
  },
  PERMISSION: {
    title: "GLOBAL_ERROR_TITLE",
    message: "PERMISSION",
    messageFallback: "You do not have permissions for this",
  },
};

const SubjectViewPDFQuestionnairePage = (props) => {
  const { allDefinitions, t } = props;

  const history = useHistory();
  const { subjectId, questionnaireType, questionnaireId, definitionCode } =
    useParams();
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);
  const returnTo = useContext(QuestionnaireContext)?.returnTo;

  let [isCurrentQuestionnaireCompleted, setIsCurrentQuestionnaireCompleted] =
    useState(false);

  let [questionnaire, setQuestionnaire] = useState(null);
  let [definition, setDefinition] = useState(null);
  let [subjectCode, setSubjectCode] = useState(null);
  let [linkingInfo, setLinkingInfo] = useState(null);
  let [loading, setLoading] = useState(true);
  let [error, setError] = useState(null);
  let [isExportingPDF, setIsExportingPDF] = useState(false);

  let [shouldUseAltDisplay, setShouldUseAltDisplay] = useState(false);

  const dashboardContext = useContext(DashboardContext);
  const handlePageChange = () => {
    dashboardContext.scrollToTop();
  };


  let additionalLabel;
  if(definition?.config?.encodeQuestionnaireId) {
    additionalLabel = btoa(questionnaire.id);
  }

  const downloadPDF = async (elementId, definitionLabel) => {
    const input = document.getElementById(elementId);
    const canvas = await html2canvas(input);

    let imgWidth = 200;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;

    const imgData = canvas.toDataURL("image/jpeg");
    const pdf = new jsPDF("p", "mm", [imgWidth + 10, imgHeight + 10]);

    pdf.addImage(imgData, "JPEG", 5, 5, imgWidth, imgHeight);

    const definitionName = definitionLabel.toLowerCase().replaceAll(" ", "_"); // TODO: Case - possible issue?

    const fileName =
      definitionName + ".pdf";
    pdf.save(fileName);
  };

  useEffect(() => {
    if (isExportingPDF) {
      const doExport = async () => {
        await downloadPDF("form_" + definition.code, definition.label);
        setIsExportingPDF(false);
      };
      setTimeout(doExport, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExportingPDF]);

  const initialize = async () => {
    if (!loading) {
      await setLoading(true);
    }

    if (isCurrentQuestionnaireCompleted) {
      setIsCurrentQuestionnaireCompleted(false);
    }

    const permissions = await SubjectService.getSubjectPermission(subjectId);
    const hasViewSubjectPermission = permissions.includes(GroupPermission.VIEW_SUBJECT);
    const hasExportQuestionnairePdfPermission = 
      permissions.includes(GroupPermission.EXPORT_QUESTIONNAIRE_PDF);

    if (!hasExportQuestionnairePdfPermission) {
      setError(ERROR_TYPE.PERMISSION)
      return;
    }

    const subjectGroups = await SubjectService.getSubjectGroups(subjectId);
    if(hasViewSubjectPermission){
      const subjectData = await SubjectService.getSubjectData(subjectId);
      setSubjectCode(subjectData.subjectCode)
    }

    const definition = cloneDeep(
      allDefinitions.find((d) => {
        return d.code === definitionCode;
      })
    );

    if (!definition || !definition.questions) {
      setError(ERROR_TYPE.UNKNOWN);
      return;
    }

    if (definition.config?.modules) {
      const tabsConfig = config.ui?.tabs ? config.ui?.tabs : [];
      const staffProfile = user.profile;

      const isModuleSubmissionAllowed =
        await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
          definition,
          subjectGroups,
          tabsConfig,
          staffProfile
        );

      if (!isModuleSubmissionAllowed) {
        setError(ERROR_TYPE.PERMISSION);
        console.log(
          "[SubjectViewPDFQuestionnairePage][initialize] Module Submission is Not Allowed"
        );
        return;
      }
    }

    const linkingInfo = await getlinkingInfo(definition, subjectGroups);
    setLinkingInfo(linkingInfo);

    const answers = {};
    // The questionnaire needs to be set before the definition in changed.
    await setQuestionnaire({ ...questionnaire, answers });
    setDefinition(definition);
    setLoading(false);
  };

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectId, questionnaireId, definitionCode]);

  const submitQuestionnaire = async (e) => {
    // no-op
  };

  const goBackToList = () => {
    if (returnTo) {
      history.push(returnTo);
      return;
    }
    history.goBack();
  };

  const goToNext = (questionnaire, definition) => {
    setIsCurrentQuestionnaireCompleted(false);
    let newQuestionnaireId = questionnaire ? questionnaire.id : "latest";

    history.push(
      `/app/subject/${subjectId}/questionnaire-type/${questionnaireType}/${definition.code}/submit/${newQuestionnaireId}`
    );
  };

  const getlinkingInfo = async (questionnaireDefinition, subjectGroups) => {
    if (questionnaireDefinition?.config?.to) {
      let toArray;
      if (!Array.isArray(questionnaireDefinition?.config?.to)) {
        toArray = [questionnaireDefinition?.config?.to];
      } else {
        toArray = questionnaireDefinition?.config?.to;
      }

      for (let i = 0; i < toArray.length; i++) {
        const to = toArray[i];
        let [type, code] = to.split("://");

        // At this time staff cannot be linked to event or content type
        // questionnaires
        if (type === "event" || type === "content") {
          console.log(
            "[SubjectViewPDFQuestionnairePage][linking] EVENT and CONTENT questionnaires cannot be linked to on web currently"
          );
          continue;
        }

        try {
          let foundDefinition = allDefinitions.find((def) => {
            return code === def.code && type.toUpperCase() === def.type;
          });

          const tabsConfig = config.ui.tabs ? config.ui.tabs : [];
          const staffProfile = user.profile;

          const isModuleSubmissionAllowed =
            await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
              foundDefinition,
              subjectGroups,
              tabsConfig,
              staffProfile
            );

          if (!isModuleSubmissionAllowed) {
            continue;
          }

          const activeNotCompleted =
            await SubjectService.getSubjectQuestionnairesActiveNotCompletedOnly(
              subjectId,
              foundDefinition.code,
              undefined, undefined,
              1
            );

          if (activeNotCompleted && activeNotCompleted.result && activeNotCompleted.result.length > 0) {
            return {
              isLinkValid: true,
              questionnaire: activeNotCompleted.result[0],
              definition: foundDefinition,
            };
          }
        } catch {
          console.error(
            "[SubjectViewPDFQuestionnairePage] Linking questionnaire not found"
          );
          return { isLinkValid: false, questionnaire: null, definition: null };
        }
      }
      return { isLinkValid: false, questionnaire: null, definition: null };
    } else {
      return { isLinkValid: false, questionnaire: null, definition: null };
    }
  };

  const buildErrorDisplay = (titleKey, messageKey, messageFallback) => {
    return (
      <Message error textAlign="left">
        <Message.Header>{t(titleKey)}</Message.Header>
        <Message.Content>{t(messageKey, messageFallback)}</Message.Content>
      </Message>
    );
  };

  const getErrorDisplay = () => {
    if (error) {
      return buildErrorDisplay(
        error.title,
        error.message,
        error.messageFallback
      );
    }
    return null;
  };

  if (!questionnaire) {
    if (loading) {
      return getErrorDisplay();
    } else {
      return <Redirect to="/app/home" />;
    }
  }

  if (!definition) {
    if (loading) {
      return <Loader active />;
    } else {
      return <Redirect to="/app/home" />;
    }
  }

  if (loading) {
    return <Loader active />;
  }

  const QuestionnaireComponent = Questionnaire;

  return (
    <Page
      key={definitionCode}
      name="QUESTIONNAIRE_PAGE"
      header={() => (
        <Header as="h3">
          <Button
            color="orange"
            style={{ padding: "0.25rem 1rem " }}
            onClick={() => history.goBack()}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon name="arrow left" />
              {!subjectCode ? <h3 >{"GLOBAL_BACK"}</h3> : <h3 style={{margin: "0rem", marginLeft: "0.5rem"}}>
                {subjectCode}
              </h3>}
            </div>
          </Button>
          <Button
              color="orange"
              style={{ padding: "0.25rem 1rem " }}
              onClick={() => setIsExportingPDF(true)}
            >
              <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon name="download left" />
              <h3 style={{margin: "0rem", marginLeft: "0.5rem"}}>{t("SUBJECT_QUESTIONNAIRE_EXPORT_PDF", "Export to PDF")}</h3>
              </div>
          </Button>
          {" " + t("VIEW_PDF_SUBJECT_QUESTIONNAIRE_HEADER", "View Questionnaire for export")}
        </Header>
      )}
    >
      {error && getErrorDisplay()}
      {definition && !isCurrentQuestionnaireCompleted && !error && (
        <div
          style={{
            display: "flex",
            flexDirection: shouldUseAltDisplay ? "column-reverse" : "row",
            marginTop: "2rem"
          }}
        >
          <div style={{ flexGrow: 1, minWidth: "800px", paddingRight: "2rem" }}>
            {additionalLabel && additionalLabel.length > 0 && <label style={{fontWeight: "bold", color: "#9D9FA2"}}>{additionalLabel}</label>}
            <QuestionnaireComponent
              key={definitionCode}
              definition={definition}
              questionnaire={questionnaire}
              onPageChange={handlePageChange}
              onSubmit={submitQuestionnaire}
              subjectId={subjectId}
              shouldShowSubmit={false}
              withoutPaging={true}
              withoutConditions={true}
              withoutValidation={true}
            />
          </div>
          <div
            style={{
              paddingBottom: "2rem",
            }}
          >
            <VideoDisplay definition={definition} questionnaire={questionnaire} />
            <ParentQuestionnaireDisplay
              questionnaire={questionnaire}
              subjectId={subjectId}
              allDefinitions={allDefinitions}
              shouldUseAltDisplay={shouldUseAltDisplay}
              setShouldUseAltDisplay={setShouldUseAltDisplay}
              shouldShow={!isCurrentQuestionnaireCompleted}
            />
          </div>
        </div>
      )}

      {isCurrentQuestionnaireCompleted && !error && (
        <QuestionnaireEnd
          questionnaire={questionnaire}
          onFinish={goBackToList}
          onLink={goToNext}
          linkingInfo={linkingInfo}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    allDefinitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(SubjectViewPDFQuestionnairePage);
