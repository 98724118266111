import React, { useState } from "react";
import { Confirm, Form, Loader } from "semantic-ui-react";
import FeedbackForm from "./FeedbackForm";
import AparitoSwitch from "../questionnaire/AparitoSwitch";

const ConfirmSwitchWithFeedback = (props) => {
  const {
    onConfirm,
    onCancel,
    isChecked,
    headerText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    size,
    placeholderText,
    mandatoryValidationText,
    disabled,
    isSubmitting,
    label
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleConfirm = () => {
    //if mandatory validation text is supplied then check feedback is supplied
    //if no mandatory validation text is supplied then go ahead and submit the form
    if (
      mandatoryValidationText &&
      (!feedback || feedback.trim().length === 0)
    ) {
      setShowError(true);
    } else {
      setIsOpen(false);
      if (onConfirm) {
        onConfirm(feedback, selectedValue);
      }
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const addFeedback = (feedbackFromComponent) => {
    setFeedback(feedbackFromComponent);
  };

  const buttonSubmit = disabled || isSubmitting;

  const theSwitch = (
    <AparitoSwitch
      checked={isChecked}
      disabled={buttonSubmit}
      onChange={(value) => {
        setSelectedValue(value);
        setIsOpen(true)
      }}
      label={label}
    />
  );

  var style = showError
    ? { display: "flex", color: "red", margin: "0.5em", marginLeft: "1em" }
    : { display: "none" };

  const contentTextWithFeedback = (
    <>
      <Form>
        <p style={{ marginLeft: "2em" }}>{contentText}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FeedbackForm
            placeholder={placeholderText}
            addFeedback={addFeedback}
          />
        </div>
        <br />
        <div style={style}>
          <p>{mandatoryValidationText}</p>
        </div>
        <br />
      </Form>
    </>
  );

  return (
    <>
      {!isSubmitting && theSwitch}
      {isSubmitting && <Loader active />}
      <Confirm
        open={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        header={headerText}
        content={contentTextWithFeedback}
        confirmButton={confirmButtonText}
        cancelButton={{ content: cancelButtonText, color: "black", basic: true }}
        size={size}
      />
    </>
  );
};

export default ConfirmSwitchWithFeedback;
