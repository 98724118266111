import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import { Button, Form, Grid, Header, Icon, Message } from "semantic-ui-react";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";

const SubjectEditSubjectCodePage = (props) => {
  const { t } = props;

  const [subjectCode, setSubjectCode] = useState();
  const [subjectData, setSubjectData]= useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);

  useEffect(() => {
    fetchSubjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
   
  const fetchSubjectData = async () => {
  
    try {
      const subjectDataResponse = await SubjectService.getSubjectData(props.match.params.subjectId);
      const hasUpdateSubjectCodePermissions = await PermissionsService.hasPermissionForSubject(subjectDataResponse.groups, GroupPermission.UPDATE_SUBJECT_CODE);
      if (!hasUpdateSubjectCodePermissions) {
        onCancel();
        return;
      }
      setSubjectCode(subjectDataResponse.subjectCode || "");
      setSubjectData(subjectDataResponse);
      setIsLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const errorHandler = (_error) => {
    let messageText;
    
    if (_error?.message?.indexOf("already exists") >= 0) {
      messageText = t("SUBJECT_SUBJECTCODE_EXISTS_ERROR_MESSAGE", "A patient with that Subjet Code already exists.");
    }

    if (_error?.message?.indexOf("with a valid 'subjectCode' property") >= 0) {
      messageText = t("SUBJECT_SUBJECTCODE_INVALID_ERROR_MESSAGE", "Please enter a valid Subject code");
    }

    if (!messageText) {
      messageText = t(
        "GLOBAL_ERROR_GENERIC_MESSAGE",
        "Please fill in all the required fields"
      );
    }
    setIsLoading(false);
    setErrorMessage(messageText);
  };

  const onSubmit = (feedbackReason) => {
    setIsLoading(true);
    return SubjectService.updateSubjectCode(
      props.match.params.subjectId,
      subjectCode,
      feedbackReason
    )
      .then(onCancel)
      .catch(errorHandler);
  };

  const inputChanged = (event) => {
    setSubjectCode(event.target.value);
  };

  const validateSubjectCode = () => {
    return (subjectCode != null && subjectCode.length > 0);
  };

  const onCancel = () => {
    props.history.goBack();
  };


  return (
    <Page
      header={() => (
        <Header as="h3">
          <Button
            color="orange"
            style={{ padding: "0.25rem 1rem " }}
            onClick={onCancel}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Icon name="arrow left" />
              <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                {subjectData.subjectCode}
              </h3>
            </div>
          </Button>
          {" " + t("SUBJECT_EDIT_SUBJECTCODE_TITLE", "Edit subject code for subject")}
        </Header>
      )}
      loading={isLoading}
    >
      {errorMessage && (
        <Message
          visible={true}
          error
          header={t("GLOBAL_ERROR_TITLE")}
          >
          {errorMessage}
        </Message>
      )}

      <Grid columns={"equal"}>
        <Grid.Column>
          <Form>
            <Form.Field>
              <label>{t("STAFF_FORM_SUBJECTCODE_LABEL", "Subject code")}</label>
              <input
                name="subjectCode"
                required
                value={subjectCode}
                onChange={inputChanged}
              />
            </Form.Field>

            <ConfirmButtonWithFeedback
              disabled={!validateSubjectCode()}
              buttonText={t("GLOBAL_BUTTON_SAVE", "Save")}
              headerText={t("STAFF_FORM_SUBJECTCODE_LABEL", "Subject code")}
              contentText={t("SUBJECT_EDIT_SUBJECTCODE_TITLE", "Edit subject code for subject")}
              confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
              cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
              onConfirm={onSubmit}
              placeholderText={t("STAFF_FORM_SUBJECTCODE_LABEL", "Subject code")}
              mandatoryValidationText={t("SUBJECT_SUBJECTCODE_INVALID_ERROR_MESSAGE", "Please enter a valid Subject code")}
            />
            <Button color={"black"} basic type="button" onClick={onCancel}>
              {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    </Page>
  );
  
}

export default withTranslation()(SubjectEditSubjectCodePage);
