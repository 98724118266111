import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { createNewTranslationsArrayForQuestion, createNewTranslationsArrayForAnswers } from "../components/admin/questionnaireEditor/helpers/createNewTranslationsArray";
import QuestionnaireDefinitionService from "../services/admin/QuestionnaireDefinitionService";
import { bySequenceAndById } from "../helpers/sortFunctions";
import { useParams } from "react-router-dom";
import InternationalisationService from "../InternationalisationService";
import { getTranslationsForQuestionnaireDefCode } from '../services/admin/AdminTranslationService';
import { set, forOwn, isArray, isObject, remove, isUndefined } from 'lodash'
import safeRegex from 'safe-regex';

function cleanArrays(obj) {
  forOwn(obj, (value, key) => {
    if (isArray(value)) {
      remove(value, isUndefined)
    }
    if (isObject(value) && !isArray(value)) {
      cleanArrays(value);
    }
  });
}

const filterOutDuplicateTranslations = (obj, index, self) =>
  index === self.findIndex((t) => (
    t.code === obj.code && t.language === obj.language
  ))




const ACTION_TYPES = {
  RESET: "RESET",
  EDIT_QUESTIONNAIRE_DEFINITION: "EDIT_QUESTIONNAIRE_DEFINITION",
  ADD_QUESTION_DEFINITION: "ADD_QUESTION_DEFINITION",
  EDIT_QUESTION_DEFINITION: "EDIT_QUESTION_DEFINITION",
  ADD_TRANSLATION_EDIT: "ADD_TRANSLATION_EDIT",
  EDIT_CONFIG: "EDIT_CONFIG",
  MOVE_QUESTION: "MOVE_QUESTION",
  TOGGLE_FOCUS: "TOGGLE_FOCUS"
};

const useCompleteQuestionnaireDefinitionInformation = () => {
  const { questionnaireDefinitionId } = useParams();

  // fetch definition data
  const [definitionData, setDefinitionData] = useState(null);
  const getDefinitonData = useCallback(async () => {
    const questionnaireDefinitionData =
      await QuestionnaireDefinitionService.getQuestionnaireDefinitionData(
        questionnaireDefinitionId
      );

    // in place sorting of questions into display order
    const questions = questionnaireDefinitionData.questionnaireDefinition.questions;
    questions.sort(
      bySequenceAndById
    )

    setDefinitionData(questionnaireDefinitionData);
  }, [questionnaireDefinitionId]);
  useEffect(() => getDefinitonData(), [getDefinitonData]);

  // get languages
  const [languages, setLanguages] = useState(null)
  const getLanguages = useCallback(async () => {
    const languages = await InternationalisationService.getLanguages()
    setLanguages(languages)
  }, [])
  useEffect(() => {
    getLanguages()
  }, [getLanguages])

  const [allExistingConfig, setAllExistingConfig] = useState(null);
  useEffect(() => {
    if (!definitionData?.questionnaireDefinition) return null
    if (!definitionData?.questionDefinitions) return null
    const fetchAllExistingConfig =  (questionnaire, questions) => {
      getTranslationsForQuestionnaireDefCode(questionnaire.code,'config').then( results => {
            setAllExistingConfig([].concat(...results));
      });
    }
    fetchAllExistingConfig(definitionData.questionnaireDefinition, definitionData.questionDefinitions);
  }, [languages, definitionData])

  // state management for save call
  const editorStateReducer = useCallback((state, action) => {
    switch (action.type) {
      case ACTION_TYPES.RESET:
        if (action.payload === null) return state;
        return {
          questionnaireDefinition: action.payload.questionnaireDefinition,
          translationArray: action.payload.serverTranslations,
          changedTranslationArray: [],
          focusedQuestions: [],
          questionsWithEditedConfig: [],
        };
      case ACTION_TYPES.EDIT_QUESTIONNAIRE_DEFINITION:
        return {
          ...state,
          questionnaireDefinition: action.payload,
        };
      case ACTION_TYPES.ADD_TRANSLATION_EDIT:
        const newTranslationObject = action.payload;
        const translationArray = [...state.translationArray];
        const changedTranslationArray = [...state.changedTranslationArray];

        // Replace or add in the changed translation array
        const presentChangedTranslationIndex = changedTranslationArray.findIndex(tO => {
          return tO.language === newTranslationObject.language && tO.code === newTranslationObject.code
        });
        if (presentChangedTranslationIndex === -1) {
          changedTranslationArray.push(newTranslationObject);
        } else {
          changedTranslationArray.splice(presentChangedTranslationIndex, 1, newTranslationObject);
        }

        // replace in the translation array
        const presentTranslationIndex = translationArray.findIndex(tO => {
          return tO.language === newTranslationObject.language && tO.code === newTranslationObject.code
        });
        if (presentTranslationIndex !== -1) {
          translationArray.splice(presentTranslationIndex, 1, newTranslationObject);
        }

        return {
          ...state,
          translationArray: translationArray,
          changedTranslationArray: changedTranslationArray,
        };
      case ACTION_TYPES.EDIT_CONFIG: {
        const { questionCode, changes } = action.payload;
        let newTranslations = [...state.translationArray];
        let newChangedTranslations = [...state.changedTranslationArray];
        let questionsWithEditedConfig = [...state.questionsWithEditedConfig];

        const translationCode = `questionnaire_${state.questionnaireDefinition.code}_questions_${questionCode}_config`;
        const isRegexSafe = safeRegex(translationCode);
        if (!isRegexSafe) {
          console.error('Potential ReDoS vulnerability detected in the regular expression.');
          return;
        }
        // nosemgrep
        const translationCodeRegex = new RegExp(translationCode);

        // as we are editing all translations, other languages translations need to be added
        // but only if they are not already present.
        if (!questionsWithEditedConfig.includes(questionCode)) {
          const allTranslationsWithDuplicates = state.translationArray.concat(allExistingConfig);
          const allChangedTranslationsWithDuplicates = state.changedTranslationArray.concat(allExistingConfig);

          newTranslations = allTranslationsWithDuplicates.filter(filterOutDuplicateTranslations);
          newChangedTranslations = allChangedTranslationsWithDuplicates.filter(filterOutDuplicateTranslations)
          questionsWithEditedConfig.push(questionCode);
        }

        newTranslations = newTranslations.map(nT => {
          if (!translationCodeRegex.test(nT.code)) return nT
          const newTranslation = { ...nT }
          try {
            const parsed = JSON.parse(nT.translation.length > 0 ? nT.translation : "{}");
            changes.forEach(c => {
              set(parsed, c.path, c.value);
            })
            cleanArrays(parsed);
            newTranslation.translation = JSON.stringify(parsed)
          } catch (e) {
            console.error(e)
          }
          return newTranslation;
        })

        newChangedTranslations = newChangedTranslations.map(nT => {
          if (!translationCodeRegex.test(nT.code)) return nT
          const newTranslation = { ...nT }
          try {
            const parsed = JSON.parse(nT.translation.length > 0 ? nT.translation : "{}");
            changes.forEach(c => {
              set(parsed, c.path, c.value);
            })
            cleanArrays(parsed);
            newTranslation.translation = JSON.stringify(parsed)
          } catch (e) {
            console.error(e)
          }
          return newTranslation;
        })

        return {
          ...state,
          translationArray: newTranslations,
          changedTranslationArray: newChangedTranslations,
          questionsWithEditedConfig: questionsWithEditedConfig
        };
      }
      case ACTION_TYPES.ADD_QUESTION_DEFINITION:
        const newQuestion = action.payload;
        const {
          clonedQuestion,
          translationsForNewQuestion
          }  = action.payload;
        const questionnaireDefinitionAQD = { ...state.questionnaireDefinition };
        const questionsAQD = translationsForNewQuestion 
        ? [...questionnaireDefinitionAQD.questions, clonedQuestion]
        : 
        [...questionnaireDefinitionAQD.questions, newQuestion];
        questionnaireDefinitionAQD.questions = questionsAQD;

        // add required translations
        const resultingTranslationObjectArray = [...state.changedTranslationArray]
        const newTranslationObjectArray = translationsForNewQuestion 
        ? translationsForNewQuestion
        :        
        createNewTranslationsArrayForQuestion(
          state.questionnaireDefinition.code,
          newQuestion.code,
          newQuestion.type,
          languages,
          []       // shouldnt be needed if creating a new set of translations
        );
        if (newQuestion?.answers?.length) {
          newTranslationObjectArray.push(createNewTranslationsArrayForAnswers(
            state.questionnaireDefinition.code,
            newQuestion.code,
            newQuestion.answers,
            languages,
            []    // shouldnt be needed if creating a new set of translations
          ));
        }

        resultingTranslationObjectArray.push(...newTranslationObjectArray)

        return {
          ...state,
          changedTranslationArray: resultingTranslationObjectArray,
          questionnaireDefinition: questionnaireDefinitionAQD
        }

      case ACTION_TYPES.EDIT_QUESTION_DEFINITION:
        const editedQuestion = action.payload;
        const questionnaireDefinitionEQD = { ...state.questionnaireDefinition };
        const questionsEQD = [...questionnaireDefinitionEQD.questions];
        const changedIndex = questionsEQD.findIndex(q => {
          return q.code === editedQuestion.code
        });

        if (changedIndex === -1) return state;

        const [oldQuestion] = questionsEQD.splice(changedIndex, 1, editedQuestion);
        questionnaireDefinitionEQD.questions = questionsEQD;

        const resultingTranslationObjectArrayEQD = [...state.changedTranslationArray]
        if (editedQuestion.type !== oldQuestion.type) {
          resultingTranslationObjectArrayEQD.push(...createNewTranslationsArrayForQuestion(
            state.questionnaireDefinition.code,
            editedQuestion.code,
            editedQuestion.type,
            languages,
            [...state.changedTranslationArray, ...state.translationArray]
          ));
        }
        if (editedQuestion?.answers?.length) {
          resultingTranslationObjectArrayEQD.push(...createNewTranslationsArrayForAnswers(
            state.questionnaireDefinition.code,
            editedQuestion.code,
            editedQuestion.answers,
            languages,
            [...state.changedTranslationArray, ...state.translationArray]
          ));
        }

        return {
          ...state,
          changedTranslationArray: resultingTranslationObjectArrayEQD,
          questionnaireDefinition: questionnaireDefinitionEQD,
        };
      case ACTION_TYPES.MOVE_QUESTION:
        const { index, direction } = action.payload;
        if (index + direction < 0 || index + direction >= state.questionnaireDefinition.questions.length) {
          return state;
        }

        const questionnaireDefinitionMQ = { ...state.questionnaireDefinition };
        const questionsMQ = questionnaireDefinitionMQ
          .questions
          .map((q, i) => {
            return { ...q, sequence: i }
          });

        // direction should be 1 or -1 depending on direction
        questionsMQ[index].sequence = questionsMQ[index].sequence + direction;
        questionsMQ[index + direction].sequence = index;
        questionsMQ.sort((a, b) => a.sequence - b.sequence)
        questionnaireDefinitionMQ.questions = questionsMQ;
        return {
          ...state,
          questionnaireDefinition: questionnaireDefinitionMQ,
        };
      case ACTION_TYPES.TOGGLE_FOCUS:
        const focusedQuestions = [...state.focusedQuestions]
        const questionCodeIndex = focusedQuestions.findIndex((fQ) => fQ === action.payload);
        if (questionCodeIndex === -1) {
          focusedQuestions.push(action.payload);
        } else {
          focusedQuestions.splice(questionCodeIndex, 1);
        }

        return {
          ...state,
          focusedQuestions
        }
      default:
        console.log("[useCompleteQuestionnaireDefinitionInformation] Action type not found for ", action.type)
    }
  }, [languages, allExistingConfig]);

  const [state, dispatch] = useReducer(editorStateReducer, {
    questionnaireDefinition: null,
    translationArray: null,
    changedTranslationArray: null,
    focusedQuestions: null,
    questionsWithEditedConfig: []
  });
  useEffect(() => {
    dispatch({ type: ACTION_TYPES.RESET, payload: definitionData });
  }, [definitionData]);


  const hasLoaded = useMemo(() => {
    return [
      state.questionnaireDefinition,
      state.translationArray,
      languages,
      allExistingConfig
    ].every((v) => v !== null);
  }, [state, languages, allExistingConfig]);

  return [hasLoaded, state, dispatch, ACTION_TYPES]
}

export { ACTION_TYPES };
export default useCompleteQuestionnaireDefinitionInformation;