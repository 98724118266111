import React, {useMemo, useRef} from "react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import RenderVideo from "./RenderVideo";
import AuthService from "../../services/AuthService";
import { typeHelper } from "atom5-branching-questionnaire";
import {Button, Form, Message} from "semantic-ui-react";
import {v4 as uuid_v4} from "uuid";
import {useTranslation} from "react-i18next";

const Video = (props) => {
  // START: See AT-2056 for the reason for this, and AT-1855 for the follow up
  const {questionnaire, question, changeValue, value} = props;
  const {t} = useTranslation();

  const fileInputRef = useRef();

  const isStaff = AuthService.isStaff();
  const shouldUseCamera = question?.config?.useCamera;

  const shouldShowRenderVideo = useMemo(() => {
    if(AuthService.isSubject()){
      const showInCompletedQuestionnaire = typeHelper.parseBool(question?.config?.showInCompletedQuestionnaire);
      return showInCompletedQuestionnaire && AuthService.isSubject() === true && questionnaire?.completionDate != null;
    }
    return questionnaire?.completionDate != null;
  }, [questionnaire, question]);

  const renderVideoProps = useMemo(() => {
    if (!shouldShowRenderVideo) {
      return;
    }
    const renderVideoConfig = {...question?.config};
    renderVideoConfig.renderAs = 'value';

    const renderVideoQustion = {...question};
    renderVideoQustion.config = renderVideoConfig;

    const renderVideoProps = {...props};
    renderVideoProps.question = renderVideoQustion;
    return renderVideoProps;
  }, [props, question, shouldShowRenderVideo]);

  // END: See AT-2056 for the reason for this, and AT-1855 for the follow up

  const fileInputAccept = question?.config?.allowedFileMimeTypes
      ? question.config.allowedFileMimeTypes
      : "";

  const hasValue = value != null;

  const handleInitClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = async (event) => {
    const file = event?.target?.files[0];
    const reference = uuid_v4();

    const wrappedAnswer = {
      answer: reference,
      data: file,
      mediaCreateDate:  file.lastModifiedDate.toISOString()
    };
    changeValue(wrappedAnswer);
  };

  return (
      <div>
        {shouldShowRenderVideo && (
            <RenderVideo {...renderVideoProps} />
        )}

        {/*Only displaying upload button if staff*/}
        {!shouldUseCamera && isStaff && (
          <Form.Field style={{ paddingBottom: 25 }}>
            <Button
                primary={true}
                content={t('VIDEO_UPLOAD_BUTTON', 'Upload Video')}
                labelPosition="left"
                icon={"file"}
                onClick={handleInitClick}
            />
            <input
                ref={fileInputRef}
                type="file"
                hidden
                accept={fileInputAccept}
                onChange={handleFileSelect}
            />

            {hasValue && (
                <div>
                  <Message style={{ marginTop: 5 }} positive>{value?.data?.name}</Message>
                </div>
            )}
          </Form.Field>
        )}
      </div>
  );
};

export default withLabel(withError(Video));
