import { DateTime } from "luxon";

export const byDecreasingCompletionDate = (a, b) => {
  const dA = DateTime.fromISO(a.completionDate);
  const dB = DateTime.fromISO(b.completionDate);
  return dB.diff(dA).milliseconds;
};

export const byDecreasingActivationToDate = (a, b) => {
  const dA = DateTime.fromISO(a.activationToDate);
  const dB = DateTime.fromISO(b.activationToDate);
  return dB.diff(dA).milliseconds;
};

export const byIncreasingActivationToDate = (a, b) => {
  const dA = DateTime.fromISO(a.activationToDate);
  const dB = DateTime.fromISO(b.activationToDate);
  return dA.diff(dB).milliseconds;
};

export const bySequenceAndById = (a, b) => {
  if (a.sequence === b.sequence) {
    return a.id - b.id; // Secondary sort by id
  }
  return a.sequence - b.sequence; // Primary sort by sequence
};

export const sortByLabel = (a, b) => a?.label?.localeCompare(b?.label);
