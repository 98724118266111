import React, { Component } from "react";
import { Button, Checkbox, Dropdown, Form, Icon, Image, Message, Modal } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import InternationalisationService from "../../InternationalisationService";
import ConfigContext from "../../context/ConfigContext";
import RequestHelper, { CONTENT_TYPE } from "../../RequestHelper";
import {serverAddress} from "../../services/config/EnvConfig";
import { typeHelper } from "atom5-branching-questionnaire";

export class DeviceOnboardingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      requestPending: false,
      visible: this.props.show,
      qrCodeUrl: null,
      deviceId: this.props.deviceId,
      onboardingCode: null,
      sendEmailStatus: null,
      sendEmailStatusMessage: null,

      email: props.subjectData.email,
      language: props.subjectData.language?.code,
      shouldOverwrite: false,
      hasChanged: false,

      languages: [],
    };
    this.pollingTimeout = null;
    this.showSendDeviceCodesByEmail = typeHelper.parseBool(Window.configuration.ui?.devices?.showSendDeviceCodesByEmail);

    InternationalisationService.getMobileLanguages()
      .then((languages) => {
        this.setState({
          languages,
        });
        if (this.state.language == null) {
          this.setState({ language: languages[0]?.code });
        }
      })
      .catch(this.errorHandler);
  }

  createQrCodeRequest = () => {
    return RequestHelper.send(
        serverAddress +
        "/devices/" +
        this.state.deviceId +
        "/qrcode",
      {
        "Content-Type": CONTENT_TYPE.APPLICATION_OCTETSTREAM,
      },
      "GET",
      undefined,
      undefined,
      {
        headers: {
          useEmptyAccept: true,
        },
      }
    );
  };

  reload = () => {
    this.setState({ sendEmailStatus: null, sendEmailStatusMessage: null });
    if (this.state.deviceId) {
      this.createQrCodeRequest().then((imageBlob) => {
        this.setState({
          qrCodeUrl: URL.createObjectURL(imageBlob),
        });
      });

      if (this.pollingTimeout === null) {
        this.pollingTimeout = setTimeout(this.pollForChanges, 2500);
      }
    }
  };

  pollForChanges = () => {
    SubjectService.getSubjectDevices(this.props.subjectData.id)
      .then((deviceList) => {
        const device = deviceList.find(
          (d) => d.deviceId === this.state.deviceId
        );
        if (device && device.state !== "CREATED") {
          // QR code has now been used
          this.onCancel();
        } else {
          // Check again soon
          this.pollingTimeout = setTimeout(this.pollForChanges, 2500);
        }
      })
      .catch(() => {});
  };

  componentWillUnmount = () => {
    this.tidyUpPollingTimer();
  };

  tidyUpPollingTimer = () => {
    if (this.pollingTimeout) {
      clearTimeout(this.pollingTimeout);
      this.pollingTimeout = null;
    }
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.show !== this.props.show) {
      this.setState({ visible: this.props.show });
      if (this.props.show) {
        this.reload();
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        visible: nextProps.show,
        deviceId: nextProps.deviceId,
        onboardingCode: nextProps.onboardingCode,
      },
      this.reload
    );
  }

  onCancel = () => {
    this.tidyUpPollingTimer();
    this.setState({ error: null, deviceId: null }, this.props.onClose);
  };

  parseSubDomain = (url) => {
    return url.substring(url.indexOf("//") + 2, url.indexOf("."));
  };

  emailQRCode = (event) => {
    event.preventDefault();
    this.setState({ sendEmailStatus: "loading", sendEmailStatusMessage: null });

    const shouldBeAbleToOverwriteEmail =
      this.context.ui?.viewAndEditSubjectEmail === "true";
    const shouldOverwriteEmail =
      this.state.shouldOverwrite && shouldBeAbleToOverwriteEmail;
    const shouldOverwriteLanguage = this.state.shouldOverwrite;

    SubjectService.sendQrCodeEmail(
      this.state.deviceId,
      this.state.email,
      this.state.language,
      shouldOverwriteEmail,
      shouldOverwriteLanguage
    )
      .then(() => {
        this.setState({ sendEmailStatus: "success", sendEmailStatusMessage: null });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ sendEmailStatus: "error", sendEmailStatusMessage: this.getErrorMessage(err) });
      });
  };

  getErrorMessage = (_error) => {
    const { t } = this.props;
    let messageText;
    if (_error?.message?.indexOf("Email exists") >= 0) {
      messageText = t("SUBJECT_EMAIL_EXISTS_ERROR_MESSAGE", "A patient with that email address already exists.");
    }
    if (!messageText) {
      messageText = t(
        "GLOBAL_ERROR_GENERIC_MESSAGE",
        "Please fill in all the required fields"
      );
    }
    console.log('MESSAGE', messageText);
    return messageText;
  };

  render() {
    const { t } = this.props;

    const languageOptions =
      this.state.languages &&
      this.state.languages.map((l) => {
        const key = `SUBJECT_LANGUAGE_${l.code.toUpperCase()}`;
        let text = t(key);
        if (text === key) {
          text = l.name;
        }
        return { key: l.code, text: l.name, value: l.code };
      });

    return (
      <Modal open={this.state.visible} onClose={this.onCancel} closeIcon>
        <Modal.Header>{t("ONBOARD_DEVICE_MODAL_HEADER_TITLE")}</Modal.Header>
        <Modal.Content>
          <div className="ui grid">
            <div
              className="eight wide column"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h4>{t("ONBOARD_DEVICE_MODAL_SCAN_QR")}</h4>
              <Image src={this.state.qrCodeUrl} />
            </div>
            <div className="eight wide column">
              <h4>{t("ONBOARD_DEVICE_MODAL_MANUAL_HEADING")}</h4>
              <p>{t("ONBOARD_DEVICE_MODAL_MANUAL_SETUP_DESCRIPTION")}</p>
              <br />
              <h2
                style={{
                  textAlign: "center",
                  width: "100%",
                  letterSpacing: "5px",
                }}
              >
                {this.state.onboardingCode}
              </h2>

              {this.showSendDeviceCodesByEmail && (
                <div>
                  <p>{t("ONBOARD_DEVICE_QR_CODE_HELP_TEXT")}</p>
                  {this.state.sendEmailStatus === "error" && (
                    <Message error content={this.state.sendEmailStatusMessage != null ? this.state.sendEmailStatusMessage : t("GLOBAL_ERROR_GENERIC_MESSAGE")} />
                  )}

                  {this.state.sendEmailStatus !== "success" && (
                    <>
                      <Form.Input
                        label={t("ONBOARD_DEVICE_MODAL_HEADER_EMAIL")}
                        name={"email"}
                        required
                        placeholder={this.props.subjectData.email}
                        onChange={({ target: { value } }) =>
                          this.setState({ email: value, hasChanged: true })
                        }
                      />
                      <br />
                      <label>{t("ONBOARD_DEVICE_MODAL_HEADER_LANGUAGE")}</label>
                      <Dropdown
                        fluid
                        selection
                        options={languageOptions}
                        onChange={(_e, item) =>
                          this.setState({ language: item.value, hasChanged: true })
                        }
                        value={this.state.language}
                      />
                      <br />
                      {this.state.hasChanged && (
                        <>
                          <Form.Field>
                            <Checkbox
                              onChange={() =>
                                this.setState({
                                  shouldOverwrite: !this.state.shouldOverwrite,
                                })
                              }
                              checked={this.state.shouldOverwrite}
                              label={t(
                                "ONBOARD_DEVICE_MODAL_HEADER_SHOULD_OVERWRITE"
                              )}
                            />
                          </Form.Field>
                          <br />
                        </>
                      )}
                      <Button
                        onClick={this.emailQRCode}
                        icon
                        labelPosition="left"
                        loading={this.state.sendEmailStatus === "loading"}
                        disabled={!this.state.email || !this.state.language}
                        primary={!!(this.state.email && this.state.language)}
                      >
                        <Icon name="mail" />
                        {t("ONBOARD_DEVICE_QR_CODE_EMAIL_TO")} {this.state.email}
                      </Button>
                    </>
                  )}

                  {this.state.sendEmailStatus === "success" && (
                    <Button icon labelPosition="left" color="green">
                      <Icon name="check" />
                      {t("ONBOARD_DEVICE_QR_CODE_EMAIL_SUCCESS")}
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>

          <Form>
            <Button
              type="button"
              color={"black"}
              basic
              onClick={this.onCancel}
              disabled={this.state.requestPending}
            >
              {t("GLOBAL_BUTTON_CANCEL")}
            </Button>

            {this.state.error && (
              <div className="ui negative message">{t(this.state.error)}</div>
            )}
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

DeviceOnboardingModal.contextType = ConfigContext;

export default withTranslation()(DeviceOnboardingModal);
