import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Page from "../../../components/page/Page";
import { getDataDefinitions } from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import { getCompletedDatas } from "../../../redux/questionnaires/subjectQuestionnairesSlice";
import SubjectAppStateService from "../../../services/SubjectAppStateService";
import SubjectQuestionnaireList from "../../../components/lists/SubjectQuestionnaireList";
import { byDecreasingCompletionDate } from "../../../helpers/sortFunctions";

const DataListPage = (props) => {
  const { t, completed, dataDefinitions } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
    SubjectAppStateService.getSubjectCompletedQuestionnairesFromServer(dispatch);
  }, [dispatch]);

  const nonModuleDefinitions = dataDefinitions.filter((definition) => {
    const hasModule = definition.config?.modules && definition.config.modules.length > 0;
    return !hasModule;
  });

  const nonModuleCompleted = completed.filter((completedData) => {
    const definition = nonModuleDefinitions.find((def) => def.id === completedData.definitionId);
    return !!definition;
  });

  return (
    <Page
      name="DATA_LIST_PAGE"
      header={t("DATA_LIST_PAGE_HEADER")}
      maxWidth="800px"
    >
      <SubjectQuestionnaireList
        questionnaireList={nonModuleCompleted}
        definitionList={nonModuleDefinitions}
        sort={byDecreasingCompletionDate}
        showEmpty
      />
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    completed: getCompletedDatas(state),
    dataDefinitions: getDataDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(DataListPage);
