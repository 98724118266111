
export const parseCSV = (csvString) => {
    const lines = csvString.trim().split('\n');
    const entries = [];

    for (let i = 1; i < lines.length; i++) {
        const data = lines[i].split(',');
        const entry = {
            id: data[0].trim(),
            frame: parseInt(data[1].trim()),
            coords: {
                x1: parseInt(data[2].trim()),
                y1: parseInt(data[3].trim()),
                x2: parseInt(data[4].trim()),
                y2: parseInt(data[5].trim())
            },
            boxId: parseInt(data[6].trim()),
            frameType: data[7].trim()
        };
        entries.push(entry);
    }
    return entries;
}
const parseJsonFile = (jsonData) => {
    const json = JSON.parse(jsonData);
    return  json.flatMap(({ frameId, face_rectangles }) =>
        face_rectangles.map(({ faceId, rect }) => ({
            frame_id: frameId,
            boxId: faceId,
            type: "op",
            top_left_x: Math.floor(rect[0]),
            top_left_y: Math.floor(rect[1]),
            bottom_right_x: Math.floor((rect[0] + rect[2])),
            bottom_right_y: Math.floor((rect[1] + rect[3]))
        }))
    ).filter(({ top_left_x, top_left_y, bottom_right_x, bottom_right_y }) =>
        !(top_left_x === 0 && top_left_y === 0 && bottom_right_x === 0 && bottom_right_y === 0)
    )
}

export const loadExtractedHeadRects= (text) => {
    const rows = text.split('\n').filter(row => row.trim().length > 0);
    // Identify the header
    const header = rows[0].split(',').map(item => item.trim());

    const headerMap = header.reduce((map, item, index) => {
        map[item] = index;
        return map;
    }, {});

    return rows.slice(1).map(row => {
        const columns = row.split(',').map(item => item.trim());

        return {
            top_left_x: parseFloat(columns[headerMap['top_left_x']]),
            top_left_y: parseFloat(columns[headerMap['top_left_y']]),
            bottom_right_x: parseFloat(columns[headerMap['bottom_right_x']]),
            bottom_right_y: parseFloat(columns[headerMap['bottom_right_y']]),
            frame_id: parseFloat(columns[headerMap['frame_id']]),
            video_name: columns[headerMap['video_name']],
            type: ('op_' + parseInt(columns[headerMap['model']], 10))
        };
    });
}

export const loadRegionSelectorData = (text) => {
    const rows = text.split('\n').filter(row => row.trim().length > 0);
    return rows.slice(1).map(row => {
        const [id, frame, top_left_x, top_left_y, bottom_right_x, bottom_right_y, boxId, frameType, blurred] = row.split(',').map(item => item.trim());
        return {
            id: id,
            frame_id: parseFloat(frame),
            top_left_x: parseFloat(top_left_x),
            top_left_y: parseFloat(top_left_y),
            bottom_right_x: parseFloat(bottom_right_x),
            bottom_right_y: parseFloat(bottom_right_y),
            boxId: parseInt(boxId, 10),
            unblurred: (blurred === "false"),
            type: frameType,
        };
    });
}

export const loadFile = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                if (file.type === 'application/json') {
                    resolve(parseJsonFile(e.target.result));
                } else if (file.type === 'text/csv') {
                    const text = e.target.result;
                    const rows = text.split('\n').filter(row => row.trim().length > 0);
                    const header = rows[0].split(',').map(item => item.trim());

                    if (header.includes('video_name') && header.includes('model')) {
                        resolve(loadExtractedHeadRects(text));
                    } else if (header.includes('id') && header.includes('frameType')) {
                        resolve(loadRegionSelectorData(text));
                    } else {
                        reject(new Error('Unknown file format'));
                    }
                }
            } catch (error) {
                reject(new Error('Error parsing file: ' + error.message));
            }
        };
        reader.onerror = () => {
            reject(new Error('Error reading file'));
        };
        reader.readAsText(file);
    });
};
