import React, {useContext, useEffect, useState} from "react";
import { withTranslation } from "react-i18next";
import { Button } from "semantic-ui-react";
import {Link} from "react-router-dom";
import ConfigContext from "../../context/ConfigContext";
import SubjectService from "../../SubjectService";
import {getDefinitions} from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import {compose} from "redux";
import {connect} from "react-redux";

const SupportButton = (props) => {
  const { t, definitions } = props;

  const [validSubject, setValidSubject] = useState(false);
  const [validDefinition, setValidDefinition] = useState(false);

  const config = useContext(ConfigContext);
  const supportConfig = config.ui.support;
  const subjectId = supportConfig?.subjectId;
  const definitionCode = supportConfig?.questionnaireCode;
  const questionnaireType = "data";

  useEffect(() => {
    const validateConfig = async () => {
      const isSubjectIdValid = !isNaN(subjectId);
      if (isSubjectIdValid) {
        try {
          const subjectGroups = await SubjectService.getSubjectGroups(subjectId);
          if (subjectGroups) {
            setValidSubject(true);
          }
        } catch (e) {}
      }
      if (definitionCode) {
        let foundDefinition = definitions.find((def) => {
          return definitionCode === def.code && questionnaireType.toUpperCase() === def.type;
        });
        if (foundDefinition) {
          setValidDefinition(true);
        }
      }
    };
    validateConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
    {validSubject && validDefinition && (
        <Button
          basic
          color={"black"}
          as={Link}
          to={
            "/app/subject/" +
            subjectId +
            "/" +
            questionnaireType +
            "/" +
            definitionCode
          }
        >
          {t("SUPPORT_BUTTON_TEXT", "Support")}
        </Button>
    )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    definitions: getDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(SupportButton);
