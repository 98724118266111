import InternationalisationService from '../InternationalisationService';
import RequestHelper, { CONTENT_TYPE } from '../RequestHelper';
import { serverAddress } from '../services/config/EnvConfig';

const serviceBaseUrl = `${serverAddress}/reports`;

const ReportService = {

  ManualDataChangeReport: {
    getBySearchCriteria: async (searchCriteria, format) => {
      const getUrl = (format) => {
        const searchUrl = `${serviceBaseUrl}/manualdatachange/search`;
        return format == null
          ? searchUrl
          : `${searchUrl}/export/${format}`
      };
      const addSpecificHeaders = (headers) => {
        switch (format) {
          case 'xls':
          case 'csv':
            headers['Accept'] = CONTENT_TYPE.APPLICATION_OCTETSTREAM;
            break;
          default:
            break;
        }
        return headers;
      };

      const url = getUrl(format);
      const requestBody = {
        siteCodes:
          searchCriteria?.siteCodes !== undefined
            ? searchCriteria.siteCodes
            : [],
        dateFrom: searchCriteria.dateFrom,
        dateTo: searchCriteria.dateTo,
        questionnaireDefinitionCodes:
          searchCriteria?.questionnaireDefinitionCodes !== undefined
            ? searchCriteria.questionnaireDefinitionCodes
            : [],
        questionnaireId: searchCriteria.questionnaireId
      };
      const headers = addSpecificHeaders({ 'Accept-Language': InternationalisationService.getLanguage() });
      const response = await RequestHelper.send(
        url,
        headers,
        'POST',
        null,
        requestBody
      );
      return response;
    },
  }

};

export default ReportService;
