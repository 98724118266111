/* javascript-obfuscator:disable */
export const serverAddress = process.env.REACT_APP_SERVER_ADDRESS

export const serverAuthClientId = process.env.REACT_APP_SERVER_CLIENT_ID
export const serverAuthHeader = process.env.REACT_APP_SERVER_BASIC_AUTHORIZATION_HEADER_VALUE
export const publicUrl = process.env.PUBLIC_URL

export const firebaseConfig = {
    apiKey: process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_API_KEY,
    projectId: process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_PROJECT_ID,
    messagingSenderId:
    process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_THIRD_PARTY_FIREBASE_FCM_APP_ID,
};
/* javascript-obfuscator:enable */
