import {Button, Form, Segment} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import Page from "../../components/page/Page";
import StaffService from "../../StaffService";
import AuthService from "../../services/AuthService";

const MultiFactorAuthentication = ({t}) => {

    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [resendDisabled, setResendDisabled] = useState(true);

    const counterInitVal = 30;
    const [timeLeft, setTimeLeft] = useState(counterInitVal);

    useEffect(() => {
        if (timeLeft === 0) {
            setResendDisabled(false);
            return;
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const handleSubmit = async () => {
        if (!code || code.trim() === "") {
            setError(t("MULTI_FACTOR_AUTHENTICATION_ERROR", "Please supply a valid code"));
            return;
        }
        setError('');
        setMessage('');

        try {
            const response = await StaffService.checkMultiFactorAuthenticationCode(code);
            await AuthService.tokenSuccessCallback(response, () => {});
            AuthService.redirectAfterLogin();
        } catch (e) {
            setError(t("MULTI_FACTOR_AUTHENTICATION_INCORRECT_CODE", "Invalid code"));
        }
    }

    const handleResendCode = async () => {
        if (resendDisabled) {
            return;
        }

        setTimeLeft(counterInitVal);
        setResendDisabled(true);
        try {
            await StaffService.resendMultiFactorAuthenticationCode();
            setError('');
            setMessage(t("MULTI_FACTOR_AUTHENTICATION_CODE_RESENT", "The code has been resent"));
        } catch (e) {
            setMessage('');
            setError(t("MULTI_FACTOR_AUTHENTICATION_PROBLEM", "There was a problem sending the code"));
        }
    }

    const resendButtonColour = resendDisabled ? 'grey' : '';
    return (
        <>
            <Page
                name="MULTI_FACTOR_AUTHENTICATION"
                header={t("MULTI_FACTOR_AUTHENTICATION_HEADER", "Multi Factor Authentication")}
                subheader={t("MULTI_FACTOR_AUTHENTICATION_SUBHEADER", "Please enter the code that has been emailed to you!")}>

                <Form size="large">
                    <Segment>
                        <Form.Input
                            fluid
                            icon={"lock"}
                            iconPosition="left"
                            onChange={(e) => setCode(e.target.value)}
                            placeholder={t("MULTI_FACTOR_AUTHENTICATION_CODE", "Multi Factor Authentication code!")}
                        />
                        {error && <div style={{color: 'red', padding: 10}}>{error}</div>}
                        {message && <div style={{color: 'green', padding: 10}}>{message}</div>}

                        <div style={{display: 'flex', gap: '10px'}}>
                            <Button
                                type="submit"
                                primary
                                fluid
                                size="large"
                                onClick={handleSubmit}
                            >
                                {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
                            </Button>
                            <Button
                                disabled={resendDisabled}
                                style={{width:'250px', backgroundColor: resendButtonColour, color: 'white'}}
                                primary
                                fluid
                                size="large"
                                onClick={handleResendCode}
                            >
                                {t("MULTI_FACTOR_AUTHENTICATION_RESEND_CODE", "Resend code in seconds")} {" (" + timeLeft + ")"}
                            </Button>
                        </div>
                    </Segment>
                </Form>

            </Page>
        </>
    );

}

export default withTranslation()(MultiFactorAuthentication);
