import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Page from "../../../components/page/Page";
import { getEventDefinitions } from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import { getCompletedEvents } from "../../../redux/questionnaires/subjectQuestionnairesSlice";
import SubjectAppStateService from "../../../services/SubjectAppStateService";
import SubjectQuestionnaireList from "../../../components/lists/SubjectQuestionnaireList";
import {byDecreasingCompletionDate, bySequenceAndById} from "../../../helpers/sortFunctions";

const EventListPage = (props) => {
  const { t, eventDefinitions, completedEvents } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
    SubjectAppStateService.getSubjectCompletedQuestionnairesFromServer(dispatch);
  }, [dispatch]);

  const nonModuleDefinitions = eventDefinitions.filter((definition) => {
    const hasModule = definition.config?.modules && definition.config.modules.length > 0;
    return !hasModule;
  });

  const nonModuleCompleted = completedEvents.filter((event) => {
    const definition = nonModuleDefinitions.find((def) => def.id === event.definitionId);
    return !!definition;
  });

  return (
    <Page
      name="EVENT_LIST_PAGE"
      header={t("EVENT_LIST_PAGE_HEADER")}
      maxWidth="800px"
    >
      <SubjectQuestionnaireList
        definitionList={nonModuleDefinitions.filter((d) => d.sequence > -1)}
        questionnaireList={null}
        sort={bySequenceAndById}
      />
      {nonModuleCompleted.length > 0 && (
        <SubjectQuestionnaireList
          title={t("GLOBAL_COMPLETED")}
          questionnaireList={nonModuleCompleted}
          definitionList={nonModuleDefinitions}
          sort={byDecreasingCompletionDate}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    eventDefinitions: getEventDefinitions(state),
    completedEvents: getCompletedEvents(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(EventListPage);
