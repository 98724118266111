
function calculateDistance(centroid1, centroid2) {
    return Math.sqrt(Math.pow(centroid1.x - centroid2.x, 2) + Math.pow(centroid1.y - centroid2.y, 2));
}
function calculateCentroid(rect) {
    return [{
        x: (rect.x1 + rect.x2) / 2,
        y: (rect.y1 + rect.y2) / 2
    }, (rect.x2 - rect.x1) * (rect.y2 - rect.y1)];
}

/*
function calculateIoU(rect1, rect2) {
    const x1 = Math.max(rect1.x1, rect2.x1);
    const y1 = Math.max(rect1.y1, rect2.y1);
    const x2 = Math.min(rect1.x2, rect2.x2);
    const y2 = Math.min(rect1.y2, rect2.y2);

    const intersectionArea = Math.max(0, x2 - x1) * Math.max(0, y2 - y1);
    const rect1Area = (rect1.x2 - rect1.x1) * (rect1.y2 - rect1.y1);
    const rect2Area = (rect2.x2 - rect2.x1) * (rect2.y2 - rect2.y1);

    return intersectionArea / (rect1Area + rect2Area - intersectionArea);
}*/

function predictPosition(lastRect, lastSeen, currentFrame) {
    const framesElapsed = currentFrame - lastSeen;
    const velocity = lastRect.velocity || { x: 0, y: 0 };
    return {
        x: lastRect.centroid.x + velocity.x * framesElapsed,
        y: lastRect.centroid.y + velocity.y * framesElapsed
    };
}

const trackObjects = (frameData,trackingSettings) => {
    console.log("Track")
    const maxIds = trackingSettings.maxId; // Define the maximum number of IDs

    const objectHistory = new Map(); // Stores object history
    const threshold = trackingSettings.distanceThreshold;
    const maxFramesLost = trackingSettings.maxFramesLost; // Max frames an object can be lost before assigning a new ID

    const cleanObjectHistory = trackingSettings.clearLostObjects;



    function getAvailableId() {
        for (let id = 0; id <= maxIds; id++) {
            if (!objectHistory.has(id)) {
                return id;
            }
        }
        return -1; // All IDs are in use
    }

    for (let p = frameData.initPos; p <= frameData.endPos; p += 1) {
        let frame = frameData.getDataPos(p);
        if (frame instanceof Array) {
            // Create distance matrix
            let distanceMatrix = [];
            let currentObjects = [];
            for (let r = 0; r < frame.length; r++) {
                let rect = frame[r];
                const [centroid, size] = calculateCentroid(rect.coords);
                rect.centroid = centroid;
                if (size === 0) continue;

                currentObjects.push(rect);
                let distances = [];
                objectHistory.forEach((value, key) => {
                    const lastSeenFrame = value.lastSeen;
                    const lastRect = value.rect;
                    const predictedCentroid = predictPosition(lastRect, lastSeenFrame, p);
                    const distance = calculateDistance(predictedCentroid, rect.centroid);
                    distances.push({ id: key, distance });
                });
                distanceMatrix.push(distances);
            }

            // Assign IDs based on minimum distances
            let assignedIds = new Set();
            for (let i = 0; i < currentObjects.length; i++) {
                let rect = currentObjects[i];
                rect.boxId = undefined;
                let distances = distanceMatrix[i];
                distances.sort((a, b) => a.distance - b.distance);

                for (let j = 0; j < distances.length; j++) {
                    let { id, distance } = distances[j];
                    if (distance < threshold && !assignedIds.has(id)) {
                        rect.boxId = id;
                        const lastRect = objectHistory.get(id).rect;
                        const velocity = {
                            x: (rect.centroid.x - lastRect.centroid.x) / (p - objectHistory.get(id).lastSeen),
                            y: (rect.centroid.y - lastRect.centroid.y) / (p - objectHistory.get(id).lastSeen)
                        };
                        objectHistory.set(rect.boxId, { lastSeen: p, rect, velocity });
                        assignedIds.add(id);
                        break;
                    }
                }

                if (rect.boxId === undefined) {
                    // No match found, assign a new ID
                    const newId = getAvailableId();
                    rect.boxId = newId !== -1 ? newId : maxIds;
                    if (newId !== -1) {
                        objectHistory.set(rect.boxId, { lastSeen: p, rect, velocity: { x: 0, y: 0 } });
                    }
                }
            }
        }

        if(cleanObjectHistory){
            // Clean up object history to remove objects not seen for too long
            objectHistory.forEach((value, key) => {
                if (p - value.lastSeen > maxFramesLost) {
                    objectHistory.delete(key);
                }
            });
        }

    }
}

export default trackObjects;
