import React, {useCallback, useRef} from 'react';
import { Line } from 'react-chartjs-2';
import { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
  } from 'chart.js';

  import annotationPlugin from "chartjs-plugin-annotation";

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, annotationPlugin);


const ChannelListScatterChart = (props) => {
  const {selectedPosition, channelList , minX, maxX, minRight, maxRight,  minLeft, maxLeft, externalAnnotations = []} = props


  const chartRef = useRef(null);

  //console.log(externalAnnotations)
  let width = 800


  const [dataSets, setDataSets] = useState([]);

  const handleDataChanged = useCallback( (chan) => {

    if(chartRef.current){
      let d = []

      for (let i = 0; i < channelList.numChannels(); i++) {
        const chan = channelList.getChannel(i)
        d.push({
          label: chan.dataChannel.name,
          data: chan.dataChannel.empty?{x:0,y:0}:chan.dataChannel.getTimeRangeMap(0, chan.dataChannel.getEndTime(), chan.dataChannel.getDuration() / width, 0.001),
          backgroundColor: chan.lineColor, // Adjust as needed
          pointRadius: 0, // Adjust the point radius
          showLine: true,
          borderColor: chan.lineColor, // Set the color for the connecting line
          borderWidth: chan.lineWidth,
          yAxisID: chan.yAxisID,
          cubicInterpolationMode: 'monotone',
          tension: 0.4
        })
      }
      setDataSets(d)
      chartRef.current.update()
    }

  },[channelList,width]);

  useEffect(() => {
    if(channelList){
      channelList.addEventListener('dataChanged', handleDataChanged);
      channelList.emitDataChangedEvent();
    }


    return () => {
      if(channelList)
        channelList.removeEventListener('dataChanged', handleDataChanged);
    };
  }, [handleDataChanged,channelList]);



  const data = {
    datasets: dataSets
  };

  const options = {
    animation: {
      duration: 0, // Set animation duration to 0 milliseconds
    },

    layout: {
      padding: 20
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: minX,         // Set the minimum value of the x-axis
        max: maxX,       // Set the maximum value of the x-axis
      },
      y: {
        id: 'Left',
        type: 'linear',
        position: 'left',
        min: minLeft,
        max: maxLeft,
        ticks: {
          max: maxLeft,
          min: minLeft
        },
        // Hide grid lines, otherwise you have separate grid lines for the 2 y axes
        grid: { display: false }
      },
      y1: {
        id: 'Right',
        type: 'linear',
        position: 'right',
        ticks: {
          max: maxRight,
          min: minRight
        },
        grid: { display: false }
      },
    },
    plugins: {
      annotation: {
        annotations: [
          {
            type: 'line',
            id: 'verticalLine',
            mode: 'vertical',
            scaleID: 'x',

            value: selectedPosition, // Replace with your slider value
            borderColor: 'red',
            borderWidth: 2,
            label: {
              content: 'Slider Position', // Replace with your label content
              enabled: true,
              position: 'top',
            },
          },
          ...externalAnnotations
        ],
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };



  return (
    <div style={charStyle}>
       <Chart chartRef = {chartRef} data={data} options = {options}/>
    </div>
  );
};

const Chart  = React.memo((props) => {
  const {chartRef, data, options} = props

  return (
    <Line ref = {chartRef} data={data} options={options} />
  )
},(prevProps, currentProps) => { return false; /*return prevProps.data.datasets === currentProps.data.datasets;*/} )

const charStyle = {
  width: '100%',
  height: 400,
}



export default React.memo(ChannelListScatterChart);
