import React, {useState, useEffect, useMemo, useCallback} from "react";
import { Dropdown } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import InternationalisationService from "../../InternationalisationService";
import {uniqBy} from 'lodash';

const LanguageSelectionGeneric = ({ inline, fluid, callback, language, useMobileLanguages=false, useWebLanguages=true, withCode = false, multiselect = false, disabled = false }) => {
  const {t} = useTranslation();

  const [languageOptions, setLanguageOptions] = useState(null);

  const showLanguageFlags = !(Window.configuration.ui.showLanguageFlags === "false");

  const getFlagImageUrl = (code) => {
    if (code) {
      const countryCode = code.substr(code.indexOf("_") + 1);
      return "/images/flags/" + countryCode.toUpperCase() + ".png";
    } else {
      return "/images/flags/_unknown.png";
    }
  }
  const getLanguages = useCallback(async () => {
    const getWebLanguages = useWebLanguages ? await InternationalisationService.getLanguages() : new Promise(r=>r([]))
    const getMobileLanguages = useMobileLanguages ? await InternationalisationService.getMobileLanguages() : new Promise(r=>r([]))

    const [webLanguages, mobileLanguages] = await Promise.all([getWebLanguages, getMobileLanguages]);
    const languageOptions = uniqBy([...webLanguages, ...mobileLanguages], l=>l.code).map((language) => ({
      key: language.code,
      value: language.code,
      text: language.name + (withCode ? ' (' + language.code + ')':''),
      image: showLanguageFlags
          ? { src: getFlagImageUrl(language.code), alt: language.code }
          : null,
    }));
    setLanguageOptions(languageOptions);
  }, [showLanguageFlags, useMobileLanguages, useWebLanguages, withCode])
  useEffect(() => {
    getLanguages();
  }, [getLanguages, showLanguageFlags]);

  const changeLanguage = (event, {value}) => {
    callback(value)
  };

  const hasLoaded = useMemo(()=>{
    return languageOptions != null;
  }, [languageOptions])

  if(!hasLoaded){
    return null;
  }

  return(
      <div style={{ display: "flex", alignItems: "center" }}>
        <Dropdown
          inline={inline}
          disabled={disabled}
          selection={!inline}
          fluid={fluid}
          options={languageOptions}
          value={language}
          onChange={changeLanguage}
          placeholder={t("LANGUAGE_SELECTION_PLACEHOLDER")}
          multiple={multiselect}
        />
      </div>
    );

}

export default LanguageSelectionGeneric;
