import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown, Form, Grid, Header, Message } from 'semantic-ui-react';
import StaffSubjectLinkCard from '../../pages/staff/StaffSubjectLinkCard';
import AparitoSwitch from '../questionnaire/AparitoSwitch';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import StaffService from '../../StaffService';
import GroupService from '../../services/GroupService';
import SubjectService from '../../SubjectService';
import AuthService from '../../services/AuthService';
import { genericCodeAndLabelToDropDownOption } from '../../helpers/mapFunctions';

const StaffSubjectLinking = ({
  t,
  allGroups,
  staff,
  initialLinkingData,
  emailAddress,
  onLinkingDataUpdate,
  onLinkingControlVisibilityChange
}) => {
  const [isStaffToSubjectLinkingEnabled, setIsStaffToSubjectLinkingEnabled] = useState(false);

  const [linkingData, setLinkingData] = useState({
    ...initialLinkingData,
    isOverrideGroupChecked: false
  });

  const [linkableGroups, setLinkableGroups] = useState([]);
  const [showLinkingControls, setShowLinkingControls] = useState(false);
  const [doesSubjectWithEmailExist, setDoesSubjectWithEmailExist] = useState(false);

  useEffect(() => {
    const isEnabled = async () => {
      const value = await StaffService.isStaffToSubjectLinkingEnabled();
      setIsStaffToSubjectLinkingEnabled(value);
      return value;
    }
    if (!isEnabled()) {
      return;
    }
    populateDefaultGroupForLinking();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (allGroups == null) {
      return;
    }
    buildGroupDropDownOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allGroups]);


  const checkLinkedSubjectExistence = () => {
    const isEmailAddressValid = (value) => {
      return (value?.length > 0 && AuthService.EMAIL_REGEX.test(value));
    }
    if (isEmailAddressValid(emailAddress)) {
      //check if subject with email exists
      SubjectService.doesSubjectExistWithEmail(emailAddress).then((subjectResult) => {
        if (subjectResult?.Id !== undefined) {
          //subject with email address exists, remove control
          setShowLinkingControls(false);
          onLinkingControlVisibilityChange && onLinkingControlVisibilityChange(false);
          setDoesSubjectWithEmailExist(true);
        } else {
          //subject with email doesnt exist, show controls
          setShowLinkingControls(true);
          onLinkingControlVisibilityChange && onLinkingControlVisibilityChange(true);
          setDoesSubjectWithEmailExist(false);
        }
      });
    }
  };

  useEffect(() => {
    if (linkingData.isCreateLinkedAccountChecked) {
      checkLinkedSubjectExistence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress])

  useEffect(() => {
    onLinkingDataUpdate && onLinkingDataUpdate(linkingData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkingData]);

  const buildGroupDropDownOptions = async () => {
    const dropDownItems = (allGroups ?? [])
      .filter(g => g.membersLinkable)
      .map(genericCodeAndLabelToDropDownOption);
    dropDownItems.unshift({
      key: 'select',
      text: t('STAFF_FORM_SELECT_GROUP', 'Select Group'),
      value: 'select_group',
    });
    setLinkableGroups(dropDownItems);
  }

  const populateDefaultGroupForLinking = async () => {
    try {
      const response = await GroupService.getDefaultGroupForNewLinkedSubject();
      const value = response?.defaultGroup ?? '';
      setLinkingData({
        ...linkingData,
        selectedLinkedGroup: value
      });
    } catch (error) {
      setLinkingData({
        ...linkingData,
        selectedLinkedGroup: ''
      });
    }
  };

  if (!isStaffToSubjectLinkingEnabled) {
    return null;
  }

  const handleUnlinking = () => {
    setLinkingData({
      ...linkingData,
      isLinked: false
    });
  };

  const handleIsCreateLinkedAccountChanged = (e) => {
    const isChecked = e.target.checked;
    setLinkingData({
      ...linkingData,
      isCreateLinkedAccountChecked: isChecked
    });
    if (isChecked) {
      checkLinkedSubjectExistence();
    }
  };

  const handleLinkedGroupChange = (event, item) => {
    if (item.value === 'select_group') {
      return;
    }
    const selected = item.options.find(group => {
      return group.key === item.value
    });
    setLinkingData({
      ...linkingData,
      selectedLinkedGroupDropdown: selected.key
    });
  }

  const handleShouldOverrideGroupChange = (value) => {
    setLinkingData({
      ...linkingData,
      isOverrideGroupChecked: value
    });
  }

  const showGroupSelectionMessage = linkingData.isCreateLinkedAccountChecked
    && linkingData.isOverrideGroupChecked === false
    && showLinkingControls
    && linkingData.selectedLinkedGroupDropdown.trim().length === 0;

  return (
    <>

      {isStaffToSubjectLinkingEnabled && (<Grid.Row style={{ paddingTop: '0.5em' }}>
        <>
          <Header>{t('STAFF_TO_SUBJECT_LINK', 'Participant linking')}</Header>
          <Form.Group>
            <Form.Field width={16}>
              {!linkingData?.isLinked && (<Form.Field>
                <label>{t('STAFF_FORM_LINK_ACCOUNT_LABEL', 'Create linked participant / link if participant already exists?')}</label>
                <input
                  type='checkbox'
                  checked={linkingData.isCreateLinkedAccountChecked}
                  onChange={handleIsCreateLinkedAccountChanged}
                />
              </Form.Field>)}
              {linkingData.isCreateLinkedAccountChecked &&
                doesSubjectWithEmailExist && (
                  <label style={{ marginTop: '5px' }}>{t('STAFF_FORM_SUBJECT_EXISTS_LABEL', 'Participant with matching email address already exist, they will be linked!')}</label>
                )
              }

              {linkingData.isCreateLinkedAccountChecked &&
                emailAddress.length === 0 && (
                  <label style={{ marginTop: '5px' }}>{t('STAFF_FORM_SUBJECT_NO_EMAIL__LABEL', 'Enter new staff member email address')}</label>
                )
              }

              {linkingData.isCreateLinkedAccountChecked &&
                linkableGroups.length === 1 &&
                showLinkingControls && (
                  <>
                    <label>{t('STAFF_FORM_SUBJECT_GROUP_LABEL', 'No groups configured for linking participants')}</label>
                    <Link to={'/app/groups'}>{t('STAFF_FORM_SUBJECT_EDIT_GROUP_LABEL', 'Manage groups here')}</Link>
                  </>
                )
              }

              {linkingData.isCreateLinkedAccountChecked &&
                linkableGroups.length > 1 &&
                showLinkingControls && (
                  <Form.Field style={{ paddingTop: '1.0em' }}>
                    <label>{t('STAFF_FORM_SUBJECT_GROUP_LABEL', 'Assign the participant to the following group (only groups with linking enabled are selectable):')}</label>
                    {showGroupSelectionMessage && (
                      <Message negative>
                        {t('STAFF_FORM_SELECT_GROUP_ERROR', 'Please select a group')}
                      </Message>
                    )}

                    <Dropdown
                      placeholder={t('STAFF_FORM_SELECT_GROUP_PLACEHOLDER', 'Select Group')}
                      fluid
                      selection
                      style={{ background: !linkingData.isOverrideGroupChecked ? 'white' : '#ddd' }}
                      options={linkableGroups}
                      value={linkingData.selectedLinkedGroupDropdown}
                      disabled={linkingData.isOverrideGroupChecked}
                      onChange={handleLinkedGroupChange}
                    />
                  </Form.Field>
                )}

              {linkingData.isCreateLinkedAccountChecked &&
                emailAddress.length > 0 && (
                  <Grid columns={'equal'} style={{ paddingTop: '1.0em' }}>
                    <Grid.Column>
                      <AparitoSwitch style={styles.aparitoSwitch}
                        checked={linkingData.isOverrideGroupChecked}
                        onChange={handleShouldOverrideGroupChange}
                        label={t('STAFF_FORM_DEFAULT_SUBJECT_GROUP_LABEL', 'Override group')} />
                    </Grid.Column>
                    <Grid.Column>
                      <input
                        disabled={!linkingData.isOverrideGroupChecked}
                        required
                        style={{ background: linkingData.isOverrideGroupChecked ? 'white' : '#ddd' }}
                        value={linkingData.selectedLinkedGroup}
                        onChange={(e) => {
                          setLinkingData({
                            ...linkingData,
                            selectedLinkedGroup: e.target.value
                          });
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                )}

            </Form.Field>
          </Form.Group>

          {staff == null &&
            t('STAFF_MANNUAL_ASSIGNING_WARNING', 'Manually assigning participants is only available when editing existing staff members!')
          }

          {staff != null &&
            (<StaffSubjectLinkCard
              index={0}
              blink={false}
              staffEntity={staff}
              groupLabels={undefined}
              handleUnlinking={handleUnlinking}
              handleUpdateButtonState={undefined} />)
          }
        </>
      </Grid.Row>)}

    </>
  );
};

const styles = {
  aparitoSwitch: {
    marginRight: '20px',
    width: '250px',
    height: '250px',
    backgroundColor: 'yellow'
  }
};

export default withTranslation()(StaffSubjectLinking);
