import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import SubjectService from "../../SubjectService";
import BrandingService from "../../services/BrandingService";
import ConfigContext from "../../context/ConfigContext";
import SUBJECT_AUTHENTICATION_STRATEGY from "../../constants/SUBJECT_AUTHENTICATION_STRATEGY";
import TrialService from "../../TrialService";
import InternationalisationService from "../../InternationalisationService";
import ConfigService from "../../services/ConfigService";
import StaffService from "../../StaffService";
import {typeHelper} from "atom5-branching-questionnaire";
import {useLocation} from "react-router-dom";

const LoginModal = (props) => {
  const { t, handleForgottenPassword } = props;

  const location = useLocation();

  // Create a URLSearchParams object from the query string
  const queryParams = new URLSearchParams(location?.search);

  const [username, setUsername] = useState(queryParams?.get('email'));
  const [password, setPassword] = useState("");
  const [requestPending, setRequestPending] = useState(false);
  const [errorType, setErrorType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const config = useContext(ConfigContext);
  const [isTestEnvironment, setIsTestEnvironment] = useState(false);

  let brandImageUrl;
  if (config?.ui?.onboardingBrandImage) {
    brandImageUrl = BrandingService.getBrandImageForOnBoarding();
  }

  const [subjectAuthenticationStrategy, setSubjectAuthenticationStrategy] =
    useState(TrialService.getDefaultSubjectAuthenticationStrategy());
  
  const fetchTrialAuthenticationSettings = async () => {
    setSubjectAuthenticationStrategy(
      await TrialService.getSubjectAuthenticationStrategy()
    );
  };

  const fetchTrialSettings = async () => {
    const trial = await TrialService.getCurrentTrial();
    setIsTestEnvironment(trial.isTest);
  };

  useEffect(() => {
    fetchTrialSettings();
    fetchTrialAuthenticationSettings();
  }, []);

  const validateForm = () => {
    return username?.length && password?.length > 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorType("");
    setErrorMessage("");
    setRequestPending(true);

    try {
      const result = await AuthService.login(username, password);
      if (!result) {
        return;
      }
      setRequestPending(false);

      if (typeHelper.parseBool(config?.ui?.mandatoryStaffTraining?.enforce)) {
        await StaffService.redirectIfStaffHasOutstandingMandatoryPros(username, password);
      }

      const showScreeningQuestionnaires = await SubjectService.shouldShowScreeningQuestionnaire()
      AuthService.redirectAfterLogin(showScreeningQuestionnaires);

    } catch (error) {
      setPassword("");
      setRequestPending(false);
      setErrorType(error);
      switch (error) {
        case "bad credentials":
          setErrorMessage(
            t(
              "LOGIN_FAILED_CREDENTIALS",
              "The email address or password entered was incorrect."
            )
          );
          break;
        case "account locked":
          setErrorMessage(
            t(
              "LOGIN_FAILED_LOCKED",
              "Your account has been locked, please reset your password to regain access to your account."
            )
          );
          break;
        case "password expired":
          setErrorMessage(
            t(
              "LOGIN_FAILED_EXPIRED",
              "Your account's password has expired, please reset your password to regain access to your account."
            )
          );
          break;
        default:
          setErrorMessage(
            t("LOGIN_FAILED_UNKNOWN", "An unknown error occured")
          );
      }
    }
  };

  const shouldDisablePasswordAutoComplete =
    !isTestEnvironment && ConfigService.shoulDisablePasswordAutoComplete();

  return (
    <Form style={styles.container} onSubmit={handleSubmit}>
      <Grid padded>
        <Grid.Row>
          <Grid.Column>
            <Image
                style={{ height: "25px" }}
                src={
                  "./images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                }
                alt={"Aparito Logo"}
            />
          </Grid.Column>
        </Grid.Row>
        {brandImageUrl && (
            <Grid.Row
                centered
                rows={3}
                style={{
                  backgroundImage: `url(${brandImageUrl})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  height: "100px",
                }}
            />
        )}
        <Grid.Row centered columns={3}>
          <Grid.Column
              width={10}
              textAlign={
                InternationalisationService.isRTL() ? "right" : undefined
              }
          >
            <Header as="h3" textAlign="center">
              {t("LOGIN_HEADER_TITLE", "Log in to your account")}
            </Header>
            {errorMessage.length > 0 && (
                <Message negative>
                  {errorMessage}
                  {["account locked", "password expired"].includes(errorType) && (
                      <Button onClick={handleForgottenPassword} color={"black"} basic fluid>
                        {errorType === "account locked" &&
                            t("LOGIN_FAILED_LOCKED_BUTTON", "Reset Password")}
                        {errorType === "password expired" &&
                            t("LOGIN_FAILED_EXPIRED_BUTTON", "Reset Password")}
                      </Button>
                  )}
                </Message>
            )}

            <div style={{height:"2vh"}} />

            <Form.Input
                fluid
                icon={
                  subjectAuthenticationStrategy ===
                  SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD
                      ? "user"
                      : "mail"
                }
                iconPosition="left"
                placeholder={
                  subjectAuthenticationStrategy ===
                  SUBJECT_AUTHENTICATION_STRATEGY.SUBJECTCODE_PWD
                      ? t("GLOBAL_LABEL_USERNAME")
                      : t("GLOBAL_LABEL_EMAIL_ADDRESS", "Email address")
                }
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                disabled={requestPending}
            />

            <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder={t("GLOBAL_LABEL_PASSWORD", "Password")}
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                disabled={requestPending}
                autoComplete={shouldDisablePasswordAutoComplete ? "off" : null}
            />

            <div style={{height: "2vh"}}/>

            <Button
                type="submit"
                primary
                fluid
                size="large"
                disabled={!validateForm() || requestPending}
            >
              {t("GLOBAL_BUTTON_SUBMIT", "Submit")}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid centered padded>
        <Grid.Row>
          <Button
              basic
              color={"black"}
              size="small"
              disabled={requestPending}
              onClick={handleForgottenPassword}
          >
            {t("FORGOTTEN_PASSWORD", "Forgotten Password")}
          </Button>
        </Grid.Row>
      </Grid>
    </Form>
  );
};

const styles = {
  container: {
    minHeight: "420px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
};

export default withTranslation()(LoginModal);
