import React from 'react';
import {convertToStandardAnatomyFormat} from "../../../../constants/BODY_PARTS";

const AnatomyDiagram = (props) => {
  const { bodyPartData, bodyType, bodyPartArray } = props;
  return (
    <>
      {bodyType === bodyPartData.diagram.type && <path d={bodyPartData.diagram.svg} stroke='#58595B' strokeWidth='2' />}
      {Object.entries(bodyPartData.bodyParts).map((entry) => {
        return (
          <>
            {bodyPartArray.map(convertToStandardAnatomyFormat).indexOf(entry[0]) > -1 && (
              <rect x={entry[1].dot.x+5} y={entry[1].dot.y+5} width='10' height='10' rx='10' fill='#F8991D' />
            )}
          </>
        );
      })}
    </>
  );
};

export default AnatomyDiagram;
