import React, { useCallback, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Button,
  Checkbox,
  Form,
  Grid,
  Input,
  TextArea,
  Label,
  Message,
  Modal, Dropdown, Loader, Icon,
} from "semantic-ui-react";
import TRIGGER_STATE from "../../constants/TRIGGER_STATE";
import CronExpressionBuilder from "../../pages/utils/components/triggers/cron/CronExpressionBuilder";
import ConfirmButtonWithFeedback from "../dashboard/ConfirmButtonWithFeedback";
import ModuleService from "../../services/ModuleService";
import ERROR_TRANSLATION_MAP from "../../utility/ErrorTranslation";
import TriggerService from "../../services/admin/TriggerService";

function AdminTriggerEditComponent({
  t,
  trigger,
  isInViewModal,
  onChange,
  onSubmit,
  onSoftDelete,
  validationErrors = [],
}) {
  const [isCronModalOpen, setIsCronModalOpen] = useState(false);

  const getTriggerStateChangeButton = (
    onComplete,
    buttonText,
    isPositive = true
  ) => {
    return (
      <ConfirmButtonWithFeedback
        fluid
        buttonText={t(buttonText)}
        headerText={t(buttonText)}
        contentText={t(
          "ADMIN_TRIGGER_EDIT_REASON_PROMPT",
          "Please give a reason why this is being changed and confirm."
        )}
        confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
        cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
        onConfirm={onComplete}
        placeholderText={t(
          "ADMIN_TRIGGER_EDIT_REASON_PLACEHOLDER_TEXT",
          "Reason"
        )}
        mandatoryValidationText={t(
          "ADMIN_TRIGGER_EDIT_REASON_VALIDATION_TEXT",
          "Please supply a reason for the change."
        )}
        color={!isPositive ? "negative" : "orange"}
      />
    );
  };

  const createValidationMessageDisplay = (key) => (validationError) => {
    if (validationError.key !== key) {
      return null;
    }

    return (
      <Message negative>
        {t(
          validationError.errorCode,
          ERROR_TRANSLATION_MAP[validationError.errorCode]
        )}
      </Message>
    );
  };

  let isJson;
  try {
    JSON.parse(trigger.action);
    isJson = true;
  } catch (e) {
    isJson = false;
  }

  const actions = isJson ? JSON.parse(trigger.action) : [trigger.action];
  const moduleSpecification = trigger?.moduleSpecification ? JSON.parse(trigger?.moduleSpecification) : [];

  const handleActionChange = async (index, value) => {
    const newActions = [...actions];
    if (value.trim().length === 0) {
      newActions.splice(index, 1);
    } else {
      newActions[index] = value;
    }

    const isSingleValue = newActions.length < 2;
    await onChange(null, {
      name: "action",
      value: isSingleValue ? newActions[0] : JSON.stringify(newActions),
    });
    let elem = document.getElementById("action-" + index);
    if (elem) {
      elem.focus();
    }
  };

  const [modules, setModules] = useState(null);
  const [accountStatesOptions, setAccountStatesOptions] = useState([]);

  const getModules = useCallback(async () => {
    const modules = await ModuleService.getModules();
    setModules(modules);
  }, [])

  const getSubjectAccountStates = useCallback(async () => {
    const states = await TriggerService.listPermittedAccountStates();
    const listOptions = states
        .filter((val) => {
          return val !== 'SUSPENDED'
        })
        .map((val) => {
          return  {key: val, value: val, text:t("SUBJECT_STATE_" + val, val)}
        }
    )
    setAccountStatesOptions(listOptions);
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    getModules();
    getSubjectAccountStates();
  }, [getModules,getSubjectAccountStates])
  const moduleOptions = useMemo(() => {
    if (!modules) return [];
    return modules.map(m => {
      return {
        key: m.code,
        text: m.code,
        value: m.code,
      }
    })
  }, [modules]);
  const specificationOptions = [
    {
      key: "create",
      text: t("MODULE_SPECIFICATION_CREATE", "Create"),
      value: "create",
    },
    {
      key: "show",
      text: t("MODULE_SPECIFICATION_SHOW", "Show"),
      value: "show",
    }
  ]
  const handleModuleSpecificationChange = async (index, value, type) => {
    const newModuleSpecification = [...moduleSpecification];
    let [module, specification] = moduleSpecification[index] ? moduleSpecification[index].split("://") : ["", ""];
    if (type === "module") module = value;
    if (type === "specification") specification = value;

    if (type === "delete") {
      newModuleSpecification.splice(index, 1);
    } else {
      newModuleSpecification[index] = module + "://" + specification;
    }

    await onChange(null, {
      name: "moduleSpecification",
      value: JSON.stringify(newModuleSpecification),
    });
  }

  if (!modules) return <Loader active={true} />

  return (
    <Form>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {validationErrors.map(
              createValidationMessageDisplay("questionnaireTrigger")
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("TRIGGER_NAME", "ID/Name")}</label>
              <Input
                value={trigger?.name}
                name={"name"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("name"))}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>
                {t("TRIGGER_ACTIVATION_WINDOW", "Activation Window")}
              </label>
              <Input
                value={trigger?.activationWindow}
                name={"activationWindow"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("activationWindow")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("TRIGGER_FREQUENCY", "Frequency")}</label>
              <Input
                value={trigger?.frequency}
                name={"frequency"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("frequency")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("TRIGGER_OFFSET", "Offset")}</label>
              <Input
                value={trigger?.offset}
                name={"offset"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("offset"))}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
            {
              getTriggerStateChangeButton(
                onSubmit,
                t("ADMIN_TRIGGER_SAVE", "Save")
              )}
            {isInViewModal && (
              <Button
                style={{ marginTop: '4px' }}
                primary
                fluid
                size="tiny"
                as={Link}
                to={"/app/utils/triggers/edit/" + trigger.id}
              >
                {t("ADMIN_TRIGGER_EDIT", "Full Edit")}
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <Form.Field>
              <label>{t("TRIGGER_MAX_OCCURRENCES", "Max Occurrences")}</label>
              <Input
                value={trigger?.maxOccurrences}
                name={"maxOccurrences"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("maxOccurrences")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Field>
              <label>{t("TRIGGER_SHOULD_NOTIFY", "should Notify?")}</label>
              <Checkbox
                checked={trigger?.shouldNotify}
                name={"shouldNotify"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("shouldNotify")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={3}>
            <Form.Field>
              <label>{t("TRIGGER_BREAK_PARENT_CHAIN", "should Break Parent Chain?")}</label>
              <Checkbox
                checked={trigger?.shouldBreakParentChain}
                name={"shouldBreakParentChain"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("shouldBreakParentChain")
              )}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            <Form.Field>
              {trigger.isActive && trigger.id && (
                <Label color="green" horizontal>
                  {t(TRIGGER_STATE.ACTIVE.label)}
                </Label>
              )}
              {!trigger.isActive && trigger.id && (
                <Label color="red" horizontal>
                  {t(TRIGGER_STATE.INACTIVE.label)}
                </Label>
              )}
              {!trigger.id && (
                <>
                  <label>{t("TRIGGER_IS_ACTIVE", "is Active?")}</label>
                  <Checkbox
                    checked={trigger?.isActive}
                    name={"isActive"}
                    fluid
                    onChange={onChange}
                  />
                </>
              )}
              {validationErrors.map(createValidationMessageDisplay("isActive"))}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} >
            {
                trigger.id &&
                trigger.isActive &&
                getTriggerStateChangeButton(
                    onSoftDelete,
                    TRIGGER_STATE.INACTIVE.actionLabel
                )}
            {
                trigger.id &&
                !trigger.isActive &&
                getTriggerStateChangeButton(
                    onSoftDelete,
                    TRIGGER_STATE.ACTIVE.actionLabel,
                    false
                )}
          </Grid.Column>
          <Grid.Column width={5} >
            <Form.Field>
              <label >
                {t("ADMIN_TRIGGER_PERMITTED_ACCOUNT_STATE_LEVEL", "Permitted Participant Account-State level")}
              </label>
              <Dropdown
                  fluid={true}
                  name={"permittedSubjectAccountStateLevel"}
                  selection
                  options={accountStatesOptions}
                  value={trigger?.permittedSubjectAccountStateLevel}
                  onChange={onChange}>
              </Dropdown>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4}>
          </Grid.Column>
          <Grid.Column width={10}>
            <Form.Field>
              <label>{t("TRIGGER_TRIGGER", "Trigger")}</label>
              <Input
                value={trigger?.trigger}
                name={"trigger"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(createValidationMessageDisplay("trigger"))}
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={2}>
            {!isInViewModal && (
              <Form.Field>
                <Button primary onClick={() => setIsCronModalOpen(true)}>
                  {t("TRIGGER_CREATE_CRON", "Generate CRON Trigger")}
                </Button>
              </Form.Field>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <Form.Field>
              <label>{t("TRIGGER_ACTION", "Action")}</label>
              {actions.map((action, index) => (
                <Form.Field key={"action" + index}>
                  <Input
                    id={"action-" + index}
                    value={action}
                    name={"action"}
                    fluid
                    onChange={(_e, data) =>
                      handleActionChange(index, data.value)
                    }
                  />
                </Form.Field>
              ))}
              {!isInViewModal && (
                <Form.Field>
                  <Input
                    value={""}
                    name={"action"}
                    fluid
                    transparent={isInViewModal}
                    disabled={isInViewModal}
                    onChange={(_e, data) =>
                      handleActionChange(actions.length, data.value)
                    }
                  />
                </Form.Field>
              )}
              {validationErrors.map(createValidationMessageDisplay("action"))}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <Form.Field>
              <label>{t("TRIGGER_MODULE_SPECIFICATION", "Module Specification")}</label>
              {moduleSpecification.map((mS, index) => {
                const [module, specification] = mS.split("://")
                return <Form.Field key={"module-" + index}>
                  <Grid>
                    <Grid.Column width={8}>
                      <Dropdown
                        key={"module-" + index}
                        id={"module-" + index}
                        value={module}
                        name={"module"}
                        fluid
                        selection
                        transparent={isInViewModal}
                        disabled={isInViewModal}
                        options={moduleOptions}
                        onChange={(_e, data) =>
                          handleModuleSpecificationChange(index, data.value, "module")
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={isInViewModal ? 8 : 6}>
                      <Dropdown
                        key={"specification-" + index}
                        id={"specification-" + index}
                        value={specification}
                        name={"specification"}
                        fluid
                        selection
                        transparent={isInViewModal}
                        disabled={isInViewModal}
                        options={specificationOptions}
                        onChange={(_e, data) =>
                          handleModuleSpecificationChange(index, data.value, "specification")
                        }
                      />
                    </Grid.Column>
                    {!isInViewModal && <Grid.Column width={2}>
                      <Button
                        color={"red"}
                        backgroundColor={"red"}
                        onClick={(_e, data) =>
                          handleModuleSpecificationChange(index, null, "delete")
                        }
                      ><Icon name={"times"} color={"white"} style={{ margin: 0 }} /></Button>
                    </Grid.Column>}
                  </Grid>
                </Form.Field>
              })}
              {!isInViewModal && (
                <Form.Field>
                  <Grid>
                    <Grid.Column width={8}>
                      <Dropdown
                        key={"module"}
                        id={"module"}
                        name={"module"}
                        fluid
                        selection
                        transparent={isInViewModal}
                        disabled={isInViewModal}
                        options={moduleOptions}
                        value={""}
                        onChange={(_e, data) =>
                          handleModuleSpecificationChange(moduleSpecification.length, data.value, "module")
                        }
                      />
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Dropdown
                        key={"specification"}
                        id={"specification"}
                        name={"specification"}
                        fluid
                        selection
                        transparent={isInViewModal}
                        disabled={isInViewModal}
                        options={specificationOptions}
                        value={""}
                        onChange={(_e, data) =>
                          handleModuleSpecificationChange(moduleSpecification.length, data.value, "specification")
                        }
                      />
                    </Grid.Column>
                  </Grid>
                </Form.Field>
              )}
              {validationErrors.map(createValidationMessageDisplay("action"))}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={4} />
          <Grid.Column width={12}>
            <Form.Field>
              <label>{t("TRIGGER_CONDITION", "Condition")}</label>
              <TextArea
                value={trigger?.condition}
                name={"condition"}
                fluid
                onChange={onChange}
              />
              {validationErrors.map(
                createValidationMessageDisplay("condition")
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Modal open={isCronModalOpen}>
        <Modal.Content>
          <CronExpressionBuilder
            initialValue={
              trigger?.trigger?.startsWith("cron://") ? trigger.trigger : ""
            }
            onSaveClick={(e, data) => {
              onChange(null, { name: "trigger", value: data });
              setIsCronModalOpen(false);
            }}
            onCancelClick={(e, data) => {
              setIsCronModalOpen(false);
            }}
          />
        </Modal.Content>
      </Modal>
    </Form>
  );
}

export default withTranslation()(AdminTriggerEditComponent);
