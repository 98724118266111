import React, {useContext, useEffect, useMemo, useState} from "react";
import {useTranslation, withTranslation} from "react-i18next";
import {useHistory} from "react-router-dom/cjs/react-router-dom";
import {Accordion, Button, Card, Icon, Table} from "semantic-ui-react";
import DashboardService from "../../../services/DashboardService";
import DateTimeService from "../../../services/DateTimeService";
import {getDefinitions} from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import {connect} from "react-redux";
import {compose} from "redux";
import InternationalisationService from "../../../InternationalisationService";
import ConfigService from "../../../services/ConfigService";
import _ from "lodash";
import questionnaireTableCardUtilities from './utility/questionnaireTableCardUtilities'
import UserContext from "../../../context/UserContext";
import GroupPermission from "../../../GroupPermission";
import PermissionsService from "../../../services/PermissionsService";
import {Link} from "react-router-dom";

function QuestionnaireTableCard({card, allQuestionnaireDefinitions}) {
  const [questionnaireObjects, setQuestionnairesObjects] = useState(null);
  const [tableDisplay, setTableDisplay] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [fullCount, setFullCount] = useState(null)
  const [completedCount, setCompletedCount] = useState(null)

  const {t} = useTranslation();
  const history = useHistory();

  const showActivationFromDate = ConfigService.shouldShowCardActivationFromDateColumn();
  const showSubjectCode = ConfigService.shouldShowCardSubjectCodeColumn()
  const showEncodedQuestionnaireId = ConfigService.shouldShowCardEncodedQuestionnaireIdColumn()
  const shuffleRows = ConfigService.shouldCardRowsBeShuffled();
  const toggleCard = () => {
    setIsOpen(iO => !iO);
  }

  const user = useContext(UserContext);

  // Technically this should be done per subject, but is not needed for now and is somewhat complex to do
  // the functionality below will treat this value as a per-subject filter to make any subsequent improvements easier.
  const hasViewStaffPermissionInAnyGroup = useMemo(
      ()=>PermissionsService.hasPermissionInAnyGroupSynchronous(user.profile, GroupPermission.VIEW_SUBJECT),
      [user]);

  const getQuestionnaires = async () => {
    let questionnaireFilterModules = questionnaireTableCardUtilities.getQuestionnaireFilterModules(user.profile, card.config.modules);

    const filteredQuestionnaireDefinitionIds = allQuestionnaireDefinitions
        .filter(questionnaireTableCardUtilities.createQuestionnaireDefinitionModuleFilter(questionnaireFilterModules))
        .map((definition) => definition.id);

    let selectedLanguage = await InternationalisationService.getLanguage();
    let workflowTasks = card.config.workflowTasks || [];
    const response = await DashboardService.getQuestionnairesForFilter(
        selectedLanguage,
        filteredQuestionnaireDefinitionIds,
        [card.config.modules],
        card.config.filters,
        workflowTasks
    );

    if (Array.isArray(response.questionnaires)) {
      // currently we dont have backend filtering for questionnaires
      // belonging to certain modules
      setCompletedCount(response.countCompleted);
      setFullCount(response.countCompleted + response.countNotCompleted)

      setQuestionnairesObjects(response.questionnaires);
      setIsLoading(false);
      setIsVisible(response.visible);
    }
  };

  const createTable = (questionnaireObjects) => {
    const response = {
      header: [],
      content: [],
      blindedQuestionnaires: [],
      blindedLink: "",
      shouldShowAsDropdown: false,
      shouldShowBlindedButton: false
    };

    const header = (
        <Table.Header>
          <Table.Row>
            {showEncodedQuestionnaireId && <Table.HeaderCell collapsing/>}
            <Table.HeaderCell>
              {t(
                  "DASHBOARD_QUESTIONNAIRE_LIST_QUESTIONNAIRE_DEFINITION",
                  "Questionnaire"
              )}
            </Table.HeaderCell>
            {showSubjectCode && <Table.HeaderCell>
              {t("DASHBOARD_QUESTIONNAIRE_LIST_QUESTIONNAIRE_SUBJECT", "Subject")}
            </Table.HeaderCell>}
            {showActivationFromDate && (<Table.HeaderCell>
              {t(
                  "DASHBOARD_QUESTIONNAIRE_LIST_QUESTIONNAIRE_ACTIVEFROM",
                  "Available Since"
              )}
            </Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>
    );

    if (!Array.isArray(questionnaireObjects)) return response;

    let viewableQuestionnaires = questionnaireObjects.filter(qO => hasViewStaffPermissionInAnyGroup);
    let blindedQuestionnaires = questionnaireObjects.filter(qO => !hasViewStaffPermissionInAnyGroup);

    if (shuffleRows) {
      viewableQuestionnaires = _.shuffle(viewableQuestionnaires);
      blindedQuestionnaires = _.shuffle(blindedQuestionnaires);
    }

    const content = viewableQuestionnaires.map((qO) => {
      const nextQuestionnaireQuery = `nextQuestionnaires=[${
          viewableQuestionnaires
              .filter(q => q.id !== qO.id)
              .map(q => {
                return btoa(`${q.subject.id},${q.questionnaireDefinition.type.toLowerCase()},${q.questionnaireDefinition.code},${q.id}`)
              }).join(",")
      }]`
      const additionalLabelQuery = showEncodedQuestionnaireId ? `additionalLabel=${btoa(qO.id)}` : null;

      const queries = [nextQuestionnaireQuery, additionalLabelQuery].filter(q => q !== null).join("&");
      let link = `/app/subject/${
          qO.subject.id
      }/questionnaire-type/${qO.questionnaireDefinition.type.toLowerCase()}/${
          qO.questionnaireDefinition.code
      }/submit/${qO.id}`

      if (queries.length > 0) {
        link += "?" + queries;
      }

      return (
          <Table.Row
              key={"row-" + qO.id}
              style={{cursor: "pointer"}}
              onClick={() => {
                history.push(link);
              }}
          >
            {showEncodedQuestionnaireId && <Table.Cell collapsing>{btoa(qO.id)}</Table.Cell>}
            <Table.Cell>{qO.questionnaireDefinition.label}</Table.Cell>
            {showSubjectCode && <Table.Cell>{qO.subject.code}</Table.Cell>}
            {showActivationFromDate && (<Table.Cell>
              {DateTimeService.build.asDisplayDateTime(
                  qO.activationFromDate
              )}
            </Table.Cell>)}
          </Table.Row>
      );
    }).filter(c => c !== null);

    response.blindedQuestionnaires = blindedQuestionnaires;
    if (blindedQuestionnaires.length > 0) {
      let link = `/app/subject/${
          blindedQuestionnaires[0].subject.id
      }/questionnaire-type/${blindedQuestionnaires[0].questionnaireDefinition.type.toLowerCase()}/${
          blindedQuestionnaires[0].questionnaireDefinition.code
      }/submit/${blindedQuestionnaires[0].id}`

      if (blindedQuestionnaires.length > 1) {
        link += `?nextQuestionnaires=[${
            blindedQuestionnaires
                .slice(1)
                .map(q => {
                  return btoa(`${q.subject.id},${q.questionnaireDefinition.type.toLowerCase()},${q.questionnaireDefinition.code},${q.id}`)
                }).join(",")
        }]`
      }

      response.blindedLink = link
    }

    response.shouldShowAsDropdown = content.length > 0;
    response.shouldShowBlindedButton = Array.isArray(questionnaireObjects) &&
        content.length !== questionnaireObjects.length;

    response.header = header;
    response.content = content;

    return response;
  };

  useEffect(() => {
    getQuestionnaires();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTableDisplay(createTable(questionnaireObjects));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionnaireObjects, user]);

  if (isLoading || !isVisible) {
    return null;
  }

  let blindedButton = null;
  if (tableDisplay.shouldShowBlindedButton) {
    blindedButton =
        <Button as={Link} to={tableDisplay.blindedLink} primary style={{marginLeft: "8px"}}>{"GO TO BLINDED"}</Button>
  }

  return (
      <Card fluid>
        <Card.Content>
          {!tableDisplay.shouldShowAsDropdown && <div>
            <label style={{flex: 1}}>
              <b>{t("HOME_CARD_" + card?.key, card?.key?.replaceAll('_', " ") + ` (${completedCount}/${fullCount})`)}</b>
            </label>
            {blindedButton}
          </div>}
          {tableDisplay.shouldShowAsDropdown && <Accordion fluid>
            <Accordion.Title style={{paddingBottom: "1rem"}} onClick={toggleCard}>
              <div style={{display: 'flex', width: '100%', justifyContent: 'spaceBetween'}}>
                <Icon name='dropdown' style={{rotate: isOpen ? '90deg' : '0deg', alignSelf: 'center'}}/>
                <label style={{flex: 1}}>
                  <b>{t("HOME_CARD_" + card?.key, card?.key?.replaceAll('_', " ") + ` (${completedCount}/${fullCount})`)}</b>
                </label>
                {blindedButton}
              </div>
            </Accordion.Title>
            <Accordion.Content active={isOpen}>
              <Table>
                {tableDisplay.header}
                <Table.Body>
                  {tableDisplay.content}
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>}
        </Card.Content>
      </Card>
  );
}

const mapStateToProps = (state) =>
{
  return {
    allQuestionnaireDefinitions: getDefinitions(state),
  };
}
;

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(QuestionnaireTableCard);
