import React from "react";
import ReactDOM from "react-dom";
// import "semantic-ui-css/semantic.min.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
// import Bootstrap from "./Bootstrap";
import AppRoot from "./AppRoot";

import "semantic-ui-less/semantic.less";
import { Provider } from "react-redux";
import store from "./redux/store";

import "./Internationalisation";
import "moment/min/locales";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

library.add(fas)

ReactDOM.render(
    <Provider store={store}>
        <AppRoot />
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
