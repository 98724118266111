import {QUESTION_TYPES} from "atom5-branching-questionnaire";

 const TYPES_DEFAULT_TRANSLATION = {
  [QUESTION_TYPES.AUTO_COMPLETE]: {
    labelTranslation: {
      translationKey: 'AUTO_COMPLETE_DEFAULT_LABEL',
      label: "AUTO COMPLETE   - DEPRICATED"
    },
    hoverDescription: {
      translationKey: 'AUTO_COMPLETE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Auto Complete - DEPRICATED'
    }
  },
  [QUESTION_TYPES.BOTTOM_BUTTONS]: {
    labelTranslation: {
      translationKey: 'BOTTOM_BUTTONS_DEFAULT_LABEL',
      label: "BOTTOM BUTTONS"
    },
    hoverDescription: {
      translationKey: 'BOTTOM_BUTTONS_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Bottom Buttons'
    }
  },
  [QUESTION_TYPES.CHART_ICON]: {
    labelTranslation: {
      translationKey: 'CHART_ICON_DEFAULT_LABEL',
      label: "CHART ICON   - single point value, semi real time display"
    },
    hoverDescription: {
      translationKey: 'CHART_ICON_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Chart Icon - Single point value - semi real time display'
    }
  },
  [QUESTION_TYPES.CHART]: {
    labelTranslation: {
      translationKey: 'CHART_DEFAULT_LABEL',
      label: "CHART -   chart that displays a wearable data type as a BAR or LINE"
    },
    hoverDescription: {
      translationKey: 'CHART_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Chart - Shows a chart that displays a wearable data type as a BAR or LINE'
    }
  },
  [QUESTION_TYPES.DATE]: {
    labelTranslation: {
      translationKey: 'DATE_DEFAULT_LABEL',
      label: 'DATE - date data input ("2024-06-08")'
    },
    hoverDescription: {
      translationKey: 'DATE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Date - Date data input ("2024-06-08")'
    }
  },
  [QUESTION_TYPES.DATETIME]: {
    labelTranslation: {
      translationKey: 'DATETIME_DEFAULT_LABEL',
      label: 'DATE TIME - date + time data input ("2024-06-08T11:02:32")'
    },
    hoverDescription: {
      translationKey: 'DATETIME_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Date Time - date + time data input ("2024-06-08T11:02:32")'
    }
  },
  [QUESTION_TYPES.DECIMAL]: {
    labelTranslation: {
      translationKey: 'DECIMAL_DEFAULT_LABEL',
      label: "DECIMAL - decimal data input (1.1)"
    },
    hoverDescription: {
      translationKey: 'DECIMAL_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Decimal - Decimal data input (1.1)'
    }
  },
  [QUESTION_TYPES.DIARY]: {
    labelTranslation: {
      translationKey: 'UDIARY_DEFAULT_LABEL',
      label: "DIARY - diary style data input, including time and text"
    },
    hoverDescription: {
      translationKey: 'DIARY_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Diary - Diary style data input, including time and text'
    }
  },
  [QUESTION_TYPES.FIXED_VALUE]: {
    labelTranslation: {
      translationKey: 'FIXED_VALUE_DEFAULT_LABEL',
      label: "FIXED VALUE - allows for the selection of multiple set answers"
    },
    hoverDescription: {
      translationKey: 'FIXED_VALUE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Fixed Value - Allows for the selection of multiple set answers'
    }
  },
  [QUESTION_TYPES.HEADING]: {
    labelTranslation: {
      translationKey: 'HEADING_DEFAULT_LABEL',
      label: "HEADING - display text (default is HTML <h3> tag)"
    },
    hoverDescription: {
      translationKey: 'HEADING_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Heading - Used to display text (default is HTML <h3> tag)'
    }
  },
  [QUESTION_TYPES.IMAGE]: {
    labelTranslation: {
      translationKey: 'IMAGE_DEFAULT_LABEL',
      label: "IMAGE - allows to capture a picture or select one from a device"
    },
    hoverDescription: {
      translationKey: 'IMAGE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Image - allows to capture a picture or select one from a device'
    }
  },
  [QUESTION_TYPES.INTEGER]: {
    labelTranslation: {
      translationKey: 'INTEGER_DEFAULT_LABEL',
      label: "INTEGER - input of a whole number (e.g. 2, -10, 0)"
    },
    hoverDescription: {
      translationKey: 'INTEGER_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Integer - allows for input of a whole number (e.g. 2, -10, 0)'
    }
  },
  [QUESTION_TYPES.LINK]: {
    labelTranslation: {
      translationKey: 'LINK_DEFAULT_LABEL',
      label: "LINK - used to navigate the user to another questionnaire"
    },
    hoverDescription: {
      translationKey: 'LINK_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Link - Can be used to navigate the user to another questionnaire'
    }
  },
  [QUESTION_TYPES.MARKUP]: {
    labelTranslation: {
      translationKey: 'MARKUP_DEFAULT_LABEL',
      label: "MARKUP - display text, allows for HTML tags to be used"
    },
    hoverDescription: {
      translationKey: 'MARKUP_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Markup - Similar to a paragraph but allows for HTML tags to be used (e.g. for hyperlinks, bold text etc.)'
    }
  },
  [QUESTION_TYPES.MONITORED_VIDEO]: {
    labelTranslation: {
      translationKey: 'MONITORED_VIDEO_DEFAULT_LABEL',
      label: "MONITORED VIDEO - renders a video that must be viewed (required)"
    },
    hoverDescription: {
      translationKey: 'MONITORED_VIDEO_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Monitored Video - renders a video for the subject to view (must finish watching the video)'
    }
  },
  [QUESTION_TYPES.MONTHYEAR]: {
    labelTranslation: {
      translationKey: 'MONTHYEAR_DEFAULT_LABEL',
      label: "MONTH YEAR - month + year data input"
    },
    hoverDescription: {
      translationKey: 'MONTHYEAR_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Month Year - month + year data input'
    }
  },
  [QUESTION_TYPES.PARAGRAPH]: {
    labelTranslation: {
      translationKey: 'PARAGRAPH_DEFAULT_LABEL',
      label: "PARAGRAPH - display text. Also used to display calculated values"
    },
    hoverDescription: {
      translationKey: 'PARAGRAPH_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Paragraph - Used to display text (similar to MARKUP & HEADING). Can also be used to display calculated values'
    }
  },
  [QUESTION_TYPES.REALTIME_SPEL]: {
    labelTranslation: {
      translationKey: 'REALTIME_SPEL_DEFAULT_LABEL',
      label: "REALTIME SPEL -  value that will recalculate every time a questionnaire is opened"
    },
    hoverDescription: {
      translationKey: 'REALTIME_SPEL_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Realtime SPEL - Value that will recalculate every time a questionnaire is opened (rather than on creation / submission)'
    }
  },
  [QUESTION_TYPES.RENDER_IMAGE]: {
    labelTranslation: {
      translationKey: 'RENDER_IMAGE_DEFAULT_LABEL',
      label: "RENDER IMAGE - displays an image"
    },
    hoverDescription: {
      translationKey: 'RENDER_IMAGE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Render Image - Shows an image and requires no response from the user'
    }
  },
  [QUESTION_TYPES.RENDER_VIDEO]: {
    labelTranslation: {
      translationKey: 'RENDER_VIDEO_DEFAULT_LABEL',
      label: "RENDER VIDEO - dsplays a video"
    },
    hoverDescription: {
      translationKey: 'RENDER_VIDEO_DESCRIPTION_LABEL',
      hover: 'Render Video - Shows a video and requires no response from the user'
    }
  },
  [QUESTION_TYPES.SIGNATURE]: {
    labelTranslation: {
      translationKey: 'SIGNATURE_DEFAULT_LABEL',
      label: "SIGNATURE - allows subject to provide a signature"
    },
    hoverDescription: {
      translationKey: 'SIGNATURE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Signature - Allows subject to provide a signature'
    }
  },
  [QUESTION_TYPES.SINGLE_FIXED_VALUE]: {
    labelTranslation: {
      translationKey: 'SINGLE_FIXED_VALUE_DEFAULT_LABEL',
      label: "SINGLE FIXED VALUE - single option Fixed Value question"
    },
    hoverDescription: {
      translationKey: 'SINGLE_FIXED_VALUE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Single Fixed Value - Single option Fixed Value question'
    }
  },
  [QUESTION_TYPES.SLIDER]: {
    labelTranslation: {
      translationKey: 'SLIDER_DEFAULT_LABEL',
      label: "SLIDER -  shows a slider, different types are available"
    },
    hoverDescription: {
      translationKey: 'SLIDER_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Slider - Shows a slider, different types are available. It is an input and returns a number'
    }
  },
  [QUESTION_TYPES.TEXT]: {
    labelTranslation: {
      translationKey: 'TEXT_DEFAULT_LABEL',
      label: "TEXT - text box for participant input"
    },
    hoverDescription: {
      translationKey: 'TEXT_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Text - Shows text box for participant input'
    }
  },
  [QUESTION_TYPES.TEXTAREA]: {
    labelTranslation: {
      translationKey: 'TEXTAREA_DEFAULT_LABEL',
      label: "TEXT AREA - text area for participant input"
    },
    hoverDescription: {
      translationKey: 'TEXTAREA_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Text Area - Shows text area for participant input'
    }
  },
  [QUESTION_TYPES.TIME]: {
    labelTranslation: {
      translationKey: 'TIME_DEFAULT_LABEL',
      label: "TIME - time data input ('11:02:32')"
    },
    hoverDescription: {
      translationKey: 'TIME_DEFAULT_DESCRIPTION_LABEL',
      hover: "Time - time data input ('11:02:32')"
    }
  },
  [QUESTION_TYPES.VIDEO]: {
    labelTranslation: {
      translationKey: 'VIDEO_DEFAULT_LABEL',
      label: "VIDEO - record a video or select one from a device"
    },
    hoverDescription: {
      translationKey: 'VIDEO_DEFAULT_DESCRIPTION_LABEL',
      hover: 'Video - Allows to record a video or select one from a device (has a robust config allowing to enable various features)'
    }
  },
  [QUESTION_TYPES.FILE]: {
    labelTranslation: {
      translationKey: 'FILE_DEFAULT_LABEL',
      label: "FILE - allows file upload"
    },
    hoverDescription: {
      translationKey: 'FILE_DEFAULT_DESCRIPTION_LABEL',
      hover: 'File - Allows a file to be uploaded to the server"'
    }
  },
  [QUESTION_TYPES.AUTHORISE]: {
    labelTranslation: {
      translationKey: 'AUTHORISE_DEFAULT_LABEL',
      label: "AUTHORISE - asks for login information to approve"
    },
    hoverDescription: {
      translationKey: 'AUTHORISE_DEFAULT_DESCRIPTION_LABEL',
      hover: '"Authorise - Asks the subject to provide their login information to approve'
    }
  },
};

export default TYPES_DEFAULT_TRANSLATION;