import React from "react";
import Switch from "react-switch";

const AparitoSwitch = ({ checked, onChange, label, isReadOnly = false }) => {
  return (
    <div style={{ display: "flex", marginTop: 8, paddingLeft: 5 }}>
      <div style={{ display: "inline-flex", alignItems: "center" }}>
        <Switch
          checked={checked}
          handleDiameter={24}
          height={22}
          onChange={onChange}
          boxShadow={"rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"}
          offColor={"#000"}
          onColor={"#000"}
          offHandleColor={"#FFFFFF"}
          onHandleColor={"#F8991D"}
          disabled={isReadOnly}
        />
      </div>
      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
          padding: "0.25rem 1rem 0.25rem 1rem",
          maxWidth: "90%",
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default AparitoSwitch;
