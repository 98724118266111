import React, {useState} from "react";

const SecondaryButton = ({height, width, rounded, noPadding, style, children, onClick, primary}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  let buttonStyle = {
    backgroundColor: primary ? "black" : "white",
    color: primary ? "white" : "black",
    border: "none",
    height: height,
    width: width,
    borderRadius: rounded ? "100%" : "4px",
    cursor: "pointer",
    padding: noPadding ? "0" : "0.8rem 1.5rem 0.8rem",
    boxShadow: isHover ? "0px 2px 6px 0px rgba(0,0,0,0.1)" : "",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  if(style) buttonStyle = {...buttonStyle, ...style}

  return <button onClick={onClick}
                 style={buttonStyle}
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}
  >
    {children}
  </button>
}

export default SecondaryButton;