import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {
  EVENTS,
  QUESTION_TYPES,
} from "atom5-branching-questionnaire";
import { Form, Header } from "semantic-ui-react";
import i18next from "i18next";

import useForceRerender from "../hooks/useForceRerender";
import API_QUESTIONNAIRE_TYPES from "../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";
import AuthService from "../services/AuthService";
import {
  replaceAnswerMap,
  replaceCursorHistoryStack,
  updateCurrentQuestionnaire,
  setCurrentQuestionnaireAsCompleted,
} from "../redux/questionnaires/currentQuestionnaireSlice";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import _ from "lodash";
import QuestionHelper from "../helpers/QuestionHelper";
import UserContext from "../context/UserContext";
import branchingQuestionnaireHelper from "../helpers/branchingQuestionnaireHelper";
import performStaffSignOffQuestionModification from "./utility/performStaffSignoffQuestionModification";
import AppStateService, {frontendCacheKeys} from "../services/AppStateService";
import VisitDateDisplay from "./display/VisitDateDisplay";
import SubjectService from "../SubjectService";
import VisitDateService from "../services/VisitDateService";
import GroupPermission from "../GroupPermission";


const QUESTIONNAIRE_LOADING_STATES = {
  UPDATE_GLOBAL_STATE: "UPDATE_GLOBAL_STATE",
  BUILD_MODIFIED_DEFINITION: "BUILD_MODIFIED_DEFINITION",
  RENDER:"RENDER"
}

const Questionnaire = ({
  definition,
  questionnaire,
  onSubmit,
  onPageChange,
  isLastQuestionnaire = true,
  shouldShowSubmit = true,
  showSubmitAs,
  subjectId: passedSubjectId,
  isReadOnly,
  currentQuestionnaire,
  isMultipart,
  shouldShowTitle = true,
  shouldDispatchToStore = true,
  withoutPaging = false,
  withoutConditions = false,
  withoutValidation = false
}) => {
  const _questionsForCreation = useRef();
  const dispatch = useDispatch();
  const user = useContext(UserContext);

  const [subjectId, setSubjectId] = useState(passedSubjectId);
  const [subjectData, setSubjectData] = useState();
  const [subjectVisitDates, setSubjectVisitDates] = useState([]);
  const [hasQuestionnaireBuilt, setHasQuestionnaireBuilt] = useState(false);
  const [modifiedDefinition, setModifiedDefinition] = useState(null);
  const [metaData, setMetaData] = useState();
  const [questionnaireLoadingState, setQuestionnaireLoadingState] = useState(null);

  const onQuestionPageChange = (page_cursor) => {
      onPageChange && onPageChange();
  }

  const buildModifiedDefinition = useCallback(() => {
    const updatedQuestions = definition.questions.map((q) => {
      const updatedQuestion = performStaffSignOffQuestionModification(user, definition, questionnaire, q, isReadOnly);

      if (q.type === QUESTION_TYPES.FIXED_VALUE) {
        const answerDisplayOrder = q?.config?.answerDisplayOrder?.mode;
        const shouldReOrder =
            user.accountType === "subject" &&
            !isReadOnly &&
            answerDisplayOrder === "RANDOM";

        if (shouldReOrder) {
          const answerDisplayAppendToEnd =
              q?.config?.answerDisplayOrder?.appendToEnd || [];
          const answersToRandomise = q.answers.filter((a) => {
            return !answerDisplayAppendToEnd.includes(a.code);
          });
          const answersToAppendToEnd = q.answers.filter((a) => {
            return answerDisplayAppendToEnd.includes(a.code);
          });

          const shuffledAnswers =
              QuestionHelper.shuffleArray(answersToRandomise);
          updatedQuestion.answers = [
            ...shuffledAnswers,
            ...answersToAppendToEnd,
          ];

          return updatedQuestion;
        }
      }
      return updatedQuestion;
    });
    setModifiedDefinition({ ...definition, questions: updatedQuestions });
  }, [definition, isReadOnly, questionnaire, user]);

  const updateGlobalState = useCallback(()=>{
    let completeQuestionnaire = { ...questionnaire, definition };
    if (shouldDispatchToStore) {
      dispatch(
          updateCurrentQuestionnaire({
            questionnaire: completeQuestionnaire,
            subjectId,
          })
      );
    }
  }, [definition, dispatch, questionnaire, shouldDispatchToStore, subjectId])

  useEffect(()=>{
    setQuestionnaireLoadingState(QUESTIONNAIRE_LOADING_STATES.UPDATE_GLOBAL_STATE);
  },[definition])

  useEffect(() => {
    // Global state needs to be updated before rendering to ensure old state is not sent to
    // the store on first render of A5BQ.
    if(questionnaireLoadingState === QUESTIONNAIRE_LOADING_STATES.UPDATE_GLOBAL_STATE){
      updateGlobalState()
      setQuestionnaireLoadingState(QUESTIONNAIRE_LOADING_STATES.BUILD_MODIFIED_DEFINITION)
    }

    if(questionnaireLoadingState === QUESTIONNAIRE_LOADING_STATES.BUILD_MODIFIED_DEFINITION){
      buildModifiedDefinition();
      setQuestionnaireLoadingState(QUESTIONNAIRE_LOADING_STATES.RENDER);
    }
  }, [buildModifiedDefinition, questionnaireLoadingState, updateGlobalState]);

  const populateSubjectData = async () => {
    try {
      setSubjectData(await SubjectService.getSubjectData(subjectId));

      const permissions = await SubjectService.getSubjectPermission(subjectId);
      const hasViewSubjectVisitDatesPermission = permissions?.includes(GroupPermission.VIEW_SUBJECT_VISITDATES);
      if (hasViewSubjectVisitDatesPermission) {
        setSubjectVisitDates(await VisitDateService.getVisitDatesForSubject(subjectId));      
      }
    } catch (error) {
      console.error('[Questionnaire][populateSubjectData] Error', error);
    }
  };

  useEffect(() => {
    const getSubjectIdFromProfile = async () => {
      if (AuthService.isSubject() && passedSubjectId == null) {
        const profile = await AuthService.getMyProfile();
        setSubjectId(profile.Id);
      }
    };
    getSubjectIdFromProfile();
    populateSubjectData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedSubjectId]);

  useEffect(() => {
    const populateMetaData = async () => {
      const isStaff = await AuthService.isStaff() && AuthService.isLoggedIn();
      const currentUser = {
        type: isStaff ? 'staff' : 'subject'
      }

      if (isStaff) {
        const staff = await AuthService.getMyProfile();
        currentUser.id = AuthService.isStaff() ? staff.id : undefined;
        currentUser.email = AuthService.isStaff() ? staff.email : undefined;
        currentUser.firstName = AuthService.isStaff() ? staff.firstName : undefined;
        currentUser.lastName = AuthService.isStaff() ? staff.lastName : undefined;
      }

      const data = {
        currentUser
      };
      setMetaData(data);
    };
    populateMetaData();
  }, []);

  const forceRerender = useForceRerender();

  const handleValueChange = (values, cursor, cursorHistoryStack) => {
    let newValues = _.cloneDeep(values);
    if (shouldDispatchToStore) {
      if(definition.type !== API_QUESTIONNAIRE_TYPES.CONTENT){
        dispatch(replaceAnswerMap(newValues));
      }
      dispatch(replaceCursorHistoryStack({ cursorHistoryStack, cursor }));
    }
    forceRerender();
  };

  const handleSubmit = (...args) => {
    if (shouldDispatchToStore) {
      dispatch(setCurrentQuestionnaireAsCompleted());
      AppStateService.updateFrontendCacheOnBackendFromRedux(frontendCacheKeys.CURRENT_QUESTIONNAIRE);
    }
    onSubmit(...args);
  };

  const [currentQuestionnaireCode, setCurrentQuestionnaireCode] =
    useState(null);

  if (!subjectId || !modifiedDefinition || !metaData) {
    return <></>;
  }

  // passedSubjectId is undefined on subject side
  if (
    modifiedDefinition.code !== currentQuestionnaireCode ||
    (passedSubjectId !== undefined && passedSubjectId !== subjectId)
  ) {
    let completeQuestionnaire = { ...questionnaire, definition };
    if (hasQuestionnaireBuilt) {
      setHasQuestionnaireBuilt(false);
    }
    if (shouldDispatchToStore) {
      dispatch(
        updateCurrentQuestionnaire({
          questionnaire: completeQuestionnaire,
          subjectId,
        })
      );
    }
  }

  if (
    currentQuestionnaire?.questionnaire?.definition?.code ===
      modifiedDefinition.code &&
    currentQuestionnaire.subjectId === subjectId &&
    !hasQuestionnaireBuilt
      && questionnaireLoadingState === QUESTIONNAIRE_LOADING_STATES.RENDER
  ) {
    let builder = branchingQuestionnaireHelper.getBuilderWithRenderers();
    setHasQuestionnaireBuilt(true);

    const customSubmitText = (() => {
      if (showSubmitAs) {
        return showSubmitAs;
      }
      if (modifiedDefinition.type === API_QUESTIONNAIRE_TYPES.CONTENT) {
        return i18next.t("MARK_AS_READ");
      }
      return null;
    })();

    const calculatedAnswers = questionnaire
      ? questionnaire?.answers
      : modifiedDefinition?.answers;

    let answersForQuestionnaire = {
      ...calculatedAnswers,
      ...currentQuestionnaire?.answerMap,
    };

    // AT-3269 Unsure why multipart was treated differently, so removing as multipart functionality has changed
    // if (!isMultipart) {
    //   answersForQuestionnaire = {
    //     ...calculatedAnswers,
    //     ...currentQuestionnaire?.answerMap,
    //   };
    // }

    // isReadOnly should only be present if defined
    const isReadOnlyPropContainer = {};
    if(isReadOnly !== undefined){
      isReadOnlyPropContainer.isReadOnly = isReadOnly;
    }
    if(definition.type === API_QUESTIONNAIRE_TYPES.CONTENT){
      isReadOnlyPropContainer.isReadOnly = false
    }

    if (withoutValidation) {
      //builder = builder.withoutValidation();
    }

    const builtQuestionnaire = builder
      .on(EVENTS.RERENDER, handleValueChange)
      .on(EVENTS.SUBMIT_SUCCESS, handleSubmit)
      .on(EVENTS.CHANGE_PAGE, onQuestionPageChange)
      .withAdditionalProps({
        questionnaireId: questionnaire
          ? questionnaire.id
          : modifiedDefinition.id,
        isLastQuestionnaire,
        shouldShowSubmit,
        subjectId,
        customSubmitText,
        definition: modifiedDefinition,
        ...isReadOnlyPropContainer,
        questionnaire,
        metaData
      })
      .withoutPaging(withoutPaging)
      .withoutConditions(withoutConditions)
      .withDefaultValues(
        answersForQuestionnaire,
        isMultipart ? 0 : currentQuestionnaire.cursor,
        isMultipart ? [] : currentQuestionnaire.cursorHistoryStack
      )
      .withQuestions(modifiedDefinition.questions)
      .withWorkflowInstance(questionnaire?.questionnaireWorkflowInstance)
      .build();

    _questionsForCreation.current = builtQuestionnaire.render();

    setCurrentQuestionnaireCode(modifiedDefinition.code);
  }

  if (!_questionsForCreation.current) {
    return <></>;
  }

  const nonNullQuestionsForCreation = Object.values(
    _questionsForCreation.current
  ).filter((renderFunction) => !!renderFunction);

  return (
    <>
      <Form id={"form_" + modifiedDefinition.code} size={"big"}>
        {shouldShowTitle === true && modifiedDefinition.label && (
          <Header as={"h2"}>{modifiedDefinition.label}</Header>
        )}
        <VisitDateDisplay
          showEditIcon={true}
          showLabel={true}
          subjectData={subjectData}
          subjectVisitDates={subjectVisitDates}
          questionnaire={questionnaire}
          style={{ display: 'block', marginTop: 20, marginBottom: 20 }}
        />
        <div style={{ maxWidth: "800px", display: "flex", flexDirection: "row", flexWrap: 'wrap', margin:'-0.5rem' }}>
          {nonNullQuestionsForCreation.map(([component, props], index) => {

            return (
              React.createElement(component, {
                ...props,
                key: modifiedDefinition.code + "_" + props?.question?.code,
              })
          )})}
        </div>

      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    currentQuestionnaire: state.currentQuestionnaire,
  };
};

const enhance = compose(connect(mapStateToProps));

export default enhance(Questionnaire);
