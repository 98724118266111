
function convertCamelCaseToCapitalised(str) {
    return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, function(match) { return match.toUpperCase(); });
}


export default {
    fromCamelCase: {
        toCapitalised: convertCamelCaseToCapitalised
    }
}