import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Label,
  Message,
  MessageHeader,
} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import Input from "semantic-ui-react/dist/commonjs/elements/Input";
import AccordianReveal from "../../generic/AccordianReveal";
import EntryDisplay from "./EntryDisplay";
import ToolButton from "./regionselector/ToolButton";
import {loadFile} from "./regionselector/AttachmentParser";

const RegionSelectorControls = (
    {t,
      boxTypes,
      selectedTypes,
      frameData, setFrameData,
      frameDataHistory,
      selectedRows, setSelectedRows,
      selectedValue, setSelectedValue,
      boxesVisible,setBoxesVisible,
      boxIdEntry, setBoxIdEntry,
      boxSelectActive,
      boxToolActive,
      selectedAnnotation,
      setIsInputFocused,
      entries,
      currentFrame,
      fps, setFPS,
      calculatedFPSData,
      filtered,
      trackingSettings, setTrackingSettings,
      handleAddItem,
      handleTypesChange,
      handleSetAnnotationRange,
      handleToggleBoxTool,
      handleSetBoxIdSelected,
      handleImportedFrames,
      handleToggleBoxSelect,
      handleSetBoxIdSelectedAnnotation,
      handleSwitchBoxIdsToRange,
      handleChangeBoxIdsToRange,
      handleFilteredToggle,
      handleDownload,
      handleNextFrame,
      handleGoToFrame,
      handleLogSingle,
      handleLogTrack,
      handleLogInit,
      handleLogEnd,
      handleInterpolateFrames,
      handlePrevFrame,
      handleRemove,
      handleUpdateBoxId,
      handleTrackIds,
      handleDeleteSelected,
      handleBlurSelected,
      handleToggleShowGraphs,
      handleUpdateGraphs,
      handleUpload,
      error
    }) => {

  const [newItemName, setNewItemName] = useState('');
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [goToFrame, setGoToFrame] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [parsedData, setParsedData] = useState(null);
  const [multipleAnnotationEnabled, setMultipleAnnotationEnabled] = useState(false);

  const fileInputRef = useRef(null);

  const areButtonsEnabled = useMemo(()=>fps !== undefined && fps > 0, [fps])

  useEffect(()=>{
    setMultipleAnnotationEnabled(selectedRows.length >= 1);
  },[selectedRows])


  const handleRowClick = (entry, index, event) => {
    if (event.shiftKey && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);
      const newSelectedRows = entries.slice(start, end + 1).map(e => e.id);
      setSelectedRows(newSelectedRows);
    } else if(event.ctrlKey || event.metaKey){
      setSelectedRows(prevSelectedRows => {
        if (prevSelectedRows.includes(entry.id)) {
          // If the entry is already selected, remove it from the selection
          return prevSelectedRows.filter(id => id !== entry.id);
        } else {
          // Otherwise, add it to the selection
          return [...prevSelectedRows, entry.id];
        }
      })
    } else
    {
      setSelectedRows([entry.id]);
      setLastSelectedIndex(index);
    }
  };

  const handleSelectRange = () => {
    setSelectedRows(entries
        .filter(item => item.frame >= trackingSettings.initFrame && item.frame <= trackingSettings.endFrame)
        .map(item => item.id))
  }

  const handleSwitchIdSelected = () => {
    if(selectedRows.length === 2){
      const entry0 = entries.find(entry => entry.id === selectedRows[0])
      const entry1 = entries.find(entry => entry.id === selectedRows[1])

      const entry0_boxId = entry0.boxId;
      const entry1_boxId = entry1.boxId;

      handleUpdateBoxId(entry0.id, entry1_boxId, entry0);
      handleUpdateBoxId(entry1.id, entry0_boxId, entry1);
    }
  };

  const handleSelectAll = () => {
    if(singleFrame){
      setSelectedRows(entries.filter(entry => entry.frame === currentFrame).map(entry => entry.id));
    }else{
      setSelectedRows(entries.map(entry => entry.id));
    }
  }

  const handleInterpolateSelection = () => {
    if(selectedRows.length === 2){
      const initEntry = entries.find(entry => entry.id === selectedRows[0]);
      const endEntry = entries.find(entry => entry.id === selectedRows[1]);
      handleInterpolateFrames(initEntry,endEntry);
    }
    else if(selectedRows.length >2){
      let filteredEntities = entries.filter(entity =>
          entity.type === "keyframe" && selectedRows.includes(entity.id)
      )
      if(filteredEntities.length > 2){
        for(let i=0;i<filteredEntities.length - 1;i++){
          const initEntry = filteredEntities[i]
          const endEntry = filteredEntities[i+1]
          handleInterpolateFrames(initEntry,endEntry);
        }
      }else {
        filteredEntities = entries.filter(entity =>
            selectedRows.includes(entity.id) && entity.type !== "calculated"
        );
        if(filteredEntities.length > 2){
          for(let i=0;i<filteredEntities.length - 1;i++){
            const initEntry = filteredEntities[i]
            const endEntry = filteredEntities[i+1]
            if((endEntry.frame - initEntry.frame) >= 2)
              handleInterpolateFrames(initEntry,endEntry);
          }
        }
      }
    }
  }

  useEffect(() => {
    if(parsedData && handleImportedFrames){
      handleImportedFrames(parsedData)
      setParsedData(null);
    }
  }, [handleImportedFrames, parsedData]);

  const onDrop = async (event) => {
    if (!fps)
      return;
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    try{
      setParsedData(await loadFile(file))
    }catch (e) {
      setErrorMessage(e.message)
    }
  }

  const onDragOver = (event) => {
    event.preventDefault();
  };


  /// Entry page operations
  const itemsPerPage = 100; // Number of items per page
  const totalItems = entries.length
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [singleFrame, setSingleFrame] = useState(false);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    } else if (newPage === 0){
      const index = findPageForFrameNumber();
      if(index !== -1){
        setCurrentPage(index);
      }
    }
  };

  const findPageForFrameNumber = () => {
    const index = entries.findIndex(entry => entry.frame === currentFrame);
    if (index !== -1) {
      return Math.floor(index / itemsPerPage) + 1;
    }

    // If frame number is not found, find the closest lower frame number
    const sortedEntries = [...entries].sort((a, b) => a.frame - b.frame);
    const closestLowerIndex = sortedEntries
        .reverse()
        .findIndex(entry => entry.frame < currentFrame);

    if (closestLowerIndex !== -1) {
      const originalIndex = entries.findIndex(entry => entry.frame === sortedEntries[closestLowerIndex].frame);
      return Math.floor(originalIndex / itemsPerPage) + 1;
    }

    return -1; // No lower frame number found
  };

  useEffect(() => {
    if(!singleFrame){
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const items = entries.slice(startIndex, endIndex)
      setCurrentItems(items);
    }
  }, [entries, currentPage, singleFrame]);

  useEffect(() => {
    if(singleFrame){
      setCurrentItems(entries.filter(entry => entry.frame === currentFrame));
    }
  }, [singleFrame, currentFrame, entries]);


  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const updateCurrentFrames = (item) => {
    setSelectedValue(item.value);
    setFrameData(frameDataHistory.find(f => f.value === item.value).frameData)

  };

  useEffect(() => {
    handleUpdateGraphs();
  }, [frameData, handleUpdateGraphs]);


  const dropdownTypeOptions = [
    ...boxTypes.map((type, index) => ({
      key: index,
      text: type,
      value: type,
    })),
  ];

  const handleDropdownChange = (e, {  value }) => {

    const selectedItem = frameDataHistory.find(item => item.value === value);
    if (selectedItem) {
      setSelectedValue(value);
      //setUpdateCharts(true);
      updateCurrentFrames(selectedItem,handleUpdateGraphs);
    }
  };

  const dropdownOptions = frameDataHistory.map((item, index) => ({
    key: index,
    text: item.listName,
    value: item.value,
  }));

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setParsedData(await loadFile(file))
      } catch (e) {
        setErrorMessage(e.message)
      }
    }
  };

  const Separator = ({ blank = true, width=10}) => (
      <div style={{ borderLeft: blank ? '' : '1px solid #ccc', height: '24px', margin: `0 ${width}px`}}></div>
  )

  return <div
      onDrop={onDrop}
      onDragOver={onDragOver}

  >
    {/*The default video controls are moved down, so spacer is needed*/}
    <div className={"spacer"} style={{height: '75px'}} />
    <input
        ref={fileInputRef}
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileInput"
    />
    <Card fluid>
      <Card.Content>
        <Form>
          {error != null && <Message negative>
            <MessageHeader>{t("GLOBAL_ERROR_TITLE","Error!")}</MessageHeader>
            <p>{error.message}</p>
          </Message>}
        <Grid style={{ padding: '0' }}>
          <Grid.Row columns={1} style={{ paddingTop: '0px', paddingBottom: '0px'}}>
            <Grid.Column width={13} style={{ paddingTop: '5px', paddingBottom: '5px', display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <Input
                  size={"small"}
                  style={{ minWidth: 200 }}
                  label={t("REGION_SELECTOR_FPS","FPS")}
                  type={'numeric'}
                  placeholder={t("REGION_SELECTOR_FPS_PLACEHOLDER", "Select FPS")}
                  onChange={e=> {
                    setFPS(parseInt(e.target.value))
                  }}
                  value={fps}
              />
              <Separator />
              <Input
                  disabled
                  style={{opacity: 0.6, minWidth: 300}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CALCULATED_FPS", "Calculated FPS")}
                  placeholder={t("REGION_SELECTOR_CALCULATED_FPD_PLACEHOLDER", "Play video to calculate")}
                  value={calculatedFPSData.fps}
              />
              <Input
                  disabled
                  style={{opacity: 0.6, minWidth: 300}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_FRAME","Current Frame")}
                  placeholder={t("REGION_SELECTOR_CURRENT_FRAME_PLACEHOLDER", "Set FPS to goto")}
                  value={currentFrame}
              />
              <Input
                  size={"small"}
                  style={{minWidth: 300}}
                  label={t("REGION_SELECTOR_CURRENT_GO_TO_FRAME","Go To Frame")}
                  placeholder={t("REGION_SELECTOR_CURRENT_FRAME_PLACEHOLDER", "Set FPS to enable")}
                  value={goToFrame}
                  onChange={e=> {
                    setGoToFrame(e.target.value)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (goToFrame.length === 0) return;
                      try {
                        const gotoFrame = parseInt(goToFrame);
                        handleGoToFrame(gotoFrame);
                      } catch (error) {
                        console.log("Cannot parse " + goToFrame);
                      }
                    }
                  }}
              />
              <Input
                  disabled
                  style={{opacity: 0.6, minWidth: 300}}
                  size={"small"}
                  label={t("REGION_SELECTOR_CURRENT_TIME", "Current time")}
                  placeholder={t("REGION_SELECTOR_CURRENT_TIME_PLACEHOLDER", "Play video to find")}
                  value={calculatedFPSData.currentTime}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3} style={{ paddingTop: '1px', paddingBottom: '0px' }}>
            <Grid.Column width={2} style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <ToolButton onClick={() => setBoxesVisible(!boxesVisible)} primary={areButtonsEnabled} disabled={!fps} iconName={boxesVisible?'window restore outline':'window close outline'}
                          tooltip={!boxesVisible?t("REGION_SELECTOR_VIEW_BOXES", "View Boxes"):t("REGION_SELECTOR_HIDE_BOXES", "Hide Boxes")}
              />
              <ToolButton onClick={handleToggleBoxTool} primary={boxToolActive} disabled={!fps} iconName='sticky note'
                          tooltip={t("REGION_SELECTOR_BOX_TOOL", "Box Tool (B)")}
              />
              <ToolButton onClick={handleToggleBoxSelect} primary={boxSelectActive} disabled={!fps} iconName='edit'
                          tooltip={t("REGION_SELECTOR_BOX_SELECT_TOOL", "Box Select (S)")}
              />
            </Grid.Column>
          <Grid.Column width={7} style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
            <ToolButton onClick={handleFilteredToggle} primary={areButtonsEnabled} disabled={!fps} iconName='filter' width={100}
                        tooltip={filtered?t("REGION_SELECTOR_UNFILTER","Unfilter"):t("REGION_SELECTOR_FILTER","Filter")}
                        buttonText={filtered?t("REGION_SELECTOR_UNFILTER","Unfilter"):t("REGION_SELECTOR_FILTER","Filter")}
            />
            <Input
                style={{ minWidth: '150px', marginRight: '20px' }}
                size={"small"}
                label={t("REGION_SELECTOR_BOX_ID", "Box Id")}
                placeholder={t("REGION_SELECTOR_BOX_ID_PLACEHOLDER", "Assigned box id")}
                value={boxIdEntry}
                onFocus={() => setIsInputFocused(true)}
                onBlur={() => setIsInputFocused(false)}
                onChange={e=> {
                  setBoxIdEntry(e.target.value)
                }}
            />
            <Separator/>
            <Dropdown
                style={ {width: 300, marginLeft: '20px' }}
                placeholder={t("REGION_SELECTOR_SELECT_TYPES", "Select Types")}
                fluid
                multiple
                selection
                options={dropdownTypeOptions}
                onChange={handleTypesChange}
                value={selectedTypes}
            />
            <Separator/>
            <ToolButton onClick={handleSetBoxIdSelected} primary={selectedRows.length === 1} disabled={selectedRows.length !== 1} iconName='id badge'
                        tooltip={t("REGION_SELECTOR_SET_BOXID_SELECTED", "Set BoxId to Selection")}
                        buttonText=""
            />
            <ToolButton onClick={handleSetBoxIdSelectedAnnotation} primary={selectedAnnotation}  width={220}
                        iconName='minus'
                        tooltip={t("REGION_SELECTOR_SET_BOX_ID", "Set Box Id to Selected Annotation")}
                        buttonText={t("REGION_SELECTOR_SET_BOX_ID", "Box Id -> Annotation")}
            />
            </Grid.Column>
            <Grid.Column width={5} style={{ paddingTop: '10px', paddingBottom: '10px', paddingRight: '50px', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <Button primary={areButtonsEnabled}>
                {t("REGION_SELECTOR_HISTORY", "History")}
              </Button>
              <Dropdown
                  style={{minWidth: 200}}
                  placeholder="Select item"
                  fluid
                  selection
                  options={dropdownOptions}
                  onChange={handleDropdownChange}
                  value={selectedValue}
              />
              <Input
                  type="text"
                  style={{minWidth: 200}}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                  placeholder={t("REGION_SELECTOR_SAVE_CURRENT_STATE", "Save Current State...")}
                  value={newItemName}
                  onChange={(e) => setNewItemName(e.target.value)}
                  action={{
                    icon: 'plus',
                    onClick: (e) => {
                    handleAddItem(newItemName)
                    setNewItemName('');
                  } ,
                  }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={8} style={{ paddingTop: '1px', paddingBottom: '0px' }}>
            <Grid.Column width={8}  style={{ paddingTop: '5px', paddingBottom: '5px', paddingRight: '10px', display: 'flex', flexDirection: 'row', gap: '2px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <ToolButton onClick={handlePrevFrame} primary={areButtonsEnabled} disabled={!fps} iconName='arrow left'
                  tooltip={t("REGION_SELECTOR_PREV", "Previous (Q)")}
                          buttonText="(Q)"
              />
              <ToolButton onClick={handleNextFrame} primary={areButtonsEnabled} disabled={!fps} iconName='arrow right'
                          tooltip={t("REGION_SELECTOR_NEXT", "Next (W)")}
                          buttonText="(W)"
              />
              <ToolButton onClick={handleLogSingle} primary={areButtonsEnabled} disabled={!fps && !boxToolActive} iconName='sticky note'
                          tooltip={t("REGION_SELECTOR_LOG", "Log (E)")}
                          buttonText="(E)"
              />
              <ToolButton onClick={handleLogInit} primary={areButtonsEnabled} disabled={!fps} iconName='caret square left'
                          tooltip={t("REGION_SELECTOR_LOG_INIT", "Log Init (I)")}
                          buttonText="(I)"
              />
              <ToolButton onClick={handleLogEnd} primary={areButtonsEnabled} disabled={!fps} width={100} iconName='caret square right'
                          tooltip={t("REGION_SELECTOR_LOG_END", "Log End (O)")}
                          buttonText="(O)"
              />
              <ToolButton onClick={handleLogTrack} primary={areButtonsEnabled} disabled={!fps} iconName='bullseye'
                          tooltip={t("REGION_SELECTOR_TRACK", "Track Object(T)")}
                          buttonText="(T)"
              />
              <ToolButton onClick={handleDownload} primary={areButtonsEnabled} disabled={!fps} iconName='download'
                          tooltip={t("REGION_SELECTOR_DOWNLOAD", "Download")}
                          buttonText=""
              />
              <ToolButton onClick={handleFileSelect} primary={areButtonsEnabled} disabled={!fps} iconName='file'
                          tooltip={t("REGION_SELECTOR_FILE_SELECT", "Load...")}
                          buttonText=""
              />
              <ToolButton onClick={handleToggleShowGraphs} primary={areButtonsEnabled} disabled={!fps} iconName='line graph'
                          tooltip={t("REGION_SELECTOR_TOGGLE_SHOW_GRAPHS", "Show Graphs")}
                          buttonText=""
              />
              <ToolButton onClick={handleUpdateGraphs} primary={areButtonsEnabled} disabled={!fps} iconName='refresh'
                          tooltip={t("REGION_SELECTOR_UPDATE_GRAPHS", "Update Graphs")}
                          buttonText=""
              />
              <ToolButton onClick={handleUpload} primary={areButtonsEnabled} disabled={!fps} iconName='upload'
                          tooltip={t("REGION_SELECTOR_UPLOAD", "Upload")}
                          buttonText=""
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={9} style={{ paddingTop: '1px', paddingBottom: '0px' }}>
            <Grid.Column width={9}  style={{ paddingLeft:'10px', paddingTop: '5px', paddingBottom: '5px', paddingRight: '5px', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <ToolButton onClick={handleTrackIds} primary={areButtonsEnabled} disabled={!fps} iconName='chart line'
                          tooltip={t("REGION_SELECTOR_TRACK", "Track")}
                          buttonText={t("REGION_SELECTOR_TRACK", "Track")}
              />
              <Input
                  size={"small"}
                  style={{ maxWidth: 200 }}
                  label={t("REGION_SELECTOR_DISTANCE_THRESHOLD", "Distance Threshold")}
                  placeholder={t("REGION_SELECTOR_CURRENT_TIME_PLACEHOLDER", "Play video to find")}
                  value={trackingSettings.distanceThreshold}
                  onChange={e=> {
                    setTrackingSettings({...trackingSettings, distanceThreshold: parseInt(e.target.value)})
                  }}
              />
              <Separator/>
              <Input
                  size={"small"}
                  style={{ maxWidth: 200 }}
                  label={t("REGION_SELECTOR_MAX_TRACK_ID", "Max Track Id")}
                  value={trackingSettings.maxId}
                  onChange={e=> {
                    setTrackingSettings({...trackingSettings, maxId: parseInt(e.target.value)})
                  }}
              />
              <Separator />
              <Input
                  size={"small"}
                  style={{ maxWidth: 100, marginRight: 100}}
                  label={t("REGION_SELECTOR_MAX_FRAMES_LOST", "Max Frames Lost")}
                  value={trackingSettings.maxFramesLost}
                  onChange={e=> {
                    setTrackingSettings({...trackingSettings, maxFramesLost: parseInt(e.target.value)})
                  }}
              />
              <Separator size={400} />
              <Form.Field>
                <Checkbox
                    size={"small"}
                    label={t("REGION_SELECTOR_CLEAR_LOST_OBJECTS", "Clear lost objects")}
                    checked={trackingSettings.clearLostObjects}
                    onChange={(e, {checked}) => {
                      setTrackingSettings({...trackingSettings, clearLostObjects: checked})
                    }}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={8} style={{ paddingTop: '1px', paddingBottom: '0px' }}>
            <Grid.Column width={7} style={{ paddingLeft:'10px', paddingTop: '10px', paddingBottom: '10px', paddingRight: '50px', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <Input
                  id="initFrameInput"
                  style={{ maxWidth: 200 }}
                  size="small"
                  label={
                    <Label
                        onClick={  () => setTrackingSettings({
                          ...trackingSettings,
                          initFrame: currentFrame
                        })}
                        style={{ cursor: 'pointer', backgroundColor: '#F8991D', color: '#fff'}}
                    >
                      {t("REGION_SELECTOR_INIT_FRAME", "Init Frame")}
                    </Label>
                  }
                  labelPosition="left"
                  placeholder={t("REGION_SELECTOR_INIT_FRAME_PLACEHOLDER", "Tracking init frame")}
                  value={trackingSettings.initFrame}
                  onChange={e => {
                    setTrackingSettings({
                      ...trackingSettings,
                      initFrame: parseInt(e.target.value, 10)
                    });
                  }}
              />
              <Input
                  size={"small"}
                  style={{ maxWidth: 180, marginRight: '10px'  }}
                  label={                    <Label
                      onClick={  () => setTrackingSettings({
                        ...trackingSettings,
                        endFrame: currentFrame
                      })}
                      style={{ cursor: 'pointer', backgroundColor: '#F8991D', color: '#fff'}}
                  >
                    {t("REGION_SELECTOR_END_FRAME", "End Frame")}
                  </Label>}
                  placeholder={t("REGION_SELECTOR_END_FRAME_PLACEHOLDER", "Tracking end frame")}
                  value={trackingSettings.endFrame}
                  onChange={e=> {
                    setTrackingSettings({...trackingSettings, endFrame: parseInt(e.target.value)})
                  }}
              />
              <Separator/>
              <ToolButton onClick={handleSetAnnotationRange} primary={selectedAnnotation} disabled={!fps} iconName='minus'  width={180}
                          tooltip={t("REGION_SELECTOR_SET_ANNOTATION", "Set Annotation Init / End")}
                          buttonText={t("REGION_SELECTOR_SET_ANNOTATION", "Annotation")}
              />
              <ToolButton onClick={handleSelectRange} primary={areButtonsEnabled} disabled={!fps} iconName='exchange' width={200}
                          tooltip={t("REGION_SELECTOR_SELECT_RANGE", "Select Range")}
                          buttonText={t("REGION_SELECTOR_SELECT_RANGE", "Select")}
              />
            </Grid.Column>
            <Grid.Column width={8} style={{ paddingLeft:'20px', paddingTop: '10px', paddingBottom: '10px', paddingRight: '50px', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <Input
                  size={"small"}
                  label={t("REGION_SELECTOR_FROM_ID", "From Id:")}
                  value={trackingSettings.fromId}
                  onChange={e=> {
                    setTrackingSettings({...trackingSettings, fromId: parseInt(e.target.value)})
                  }}
              />
              <Input
                  size={"small"}
                  style={{ maxWidth: 200, marginRight: '50px'  }}
                  label={t("REGION_SELECTOR_TO_ID", "To id:")}
                  value={trackingSettings.toId}
                  onChange={e=> {
                    setTrackingSettings({...trackingSettings, toId: parseInt(e.target.value)})
                  }}
              />
             <ToolButton onClick={handleSwitchBoxIdsToRange} primary={areButtonsEnabled} disabled={!fps} iconName='exchange' width={100}
                          tooltip={t("REGION_SELECTOR_SWITCH_BOX_ID_RANGE", "Switch Ids")}
                          buttonText={t("REGION_SELECTOR_SWITCH", "Switch")}
              />
              <ToolButton onClick={handleChangeBoxIdsToRange} primary={areButtonsEnabled} disabled={!fps} iconName='long arrow alternate right'  width={100}
                          tooltip={t("REGION_SELECTOR_SET_BOX_ID_RANGE", "Change Ids")}
                          buttonText={t("REGION_SELECTOR_CHANGE", "Change")}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '1px', paddingBottom: '0px' }}>
            <Grid.Column width={8} style={{ paddingLeft:'10px', paddingBottom: '10px', paddingRight: '50px', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "1rem" }}>
                <Button onClick={() => setSingleFrame(!singleFrame)}>
                  {singleFrame ? t("REGION_SELECTOR_SINGLE_FRAME","Single Frame") : t("REGION_SELECTOR_FRAME_LIST","Frame List")}
                </Button>
                { !singleFrame && (<>
                  <Button onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                    {"|<"}
                  </Button>
                  <Button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    {"<"}
                  </Button>
                  <Button onClick={() => handlePageChange(0)} >
                    {"o"}
                  </Button>
                  <Button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    {">"}
                  </Button>
                  <Button onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                    {">|"}
                  </Button>
                  { currentItems.length > 0 && (                <span>
              Displaying Frames: {currentItems[0].frame} - {currentItems[currentItems.length - 1].frame} Indexes:  {startIndex + 1}-{Math.min(endIndex, totalItems)} of {totalItems}
            </span>)}

                </>)}

              </div>
            </Grid.Column>
            <Grid.Column width={5} style={{ paddingLeft:'10px', paddingBottom: '10px', paddingRight: '50px', display: 'flex', flexDirection: 'row', gap: '20px', alignItems: 'center', border: '1px solid lightgray', borderRadius: '5px' }}>
              <ToolButton onClick={handleSelectAll} primary={areButtonsEnabled} disabled={!fps} iconName='window restore'
                          tooltip={t("REGION_SELECTOR_SELECT_ALL", "Select All")}
                          buttonText=""
              />
              <ToolButton onClick={handleDeleteSelected} primary={multipleAnnotationEnabled} disabled={selectedRows.length < 1} iconName='window close'
                          tooltip={t("REGION_SELECTOR_DELETE_SELECTED", "Delete Selected")}
                          buttonText=""
              />
              <ToolButton onClick={handleInterpolateSelection} primary={multipleAnnotationEnabled} disabled={selectedRows.length < 2} iconName='chart line'
                          tooltip={t("REGION_SELECTOR_INTERPOLATE_SELECTED", "Interpolate Selected")}
                          buttonText=""
              />
              <ToolButton onClick={handleSwitchIdSelected} primary={multipleAnnotationEnabled} iconName='random'
                          tooltip={t("REGION_SELECTOR_SWITCH_ID_SELECTED", "Switch Ids Selected")}
                          buttonText=""
              />
              <ToolButton onClick={handleSetBoxIdSelected} primary={areButtonsEnabled} disabled={selectedRows.length === 0} iconName='id badge'
                          tooltip={t("REGION_SELECTOR_SET_BOXID_SELECTED", "Set BoxId to Selection")}
                          buttonText=""
              />
              <ToolButton onClick={() => handleBlurSelected(true)} primary={areButtonsEnabled} disabled={selectedRows.length === 0} iconName='eye slash'
                          tooltip={t("REGION_SELECTOR_TOGGLE_BLUR", "Blur Selected")}
                          buttonText=""
              />
              <ToolButton onClick={() => handleBlurSelected(false)} primary={areButtonsEnabled} disabled={selectedRows.length === 0} iconName='eye'
                          tooltip={t("REGION_SELECTOR_TOGGLE_UNBLUR", "Unblur Selected")}
                          buttonText=""
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '1px', paddingBottom: '5px' }}>
            <AccordianReveal displayText={t("REGION_SELECTOR_MORE","More")} noPadding={true}>
              <div style={styles.scrollableGridStyle}>
              <Grid padded >
                <Grid.Row><p>{t("REGION_SELECTOR_INSTRUCTIONS", "Box Region can be moved with I, J, K, and L, and resized with U and O.")}</p></Grid.Row>
                <Grid.Row><p>{errorMessage}</p></Grid.Row>
                <Grid.Row columns={16}>
                  <Grid.Column width={2}>
                    <p><strong>{t("REGION_SELECTOR_ENTRY_FRAME", "Frame")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_BOXID", "boxId")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_TYPE", "type")}</strong></p>
                  </Grid.Column>
                  <Grid.Column >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_X", "x")}</strong></p>
                  </Grid.Column>
                  <Grid.Column width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_Y", "y")}</strong></p>
                  </Grid.Column>
                  <Grid.Column >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_X'", "x'")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_ENTRY_Y'", "y'")}</strong></p>
                  </Grid.Column>
                  <Grid.Column  width={2} >
                    <p><strong>{t("REGION_SELECTOR_UNBLURRED", "Unblurred")}</strong></p>
                  </Grid.Column>
                </Grid.Row>
                {currentItems.map((e,index) => (
                      <EntryDisplay
                          key={'entry-display-' + e.id}
                          entry={e} handleRemove={handleRemove}
                                    isSelected={selectedRows.includes(e.id)}
                                    onClick={(event) => handleRowClick(e, index, event)}
                                  handleGoToFrame={handleGoToFrame}
                      />
                ))}
              </Grid>
              </div>
            </AccordianReveal>
          </Grid.Row>
        </Grid>
        </Form>
      </Card.Content>
    </Card>
  </div>
}

const styles = {
  scrollableGridStyle : {
    maxHeight: '600px',
    overflowY: 'auto',
  }
}

export default withTranslation()(RegionSelectorControls);
