import React from "react";
import { withTranslation } from "react-i18next";
import InternationalisationService from "../../InternationalisationService";
import AuthService from "../../services/AuthService";
import TrialService from "../../TrialService";
import SubjectService from "../../SubjectService";
import LanguageSelection from "../../components/languageselection/LanguageSelection";
import { Message, Placeholder } from "semantic-ui-react";
import MultiQuestionnaire from "../../questionnaires/MultiQuestionnaire";
import Questionnaire from "../../questionnaires/Questionnaire";

class SubjectConsentPage extends React.Component {
  constructor(props) {
    super(props);

    let urlParams = this.props.location.search.substr(1);
    var searchParams = new URLSearchParams(urlParams);

    if (searchParams.has("lang")) {
      let lang = searchParams.get("lang");
      InternationalisationService.changeLanguage(lang);
    }
    var subjectId = "Unknown";
    if (searchParams.has("subjectId")) {
      subjectId = searchParams.get("subjectId");
    }
    var encodedSubjectData = "NA";
    if (searchParams.has("subjectData")) {
      encodedSubjectData = searchParams.get("subjectData");
    }

    SubjectService.recordConsentLanding(subjectId, encodedSubjectData);
    this.state = this.refresh();
  }

  refresh = async () => {
    let contentDefinitions = await SubjectService.getQuestionnaireDefinitions(
      "CONTENT"
    );
    let eventDefinitions = await SubjectService.getQuestionnaireDefinitions(
      "EVENT"
    );

    let questionnaireDefinitions = contentDefinitions.concat(eventDefinitions);

    let infoQuestionnaires = [];
    if (
      Window.configuration.ui.selfOnboarding &&
      Window.configuration.ui.selfOnboarding.infoQuestionnaires
    ) {
      infoQuestionnaires = questionnaireDefinitions.filter((qDef) =>
        Window.configuration.ui.selfOnboarding.infoQuestionnaires.includes(
          qDef.code
        )
      );
    }

    const consentQuestionnaireCode = Window.configuration.ui.selfOnboarding
      ? Window.configuration.ui.selfOnboarding.consentQuestionnaire
      : "study-consent";
    const consentQuestionnaire = questionnaireDefinitions.find(
      (qDef) => qDef.code === consentQuestionnaireCode
    );

    const hasInfoQuestionnaires = infoQuestionnaires.length > 0;

    this.setState({
      infoQuestionnaires,
      consentQuestionnaire,
      loading: false,
      showingInfoQuestionnaires: hasInfoQuestionnaires,
      showingConsentQuestionnaire: !hasInfoQuestionnaires,
    });

    return {
      language: InternationalisationService.getLanguage(),
      loading: true,
      error: false,
      showingInfoQuestionnaires: false,
      showingConsentQuestionnaire: false,
      infoQuestionnaires: [],
      consentQuestionnaire: null,
    };
  };

  componentWillUpdate() {
    if (this.state.language !== InternationalisationService.getLanguage()) {
      this.setState(
        { language: InternationalisationService.getLanguage() },
        () => {
          this.refresh();
        }
      );
    }
  }

  changeLang = () => {
    this.refresh();
  };

  infoQuestionnaireCompleted = () => {
    this.setState({
      showingInfoQuestionnaires: false,
      showingConsentQuestionnaire: true,
    });
  };

  onConsent = async (answers) => {
    // If a subject is consenting it means a new subject is
    // signing up. Get rid of old sign in data.
    await AuthService.clearAuth();

    this.setState({ loading: true });
    let passedId = null;
    let encodedSubjectData = null;

    let urlParams = this.props.location.search.substr(1);
    let searchParams = new URLSearchParams(urlParams);

    if (searchParams.has("subjectId")) {
      passedId = searchParams.get("subjectId");
    }

    if (searchParams.has("subjectData")) {
      encodedSubjectData = searchParams.get("subjectData");
    }

    TrialService.getCurrentTrial().then(async (trial) => {
      let postData = JSON.stringify({});
      let decodedSubjectData = encodedSubjectData
        ? atob(encodedSubjectData)
        : postData;
      let subjectJSON = {
        subjectData: JSON.parse(decodedSubjectData),
      };

      const relatedSubjectRecord =
        Window.configuration.ui.selfOnboarding.relatedSubjectRecord;
      if (relatedSubjectRecord && this.state.answers) {
        // prepend the questionnaire code
        let key,
          value = "";
        for ([key, value] of Object.entries(this.state.answers)) {
          subjectJSON.subjectData[relatedSubjectRecord + "_" + key] = value;
        }
      }

      const consentQuestionnaireCode = Window.configuration.ui.selfOnboarding
        ? Window.configuration.ui.selfOnboarding.consentQuestionnaire
        : "study-consent";

      let answerMap = {};
      Object.entries(answers).forEach((entry) => {
        answerMap[consentQuestionnaireCode + "_" + entry[0]] = entry[1];
      });

      subjectJSON.answerMap = answerMap;
      subjectJSON.consentQuestionnaireCode = consentQuestionnaireCode;

      if (
        trial.selfOnboardingPassthruEnabled ||
        (this.props.location.search &&
          this.props.location.search.includes("passthru"))
      ) {
        const subjectId = passedId ? passedId : "PASSTHRU";
        await SubjectService.selfOnboardSubject(subjectId, subjectJSON).then(
          (data) => {
            AuthService.tokenSuccessCallback(data, () => {});
            AuthService.setAccountType("subject");
            window.location = "/app/home";
          }
        );
      } else {
        // generate random externalId
        const subjectId = passedId
          ? passedId
          : Math.random().toString(20).substr(2, 8);
        const subjectData = await SubjectService.selfOnboardSubject(
          subjectId,
          subjectJSON
        );

        if (subjectData.token !== null && subjectData.id !== null) {
          window.location =
            "/subject/signup?id=" +
            subjectData.id +
            "&token=" +
            subjectData.token;
        } else if (subjectData.preExisting && subjectData.email) {
          window.location = "/login?&email=" + subjectData.email;
        }
      }
    });
  };

  render() {
    const { t } = this.props;

    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: 850, padding: "20px" }}>
          {this.state.error && (
            <Message error>{t("CONSENT_ERROR_MESSAGE")}</Message>
          )}
          {this.state.loading && !this.state.error && (
            <Placeholder>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
          )}
          {!this.state.loading &&
            !this.state.error &&
            this.state.consentQuestionnaire && (
              <>
                <LanguageSelection callback={this.changeLang} />

                {this.state.showingInfoQuestionnaires && (
                  <div style={{ "margin-top": "2rem" }}>
                    <MultiQuestionnaire
                      definitions={this.state.infoQuestionnaires}
                      onSubmit={this.infoQuestionnaireCompleted}
                    />
                  </div>
                )}

                {this.state.showingConsentQuestionnaire && (
                  <Questionnaire
                    definition={this.state.consentQuestionnaire}
                    onSubmit={this.onConsent}
                  />
                )}
              </>
            )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(SubjectConsentPage);
