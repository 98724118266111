import React, { useState, useEffect } from "react";
import Page from "../../components/page/Page";
import { withTranslation } from "react-i18next";
import { Button, Form, Grid, Input, Checkbox, Icon } from "semantic-ui-react";
import ConfirmButton from "../../components/dashboard/ConfirmButton";
import "./AdminQuestionnaireDefinitionCreatePage.css"
import ClientsDropdown from "./ClientsDropdown";
import ClientsOptions from "./ClientsOptions";
import ConfigService from "../../services/admin/ConfigService";
import VanillaJsonEditor from "./components/jsoneditor/VanillaJsonEditor";
import StaffPermissionService from "../../services/StaffPermissionService";
import { compose } from "redux";
import withPermissionWrapper from "../../security/withPermissionWrapper";

const AdminConfigToolsCreateOrEditPage = (props) => {

    const { t,
            selected,
            loadConfiguration,
            handleCancelAddNewItem,
            onlyDefaultClients,
            loadedList } = props;

    const [selectedDropdown, setSelectedDropdown] = useState(ClientsOptions.getDefaultDisplayLabels());

    const [errorMsg, setErrorMsg] = useState("");
    const [itemAddedMsg, setItemAddedMsg] = useState("");
    const [hasErrors,setHasErrors] = useState(true);
    const [loading, setLoading] = useState(false);
    const [errorResponse,setErrorResponse] = useState();

    const [client, setClient] = useState("");
    const [theKey, setTheKey] = useState("");
    const [isKeyChanged, setIsKeyChanged] = useState(false);
    const [value, setValue] = useState("");
    const [isJson, setIsJson] = useState(false);


    useEffect(() => {
      if (client.trim().length === 0 || theKey.trim().length === 0) {
        setHasErrors(true);
        return;
      }
      if (isKeyChanged) {
        setHasErrors(false);
        return;
      }
      if (
        (typeof value === 'object' && value !== null && !Array.isArray(value) && Object.keys(value).length === 0) || 
        (Array.isArray(value) && value.length === 0)) {
          setValue("");
          return
        }
      if(isJson){
        if (!value || value.trim() === '') {
          setHasErrors(false);
          return
        }
          try {
              const jsonVal = JSON.parse(value)
              console.log('parsed', jsonVal)
              setHasErrors(false);
          }catch(e){
              console.log("error while parsing json", e)
              setHasErrors(true);
              return;
          }
      }

      setItemAddedMsg(null);

      //check not a duplicate if we are adding a new item
      if (!selected && alreadyExists()) {
        setErrorMsg(t("ADMIN_CONFIG_ALREADY_EXISTS", "New Config (Client & Key combination) already exists!"));
        setHasErrors(true);
      } else {
        if (selected && value.trim() === selected.value) {
          //if value same as its current value then disable submit button
          setHasErrors(true);
        } else {
          setErrorMsg("");
          setHasErrors(false);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client, theKey, value]);
    
    useEffect(() => {
      if (selected) {
        setClient(selected.client);
        setTheKey(selected.key);
        setValue(selected.value);
        const isJsonSelected = selected.value && (selected.value.trim().startsWith('[') || selected.value.trim().startsWith('{'))
        setIsJson(isJsonSelected)
          if(isJsonSelected){
              try {
                  JSON.parse(value)
              }catch(e){
                  console.log("selected error while parsing json", e)
                  setHasErrors(true)
              }
          }
      }
        // eslint-disable-next-line
    }, [selected]);

    const alreadyExists = () => {
      if (loadedList === undefined) {
        return true;
      }
      let item = loadedList.filter(f => f.client === client && f.key === theKey)[0];
      return item !== undefined;
    }

    const addNewConfigItem = async () => {
        try {
            setLoading(true);
            await ConfigService.addNewConfig({"client":client,"key":theKey,"value":value});
            loadConfiguration();
            setTheKey("");
            setValue("");
            setItemAddedMsg(t("ADMIN_CONFIG_ITEM_ADDED", "New configuration item added"));
            setLoading(false);
        } catch(response){
            setLoading(false);
            setErrorResponse(response);
        }
    }

    const editConfigItem = async () => {
      try {
          setLoading(true);
          //client is read-only
          await ConfigService.editConfig(selected.id, {"key":theKey, "value":value});
          loadConfiguration();
          setItemAddedMsg(t("ADMIN_CONFIG_ITEM_ADDED", "Configuration item updated"));
          setLoading(false);
          //close the side panel
          handleCancelAddNewItem();
      } catch(response){
          setLoading(false);
          setErrorResponse(response);
      }
  }

    const isValid = () => {
        return !hasErrors && !loading;
    }

    const handleDropdownChangeHandler = (data) => {
      setSelectedDropdown(data.value);
      setClient(data.value);
    };

    const handleKeyChangeHandler = (value) => {
      setTheKey(value);
      setIsKeyChanged(true);
    };

    const handleCopyKeyText = () => {
      navigator.clipboard.writeText(theKey);
    };

    // Function to clean up the JSON string
    const cleanJsonString = (str) => {
        // Remove carriage returns and line feeds
        return str.replace(/[\r\n]/g, '').replace(/\s+/g, ' ').trim();
    };


    const buttonText = selected === null
                        ? t("GLOBAL_BUTTON_SUBMIT", "Submit")
                        : t("GLOBAL_BUTTON_UPDATE", "Update");

    //if readonly the add a space to input textbox
    const readOnly = selected !== null
      ? {cursor: "not-allowed",marginBottom: "15px"}
      : {marginBottom: "15px"};

    return (
        <Page
            name="Create New Config Item"
            header={selected === null?
              t("ADMIN_CONFIG_HEADER", "New Config Item")
              :t("ADMIN_CONFIG_HEADER_EDIT", "Edit Config Item")
            }
        >

        {errorResponse && (
          <p className="error">{errorResponse}</p>
        )}

        <Form>
            <Grid>
                { selected && (<Grid.Row className="new_config_row">
                  <Grid.Column style={{ color:'red' }}>
                    Client values are read-only!
                  </Grid.Column>
                </Grid.Row>)}
                <Grid.Row className="new_config_row">
                    <Grid.Column>
                      <Form>
                        <div style={readOnly}>
                          <Form.Field>
                            <ClientsDropdown
                              disabled={selected !== null}
                              onlyDefaultClients={onlyDefaultClients}
                              multiple={false}
                              selectedDropdown={selectedDropdown}
                              onChangeHandler={handleDropdownChangeHandler}
                              selected={selected?.client} />
                          </Form.Field>
                          <Form.Field>
                      <div style={{ display: "flex", justifyContent: "flex-start" }}>
                        <Input
                          label={t("ADMIN_CONFIG_ITEM_KEY", "Key")}
                          value={theKey}
                          name={"theKey"}
                          fluid
                          onChange={(value) => handleKeyChangeHandler(value.target.value)}
                        />
                        <Button
                          style={{ background: 'none' }}
                          type='button'
                          onClick={handleCopyKeyText}>
                          <Icon style={{ cursor: "pointer" }} color="black" name='copy' />
                        </Button>
                      </div>
                          </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    label={t("USE JSON EDITOR", "Edit as JSON")}
                                    checked={isJson}
                                    name={"useJsonEditor"}
                                    fluid
                                    onChange={(value) => setIsJson(!isJson)}
                                    disabled={false}
                                    transparent={selected}
                                />
                            </Form.Field>
                        </div>
                        <Form.Field>
                            {isJson &&
                                <div className="my-editor">
                                    <VanillaJsonEditor
                                        content={{text:value}}
                                        readOnly={false}
                                        onChange={
                                        updated=>{
                                            if(updated.json) {
                                                setValue( JSON.stringify(updated.json))}
                                            else {
                                                if(isJson) {
                                                    // Clean the JSON string
                                                    const cleanedString = cleanJsonString(updated.text || "");

                                                    try {
                                                      if (cleanedString.trim() === "") {
                                                        setValue("");
                                                      } else {
                                                        // Parse the cleaned JSON string
                                                        const jsonObject = JSON.parse(cleanedString);
                                                        setValue( JSON.stringify(jsonObject))
                                                      }
                                                    } catch (error) {
                                                        console.error('Invalid JSON string:', error);
                                                    }
                                                }else {
                                                    setValue(updated.text || "")
                                                }

                                            }
                                        }}
                                    />
                                </div>
                            }
                            {!isJson && <Input
                                fluid
                                autoFocus={selected}
                                label={t("ADMIN_CONFIG_ITEM_VALUE", "Value")}
                                value={value}
                                name={"value"}
                                onChange={(value) => setValue(value.target.value)}
                            />}
                        </Form.Field>
                      </Form>
                    </Grid.Column>
                </Grid.Row>
                {errorMsg && (<Grid.Row>
                  <Grid.Column>
                      <p className="error">{errorMsg}</p>
                  </Grid.Column>
                </Grid.Row>)}
                {itemAddedMsg && (<Grid.Row>
                  <Grid.Column>
                    <p className="validated">{itemAddedMsg}</p>
                  </Grid.Column>
                </Grid.Row>)}
                <Grid.Row>
                    <Grid.Column>
                        <ConfirmButton
                            buttonText={buttonText}
                            headerText={buttonText}
                            contentText={t(
                                "ADMIN_CONFIG_NEW_ITEM_NO_FEEDBACK",
                                "Please confirm that you want to make this change"
                            )}
                            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                            onConfirm={selected ? editConfigItem : addNewConfigItem}
                            disabled={!isValid()}
                            color={isValid() ? "orange" : "grey"}
                            />

                        <Button onClick={handleCancelAddNewItem}>
                            {t("ADMIN_CONFIG_TOOL_CANCEL", "Cancel")}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
      </Page>);
}

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageConfig })(AdminConfigToolsCreateOrEditPage);
