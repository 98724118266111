import React, {useState, useRef, useEffect} from "react";
import { withTranslation } from "react-i18next";

import {
    Button,
    Form,
    Grid, Message, Modal
} from "semantic-ui-react";


const UploadTranslationsModal = ({open, onClose, t, processColumnOverrideXlsHandler}) => {
    const fileInputRef = useRef();
    const [file, setFile] = useState();
    const [error, setError] = useState("");

    useEffect(() => {
        setError("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const doImport = async () => {
        if (!file) {
            setError("Must select a file first");
            return;
        }

        try {
            processColumnOverrideXlsHandler(file);
        } catch (e) {
            console.error("Error importing file",e);
            setError(e);
        }
        onClose();
    }

    const handleInitClick = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = async (event) => {
        const fileToUpload = event?.target?.files[0];
        setFile(fileToUpload);
    };


    return (
    <Modal
        size={"large"}
        open={open}
        onClose={onClose}
    >
            <Modal.Content scrolling>
                {error && error.length>0 &&  <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={error}
                />}
                <Form>
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_UPLOAD_FILE", "Select XLS file - make sure it has relevant columns")}</label>
                        <input
                            ref={fileInputRef}
                            type="file"
                            hidden
                            accept={[".xls", "application/vnd.ms-excel", ".xlsx"]}
                            onChange={handleFileSelect}
                        />
                        <label>File selected: {file && file?.name ? file.name : "None"}</label>

                        <Button
                            primary={true}
                            content={t("GLOBAL_BUTTON_SELECT_FILE", "Select file")}
                            labelPosition="left"
                            icon={"file"}
                            onClick={handleInitClick}
                        />
                    </Form.Field>
                </Form>
            </Modal.Content>
            <Grid centered padded>
                <Grid.Row>

                    <Button
                        primary
                        size="small"
                        onClick={() => doImport()}
                    >
                        {t("GLOBAL_BUTTON_IMPORT", "Import")}
                    </Button>

                    <Button
                        basic
                        color={"black"}
                        size="small"
                        onClick={onClose}
                    >
                        {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    </Button>
                </Grid.Row>
            </Grid>

    </Modal>
    )
}

export default withTranslation()(UploadTranslationsModal);
