import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PermissionsService from '../../services/PermissionsService';
import { Form } from 'semantic-ui-react';
import ConfigContext from '../../context/ConfigContext';

const StaffDetails = ({
  t,
  initialStaffDetails,
  isCurrentUserASuperAdmin = false,
  onChange,
  onEmailAddressChanged
}) => {
  const config = useContext(ConfigContext);
  const showDrugManagerInputs = config.ui?.showDrugManager !== 'false'; // Pre-existing logic, the system expects no config to be present, and if that's teh case this is true.
  const isPrimarySuperAdmin = PermissionsService.isPrimarySuperAdmin(initialStaffDetails);

  const [staffDetails, setStaffDetails] = useState(initialStaffDetails);

  useEffect(() => {
    onChange && onChange(staffDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffDetails]);

  const handleEmailAddressChanged = () => {
    onEmailAddressChanged && onEmailAddressChanged(staffDetails.email);
  };

  return (
    <>
      {isCurrentUserASuperAdmin && (
        <Form.Field>
          <label>{t('STAFF_FORM_IS_SUPER_ADMIN_LABEL', 'Is Super Admin?')}</label>
          <input
            type='checkbox'
            name={'superAdmin'}
            checked={staffDetails.superAdmin}
            value='superAdmin'
            onChange={(e) => {
              setStaffDetails({ ...staffDetails, superAdmin: e.target.checked })
            }}
            disabled={isPrimarySuperAdmin}
          />
        </Form.Field>
      )}
      <Form.Field>
        <label>{t('STAFF_FORM_FIRSTNAME_LABEL', 'First name')}</label>
        <input
          autoFocus
          name={'firstName'}
          required
          value={staffDetails.firstName}
          onChange={(e) => {
            setStaffDetails({ ...staffDetails, firstName: e.target.value })
          }}
          disabled={isPrimarySuperAdmin}
        />
      </Form.Field>
      <Form.Field>
        <label>{t('STAFF_FORM_LASTNAME_LABEL', 'Last name')}</label>
        <input
          name={'lastName'}
          required
          value={staffDetails.lastName}
          onChange={(e) => {
            setStaffDetails({ ...staffDetails, lastName: e.target.value })
          }}
          disabled={isPrimarySuperAdmin}
        />
      </Form.Field>
      {showDrugManagerInputs && (
        <Form.Field>
          <label>{t('STAFF_FORM_DRUG_MANAGER_LABEL', 'Drug Manager?')}</label>
          <input
            type='checkbox'
            name={'drugManager'}
            checked={staffDetails.drugManager}
            value='drugManager'
            onChange={(e) => {
              setStaffDetails({ ...staffDetails, drugManager: e.target.checked })
            }}
          />
        </Form.Field>
      )}
      <Form.Field>
        <label>{t('STAFF_FORM_EMAIL_LABEL', 'Email')}</label>
        <input
          name={'email'}
          required
          value={staffDetails.email}
          onChange={(e) => {
            setStaffDetails({ ...staffDetails, email: e.target.value })
          }}
          onBlur={handleEmailAddressChanged}
          disabled={isPrimarySuperAdmin}
        />
      </Form.Field>

    </>
  );
};

export default withTranslation()(StaffDetails);
