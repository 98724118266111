import RequestHelper from "../../RequestHelper";
import {serverAddress} from "../config/EnvConfig";

const ConfigService = {
  getConfig: () => {
    return RequestHelper.send(
      serverAddress + "/trial/configuration",
      {},
      "GET",
      {}
    );
  },
  getClientConfig: (client) => {
    return RequestHelper.send(
        serverAddress + "/trial/configuration/" + client,
        {},
        "GET",
        {}
    );
  },
  addNewConfig: (json) => {
    const requestBody = { client: json.client, key: json.key, value: json.value };
    return RequestHelper.send(
      serverAddress + "/trial/configuration/new",
      {},
      "POST",
      null,
      requestBody,
    );
  },
  editConfig: (id, json) => {
    const requestBody = { key: json.key, value: json.value };
    return RequestHelper.send(
      serverAddress + "/trial/configuration/" + id,
      {},
      "PUT",
      null,
      requestBody,
    );
  },
  getNonEditableConfigItems: () => {
    return Window.configuration?.ui?.admin?.config?.notEditable;
  }
}

export default ConfigService;