import InternationalisationService from "../InternationalisationService";
import RequestHelper from "../RequestHelper";
import {serverAddress} from "./config/EnvConfig";

export default {
  async getQuestionnairesForFilter(language, questionnaireDefinitionIds, moduleCodes, filters, workflowTasks) {
    let response = await RequestHelper.send(
      serverAddress + "/questionnaire-summary",
      {},
      "GET",
      {
        language,
        limit: 100,
        moduleCodes,
        questionnaireDefinitionIds,
        filters,
        workflowTasks
      }
    );
    return response;
  },

  async getLatestQuestionnairesOfTypeForDefinition(
    questionnaireDefinitionCode,
    groupCode
  ) {
    let response = await RequestHelper.send(
      serverAddress +
        "/questionnaires/latest/" +
        questionnaireDefinitionCode,
      {},
      "GET",
      groupCode ? { groupCode } : undefined
    );
    return response;
  },

  async getSubmittedQuestionnairesSinceLastLogin(language, staffId) {
    let response = await RequestHelper.send(
      serverAddress +
        "/questionnaires/completed-since-last-login",
      {},
      "GET",
      { language, staffId }
    );
    return response;
  },

  async getCompliance(compliaceThreshold) {
    const queryParams = {};
    if (compliaceThreshold.pro) {
      queryParams.proLowThreshold = compliaceThreshold.pro;
    }
    if (compliaceThreshold.mediaction) {
      queryParams.medicationLowThreshold = compliaceThreshold.medication;
    }
    if (compliaceThreshold.weraable) {
      queryParams.wearableLowThreshold = compliaceThreshold.wearable;
    }

    let response = await RequestHelper.send(
      serverAddress + "/compliance/aggregate",
      {},
      "GET",
      queryParams
    );
    return response;
  },

  async getBoundedCompliance(
    questionnaireCode,
    questionCodes,
    page,
    searchQuery,
    sortBy,
    sortDirection,
    pageLimit
  ) {
    let queryParams = {};
    if (searchQuery) {
      queryParams.searchQuery = searchQuery;
    }
    if (page) {
      queryParams.page = page;
    }
    if (sortBy) {
      queryParams.sortBy = sortBy;
    }
    if (sortDirection) {
      queryParams.sortDirection = sortDirection;
    }
    if (pageLimit) {
      queryParams.pageLimit = pageLimit;
    }
    if (questionCodes) {
      queryParams.questionCodes = questionCodes;
    }
    if (questionnaireCode) {
      queryParams.questionnaireCode = questionnaireCode;
    }

    let response = await RequestHelper.send(
      serverAddress + "/compliance/bounded-compliance",
      { "Accept-Language": InternationalisationService.getLanguage() },
      "GET",
      queryParams
    );
    return response;
  },
};
