import React, { useState } from "react";
import withError from "./hocs/withError";
import RCSlider from "rc-slider";
import DOMPurify from "dompurify";

import "rc-slider/assets/index.css";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";

const Slider = ({ question, value, changeValue, isReadOnly }) => {
  const [internalValue, setInternalValue] = useState(value);

  const onChange = (newValue) => {
    setInternalValue(newValue);
    changeValue(newValue);
  };

  const min = parseFloat(question.min);
  const max = parseFloat(question.max);
  const step =
    "config" in question && question.config.step ? question.config.step : 1;

  let marks = {};
  const shouldShowDisplay =
    !("hideDisplay" in question.config) ||
    ("hideDisplay" in question.config && !question.config.hideDisplay);
  const shouldShowTicks =
    !("hideTicks" in question.config) ||
    ("hideTicks" in question.config && !question.config.hideTicks);

  let accessibilityHint = question.label;
  if (typeof question?.config?.accessibilityHint === "string") {
    accessibilityHint = question.config.accessibilityHint;
  }

  const labelMin =
    typeof question.config.labelMin === "object"
      ? ""
      : question.config.labelMin;
  const labelMax =
    typeof question.config.labelMax === "object"
      ? ""
      : question.config.labelMax;
  const displaySuffix =
    typeof question.config.displaySuffix === "string"
      ? question.config.displaySuffix
      : "";
  const shouldBookendSuffix =
    "shouldBookendSuffix" in question.config
      ? question.config.shouldBookendSuffix
      : false;

  return (
    <div data-question-answer-value={internalValue}>
      <div style={styles.viewOuter}>
        <div
          style={styles.viewInner1}
          accessible
          accessibilityRole={"adjustable"}
          accessibilityHint={accessibilityHint}
          accessibilityValue={{ min, max, now: value }}
        >
          {shouldShowDisplay && (
            <div style={styles.viewDisplayContainer}>
              <div style={styles.viewValueDisplay}>
                <h3 style={styles.textValueDisplay}>
                  {[undefined, "undefined", null, "null"].some(
                    (v) => v === internalValue
                  )
                    ? "?" + displaySuffix
                    : internalValue + displaySuffix}
                </h3>
              </div>
            </div>
          )}
          {shouldShowTicks && <Track max={max} min={min} step={step}/>}
          <div style={{ paddingLeft: "9px", paddingRight: "9px" }}>
            <RCSlider
              onChange={onChange}
              min={min}
              max={max}
              step={question.config?.step}
              defaultValue={internalValue}
              marks={marks}
              styles={{
                handle: {
                  backgroundColor: "#fff",
                  border: "2px solid #f8991d",
                  outline: "2px solid #f8991d",
                  opacity: 1
                },
                track: { backgroundColor: "#f8991d" },
                rail: { backgroundColor: "#000" }
              }}
              style={{ backgroundColor: null }}
              disabled={isReadOnly}
            />
          </div>

          <NumberMarks
            max={max}
            min={min}
            step={step}
            displaySuffix={displaySuffix}
            shouldBookendSuffix={shouldBookendSuffix}
          />
        </div>
        <div style={styles.viewInner2}>
          <div style={styles.text}>
            <div
              style={styles.text}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(labelMin),
              }}
            />
          </div>
          <div style={styles.text}>
            <div
              style={styles.text}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(labelMax),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Track = (props) => {
  const { max, min, step } = props;

  const array = Array((max - min) / step + 1).fill(true);
  const ticks = array.map((val, index) => {
    return <div style={trackStyles.viewTick} />;
  });

  return (
    <div style={trackStyles.viewOuter}>
      <div style={trackStyles.viewInner}>{ticks}</div>
      <div style={trackStyles.viewDivider} />
    </div>
  );
};

const NumberMarks = (props) => {
  const { max, min, step, displaySuffix, shouldBookendSuffix } = props;
  const numbers = Array.apply(null, { length: (max - min) / step + 1 }).map(
    (v, i) => {
      return i * step + min;
    }
  );

  const numberMarks = numbers.map((val) => {
    const bookendLabel =
      shouldBookendSuffix && (val === min || val === max) ? displaySuffix : "";
    const style =
      shouldBookendSuffix && (val === min || val === max)
        ? numberMarkStyles.bookend
        : numberMarkStyles.text;
    return (
      <div style={numberMarkStyles.textWrapper}>
        <div style={style}>{val + bookendLabel}</div>
      </div>
    );
  });
  return <div style={numberMarkStyles.view}>{numberMarks}</div>;
};

const numberMarkStyles = {
  view: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%",
    paddingTop: "16px",
  },
  text: {
    textAlign: "center",
    fontFamily: "Poppins-SemiBold",
    color: "#000",
    width: "40px",
  },
  bookend: {
    textAlign: "center",
    fontFamily: "Poppins-Bold",
    color: "#000",
    width: "60px",
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "16px",
  },
};

const trackStyles = {
  viewTick: {
    width: "2px",
    backgroundColor: "#000",
    height: "32px",
  },
  viewDivider: {
    height: "2px",
    width: "100%",
  },
  viewOuter: {
    width: "100%",
    height: "0px",
    overflow: "visible",
    paddingLeft: "8px",
    paddingRight: "8px",
  },
  viewInner: {
    height: "16px",
    display: "flex",
    flexDirection: "row",
    position: "relative",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const styles = {
  viewOuter: {},
  viewValueDisplay: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "30px",
    height: "60px",
    minWidth: "60px",
    paddingLeft: "15px",
    paddingRight: "15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F8991D",
  },
  viewDisplayContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "16px",
  },
  textValueDisplay: {
    fontFamily: "Poppins-Bold",
    color: "#000",
  },
  viewInner1: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  viewInner2: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBottom: "16px",
  },
  text: { width: "100px", textAlign: "center" },
  label: {
    paddingBottom: "8px",
    fontFamily: "Poppins-Medium",
  },
  slider: {
    width: "100%",
  },
};

export default withContainer(withLabel(withError(Slider)));
