import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Message,
  Segment,
} from 'semantic-ui-react';
import Page from '../components/page/Page';
import ReportService from './ReportService';
import LocalStorageHelper from '../helpers/LocalStorageHelper';
import { LOCALDATA_KEYS } from '../services/LocalDataService';
import _ from 'lodash';
import moment from 'moment';
import DateRangeControls from '../components/DateRangeControls';
import DateTimeService, { DATE_FORMATS } from '../services/DateTimeService';
import ConfigContext from '../context/ConfigContext';
import { saveAs } from 'file-saver';
import StaffPermissionService from '../services/StaffPermissionService';
import withPermissionWrapper from '../security/withPermissionWrapper';
import GeneralHelpers from '../helpers/GeneralHelpers';
import { AgGridReact } from 'ag-grid-react';
import SubjectQuestionnaireService from '../services/SubjectQuestionnaireService';
import { getDefinitions } from '../redux/questionnaires/questionnaireDefinitionsSlice';
import UserContext from '../context/UserContext';
import { connect } from 'react-redux';
import { typeHelper } from 'atom5-branching-questionnaire';
import StaffService from '../StaffService';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

const REPORTS_MANUALDATACHANGE_INPUT_ERRORS = {
  REPORTS_MANUALDATACHANGE_INPUT_ERROR_NONE: {
    translationKey: 'REPORTS_MANUALDATACHANGE_INPUT_ERROR_NONE',
    fallbackText: 'No search criteria specified',
  },
  REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_SITES_SPECIFIED: {
    translationKey: 'REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_SITES_SPECIFIED',
    fallbackText: 'No sites specified',
  },
  REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_DATEFROM_SPECIFIED: {
    translationKey: 'REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_DATEFROM_SPECIFIED',
    fallbackText: 'No date to specified',
  },
  REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_DATETO_SPECIFIED: {
    translationKey: 'REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_DATETO_SPECIFIED',
    fallbackText: 'No date from specified',
  }
};

const ManualDataChangeReport = (props) => {
  const { t, allQuestionnaireDefinitions } = props;

  const config = useContext(ConfigContext);
  const user = useContext(UserContext);

  const defaultSearchCriteriaNumberOfDays =
    config?.reports?.ManualDataChangeReport?.search?.defaultSearchCriteriaNumberOfDays || 7;

  const [isSearching, setIsSearching] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // Search Inputs/Filters
  const [
    searchCriteriaSiteFilterOptions,
    setSearchCriteriaSiteFilterOptions
  ] = useState();
  const [
    searchCriteriaQuestionnaireFilterOptions,
    setSearchCriteriaQuestionnaireFilterOptions
  ] = useState();

  const defaultSearchCriteria = {
    siteCodes: [],
    dateFrom: moment().subtract(defaultSearchCriteriaNumberOfDays, 'days').format(DATE_FORMATS.DATE),
    dateTo: moment().subtract().format(DATE_FORMATS.DATE),
    questionnaireDefinitionCodes: [],
    questionnaireId: null
  };
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchCriteria);

  // Search Results
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [searchResultsHasExceededLimit, setSearchResultsHasExceededLimit] = useState(false);

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setupSearchCriteriaInputs = async () => {
    await setupSearchCriteriaSiteFilter();
    await setupSearchCriteriaQuestionnaireFilter();
  };

  const setupSearchCriteriaSiteFilter = async () => {
    const groupTranslations = await StaffService.getMyGroupTranslations();

    const groupsForFilter = groupTranslations
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .map((g) => {
        return {
          key: g.code,
          text: g.label,
          value: g.code
        };
      });
    setSearchCriteriaSiteFilterOptions(groupsForFilter);
  };

  /**
   * @private
   * @deprecated
   * Added to complete AT-3842 (the permissions filtering of questionnaires for AT-1419)
   * It is added to work with the current UI oriented handling of filtering based on Modules
   * In future we can make use of the changes in AT-1384, maybe with some additional
   *  development to handle it from nucleus. It would also need existing studies to have the
   *  config migrated too.
   * When looking at this, search for the same method name in the project
   */
  const _getQuestionnaireDefinitionsForUser = async (
    questionnaireDefinitions
  ) => {
    const tabsConfig = config.ui?.tabs ? config.ui?.tabs : [];
    const staffProfile = user.profile;
    const groups = user.profile.groupMappings.map((gm) => gm.group);
    const filtered = [];
    for (const qd of questionnaireDefinitions) {
      const cs =
        await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
          qd,
          groups,
          tabsConfig,
          staffProfile
        );
      if (cs === true) {
        filtered.push(qd);
      }
    }
    return filtered;
  };

  const setupSearchCriteriaQuestionnaireFilter = async () => {
    const permittedDefinitions = await _getQuestionnaireDefinitionsForUser(
      allQuestionnaireDefinitions,
      null
    );

    const definitionForFilter = permittedDefinitions
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .map((qd) => {
        let label = SubjectQuestionnaireService.getQuestionnaireLabel(qd);
        const questionnaireModuleLabels =
          SubjectQuestionnaireService.getQuestionnaireModuleLabels(t, qd);
        if (questionnaireModuleLabels !== undefined) {
          label = `${label} (${questionnaireModuleLabels})`;
        }

        // Fallback to qd code if no translation found
        if (label == null || label.trim().length === 0) {
          label = qd.code;
        }

        return {
          key: qd.code,
          text: label ?? qd.code,
          value: qd.code
        };
      });
    setSearchCriteriaQuestionnaireFilterOptions(definitionForFilter);
  };

  const init = async () => {
    await setupSearchCriteriaInputs();
    const persistedCriteria = LocalStorageHelper.getJsonObject(
      LOCALDATA_KEYS.REPORT_MANUAL_DATA_CHANGE
    );
    if (!_.isEmpty(persistedCriteria)) {
      setSearchCriteria(persistedCriteria);
    }
  };

  const onFormSubmit = async (e) => {
    GeneralHelpers.stopEvent(e);
    performSearch();
  };

  const onResetSearchCriteria = (e) => {
    GeneralHelpers.stopEvent(e);
    setSearchCriteria(defaultSearchCriteria);
    setErrorMessage();
    setColumnDefs([]);
    setRowData([]);
    setSearchResultsHasExceededLimit(false);
    LocalStorageHelper.setJson(
      LOCALDATA_KEYS.REPORT_MANUAL_DATA_CHANGE,
      defaultSearchCriteria
    );
  };

  const initSearchState = async () => {
    setIsSearching(true);
    setErrorMessage();
    setSearchResultsHasExceededLimit(false);
    setColumnDefs([]);
    setRowData([]);
  };

  const validateSearchCriteria = () => {
    if (searchCriteria == null) {
      throw new Error(REPORTS_MANUALDATACHANGE_INPUT_ERRORS.REPORTS_MANUALDATACHANGE_INPUT_ERROR_NONE.translationKey);
    }
    if (searchCriteria.siteCodes == null || !Array.isArray(searchCriteria.siteCodes) || searchCriteria.siteCodes.length === 0) {
      throw new Error(REPORTS_MANUALDATACHANGE_INPUT_ERRORS.REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_SITES_SPECIFIED.translationKey);
    }
    if (searchCriteria.dateFrom == null) {
      throw new Error(REPORTS_MANUALDATACHANGE_INPUT_ERRORS.REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_DATEFROM_SPECIFIED.translationKey);
    }
    if (searchCriteria.dateTo == null) {
      throw new Error(REPORTS_MANUALDATACHANGE_INPUT_ERRORS.REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_DATETO_SPECIFIED.translationKey);
    }
  };

  const performSearch = async () => {
    await initSearchState();
    try {
      validateSearchCriteria();

      LocalStorageHelper.setJson(
        LOCALDATA_KEYS.REPORT_MANUAL_DATA_CHANGE,
        searchCriteria
      );
      const rawResults = await ReportService.ManualDataChangeReport.getBySearchCriteria(
        searchCriteria
      );
      setSearchResultsHasExceededLimit(rawResults?.hasExceededLimit);

      const colDefs = rawResults?.columns?.map(col => {
        return { headerName: col.label, field: col.key, cellDataType: false }
      });
      setColumnDefs(colDefs);
      setRowData(rawResults?.rows);

    } catch (error) {
      let errorMessage = error?.message ? error.message : error; 
      if (typeof error?.message === "string" && error.message != null && error.message.startsWith('REPORTS_MANUALDATACHANGE_INPUT_ERROR_')) {
        errorMessage = t(error.message, REPORTS_MANUALDATACHANGE_INPUT_ERRORS.REPORTS_MANUALDATACHANGE_INPUT_ERROR_NO_SITES_SPECIFIED?.fallbackText);
      }
      setErrorMessage(errorMessage);
    } finally {
      setIsSearching(false);
    }
  };

  const getRecordCount = () => {
    const count = rowData?.length;
    return count;
  };

  const buildSearchCriteriaFilterInputs = () => {
    return (
      <Grid columns='equal'>
        <Grid.Row>

          <Grid.Column width={6}>
            <label>
              {t(
                'REPORTS_MANUALDATACHANGE_INPUT_SITES',
                'Sites'
              )}
            </label>
            <Dropdown
              placeholder={t(
                [
                  'REPORTS_MANUALDATACHANGE_INPUT_SITES_PLACEHOLDER',
                  'REPORTS_MANUALDATACHANGE_INPUT_SITES'
                ],
                'Sites'
              )}
              fluid
              multiple
              selection
              options={searchCriteriaSiteFilterOptions || []}
              onChange={(_e, data) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.siteCodes = data.value;
                setSearchCriteria(newSearchCriteria);
              }}
              value={searchCriteria?.siteCodes || []}
            />
          </Grid.Column>

          <Grid.Column width={6}>
            <label>
              {t('REPORTS_MANUALDATACHANGE_INPUT_DATERANGE', 'Date Range')}
            </label>
            <DateRangeControls
              disableFormSubmit={true}
              notifyParentOnLoad={false}
              onChange={(startDate, endDate) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.dateFrom = startDate;
                newSearchCriteria.dateTo = endDate;
                setSearchCriteria(newSearchCriteria);
              }}
              start={moment(searchCriteria.dateFrom)}
              end={moment(searchCriteria.dateTo)}
            />
          </Grid.Column>

        </Grid.Row>
        <Grid.Row>

          <Grid.Column width={6}>
            <label>
              {t(
                'REPORTS_MANUALDATACHANGE_INPUT_QUESTIONNAIRE_DEFINITION',
                'Questionnaires'
              )}
            </label>
            <Dropdown
              placeholder={t(
                [
                  'REPORTS_MANUALDATACHANGE_INPUT_QUESTIONNAIRE_DEFINITION_PLACEHOLDER',
                  'REPORTS_MANUALDATACHANGE_INPUT_QUESTIONNAIRE_DEFINITION'
                ],
                'Questionnaires'
              )}
              fluid
              multiple
              selection
              options={searchCriteriaQuestionnaireFilterOptions || []}
              onChange={(_e, data) => {
                const newSearchCriteria = { ...searchCriteria };
                newSearchCriteria.questionnaireDefinitionCodes = data.value;
                setSearchCriteria(newSearchCriteria);
              }}
              value={searchCriteria?.questionnaireDefinitionCodes || []}
            />
          </Grid.Column>

          <Grid.Column width={4}>
            <label>
              {t('REPORTS_MANUALDATACHANGE_INPUT_QUESTIONNAIREID', 'Questionnaire Id')}
            </label>
            <Input
              fluid
              type='number'
              step={1}
              onChange={(_e, data) => {
                const newSearchCriteria = { ...searchCriteria };
                const id = data?.value.trim().length > 0 ? data.value.trim() : undefined;
                newSearchCriteria.questionnaireId = typeHelper.parseNumber(id);
                setSearchCriteria(newSearchCriteria);
              }}
              placeholder={t(
                [
                  'REPORTS_MANUALDATACHANGE_INPUT_QUESTIONNAIREID_PLACEHOLDER',
                  'REPORTS_MANUALDATACHANGE_INPUT_QUESTIONNAIREID'
                ],
                'Questionnaire Id'
              )}
              value={searchCriteria?.questionnaireId || ''}
            />
          </Grid.Column>


        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Form onSubmit={onFormSubmit} className={null}>
              <Button primary disabled={isSearching} type='submit'>
                {t(['REPORTS_SEARCH_BUTTON', 'REPORTS_MANUALDATACHANGE_SEARCH_BUTTON'], 'Search')}
              </Button>
              <Button secondary disabled={isSearching} onClick={onResetSearchCriteria}>
                {t(['REPORTS_RESET_BUTTON', 'REPORTS_MANUALDATACHANGE_RESET_BUTTON'], 'Reset search')}
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const initiateDownload = async (format) => {
    const persistedCriteria = LocalStorageHelper.getJsonObject(
      LOCALDATA_KEYS.REPORT_MANUAL_DATA_CHANGE
    );

    const blobData = await ReportService.ManualDataChangeReport.getBySearchCriteria(
      !_.isEmpty(persistedCriteria) ? persistedCriteria : searchCriteria,
      format
    );

    const dateTimeWithMillis = DateTimeService.now.asString();
    const filename = `manual-data-change-report-${dateTimeWithMillis}.${format}`;
    saveAs(blobData, filename);
  };

  const shouldShowExportControls = (getRecordCount() != null && getRecordCount() > 0);

  return (
    <Page
      name='REPORTS_MANUAL_DATA_CHANGE_PAGE'
      header={t('REPORTS_MANUAL_DATA_CHANGE_TITLE', 'Manual Data Changes')}
    >
      {errorMessage && (
        <Message
          error
          header={t('GLOBAL_ERROR_TITLE', 'Error')}
          content={errorMessage}
        />
      )}
      {buildSearchCriteriaFilterInputs()}
      {searchResultsHasExceededLimit && (
        <Message
          warning
          header={t('GLOBAL_INFO_TITLE', 'Warning')}
          content={t(
            'REPORTS_RESULTS_EXCEEDED_LIMIT',
            'Your search returned a lot of results, it is advisable to add more filters to reduce the results.'
          )}
        />
      )}
      {getRecordCount() !== undefined && (
        <Segment>
          {t('REPORTS_RESULTS_ITEM_COUNT', 'Count')}:{' '}
          {getRecordCount()}
        </Segment>
      )}

      <div className='ag-theme-balham' style={{ width: '100%', height: 500 }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
        />
      </div>

      <div style={{ marginTop: 20 }}>
        <Grid columns='equal'>
          <Grid.Row>
            {shouldShowExportControls && (
              <Grid.Column width={8}>
                <Button
                  primary
                  icon
                  labelPosition='left'
                  onClick={() => initiateDownload('csv')}
                >
                  {t('EXPORT_TABLE', 'Export Table as CSV')}
                  <Icon name='table' />
                </Button>
                <Button
                  primary
                  icon
                  labelPosition='left'
                  onClick={() => initiateDownload('xls')}
                >
                  {t('EXPORT_TABLE_XLS', 'Export Table as Excel')}
                  <Icon name='table' />
                </Button>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    allQuestionnaireDefinitions: getDefinitions(state)
  };
};

const withEnhancements = (options) => compose(
  connect(mapStateToProps),
  withPermissionWrapper(options),
  withTranslation()
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.Reports.canAccessManualDataChangeReport })(ManualDataChangeReport);
