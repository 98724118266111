import React, { useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import Page from "../../../components/page/Page";
import { getContentDefinitions, getDataDefinitions, getEventDefinitions, getProDefinitions } from "../../../redux/questionnaires/questionnaireDefinitionsSlice";
import { getCompletedDatas, getCompletedEvents, getCompletedPros, getContents, getCurrentPros } from "../../../redux/questionnaires/subjectQuestionnairesSlice";
import SubjectAppStateService from "../../../services/SubjectAppStateService";
import SubjectQuestionnaireList from "../../../components/lists/SubjectQuestionnaireList";
import {byDecreasingCompletionDate, bySequenceAndById} from "../../../helpers/sortFunctions";
import ConfigContext from "../../../context/ConfigContext";
import { useLocation } from "react-router";
import API_QUESTIONNAIRE_DEFINITION_TYPES from "../../../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";

const ModuleListPage = (props) => {
  const { t, completed, definitions, currentPros, contents } = props;

  const config = useContext(ConfigContext);
  const dispatch = useDispatch();
  const location = useLocation();

  const pathArray = location.pathname.split("/");
  const moduleName = pathArray[pathArray.length - 1];

  const definitionFilter = (def) => {
    return def.config?.modules?.indexOf(moduleName) >= 0;
  };
  const questionnaireFilter = (questionnaire) => {
    if (questionnaire?.modules?.length > 0) {
      return questionnaire.modules.some(module => module.code === moduleName);
    }
    const definition = definitions.find((def) => def.id === questionnaire.definitionId);
    return definitionFilter(definition);
  }

  const filteredQuestionnaires = [...currentPros, ...contents].filter(questionnaireFilter);
  const filteredDefinitions = definitions.filter(definitionFilter).filter((definition) => (
    definition.type !== API_QUESTIONNAIRE_DEFINITION_TYPES.CONTENT &&
    definition.type !== API_QUESTIONNAIRE_DEFINITION_TYPES.PRO
  ));
  const filteredCompleted = completed.filter(questionnaireFilter);

  const navConfig = config.home?.navigation;
  const moduleFromNav = navConfig.find((nav) => nav.screen.split('/')[1] === moduleName);

  useEffect(() => {
    SubjectAppStateService.getSubjectQuestionnairesFromServer(dispatch);
    SubjectAppStateService.getSubjectCompletedQuestionnairesFromServer(dispatch);
  }, [dispatch]);

  return (
    <Page
      name="MODULE_LIST_PAGE"
      header={t(moduleFromNav.webText)}
      maxWidth="800px"
    >
      {filteredDefinitions.length > 0 && (
        <>
          <SubjectQuestionnaireList
            definitionList={filteredDefinitions}
            questionnaireList={null}
          />
          <br />
        </>
      )}
      <SubjectQuestionnaireList
        questionnaireList={filteredQuestionnaires}
        definitionList={definitions}
        sort={bySequenceAndById}
        showEmpty
      />
      {filteredCompleted.length > 0 && (
        <SubjectQuestionnaireList
          title={t("GLOBAL_COMPLETED")}
          questionnaireList={filteredCompleted}
          definitionList={definitions}
          sort={byDecreasingCompletionDate}
        />
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    definitions: [
      ...getContentDefinitions(state),
      ...getDataDefinitions(state),
      ...getEventDefinitions(state),
      ...getProDefinitions(state),
    ],

    contents: getContents(state),

    completed: [
      ...getCompletedDatas(state),
      ...getCompletedEvents(state),
      ...getCompletedPros(state),
    ],

    currentPros: getCurrentPros(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(ModuleListPage);
