import React, { useState, useMemo, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";
import StaffService from "../../StaffService";
import TrialService from "../../TrialService";
import Page from "../../components/page/Page";
import AuthService from "../../services/AuthService";
import {PERMISSION_DEFAULT_TRANSLATION} from "../../GroupPermission";


const RolesPermissionsPage = (props) => {
  const { t } = props;

  const [groupLabels, setGroupLabels] = useState();
  const [roleLabels, setRoleLabels] = useState([]);

  const [myProfile, setMyProfile] = useState()

  const initialise = async () => {
    try {
      const profile = await AuthService.getMyProfile();
      setMyProfile(profile)
      populateLabels();
    } finally {

    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateLabels = () => {
    TrialService.getGroups().then((allGroups) => {
      const newGroupLabels = {};
      for (const group of allGroups) {
        newGroupLabels[group.code] = group.label;
        addRoleLabelsForGroup(group.code);
      }
      setGroupLabels(newGroupLabels);
    });
  };

  const addRoleLabelsForGroup = () => {
    const newRoleLabels = { ...roleLabels };
    StaffService.getMyRoleTranslations().then((availableRoles) => {
      for (const role of availableRoles) {
        newRoleLabels[role.code] = role.label;
      }
      if (Object.keys(newRoleLabels).length !== 0) {
        setRoleLabels(newRoleLabels);
      }
    });
  };

  const hasLoaded = useMemo(() => {
    if (myProfile == null) return false
    if (groupLabels == null) return false
    if (roleLabels == null) return false

    return true
  }, [myProfile, groupLabels, roleLabels]);



  return (
    <Page
      name="ROLES_PERMISSIONS"
      header={t("ROLES_PERMISSIONS_HEADER", 'Roles & Permissions')}
      subheader={t("ROLES_PERMISSIONS_SUBHEADER", 'View your current roles & permissions')}
      loading={!hasLoaded}
    >

      {hasLoaded && myProfile.groupMappings.map(gM => {
        return (
          <Card fluid style={{ padding: '1rem' }}>
            <Card.Header style={{
              fontSize: '1.7rem',
              paddingBottom: '1rem'
            }}>
              {groupLabels[gM.group.code]}
            </Card.Header>
            {gM.roles.map(r => {
              const per = r.permissions.map(p => {
                const translation = PERMISSION_DEFAULT_TRANSLATION[p]
                if (translation == null) {
                  console.warn('Missing permission translation for ', p);
                }
                return `${translation.labelTranslation.label}`;
            });
              return (
                <div>
                  <h4>{roleLabels[r.code]}</h4>
                  <Card.Content style={{ paddingBottom: '1rem' }} description={per.join(', ')} />
                </div>
              )
            })}
          </Card>
        )
      })}
    </Page>
  );
};

export default withTranslation()(RolesPermissionsPage);
