import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import DateTimeField from '../../components/DateTimeField';
import withQuestionRequiredText from '../../questionnaires/question-renderers/hocs/withQuestionRequiredText';
import ScheduledItemService, { SCHEDULED_ITEM_TYPE } from '../../services/ScheduledItemService';
import { useParams } from 'react-router';

const STANDARD_DURATIONS = [5, 10, 15, 20, 30, 40, 45, 60, 75, 90, 105, 120];

const SubjectVideoCallCreatePage = (props) => {
  const { t, questionRequiredText } = props;
  const { subjectId } = useParams();
  const scheduledItemService = new ScheduledItemService(SCHEDULED_ITEM_TYPE.VIDEOCALL);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [name, setName] = useState('');
  const [startDateMillis, setStartDateMillis] = useState();
  const [durationMinutes, setDurationMinutes] = useState(30);

  const [validationStatus, setValidationStatus] = useState({
    name: true,
    startDateMillis: true,
    durationMinutes: true
  });

  useEffect(() => {
    console.log('useEffect validationStatus', validationStatus);
    let message;
    if (!validationStatus.name) {
      message = t('VIDEO_CALL_NAME_INVALID', 'You must specify a name');
    } else if (!validationStatus.startDateMillis) {
      message = t('VIDEO_CALL_START_DATE_INVALID', 'Start date must be in the future');
    } else if (!validationStatus.durationMinutes) {
      message = t('VIDEO_CALL_END_DATE_INVALID', 'End date is invalid');
    }
    setErrorMessage(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationStatus]);

  const validateName = (value) => {
    const valid = value !== null && value.length > 0
    setValidationStatus({ ...validationStatus, name: valid });
  }

  const handleNameChange = (_e, v) => {
    const value = v.value;
    validateName(value);
    setName(value);
  }

  const validateStartDate = (value) => {
    const now = moment();
    const startDate = moment(value);
    const valid = startDate.isValid() && startDate >= now;
    setValidationStatus({ ...validationStatus, startDateMillis: valid });
  }

  const handleStartDateChange = (momentValue) => {
    if (momentValue.isValid()) {
      const value = momentValue.valueOf();
      validateStartDate(value);
      setStartDateMillis(value);
    }
  }

  const validateDurationMinutes = (value) => {
    const valid = !isNaN(value) && value > 0;
    setValidationStatus({ ...validationStatus, durationMinutes: valid });
  }

  const handleDurationMinutesChange = (_e, v) => {
    const value = v.value;
    validateDurationMinutes(value);
    setDurationMinutes(value);
  }

  const isInputValid = useMemo(() => {
    return validationStatus.name && validationStatus.startDateMillis && validationStatus.durationMinutes;
  }, [validationStatus]);

  const handleSubmit = async () => {
    if (!isInputValid) {
      return;
    }

    const startDate = moment(startDateMillis);
    const endDate = moment(startDate).add(durationMinutes, 'minutes');
    const payload = {
      name,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    };

    setIsLoading(true);
    try {
      await scheduledItemService.createScheduledItem(subjectId, payload);
      handleCancel();
    } catch (error) {
      console.error(error);
      setErrorMessage(t('GLOBAL_ERROR_GENERIC_MESSAGE'));
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    window.location.href =
      window.location.origin +
      '/app/subject/' +
      subjectId +
      '/tabs/videocalls';
  };

  const getDefaultDurationListOptions = () => {
    return STANDARD_DURATIONS.map(t => {
      return <option key={t} value={t} />
    });
  };

  const durationListOptions = useMemo(getDefaultDurationListOptions, []);

  return (
    <Segment>
      <Form
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        error={errorMessage?.length > 0}
        style={{minHeight: '600px'}}
      >
        <Message
          error
          header={t('GLOBAL_ERROR_TITLE')}
          content={errorMessage}
        />
        <Grid columns={'equal'}>
          <Grid.Column>
            <Header>{t('VIDEO_CALL_CREATE_VIDEO_CALL_SECTION_HEADER', 'Create Video Call')}</Header>
            <Form.Field>
              <label>{t('VIDEO_CALL_NAME', 'Name') + ' ' + questionRequiredText}</label>
              <Form.Input
                type='text'
                name='name'
                id='name'
                required
                onChange={handleNameChange}
                value={name}
              />
            </Form.Field>
            <DateTimeField
              label={t('VIDEO_CALL_START_DATE', 'Start date') + ' ' + questionRequiredText}
              min={new moment()}
              onChange={handleStartDateChange}
              mode={'datetime'}
            />
            <Form.Field>
              <label>{t('VIDEO_CALL_DURATION', 'Duration (Minutes)') + ' ' + questionRequiredText}</label>
              <datalist id="durationList">
                {durationListOptions}
              </datalist>
              <Form.Input
                type='number'
                name='duration'
                id='duration'
                required
                onChange={handleDurationMinutesChange}
                value={durationMinutes}
                autoComplete={"off"}
                list="durationList"
              />
            </Form.Field>

          </Grid.Column>
          <Grid.Row>
            <Grid.Column>
              <Button
                type='submit'
                loading={isLoading}
                disabled={!isInputValid}
                primary
              >
                {t('GLOBAL_BUTTON_SAVE', 'Save')}
              </Button>
              {handleCancel && (
                <Button color={"black"} basic type='button' onClick={handleCancel}>
                  {t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
                </Button>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  );
};

const enhance = compose(withTranslation(), withQuestionRequiredText)

export default enhance(SubjectVideoCallCreatePage);
