import RequestHelper, {CONTENT_TYPE} from "../RequestHelper";
import {serverAddress} from "../services/config/EnvConfig";

export const DICTIONARY_TYPES = {
  "COUNTRY": "country",
  "GROUP": "group",
  "SUBJECT": "subject"
}

export function getDictionary(dictionaryType, countryFilters=[], groupFilters=[], subjectFilters=[]){
  return RequestHelper.send(
      serverAddress + "/reports/visitcompliance/getdictionary/"+dictionaryType,
      {},
      "POST",
      null,
      {
        page:0,
        countries:countryFilters,
        groupCodes: groupFilters,
        subjectCodes: subjectFilters
      }
  );
}

export function getVisitCompliance(page, countryFilters=[], groupFilters=[], subjectFilters=[]){
  return RequestHelper.send(
      serverAddress + "/reports/visitcompliance/search",
      {},
      "POST",
      null,
      {
        page: page,
        countries:countryFilters,
        groupCodes: groupFilters,
        subjectCodes: subjectFilters
      }
  );
}

export function getAggregateCompliance(countryFilters=[], groupFilters=[], subjectFilters=[]){
  return RequestHelper.send(
      serverAddress + "/reports/visitcompliance/searchcompliancecalc",
      {},
      "POST",
      null,
      {
        page:0,
        countries:countryFilters,
        groupCodes: groupFilters,
        subjectCodes: subjectFilters
      }
  );
}

export function getCSVExport(countryFilters=[], groupFilters=[], subjectFilters=[]){
  return RequestHelper.send(
      serverAddress + "/reports/visitcompliance/export-csv",
      {
        Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
      },
      "POST",
      null,
      {
        page:0,
        countries:countryFilters,
        groupCodes: groupFilters,
        subjectCodes: subjectFilters
      }
  );
}

export default {
  DICTIONARY_TYPES,
  getDictionary,
  getVisitCompliance,
  getAggregateCompliance,
  getCSVExport
}