/* eslint no-unused-vars: 0 */

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import AuthService from "../../services/AuthService";

class SubjectDeviceCreatePage extends Component {
  state = {
    loading: false,
    description: "",
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmit = (device) => {
    this.setState({ isSubmitting: true });
    return SubjectService.createDevice(
      this.props.match.params.subjectId,
      this.state.description
    )
      .then(() => {
        this.onCancel();
      })
      .catch(() => {
        this.setState({ error: true, isSubmitting: false });
      })
      .finally(() => this.setState({ isSubmitting: false }));
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;
    const errorMessage = this.state.error
      ? t("DEVICE_NAME_EXISTS_ERROR_MESSAGE")
      : undefined;

    return (
      <Page
        name="DEVICE_CREATE"
        header={t("DEVICE_CREATE_HEADER")}
        subheader={t("DEVICE_CREATE_SUBHEADER")}
        loading={this.state.loading}
      >
        <Form
          onSubmit={this.onSubmit}
          onCancel={this.onCancel}
          error={this.state.error}
        >
          <Message
            error
            header={t("DEVICE_CREATE_ERROR_TITLE")}
            content={errorMessage}
          />

          <Grid columns={"equal"}>
            <Grid.Column>
              <Segment>
                <Header>{t("DEVICE_CREATE_DETAILS_HEADER")}</Header>
                <Form.Field>
                  <label>{t("DEVICE_CREATE_DESCRIPTION_LABEL")}</label>
                  <input
                    name={"description"}
                    required
                    value={this.state.description}
                    onChange={this.inputChanged}
                  />
                </Form.Field>
              </Segment>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  disabled={this.state.isSubmitting}
                  loading={this.state.isSubmitting}
                  primary
                >
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                {this.props.onCancel && (
                  <Button color={"black"} basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL")}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Page>
    );
  }
}

export default withTranslation()(SubjectDeviceCreatePage);
