import { useCallback, useState } from "react";
import _ from "lodash";
import { Form } from "semantic-ui-react";

const DebouncedTextInput = (props) => {
  const { onChange: containerOnChange } = props;

  const [internalValue, setInternalValue] = useState(props?.value ?? '');

  const performSearch = (e, data) => {
    containerOnChange && containerOnChange(e, data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPerformSearch = useCallback(_.debounce(performSearch, props.debounceWait ?? 500), []);

  const handleChange = (e, data) => {
    setInternalValue(data.value);
    debouncedPerformSearch(e, data);
  };

  const updatedProps = {
    ...props,
    value: internalValue,
    onChange: handleChange
  }

  return (<Form.Input {...updatedProps} />);
};

export default DebouncedTextInput;
