import React, { useEffect, useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { compose } from "redux";
import Page from "../../components/page/Page";
import Questionnaire from "../../questionnaires/Questionnaire";
import UserContext from "../../context/UserContext";
import { getContentDefinitions } from "../../redux/questionnaires/questionnaireDefinitionsSlice";
import { connect } from "react-redux";

const StudyInformationQuestionnaire = (props) => {
  const { contentDefinitions, informationQuestionnaireCode, t } = props;
  const user = useContext(UserContext);

  const [informationQuestionnaire, setInformationQuestionnaire] =
    useState(null);
  const [loading, setLoading] = useState(true);

  const getInformationQuestionnaire = async () => {
    const informationQuestionnaire = contentDefinitions.find(
      (questionnaire) => questionnaire.code === informationQuestionnaireCode
    );

    if (informationQuestionnaire) {
      await setInformationQuestionnaire(informationQuestionnaire);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (contentDefinitions && contentDefinitions.length > 0) {
      getInformationQuestionnaire();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentDefinitions]);

  if (!loading && !informationQuestionnaire) {
    if (user.isLoggedIn) {
      if (user.accountType !== "subject") {
        return <Redirect to="/app/home" />;
      }
    } else {
      return <Redirect to="/#login" />;
    }
  }

  // TODO: make a loading component
  if (loading) {
    return <></>;
  }

  return (
    <Page>
      {informationQuestionnaire && (
        <Questionnaire
          definition={informationQuestionnaire}
          shouldShowSubmit={false}
          subjectId={-1}
        />
      )}
      {!informationQuestionnaire && t("QUESTIONNAIRE_NOT_FOUND")}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    contentDefinitions: getContentDefinitions(state),
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(StudyInformationQuestionnaire);
