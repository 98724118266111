import React from 'react';
import {convertToStandardAnatomyFormat} from "../../../../constants/BODY_PARTS";

const AnatomySelectors = (props) => {
  const { bodyPartData, onClick, bodyPartArray, isSelectedArray, selectedColor, unselectedColor } = props;
  return Object.entries(bodyPartData.bodyParts).map((entry) => {
    const isSelected = isSelectedArray.map(convertToStandardAnatomyFormat).indexOf(entry[0]) > -1;
    const answerKeyValueIndex = bodyPartArray.findIndex(v=>convertToStandardAnatomyFormat(v) === entry[0])
    return (
      <>
        {bodyPartArray.map(convertToStandardAnatomyFormat).indexOf(entry[0]) > -1 && (
          <path
            onClick={() => onClick(bodyPartArray[answerKeyValueIndex])}
            d={entry[1].svg}
            fill={isSelected ? selectedColor : unselectedColor}
            stroke='#58595B'
            strokeWidth='2'
            strokeDasharray='2 2'
          />
        )}
      </>
    );
  });
};

export default AnatomySelectors;
