import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import NotFound from "../NotFound";
import SelfOnboardSignupPage from "../pages/login/SelfOnboardSignupPage";
import SubjectConsentPage from "../pages/account-activation/SubjectConsentPage";
import SubjectScreeningPage from "../pages/account-activation/SubjectScreeningPage";
import SubjectNonConsentPage from "../pages/account-activation/SubjectNonConsentPage";
import AccountActivationPage from "../pages/account-activation/AccountActivationPage";
import ForgotPasswordPage from "../pages/profile/ForgotPasswordPage";
import DigitalSignatureReturnPage from "../pages/thirdparty/DigitalSignatureReturnPage";
import DigitalSignatureRedirectPage from "../pages/thirdparty/DigitalSignatureRedirectPage";
import DashboardRoutes from "./DashboardRoutes";
import StudyInformationPage from "../pages/information/StudyInformationPage";
import GenericReturnPage from "../pages/thirdparty/GenericReturnPage";
import MultiFactorAuthentication from "../pages/secure/MultiFactorAuthentication";

const RootNavigator = (props) => {
  const { loggedIn } = props;
  const pathname = `?referrer=${window.location.pathname}` || "";

  const redirectAndShowLoginModal = (rest) => {
      if (rest?.location?.search) {
          const params = new URLSearchParams(rest.location.search);
          const queryString = params.toString();
          window.location.href = '/?' + queryString + '#login';
      } else {
          window.location.href = '/#login';
      }
  }

  return (
    <Switch>
      <Route exact path="/" render={() => <StudyInformationPage />} />
      <Route
        exact
        path="/subject/signup"
        render={({ ...rest }) => <SelfOnboardSignupPage {...rest} />}
      />
      <Route
        exact
        path="/subject/consent"
        render={({ ...rest }) => <SubjectConsentPage {...rest} />}
      />
      <Route
        exact
        path="/subject/non-consent"
        render={({ ...rest }) => <SubjectNonConsentPage {...rest} />}
      />
      <Route
        exact
        path="/app/subject/screening"
        render={({ ...rest }) =>
          loggedIn ? <SubjectScreeningPage {...rest} /> : redirectAndShowLoginModal(rest)
        }
      />
      <Route
        exact
        path="/app/secure"
        render={({ ...rest }) => <MultiFactorAuthentication {...rest} />}
      />
      <Route
        exact
        path="/thirdparty/message"
        render={({ ...rest }) => <GenericReturnPage {...rest} />}
      />
      <Route
        exact
        path="/thirdparty/digitalsignatureredirect"
        render={({ ...rest }) => <DigitalSignatureRedirectPage {...rest} />}
      />
      <Route
        exact
        path="/thirdparty/digitalsignature"
        render={({ ...rest }) => <DigitalSignatureReturnPage {...rest} />}
      />

      <Route
        exact
        path="/login"
        render={({ ...rest }) =>
          loggedIn ? <Redirect to="/" /> : redirectAndShowLoginModal(rest)
        }
      />

      <Route
        exact
        path="/activate"
        render={({ ...rest }) =>
          loggedIn ? <Redirect to="/" /> : <AccountActivationPage {...rest} />
        }
      />

      <Route
        exact
        path="/reset-password"
        render={({ ...rest }) =>
          loggedIn ? <Redirect to="/" /> : <ForgotPasswordPage {...rest} />
        }
      />
      {loggedIn ? <DashboardRoutes /> : <Redirect to={`/login${pathname}`} />}
      <Route component={NotFound} />
    </Switch>
  );
};
export default RootNavigator;
