import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Accordion, Header, Label, List, Menu, Modal, Popup } from 'semantic-ui-react';
import ControlButton from './ControlButton';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';
import { useFullScreen } from '../../../../context/FullScreenContext';
import { DISPLAY_TIME_FORMAT } from '../constants/DISPLAY_TIME_FORMAT';

const AnnotationList = ({
  t,
  controlState,
  annotationOptions,
  annotations: passedAnnotations
}) => {

  const fullScreenContext = useFullScreen();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(-1);

  const getAnnotationsByCategory = () => {
    const sortedAnnotations = passedAnnotations
      .sort((a, b) => {
        return a.timeStart - b.timeStart;
      });
    return Object.entries(
      sortedAnnotations.reduce((annotations, annotation) => {
        const category = annotation.category;
        annotations[category.code] = annotations[category.code] ? [...annotations[category.code], annotation] : [annotation];
        return annotations;
      }, {})
    );
  };

  const annotationsByCategory = useMemo(getAnnotationsByCategory, [passedAnnotations]);

  const handleListOpenClick = (_action, e) => {
    GeneralHelpers.stopEvent(e);
    setIsModalOpen(true);
  }

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleCategoryClick = (e, titleProps) => {
    GeneralHelpers.stopEvent(e);
    const { index } = titleProps
    const newIndex = activeCategoryIndex === index ? -1 : index
    setActiveCategoryIndex(newIndex);
  }

  const buildCategoryMenuItems = () => {
    const menuItems = Array.from(annotationsByCategory.values())
      .map(([category, categoryAnnotations], index) => {
        const categoryDefinition = annotationOptions?.categories?.find(cat => cat.code === category);
        const categoryText = t(categoryDefinition?.titleTranslationKey, categoryDefinition?.titleDefault, categoryDefinition?.code, category) || category;
        const categoryLabel = (
          <>
            {t('TODO:', categoryText)}
            <Label size='mini' circular color={'black'}>{categoryAnnotations.length}</Label>
          </>
        );

        const annotationList = buildAnnotationsForCategory(categoryAnnotations);

        return (
          <Menu.Item key={category}>
            <Accordion.Title
              active={index === activeCategoryIndex}
              content={
                <Header as='h4'>{categoryLabel}</Header>
              }
              index={index}
              onClick={handleCategoryClick}
            />
            <Accordion.Content active={index === activeCategoryIndex}>
              <List divided relaxed>
                {annotationList}
              </List>
            </Accordion.Content>
          </Menu.Item>
        );
      });
    return menuItems;
  };

  const buildAnnotationsForCategory = (categoryAnnotations) => {
    const annotationItems = categoryAnnotations
      .map((annotation) => {

        const start = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeStart);
        const end = DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeEnd);

        let timeString = start.toString();
        if (annotation.timeEnd != null && annotation.timeStart !== annotation.timeEnd) {
          timeString += ` - ${end} ${t('ANNOATION_LIST_DURATION','Duration')}: ${DISPLAY_TIME_FORMAT[controlState.timeDisplayUnit].toString(annotation.timeEnd - annotation.timeStart)}`;
        }

        return (
          <List.Item key={annotation.id}>
            <List.Content>
              <List.Header as='h5'>{annotation.name}</List.Header>
              {timeString}
              {annotation?.notes?.length > 0 && (
                <List.Description>
                  {annotation.notes}
                </List.Description>
              )}
            </List.Content>
          </List.Item>
        );
      })
    return annotationItems;
  };

  const categoryMenuItems = buildCategoryMenuItems();

  const popupMountNode = fullScreenContext?.containerRef?.current || document.body;

  return (
    <>
      <Popup
        mountNode={popupMountNode}
        trigger={<ControlButton
          buttonText={t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_ANNOTATIONS_LIST', 'List annotations')}
          iconName={'file-alt'}
          onClick={handleListOpenClick}
        />}
        on='hover'
        content={t('TODO KEY FOR THIS', 'Playback rate')}
        inverted
      />

      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        closeIcon={true}
        closeOnDimmerClick={true}
        closeOnDocumentClick={true}
      >
        <Modal.Content scrolling>
          <Header as='h2'>{t('ANNOTATIONLIST_ANNOTATIONS', 'Annotations')}</Header>
          <Accordion as={Menu} vertical>
            {categoryMenuItems}
          </Accordion>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default memo(withTranslation()(AnnotationList));
