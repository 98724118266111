import React, {
  useState
} from 'react';
import Page from '../../components/page/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import './AdminQuestionnaireDefinitionCreatePage.css';
import {
  Button,
  Form,
  Input,
  Table, TableRow, TableCell
} from 'semantic-ui-react';
import { getContentDefinitions, getDataDefinitions, getEventDefinitions, getProDefinitions } from '../../redux/questionnaires/questionnaireDefinitionsSlice';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import StaffPermissionService from '../../services/StaffPermissionService';
import withPermissionWrapper from '../../security/withPermissionWrapper';

function AdminSimpleTranslationsPage({ definitions }) {
  const { t } = useTranslation();

  // Translation searching should be done when the language or search changes so to get this to happen
  // automatically searchTerm has been split into a fast and slow updating variable.
  const [searchTermStore, setSearchTermStore] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearchChange = (_e, v) => {
    setSearchTermStore(v.value);
  };
  const handleSearchRequest = () => {
    setSearchTerm(searchTermStore);
  };

  return (
    <Page
      name='Edit Questionnaire Translations - User'
      header={t(
        'USER_TRANSLATION_EDIT_HEADER',
        'Translation Editor'
      )}
    >
      <Form style={{ display: 'flex', paddingBottom: '2rem' }}>
        <Input
          fluid value={searchTermStore} onChange={handleSearchChange}
          style={{ marginRight: '0.5rem', flexGrow: 1 }}
        />
        <Button primary onClick={handleSearchRequest}>{t('TRANSLATION_EDIT_SEARCH', 'Search')}</Button>
      </Form>

      <Table>{definitions.map((d) => {
        if (searchTerm.length > 0) {
          const isFound = d.code.indexOf(searchTerm) > -1 || d.label.indexOf(searchTerm) > -1;
          if (!isFound) return null;
        }

        return (
          <TableRow key={'translation-' + d.code} style={{ borderRadius: '4px' }} selectable  >
            <TableCell width={12}>
              <Link style={{ textDecoration: 'underline', color: 'black', fontWeight: 'bold' }} to={'/app/translations-simplified/' + d.code}>{d.label.length > 0 ? d.label : t('', 'No Title')}</Link>
            </TableCell>
            <TableCell
              width={4}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ fontWeight: 900, fontSize: '12px', color: '#444' }}>{`${d.code}`}</div>
                <div style={{ fontSize: '12px', color: '#444' }}>{`${d.type}`}</div>
              </div>
            </TableCell>
          </TableRow>
        );
      })}
      </Table>

    </Page>
  );
}

const mapStateToProps = (state) => {
  return {
    definitions: [
      ...getProDefinitions(state),
      ...getEventDefinitions(state),
      ...getContentDefinitions(state),
      ...getDataDefinitions(state)
    ]
  };
};

const withEnhancements = (options) => compose(
  withPermissionWrapper(options),
  withTranslation(),
  connect(mapStateToProps)
);
export default withEnhancements({ permissionFunctionDelegate: StaffPermissionService.canManageTranslations })(AdminSimpleTranslationsPage);
