import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal, Grid, Button, Input, Message } from "semantic-ui-react";
import SubjectQuestionnaireService from "../../services/SubjectQuestionnaireService";
import SubjectService from "../../SubjectService";
import { debounce } from "lodash";


function QuestionnaireParentIdUpdateModal(
  { t, isOpen, handleModalClosure, questionnaire }) {

  const [parent, setParent] = useState(questionnaire?.parentQuestionnaire);
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState(false);  
  const [allIds, setAllIds] = useState([]);


  const submitId = async () => {
    if (questionnaire.subjectId != null && questionnaire.id != null) {
      const data = { parent: parent };
      await SubjectQuestionnaireService.addEditParentId(data, questionnaire.subjectId, questionnaire.id);
      handleModalClosure && handleModalClosure(true);
    };
  };

  useEffect(() => {
    setParent(questionnaire?.parentQuestionnaire);
  }, [questionnaire]);

  useEffect(() => {
  const fetchAllIds = async () => {
    if (questionnaire?.subjectId != null) {
    try {
      const data = await SubjectService.getSubjectQuestionnairesAllById(questionnaire?.subjectId);
      if (Array.isArray(data)) {
        const ids = data.map(item => item.id);
        setAllIds(ids);
      } else {
        throw new Error('Invalid response structure');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }
  };

  fetchAllIds();
}, [questionnaire?.subjectId]);


useEffect(() => {
  const debouncedCheckId = debounce((inputId) => {
    if (!inputId) {
      setIsValid(null);
      setError('');
      return;
    }

    if (allIds.includes(Number(inputId))) { 
      setIsValid(true);
      setError('');
    } else {
      setIsValid(false);
      setError('ID is invalid.');
    }
  }, 500);

  debouncedCheckId(parent);

  return () => {
    debouncedCheckId.cancel();
  };
}, [parent, allIds]); 


  const handleChange = (e) => {
    const inputId = e.target.value;
    setParent(inputId);
  };


  return (
    <Modal open={isOpen}
      closeIcon
      size="small"
      onClose={() => handleModalClosure && handleModalClosure(false)}>
      <Modal.Content key={questionnaire?.id} >
        <Modal.Description>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
              </Grid.Column>
              <Grid.Column width={5}>
                <Input
                  label={t("SUBJECT_TAB_PARENT_ID", "Enter questionnaire ID")}
                  onChange={handleChange}
                  value={parent}
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                {!isValid && error && <Message warning>
                  <Message.Content >{t("SET_PARENT_CODE_ERROR", "ID either does not exist or does not belong to this participant.")}</Message.Content>
                </Message>}
                {isValid && !error && (
                  <Button primary floated="right"
                    onClick={submitId}>
                    {t("SUBJECT_TAB_DATA_TABLE_SUBMIT_PARENT_ID", "Submit")}
                  </Button>
                )}
                <Button floated="right" onClick={() => handleModalClosure(false)}>
                  {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
}

export default withTranslation()(QuestionnaireParentIdUpdateModal);

