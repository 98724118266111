import React, {Component} from "react";
import {
  Button,
  Form,
  Grid,
  Header,
  Loader,
  Message,
  Segment,
} from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import AuthService from "../../services/AuthService";
import InternationalisationService from "../../InternationalisationService";
import PasswordSetterInput from "../profile/PasswordSetterInput";
import SubjectService from "../../SubjectService";
import StaffService from "../../StaffService";
import {typeHelper} from "atom5-branching-questionnaire";

class AccountActivationPage extends Component {
  constructor(props, context) {
    super(props, context);
    const { location } = props;
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    const accountType = query.get("type");
    const language = query.get("language");

    this.state = {
      tokenValid: false,
      verificationPending: true,
      password: "",
      token,
    };

    if (language) {
      InternationalisationService.changeLanguage(language);
    }

    if (accountType) {
      AuthService.setAccountType(accountType);
    }

    AuthService.verifyActivationToken(token, accountType)
      .then(() => {
        this.setState({ tokenValid: true });
      })
      .catch(() => {
        this.setState({ tokenValid: false });
      })
      .finally(() => {
        this.setState({ verificationPending: false });
      });
  }

  newPasswordValid = (newPassword) => {
    this.setState({ password: newPassword, error: null });
  };
  newPasswordError = (errorState) => {
    this.setState({ password: null, error: errorState.error });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    const accountType = query.get("type");

    try {
      const user = await AuthService.activateAccount(
        this.state.token,
        this.state.password,
        accountType
      );

      await AuthService.login(user.email, this.state.password, accountType);

      if (typeHelper.parseBool(Window.configuration.ui?.mandatoryStaffTraining?.enforce)) {
        await StaffService.redirectIfStaffHasOutstandingMandatoryPros(user.email, this.state.password);
      }

      const showScreeningQuestionnaires = await SubjectService.shouldShowScreeningQuestionnaire();

      if (showScreeningQuestionnaires) {
        window.location.href = "/app/subject/screening";
      } else {
        window.location.href = "/app/subject/study-consent";
      }
    } catch (_error) {
      if (_error === 'Password already used') {
        this.setState({ error: 'PASSWORD_ALREADY_USED' });
      } else if (_error.includes('too common')) {
        this.setState({ error: 'PASSWORD_TOO_SIMPLE' });
      } else if (_error.includes('too simple')) {
        this.setState({ error: 'PASSWORD_TOO_SIMPLE' });
      } else {
        this.setState({ tokenValid: false });
      }

    }
  };

  render() {
    const { t } = this.props;
    const showPasswordForm =
      this.state.tokenValid && !this.state.verificationPending;
    const canSetPassword =
      this.state.tokenValid &&
      !this.state.verificationPending &&
      !!this.state.password;

    return (
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Grid.Column
          style={{ maxWidth: 450 }}
          textAlign={InternationalisationService.isRTL() ? "right" : undefined}
        >
          <Header as="h2" textAlign="center">
            {t("ACCOUNT_ACTIVATION_TITLE")}
          </Header>

          {this.state.verificationPending && (
            <Loader style={{ marginTop: 50 }} active />
          )}

          {!this.state.verificationPending && (
            <>
              {!this.state.tokenValid && (
                <Message error>
                  <Message.Header>
                    {t("ACCOUNT_ACTIVATION_ERROR_TITLE")}
                  </Message.Header>
                  {t("ACCOUNT_ACTIVATION_ERROR_MESSAGE")}
                </Message>
              )}

              {showPasswordForm && (
                <Form size="large" onSubmit={this.handleSubmit}>
                  <Message>{t("ACCOUNT_ACTIVATION_INFO_MESSAGE")}</Message>
                  <Segment>
                    <PasswordSetterInput
                      onValid={this.newPasswordValid}
                      onError={this.newPasswordError}
                      disabled={this.state.requestPending}
                    />

                    <Button
                      type="submit"
                      primary
                      fluid
                      size="large"
                      disabled={!canSetPassword}
                    >
                      {t("GLOBAL_BUTTON_SET_PASSWORD")}
                    </Button>
                  </Segment>

                  {this.state.error && (
                    <div className="ui negative message">
                      {t(this.state.error)}
                    </div>
                  )}
                </Form>
              )}
            </>
          )}
        </Grid.Column>
      </Grid>
    );
  }
}

export default withTranslation()(AccountActivationPage);
