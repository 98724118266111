import React, {forwardRef, useEffect, useMemo, useState} from "react";
import {DataChannel} from "atom5-data-analysis/src/utils/DataChannel";
import {v4 as uuid_v4} from "uuid";
import {ChannelList} from "atom5-data-analysis";
import Switch from "react-switch";
import ChannelListScatterChart from "../../../data-analysis/ui/graphs/ChannelListScatterChart";

const calculateMidpoint = (coords) => {
    const midpointX = (coords.x1 + coords.x2) / 2;
    const midpointY = (coords.y1 + coords.y2) / 2;
    return { x: midpointX, y: midpointY };
};

const calculateSize = (coords) => {
    const width = (coords.x2 - coords.x1);
    const height = (coords.y2 - coords.y1);
    return width * height;
};

const calculateEuclideanDistance = (point1,point2) => {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
};

const calculateIntervals = (chan) => {
    const intervals = [];
    let intervalStart = null;

    for (let i = chan.initPos; i <= chan.endPos; i++) {
        const data = chan.getDataPos(i);

        if (data !== 0) {
            if (intervalStart === null) {
                intervalStart = i; // Mark the start of a new interval
            }
        } else {
            if (intervalStart !== null) {
                intervals.push({ start: chan.posToTime(intervalStart), end: chan.posToTime(i - 1) }); // Mark the end of the interval
                intervalStart = null; // Reset the start position
            }
        }
    }

    // If the last interval goes until the end
    if (intervalStart !== null) {

        intervals.push({ start: chan.posToTime(intervalStart), end: chan.posToTime(chan.endPos)});
    }

    return intervals;
};

const RegionSelectorAnnotations  = forwardRef(({t, showGraphs, videoPlayerRef, video, handleSetVideoPlayerAnnotations}, ref) => {
    const [plotChannelListA] = useState(new ChannelList(20,100));
    const [plotChannelListB] = useState(new ChannelList(20,100));
    const [plotChannelListC] = useState(new ChannelList(20,100));
    const [maxTimeSeconds, setMaxTimeSeconds] = useState(0)
    const colors = useMemo(() => ['blue', 'green', 'purple', 'orange', 'yellow', 'darkRed'], []);
    const [showPosition,setShowPosition] = useState(true);
    const [showVelocity,setShowVelocity] = useState(false);
    const [showSize,setShowSize] = useState(false);
    const [updateChartsActive, setUpdateChartsActive] = useState(false);
    const [currentTimeSeconds, setCurrentTimeSeconds] = useState(0); // Initial slider value
    const [initialised, setInitialised] = useState(false);

    React.useImperativeHandle(ref, () => ({
        handleFrameDataSet(channelList,frameData) {
            if(initialised)
                return;
            channelList.addEventListener('dataChanged', handleDataChanged);
            channelList.emitDataChangedEvent();
            setInitialised(true);
        },
        updateData(fameData){
            updateGraphs(fameData)
        }
    }));

    useEffect(() => {
        const listeners = [
            { object: video, event: 'timeupdate', handler: handleTimeUpdate }
        ];

        for (const listener of Object.values(listeners)) {
            listener.object.addEventListener(listener.event, listener.handler);
        }

        return () => {
            for (const listener of Object.values(listeners)) {
                listener.object.removeEventListener(listener.event, listener.handler);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTimeUpdate = () => {
        setCurrentTimeSeconds(videoPlayerRef.getCurrentTime());
    }

    const handleDataChanged = (channelList) => {
        if (channelList.channels.length === 0)
            return;
        const frameData = channelList.getChannel(0).dataChannel;
        updateGraphs(frameData);
    }

    const updateGraphs = (frameData) => {

        plotChannelListA.clearData();
        plotChannelListB.clearData();
        plotChannelListC.clearData();

        for(let i=frameData.initPos; i<= frameData.endPos; i++){
            const frame = frameData.getDataPos(i);

            if(frame === 0)
                continue;
            frame.forEach(item => {
                if(plotChannelListA.getChannelName(item.boxId) === undefined){
                    plotChannelListA.addDataChannel(new DataChannel(item.boxId,frameData.freq,100),colors[plotChannelListA.numChannels()],2);
                    plotChannelListB.addDataChannel(new DataChannel(item.boxId + "S",frameData.freq,100),colors[plotChannelListB.numChannels()],2);
                    plotChannelListC.addDataChannel(new DataChannel(item.boxId + "V",frameData.freq,100),colors[plotChannelListC.numChannels()],2);
                }
                const plotChanD = plotChannelListA.getChannelName(item.boxId).dataChannel;
                plotChanD.setWritePos(i,!plotChanD.empty);
                plotChanD.add(calculateEuclideanDistance(calculateMidpoint(item.coords),{x:0,y:0}));
                plotChanD.updateIntervals();

                const plotChanS = plotChannelListB.getChannelName(item.boxId + "S").dataChannel;
                plotChanS.setWritePos(i,!plotChanS.empty);
                plotChanS.add(calculateSize(item.coords));
                plotChanS.updateIntervals();

                if(i > frameData.initPos){
                    const plotChanV = plotChannelListC.getChannelName(item.boxId + "V").dataChannel;
                    plotChanV.setWritePos(i,!plotChanV.empty);
                    const d1 = plotChanD.getDataPos(i);
                    const d0 = plotChanD.getDataPos(i-1)
                    plotChanV.add(d1-d0);
                    plotChanV.updateIntervals();
                }

            });


        }
        plotChannelListC.applyLowPassFilter(5);

        setMaxTimeSeconds(frameData.posToTime(frameData.endPos) / 1000)
        plotChannelListA.emitDataChangedEvent();
        plotChannelListB.emitDataChangedEvent();
        plotChannelListC.emitDataChangedEvent();

        const annotations = [];
        for(let i=0;i<plotChannelListA.numChannels();i++){
            const chan = plotChannelListA.getChannel(i)
            const intervals = calculateIntervals(chan.dataChannel);

            for( let j=0;j<intervals.length;j++) {
                const interval = intervals[j];
                const name =  "Channel " + chan.dataChannel.name
                if(interval.start === interval.end){
                    annotations.push({ id: uuid_v4(), timeStart: interval.start === 0? 1: interval.start, level: i+1, name: name , notes: JSON.stringify({ boxId: chan.dataChannel.name }), category: { type: 'INSTANT', code: 'Tracking', color: colors[i]} })
                }else{
                    annotations.push({ id: uuid_v4(), timeStart: interval.start === 0? 1: interval.start, timeEnd: interval.end, level: i+1, name: name, notes: JSON.stringify({ boxId: chan.dataChannel.name }), category: { type: 'INTERVAL', code: 'Tracking', color: colors[i]} })
                }
            }
        }

        if(handleSetVideoPlayerAnnotations)
            handleSetVideoPlayerAnnotations(annotations);

    }

    return (
        <div>
            {showGraphs && (
                <div style={{maxWidth: '800px', minWidth:'800px', minHeight: '1000px', backgroundColor: 'white'}}>
                    <div style={{ display: 'flex', padding: 10}}>
                        <div style={{ marginRight: '10px' }}>Update charts</div>
                        <Switch  checked={updateChartsActive} onChange={(checked) => setUpdateChartsActive(checked)}/>
                        <div style={{ marginRight: '10px' }}>Show Position:</div>
                        <Switch  checked={showPosition} onChange={(checked) => setShowPosition(checked)}/>
                        <div style={{ marginRight: '10px' }}>Show Size:</div>
                        <Switch  checked={showSize} onChange={(checked) => setShowSize(checked)}/>
                        <div style={{ marginRight: '10px' }}>Show Velocity:</div>
                        <Switch  checked={showVelocity} onChange={(checked) => setShowVelocity(checked)}/>

                    </div>
                    { showPosition && (<>
                        <p>Distance Chart</p>
                        <ChannelListScatterChart channelList = {plotChannelListA} selectedPosition = {updateChartsActive?currentTimeSeconds:0}  minRight = {0}  maxRight = {1000} minX = {0} maxX = {Math.round(maxTimeSeconds)} />
                    </>)}
                    { showSize && (<>
                        <p>Box Size Chart</p>
                        <ChannelListScatterChart channelList = {plotChannelListB} selectedPosition = {updateChartsActive?currentTimeSeconds:0}  minRight = {0}  maxRight = {1000} minX = {0} maxX = {Math.round(maxTimeSeconds)} />
                    </>)}
                    { showVelocity && (<>
                        <p>Velocity Chart</p>
                        <ChannelListScatterChart channelList = {plotChannelListC} selectedPosition = {updateChartsActive?currentTimeSeconds:0}  minLeft = {0}  maxLeft = {10} minRight = {0}  maxRight = {10} minX = {0} maxX = {Math.round(maxTimeSeconds)} />
                    </>)}
                </div>
            )}
        </div>)
});


export default RegionSelectorAnnotations;
