import React from 'react';
import {isRightJoint, MLKitPoseLandmarkIds} from "atom5-data-analysis";

const SkeletonView = ({ skeleton, skeletonSV, lineColor, invertY, width, height, scale = {x : 1, y: 1} }) => {

      const jointIds = [ 
        MLKitPoseLandmarkIds.LEFT_WRIST, 
        MLKitPoseLandmarkIds.LEFT_ELBOW, 
        MLKitPoseLandmarkIds.LEFT_SHOULDER,
        MLKitPoseLandmarkIds.LEFT_HIP,
        MLKitPoseLandmarkIds.LEFT_KNEE,
        MLKitPoseLandmarkIds.LEFT_ANKLE,
        MLKitPoseLandmarkIds.RIGHT_WRIST,         
        MLKitPoseLandmarkIds.RIGHT_ELBOW, 
        MLKitPoseLandmarkIds.RIGHT_SHOULDER,
        MLKitPoseLandmarkIds.RIGHT_HIP,
        MLKitPoseLandmarkIds.RIGHT_KNEE,
        MLKitPoseLandmarkIds.RIGHT_ANKLE,
      ]

      const bones = [
        [MLKitPoseLandmarkIds.LEFT_WRIST, MLKitPoseLandmarkIds.LEFT_ELBOW],
        [MLKitPoseLandmarkIds.LEFT_ELBOW, MLKitPoseLandmarkIds.LEFT_SHOULDER],
        [MLKitPoseLandmarkIds.LEFT_SHOULDER, MLKitPoseLandmarkIds.LEFT_HIP],
        [MLKitPoseLandmarkIds.LEFT_SHOULDER, MLKitPoseLandmarkIds.LEFT_HIP],
        [MLKitPoseLandmarkIds.LEFT_HIP, MLKitPoseLandmarkIds.LEFT_KNEE],
        [MLKitPoseLandmarkIds.LEFT_KNEE, MLKitPoseLandmarkIds.LEFT_ANKLE],
        [MLKitPoseLandmarkIds.RIGHT_WRIST, MLKitPoseLandmarkIds.RIGHT_ELBOW],
        [MLKitPoseLandmarkIds.RIGHT_ELBOW, MLKitPoseLandmarkIds.RIGHT_SHOULDER],
        [MLKitPoseLandmarkIds.RIGHT_SHOULDER, MLKitPoseLandmarkIds.RIGHT_HIP],
        [MLKitPoseLandmarkIds.RIGHT_HIP, MLKitPoseLandmarkIds.RIGHT_KNEE],
        [MLKitPoseLandmarkIds.RIGHT_KNEE, MLKitPoseLandmarkIds.RIGHT_ANKLE],
        [MLKitPoseLandmarkIds.LEFT_SHOULDER, MLKitPoseLandmarkIds.RIGHT_SHOULDER],
        [MLKitPoseLandmarkIds.LEFT_HIP, MLKitPoseLandmarkIds.RIGHT_HIP]
      ]


      const svgWidth = width
      const svgHeight = height

      const scaleX = svgWidth * scale.x
      const scaleY = svgHeight * scale.y

    return (
      <svg width={svgWidth} height={svgHeight}>
      {jointIds.map((jointId, index) => {
        if(skeleton === undefined || typeof skeleton !== 'object' )
          return null;
        const joint = skeleton.getJoint(jointId);
        if (joint) {
          return (
            <circle
            key={`circle-${index}`}
              cx={joint.x * scaleX}
              cy={joint.y * scaleY}
              r={5}
              stroke="white"
              strokeWidth={2}
              fill={isRightJoint(jointId)?"red":"blue"}
            />
          );
        }
        return null;
      })}

      {
        bones.map((jointIds,index)=>{
          if(skeleton === undefined || typeof skeleton !== 'object')
            return null;
            
          const joint1 = skeleton.getJoint(jointIds[0]);
          const joint2 = skeleton.getJoint(jointIds[1]);
          return(
            <line
            key={`line-${index}`}
            x1={joint1.x * scaleX}
            y1={joint1.y * scaleY}
            x2={joint2.x * scaleX}
            y2={joint2.y * scaleY}
            stroke={"white"} strokeWidth="2"  />);
        })
      }
    </svg>
    );
}

/*

*/
export default SkeletonView;
