import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { saveTranslation } from "../../services/admin/AdminTranslationService"

import {
    Button, Dropdown,
    Form,
    Grid, Image, Label, Message, Modal
} from "semantic-ui-react";

const EditTranslationModal = ({open, onClose, record, t}) => {

    const [editMode, setEditMode] = useState(false)
    const [newTranslation, setNewTranslation] = useState("")
    const [newCode, setNewCode] = useState("")
    const [newClient, setNewClient] = useState("")
    const [error, setError] = useState("")
    const [info, setInfo] = useState("")
    const [isJson, setIsJson] = useState(false);
    const [isTranslationValid, setIsTranslationValid] = useState(false);

    useEffect(() => {
        setNewTranslation(record?.translation)
        setNewCode(record?.code)
        setNewClient(record?.client)
        setEditMode(record?.createNew)
        setError("")
        setInfo("")

        const isConfig = record?.code?.endsWith('_config');
        setIsJson(isConfig);
        setIsTranslationValid(isTranslationValueValid(isConfig, record?.translation));
    }, [record]);

    const doSave = async () => {
        try {
            if (record?.createNew) {
                await saveTranslation(record.language, newClient, newCode, newTranslation)
            } else {
                await saveTranslation(record.language, record.client, record.code, newTranslation)
            }
            setEditMode(false)
            onClose()
        }catch (e) {
            console.error("Error saving translation",e)
            setError(e)
        }
    }

    const isTranslationValueValid = (isConfig, translation) => {
        if (!isConfig) {
            return true;
        }
        
        if (translation == null || translation.length === 0) {
            return true;
        }

        try {
            JSON.parse(translation);
            return true;
        } catch {
            return false;
        }
    };

    const calculateTextAreaRows = () => {
        if(!record){
            return 4;
        }
        if(record?.translation?.length > 1000){
            return 32;
        }
        if(record?.translation?.length > 300){
            return 16;
        }
        if(record?.translation?.length > 100){
            return 8;
        }
        return 4;
    }
    let rows = calculateTextAreaRows()

    return (
    <Modal
        size={"large"}
        open={open}
        onClose={onClose}
    >
            <Grid padded>
                <Grid.Row>
                    <Grid.Column>
                        <Image
                            style={{ height: "25px" }}
                            src={
                                "../../images/aparito/logo/aparito-noborder-darktext-transparentbg-320.png"
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal.Content scrolling>
                {error && error.length>0 &&  <Message
                    error
                    header={t("GLOBAL_ERROR_TITLE", "Error")}
                    content={error}
                />}
                {info && info.length>0 &&  <Message
                    info
                    header={t("GLOBAL_INFO_TITLE", "Info")}
                    content={info}
                />}
                <Form>
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_LANGUAGE", "Language")}</label>
                        <input
                            name="language"
                            value={record?.language}
                            readOnly={true}
                            onChange={(e) => {
                                e.preventDefault()
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_CLIENT", "Client")}</label>
                        <Dropdown
                            name="client"
                            selection
                            options={[
                                {
                                    key:"server",
                                    value:"server",
                                    text:"server"
                                },
                                {
                                    key:"nucleus",
                                    value:"nucleus",
                                    text:"nucleus"
                                },
                                {
                                    key:"electron-subject-mobile",
                                    value:"electron-subject-mobile",
                                    text:"electron-subject-mobile"
                                },
                                {
                                    key:"electron-clinician-web",
                                    value:"electron-clinician-web",
                                    text:"electron-clinician-web"
                                }
                            ]}
                            value={newClient}
                            onChange={(e, data) => {
                                e.preventDefault()
                                setNewClient(data.value)
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION_CODE", "Code")}</label>
                        <input
                            name="code"
                            value={newCode}
                            readOnly={!record?.createNew}
                            onChange={(e) => {
                                e.preventDefault()
                                setNewCode(e.target.value)
                            }}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>{t("GLOBAL_TRANSLATION", "Translation")}</label>

                        <textarea
                            readOnly={!editMode}
                            name="translated"
                            rows={rows}
                            value={newTranslation}
                            onChange={(e) => {
                                e.preventDefault()
                                setNewTranslation(e.target.value);
                                setIsTranslationValid(isTranslationValueValid(isJson, e.target.value));
                            }}
                        />
                        {editMode && isJson && !isTranslationValid && (
                            <Label color='red' size='large'>Invalid JSON</Label>
                        )}
                    </Form.Field>


                </Form>
            </Modal.Content>
            <Grid centered padded>
                <Grid.Row>
                    {editMode &&
                    <Button
                        primary
                        size="small"
                        onClick={() => doSave()}
                        disabled={!isTranslationValid}
                    >
                        {t("GLOBAL_BUTTON_SAVE", "Save")}
                    </Button>
                        }
                        {!editMode &&
                        <Button
                            basic
                            color={"black"}
                            size="small"
                            onClick={()=>setEditMode(true)}
                        >
                            {t("GLOBAL_BUTTON_EDIT", "Edit")}
                        </Button>
                            }
                    <Button
                        basic
                        color={"black"}
                        size="small"
                        onClick={onClose}
                    >
                        {t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                    </Button>
                </Grid.Row>
            </Grid>

    </Modal>
    )
}

export default withTranslation()(EditTranslationModal);
