import React, { useContext } from "react";
import ConfigContext from "../../context/ConfigContext";
import StudyInformationQuestionnaire from "./StudyInformationQuestionnaire";
import StaticPage from "../../components/dashboard/StaticPage";
import AuthService from "../../services/AuthService";
import UserContext from "../../context/UserContext";

const StudyInformationPage = () => {
  const config = useContext(ConfigContext);
  const user = useContext(UserContext);

  let informationStaticPageKey;
  if (AuthService.isStaff() && user.isLoggedIn) {
    informationStaticPageKey = config?.ui?.information?.staff;
  } else if (AuthService.isSubject() && user.isLoggedIn) {
    informationStaticPageKey = config?.ui?.information?.subject;
  } else {
    informationStaticPageKey = config?.ui?.information?.public;
  }

  if (informationStaticPageKey) {
    return <StaticPage staticPageKey={informationStaticPageKey} />;
  }

  const informationQuestionnaire = config?.ui?.informationQuestionnaire;

  return (
    <StudyInformationQuestionnaire
      informationQuestionnaireCode={informationQuestionnaire || "information"}
    />
  );
};

export default StudyInformationPage;
