import React, { useState } from 'react';
import AnatomyFull from './anatomical/AnatomyFull';
import { QUESTION_SUBTYPES } from 'atom5-branching-questionnaire';
import { withTranslation } from 'react-i18next';
import {Button} from "semantic-ui-react";

const AnatomicalDiagram = ({
  t,
  type,
  bodyParts,
  selectedBodyParts,
  style,
  getOnChange,
  selectors,
  isReadOnly
}) => {
  const [showSelectors, setShowSelectors] = useState(false);

  const toggleShowHide = () => {
    setShowSelectors((previousValue) => !previousValue);
  };

  //omitting browser scaling for this version
  const scale = 1;

  let bodyType;
  switch (type) {
    case QUESTION_SUBTYPES.FIXED_VALUE.ANATOMY_FRONT_ASYMMETRICAL_MULTIPLE:
    case QUESTION_SUBTYPES.FIXED_VALUE.ANATOMY_FRONT_ASYMMETRICAL_SINGLE:
      bodyType = 'FRONTASYM';
      break;
    case QUESTION_SUBTYPES.FIXED_VALUE.ANATOMY_FRONT_MULTIPLE:
    case QUESTION_SUBTYPES.FIXED_VALUE.ANATOMY_FRONT_SINGLE:
      bodyType = 'FRONT';
      break;
    default:
      console.error('[AnatomicalDiagram] Error setting bodyType');
      break;
  }

  const isCheckbox = !QUESTION_SUBTYPES.FIXED_VALUE.isSingle(type);
  const combinedStyles = {...styles.view, ...style.container};

  return (
    <>
      <div style={combinedStyles}>
        <div
            style={{height: 1200 * scale, width: 600 * scale, display: 'flex', flexDirection: 'column'}}
            accessible
            accessibilityRole='imagebutton'
          >
            <AnatomyFull
              isSelectedArray={selectedBodyParts}
              height={1200 * scale}
              width={600 * scale}
              onClick={!isReadOnly ? getOnChange(isCheckbox) : () => {}}
              bodyPartArray={bodyParts}
              bodyType={bodyType}
            />
        </div>

        <div style={styles.controlsDiv}>
            <Button primary onClick={toggleShowHide}>
              <div>{showSelectors ? t('ANATOMY_HIDE_CONTROLS', 'Hide alternative input') : t('ANATOMY_SHOW_CONTROLS', 'Show alternative input')}</div>
            </Button>

            <div style={{marginTop:20}}>
              {showSelectors && selectors}
            </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  view: {
    width: 1200,
    marginTop: -50,
    display: 'flex',
    alignItems: 'center'
  },
  touchableAnswer: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    flexShrink: 1
  },
  answerContainer: {
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexShrink: 1
  },
  answerDisplay: {
    flexDirection: 'row',
    flexShrink: 1
  },
  textAnswer: {
    fontSize: 10,
    paddingLeft: 10,
    fontFamily: 'Poppins-Regular',
    flexGrow: 1,
    flexShrink: 1
  },
  viewButton: {
    width: '100%',
    padding: 10
  },
  controlsDiv: {
    marginTop: 80,
    display: 'flex',
    alignSelf: 'flex-start',
    flexDirection: 'column'
  }
};

export default withTranslation()(AnatomicalDiagram);
