import {Button, Dropdown, Loader, Modal, ModalContent} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import SubjectSelectModalInsert from "./SubjectSelectModalInsert";
import {useCallback, useContext, useMemo, useState} from "react";
import GroupsContext from "../../context/GroupsContext";


function VisitComplianceReportFilters({
    countries,
    setCountries,
    allCountries,
    groups,
    setGroups,
    allGroups,
    setSubjects,
    subjects,
                                      }){
  const {t} = useTranslation();
  const groupsContext = useContext(GroupsContext);
  const groupLabels = useMemo(()=>{
    const map = {}
    groupsContext.forEach(group => map[group.code] = group.label)
    return map
  }, [groupsContext])

  const hasSubjectModalRequiredDataLoaded = Array.isArray(allGroups) &&
      allGroups.length > 0 &&
      Object.keys(groupLabels).length > 0;
  const [isSubjectModalOpen, setIsSubjectModalOpen] = useState(false)
  const toggleOpen = useCallback(()=>setIsSubjectModalOpen(o=>!o), [])

  return <div style={styles.filters}>
    <div style={styles.container}>
      <label style={styles.label}  htmlFor={"visit-compliance-dropdown-country-select"} >
        {t("VISIT_COMPLIANCE_COUNTRY_FILTER_LABEL", "Filter by Country")}
      </label>
      <Dropdown
        id={"visit-compliance-dropdown-country-select"}
        placeholder={t("VISIT_COMPLIANCE_SELECT_PLACEHOLDER", "Select...")}
        selection
        multiple
        options={allCountries.map(c=>({text:t("VISIT_COMPLIANCE_COUNTRY_"+c.toUpperCase(), c), value:c}))}
        value={countries}
        onChange={(e, data)=>setCountries(data.value)}
      />
    </div>
    <div style={styles.container}>
      <label style={styles.label}  htmlFor={"visit-compliance-dropdown-group-select"}>
        {t("VISIT_COMPLIANCE_GROUP_FILTER_LABEL", "Filter by Site")}
      </label>
      <Dropdown
          id={"visit-compliance-dropdown-group-select"}
          placeholder={t("VISIT_COMPLIANCE_SELECT_PLACEHOLDER", "Select...")}
          multiple
          selection
          options={allGroups.map(g=>({text:groupLabels[g], value:g}))}
          value={groups}
          onChange={(e, data)=> setGroups(data.value)}
      />
    </div>
    <div style={styles.container}>
      <Button
        id={"visit-compliance-dropdown-subject-select"}
        onClick={toggleOpen}
        primary
      >
        {subjects.length ?
            t("VISIT_COMPLIANCE_SUBJECT_FILTER_BUTTON_TEXT_SELECTED", "{0} Participant(s) Selected").replace('{0}', subjects.length) :
            t("VISIT_COMPLIANCE_SUBJECT_FILTER_BUTTON_TEXT_EMPTY", "Select Participants")
        }
      </Button>
      <Modal open={isSubjectModalOpen} closeOnDimmerClick closeOnEscape closeIcon onClose={toggleOpen} >
        <ModalContent scrolling>
        {!hasSubjectModalRequiredDataLoaded && <Loader active /> }
        {hasSubjectModalRequiredDataLoaded && <SubjectSelectModalInsert
            allGroups={allGroups}
            groups={groups}
            groupLabels={groupLabels}
            subjects={subjects}
            setSubjects={setSubjects}/>}
        </ModalContent>
      </Modal>
    </div>
  </div>
}

const styles = {
  filters: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingBottom: '16px',
    paddingRight: '24px',
  },
  label: {
    fontWeight: 'bold',
    paddingRight: '8px'
  }
}

export default VisitComplianceReportFilters;