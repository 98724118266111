import React, {useState} from "react";
import {withTranslation} from "react-i18next";
import {Button, Table, Dropdown} from "semantic-ui-react";
import Page from "../../../components/page/Page";
import StaffPermissionService from "../../../services/StaffPermissionService";
import withPermissionWrapper from "../../../security/withPermissionWrapper";
import {compose} from "redux";
import AdminDataSegregationService from "../../../services/admin/AdminDataSegregationService";

const AdminDataSegregationPage = (props) => {
    const {t} = props;

    const [isRunning, setIsRunning] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState('ALL');
    const [entityId, setEntityId] = useState();

    const rows = [(<Table.Row key={'reconcile_all'}>
        <Table.Cell key={'reconcile_all_item'}><Dropdown
            placeholder={t("ENTITY_TYPES", "Select Entity To Reconcile")}
            fluid
            selection
            options={[{
                key: 'ALL',
                text: 'ALL',
                value: 'ALL',
            },
                {
                    key: 'Attachment',
                    text: 'Attachment',
                    value: 'Attachment',
                },
                {
                    key: 'Questionnaire',
                    text: 'Questionnaire',
                    value: 'Questionnaire',
                },
                {
                    key: 'Subject',
                    text: 'Subject',
                    value: 'Subject',
                }]}
            value={selectedEntity}
            onChange={(e, item) => {
                setSelectedEntity(item.value)
            }}
        /></Table.Cell>
        <Table.Cell>
            <input
                name="query"
                value={entityId}
                placeholder={t("ENTITY_ID", "Id")}
                onChange={(e) => {
                    e.preventDefault();
                    setEntityId(e.target.value);
                }}
            /></Table.Cell>
        <Table.Cell key={'reconcile_all_action'}>

            <Button
                primary
                disabled={isRunning}
                onClick={() => {
                    AdminDataSegregationService.reconcile(selectedEntity === 'ALL' ? null : selectedEntity, entityId && entityId !== '' ? entityId : null);
                    setIsRunning(true)
                }
                }
            >
                {t("RECONCILE", "Reconcile")}
            </Button>


        </Table.Cell>
    </Table.Row>)]

    return (
        <Page
            name="Data Segregation"
            header={t("DATA_SEGREGATION", "Data Segregation")}
        >

            <Table selectable>
                <Table.Header>
                    <Table.Row key={"header"}>
                        <Table.HeaderCell key={"item"}>
                            {t("ADMIN_DATA_SEGREGATION_TYPE", "Item")}
                        </Table.HeaderCell>
                        <Table.HeaderCell key={"action"}>
                            {t("ADMIN_DATA_SEGREGATION_ACTION", "Action")}
                        </Table.HeaderCell>
                    </Table.Row>
                    {rows}
                </Table.Header>
            </Table>
        </Page>
    );
};

const withEnhancements = (options) => compose(
    withPermissionWrapper(options),
    withTranslation()
);
export default withEnhancements({permissionFunctionDelegate: StaffPermissionService.canManageDataSegregation})(AdminDataSegregationPage);
