import React, { useState } from "react";
import { Confirm, Dropdown, Form, Loader} from "semantic-ui-react";
import FeedbackForm from "./FeedbackForm";

const ConfirmDropDownMenuWithFeedback = (props) => {
  const {
    onConfirm,
    onCancel,
    context,
    listValue,
    listItems,
    headerText,
    contentText,
    confirmButtonText,
    cancelButtonText,
    size,
    fluid,
    placeholderText,
    mandatoryValidationText,
    disabled,
    isSubmitting,
    propStyle
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [feedback, setFeedback] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleConfirm = () => {
    //if mandatory validation text is supplied then check feedback is supplied
    //if no mandatory validation text is supplied then go ahead and submit the form
    if (
      mandatoryValidationText &&
      (!feedback || feedback.trim().length === 0)
    ) {
      setShowError(true);
    } else {
      setIsOpen(false);
      if (onConfirm) {
        onConfirm(context,feedback,selectedValue);
      }
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const addFeedback = (feedbackFromComponent) => {
    setFeedback(feedbackFromComponent);
  };

const buttonSubmit = disabled || isSubmitting;

  const theList = (
      <Dropdown
                fluid={fluid}
                size={size}
                disabled={buttonSubmit}
                style={propStyle}
                value={listValue}
                options={listItems}
                onChange={(_e,data)=> {
                  setSelectedValue(data.value);
                  setIsOpen(true)
                }
              }>
      </Dropdown>
  );

  var style = showError
    ? { display: "flex", color: "red", margin: "0.5em", marginLeft: "1em" }
    : { display: "none" };

  const contentTextWithFeedback = (
    <>
      <Form>
        <p style={{ marginLeft: "2em" }}>{contentText}</p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FeedbackForm
            placeholder={placeholderText}
            addFeedback={addFeedback}
          />
        </div>
        <br />
        <div style={style}>
          <p>{mandatoryValidationText}</p>
        </div>
        <br />
      </Form>
    </>
  );

  return (
    <>
      {!isSubmitting && theList}
      {isSubmitting && <Loader active />}
      <Confirm
        open={isOpen}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
        header={headerText}
        content={contentTextWithFeedback}
        confirmButton={confirmButtonText}
        cancelButton={{ content: cancelButtonText, color: "black", basic: true }}
        size={size}
      />
    </>
  );
};

export default ConfirmDropDownMenuWithFeedback;
