import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
import {
  Button,
  Checkbox,
  Confirm,
  Dropdown,
  Form,
  Grid,
  Input,
  Message,
  Segment,
  Table
} from 'semantic-ui-react';
import Page from '../../components/page/Page';
import { getDefinitions } from '../../redux/questionnaires/questionnaireDefinitionsSlice';
import AttachmentService, {
  INTERNAL_WORKFLOW_STATUS,
  MEDIA_STATE
} from '../../services/AttachmentService';
import PermissionsService from '../../services/PermissionsService';
import GroupPermission from '../../GroupPermission';
import { Link, Redirect } from 'react-router-dom';
import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import DateTimeService from '../../services/DateTimeService';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';
import { LOCALDATA_KEYS } from '../../services/LocalDataService';
import _ from 'lodash';
import DisplayMediaQuestion from '../../questionnaires/display/DisplayMediaQuestion';
import SubjectService from '../../SubjectService';
import SubjectQuestionnaireService from '../../services/SubjectQuestionnaireService';
import ConfigContext from '../../context/ConfigContext';
import TypeHelper from '../../helpers/TypeHelper';
import ConfirmButton from '../../components/dashboard/ConfirmButton';
import UserContext from '../../context/UserContext';
import SubjectCodeLookup from '../../components/subjects/SubjectCodeLookup';
import ChildQuestionnaire from '../../questionnaires/display/ChildQuestionnaire';
import AttachmentWorkflowStatusOverride from '../../questionnaires/display/AttachmentWorkflowStatusOverride';

const SEARCH_CRITERIA_STATE = {
  'INITIAL': 'INITIAL',
  'MODIFIED': 'MODIFIED'
}

const AttachmentSearch = (props) => {
  const { t, allQuestionnaireDefinitions } = props;

  const config = useContext(ConfigContext);
  const user = useContext(UserContext);

  const [hasPermission, setHasPermission] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  // Search Inputs/Filters
  const [
    searchCriteriaAttachmentStateFilter,
    setSearchCriteriaAttachmentStateFilter
  ] = useState();
  const [
    searchCriteriaAttachmentWorkflowStatusFilter,
    setSearchCriteriaAttachmentWorkflowStatusFilter
  ] = useState({});
  const [
    searchCriteriaQuestionnaireFilterOptions,
    setSearchCriteriaQuestionnaireFilterOptions
  ] = useState();
  const [isSearchReady, setIsSearchReady] = useState(false);

  const CURRENT_SEARCH_CRITERIA_OBJECT_VERSION = 3;
  const defaultSearchCriteria = {
    objectVersion: CURRENT_SEARCH_CRITERIA_OBJECT_VERSION,
    state: SEARCH_CRITERIA_STATE.INITIAL,
    attachmentState: 'COMPLETED',
    subjects: [],
    questionnaireDefinitionCodes: [],
    hideStoppedSubjects: true,
    attachmentWorkflowStatus: INTERNAL_WORKFLOW_STATUS._ANY_.value,
    primaryQuestionnaireId: undefined,
    primaryQuestionnaireAnswerValueSearchCriteria: []
  };
  const [searchCriteria, setSearchCriteria] = useState(defaultSearchCriteria);

  // Search Results
  const [searchResults, setSearchResults] = useState();
  const [searchResultsHasExceededLimit, setSearchResultsHasExceededLimit] =
    useState(false);
  const [searchResultSubjectPermissions, setSearchResultSubjectPermissions] =
    useState();

  // Checkboxes / Selected items
  const [isCheckboxColumnVisible, setIsCheckboxColumnVisible] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // Workflow Status update
  const [hasWorkflowStatuses, setHasWorkflowStatuses] = useState(false);
  const [
    hasPermissionToUpdateAttachmentWorkflow,
    setHasPermissionToUpdateAttachmentWorkflow
  ] = useState(false);
  const [allowedNextWorkflowStatuses, setAllowedNextWorkflowStatuses] =
    useState();
  const [selectedNextWorkflowStatus, setSelectedNextWorkflowStatus] =
    useState('');
  const [isUpdateWorkflowCompleteVisible, setIsUpdateWorkflowCompleteVisible] =
    useState(false);

  const showPrimaryQuestionnaireIdSearch =
      config?.ui?.search?.attachment?.showPrimaryQuestionnaireIdSearch || false;
  const showPrimaryQuestionnaireAnswerValueSearch =
      config?.ui?.search?.attachment?.primaryQuestionnaireAnswerValueSearch?.isEnabled || false;
  const primaryQuestionnaireAnswerValueSearchPossibleAnswers =
      config?.ui?.search?.attachment?.primaryQuestionnaireAnswerValueSearch?.possibleAnswers || [];

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPermisisons = async () => {
    const hasCorrectPermission =
      await PermissionsService.hasPermissionInAnyGroup(
        GroupPermission.SEARCH_ATTACHMENTS
      );
    setHasPermission(hasCorrectPermission);

    const hasUpdateWorkflowPermission =
      await PermissionsService.hasPermissionInAnyGroup(
        GroupPermission.UPDATE_ATTACHMENT_WORKFLOW
      );
    setHasPermissionToUpdateAttachmentWorkflow(hasUpdateWorkflowPermission);
  };

  const setupSearchCriteriaInputs = async () => {
    await setupSearchCriteriaAttachmentStateFilter();
    await setupSearchCriteriaAttachmentWorkflowStatusFilter();
    await setupSearchCriteriaQuestionnaireFilter();
  };

  const setupSearchCriteriaAttachmentStateFilter = async () => {
    const states = Object.keys(MEDIA_STATE)
      .filter((stateKey) => {
        const attachmentSearchConfig = MEDIA_STATE[stateKey]?.attachmentSearch;
        const showInFilter =
          attachmentSearchConfig.showInFilter == null
            ? true
            : attachmentSearchConfig.showInFilter;
        return showInFilter;
      })
      .map((stateKey) => {
        const attachmentSearchConfig = MEDIA_STATE[stateKey]?.attachmentSearch;
        const text = t(
          attachmentSearchConfig?.translationKey,
          attachmentSearchConfig?.fallbackText
        );
        return {
          key: stateKey,
          text: text,
          value: stateKey
        };
      });
    setSearchCriteriaAttachmentStateFilter(states);
  };

  const setupSearchCriteriaAttachmentWorkflowStatusFilter = async () => {
    const workflowStatusConfigStatuses =
      config?.workflows?.attachments?.statuses || [];
    if (Object.keys(workflowStatusConfigStatuses).length === 0) {
      setHasWorkflowStatuses(false);
      return;
    }

    const filteredStatusKeys = Object.keys(workflowStatusConfigStatuses)
      .filter((statusKey) => {
        return TypeHelper.parseBool(
          workflowStatusConfigStatuses[statusKey].search?.includeInFilter
        );
      })
      .sort((a, b) => {
        const sequenceA = workflowStatusConfigStatuses[a].search?.sequence || 0;
        const sequenceB = workflowStatusConfigStatuses[b].search?.sequence || 0;
        return sequenceA < sequenceB ? -1 : 1;
      });

    Object.values(INTERNAL_WORKFLOW_STATUS).forEach(v => filteredStatusKeys.push(v.value));
    
    const workflowStatuses =
      buildWorkflowStatusDropDownOptions(filteredStatusKeys);
    setSearchCriteriaAttachmentWorkflowStatusFilter(workflowStatuses);
    setHasWorkflowStatuses(true);
  };

  const buildWorkflowStatusDropDownOptions = (keys) => {
    if (!keys) {
      return undefined;
    }
    const options = keys.map((statusKey) => {
      const text = AttachmentService.getWorkflowStatusText(t, statusKey);
      return {
        key: statusKey,
        text: text,
        value: statusKey
      };
    });
    return options;
  };

  const configureCheckboxes = () => {
    const workflowStatusConfigStatuses =
      config?.workflows?.attachments?.statuses || [];
    if (Object.keys(workflowStatusConfigStatuses).length === 0) {
      setIsCheckboxColumnVisible(false);
      return;
    }

    const nextStatuses =
      workflowStatusConfigStatuses[searchCriteria?.attachmentWorkflowStatus]
        ?.allowedNextStatuses;

    const workflowStatuses = buildWorkflowStatusDropDownOptions(nextStatuses);
    setAllowedNextWorkflowStatuses(workflowStatuses);

    const showCheckboxes =
      hasPermissionToUpdateAttachmentWorkflow &&
      nextStatuses !== undefined &&
      Array.isArray(nextStatuses) &&
      nextStatuses.length > 0;
    setIsCheckboxColumnVisible(showCheckboxes);
  };

  /**
   * @private
   * @deprecated
   * Added to complete AT-1427 (the permissions filtering of questionnaires for AT-1419)
   * It is added to work with the current UI oriented handling of filtering based on Modules
   * In future we can make use of the changes in AT-1384, maybe with some additional
   *  development to handle it from nucleus. It would also need existing studies to have the
   *  config migrated too.
   */
  const _getQuestionnaireDefinitionsForUser = async (
    questionnaireDefinitions
  ) => {
    const tabsConfig = config.ui?.tabs ? config.ui?.tabs : [];
    const staffProfile = user.profile;
    const groups = user.profile.groupMappings.map((gm) => gm.group);
    const filtered = [];
    for (const qd of questionnaireDefinitions) {
      const cs =
        await SubjectQuestionnaireService.canStaffViewQuestionnaireModule(
          qd,
          groups,
          tabsConfig,
          staffProfile
        );
      if (cs === true) {
        filtered.push(qd);
      }
    }
    return filtered;
  };

  const setupSearchCriteriaQuestionnaireFilter = async () => {
    const attachmentQuestionnaireDefinitions =
      allQuestionnaireDefinitions.filter((qd) => {
        const hasAttachmentQuestion = qd?.questions?.some((q) => {
          return (
            QUESTION_TYPES.isAttachmentType(q.type) &&
            q.type !== QUESTION_TYPES.SIGNATURE
          );
        });
        return hasAttachmentQuestion;
      });

    const permittedDefinitions = await _getQuestionnaireDefinitionsForUser(
      attachmentQuestionnaireDefinitions,
      null
    );

    const definitionForFilter = permittedDefinitions
      .sort((a, b) => (a.label < b.label ? -1 : 1))
      .map((qd) => {
        let label = SubjectQuestionnaireService.getQuestionnaireLabel(qd);
        const questionnaireModuleLabels =
          SubjectQuestionnaireService.getQuestionnaireModuleLabels(t, qd);
        if (questionnaireModuleLabels !== undefined) {
          label = `${label} (${questionnaireModuleLabels})`;
        }
        return {
          key: qd.code,
          text: label,
          value: qd.code
        };
      });
    setSearchCriteriaQuestionnaireFilterOptions(definitionForFilter);
  };

  const init = async () => {
    await checkPermisisons();
    await setupSearchCriteriaInputs();
    const persistedCriteria = LocalStorageHelper.getJsonObject(
      LOCALDATA_KEYS.ATTACHMENT_SEARCH_CRITERIA
    );
    // Check the objectVersion to see if the persisted object is compatible
    if (persistedCriteria.objectVersion === CURRENT_SEARCH_CRITERIA_OBJECT_VERSION) {
      setSearchCriteria(persistedCriteria);
    }
    setIsSearchReady(true);
  };

  const updateSearchCriteria = (criteria) => {
    criteria.state = SEARCH_CRITERIA_STATE.MODIFIED;
    setSearchCriteria(criteria);
  };

  useEffect(() => {
    setIsLoading(false);
    if (isSearchReady) {
      if (searchCriteria?.state === SEARCH_CRITERIA_STATE.MODIFIED) {
        performSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchReady]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    performSearch();
  };

  const handleResetSearchCriteria = (e) => {
    e.preventDefault();
    setSearchCriteria(defaultSearchCriteria);
    setErrorMessage();
    setSearchResults();
    setSearchResultsHasExceededLimit(false);
    LocalStorageHelper.setJson(
      LOCALDATA_KEYS.ATTACHMENT_SEARCH_CRITERIA,
      defaultSearchCriteria
    );
  };

  const initSearchState = async () => {
    setIsLoading(true);
    setErrorMessage();
    setSearchResultsHasExceededLimit(false);
    setSearchResults();
    setSelectedItems([]);
    setIsSelectAllChecked(false);
    setAllowedNextWorkflowStatuses();
    setSelectedNextWorkflowStatus('');
  };

  const performSearch = async () => {
    await initSearchState();
    try { 
      const updatedSearchCriteria = searchCriteria!= null ? {...searchCriteria} : {};
      if (!hasWorkflowStatuses) {
        // Ensure that if an environment had workflowstatuses set, but has since had them removed, that the search makes sense
        updatedSearchCriteria.attachmentWorkflowStatus = undefined;
      }
      LocalStorageHelper.setJson(
        LOCALDATA_KEYS.ATTACHMENT_SEARCH_CRITERIA,
        updatedSearchCriteria
      );
      const rawResults = await AttachmentService.getBySearchCriteria(
        updatedSearchCriteria
      );
      const transFormedResults = await transformSearchResults(rawResults);

      setSearchResultsHasExceededLimit(rawResults?.hasExceededLimit);

      const allSubjectIds = rawResults?.attachments.map(
        (att) => att.subject.Id
      );
      const subjectIds = [...new Set(allSubjectIds)]; // Unique Subject IDs
      const subjectPermissions = await SubjectService.getSubjectPermissionsBulk(
        subjectIds
      );

      setSearchResultSubjectPermissions(subjectPermissions);
      setSearchResults(transFormedResults);

      configureCheckboxes();
    } catch (error) {
      setErrorMessage(error?.message ? error.message : error);
    } finally {
      setIsLoading(false);
    }
  };

  const transformSearchResults = async (rawResults) => {
    const results = rawResults.attachments.map((attachment) => {
      const transformedAttachment = { ...attachment };

      attachment.subject.subjectCode = rawResults.subjectCodeMapping.find(
        (s) => s.subjectId === attachment.subject.Id
      )?.subjectCode;

      const questionnaireId = attachment.attachedToQuestionnaireId;
      const questionnaire = getQuestionnaireFromRawSearchResults(
        rawResults,
        questionnaireId
      );
      transformedAttachment.questionnaire = questionnaire;

      const questionnaireDefinition = getQuestionnaireDefinition(
        questionnaire.definitionId
      );
      transformedAttachment.questionnaireDefinition = questionnaireDefinition;

      const childQuestionnaires = getQuestionnaireWithAllChildren(
        rawResults.questionnaires,
        questionnaire
      );
      transformedAttachment.childQuestionnaires = childQuestionnaires;

      return transformedAttachment;
    });

    const sortedResults = _.orderBy(
      results,
      ['subject.subjectCode', 'questionnaire.completionDate'],
      ['asc', 'asc']
    );

    return sortedResults;
  };

  const buildSearchCriteriaFilterInputs = () => {
    return (
      <Form onSubmit={handleFormSubmit}>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column width={4}>
              <label>
                {t(
                  'ATTACHMENTS_SEARCH_INPUT_ATTACHMENT_STATE',
                  'Attachment Status'
                )}
              </label>
              <Dropdown
                placeholder={t(
                  [
                    'ATTACHMENTS_SEARCH_INPUT_ATTACHMENT_STATE_PLACEHOLDER',
                    'ATTACHMENTS_SEARCH_INPUT_ATTACHMENT_STATE'
                  ],
                  'Attachment Status'
                )}
                fluid
                selection
                options={searchCriteriaAttachmentStateFilter || []}
                defaultValue='COMPLETED'
                onChange={(_e, data) => {
                  const newSearchCriteria = { ...searchCriteria };
                  newSearchCriteria.attachmentState = data.value;
                  updateSearchCriteria(newSearchCriteria);
                }}
                value={searchCriteria?.attachmentState}
              />
            </Grid.Column>
            {hasWorkflowStatuses && (
              <Grid.Column width={4}>
                <label>
                  {t(
                    'ATTACHMENTS_SEARCH_INPUT_ATTACHMENT_WORKFLOW_STATUS',
                    'Workflow Status'
                  )}
                </label>
                <Dropdown
                  placeholder={t(
                    [
                      'ATTACHMENTS_SEARCH_INPUT_ATTACHMENT_WORKFLOW_STATUS_PLACEHOLDER',
                      'ATTACHMENTS_SEARCH_INPUT_ATTACHMENT_WORKFLOW_STATUS'
                    ],
                    'Workflow Status'
                  )}
                  fluid
                  selection
                  options={searchCriteriaAttachmentWorkflowStatusFilter || []}
                  onChange={(_e, data) => {
                    const newSearchCriteria = { ...searchCriteria };
                    newSearchCriteria.attachmentWorkflowStatus = data.value;
                    updateSearchCriteria(newSearchCriteria);
                  }}
                  value={searchCriteria?.attachmentWorkflowStatus}
                />
              </Grid.Column>
            )}
            <Grid.Column width={4}>
              <label>
                {t('ATTACHMENTS_SEARCH_INPUT_SUBJECT_CODE', 'Subject codes')}
              </label>
              <SubjectCodeLookup
                multiple
                onChange={(subjects) => {
                  const newSearchCriteria = { ...searchCriteria };
                  newSearchCriteria.subjects = subjects;
                  updateSearchCriteria(newSearchCriteria);
                }}
                placeholder={t(
                  [
                    'ATTACHMENTS_SEARCH_INPUT_SUBJECT_CODE_PLACEHOLDER',
                    'ATTACHMENTS_SEARCH_INPUT_SUBJECT_CODE'
                  ],
                  'Subject codes (minimum 5 characters)'
                )}
                value={searchCriteria?.subjects || []}
              />
            </Grid.Column>
            {showPrimaryQuestionnaireIdSearch && (
              <Grid.Column width={4}>
                <label>
                  {t('ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_ID', 'Primary questionnaire Id')}
                </label>
                <Input
                  fluid
                  type='number'
                  step={1}
                  onChange={(_e, data) => {
                    const newSearchCriteria = { ...searchCriteria };
                    const id = data?.value.trim().length > 0 ? data.value.trim() : undefined;
                    newSearchCriteria.primaryQuestionnaireId = id;
                    updateSearchCriteria(newSearchCriteria);
                  }}
                  placeholder={t(
                    [
                      'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_ID_PLACEHOLDER',
                      'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_ID'
                    ],
                    'Primary questionnaire Id'
                  )}
                  value={searchCriteria?.primaryQuestionnaireId || ''}
                />
              </Grid.Column>
            )}
            {showPrimaryQuestionnaireAnswerValueSearch && (
              <Grid.Column width={4}>
                <label>
                  {t('ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_ANSWER_VALUE', 'Filter by a question value')}
                </label>
                <datalist id="primaryQuestionnaireAnswerValueSearchPossibleAnswersDataList">
                  {primaryQuestionnaireAnswerValueSearchPossibleAnswersDataListOptions}
                </datalist>
                <Input
                  fluid
                  autoComplete={"off"}
                  list="primaryQuestionnaireAnswerValueSearchPossibleAnswersDataList"
                  onChange={(_e, data) => {
                    const newPrimaryQuestionnaireAnswerValueSearchCriteria = {
                      questionDefinitionCode: config?.ui?.search?.attachment?.primaryQuestionnaireAnswerValueSearch?.searchQuestionDefinitionCode || '',
                      value: data.value
                    };
                    const newSearchCriteria = { ...searchCriteria };
                    newSearchCriteria.primaryQuestionnaireAnswerValueSearchCriteria = newPrimaryQuestionnaireAnswerValueSearchCriteria;
                    updateSearchCriteria(newSearchCriteria);
                  }}
                  placeholder={t(
                    [
                      'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_ANSWER_VALUE_PLACEHOLDER',
                      'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_ANSWER_VALUE'
                    ],
                    'Filter by a question value'
                  )}
                  value={searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria?.value || ''}
                />
              </Grid.Column>
            )}
            
            <Grid.Column>
              <label>
                {t(
                  'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_DEFINITION',
                  'Questionnaires'
                )}
              </label>
              <Dropdown
                placeholder={t(
                  [
                    'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_DEFINITION_PLACEHOLDER',
                    'ATTACHMENTS_SEARCH_INPUT_QUESTIONNAIRE_DEFINITION'
                  ],
                  'Questionnaires'
                )}
                fluid
                multiple
                selection
                options={searchCriteriaQuestionnaireFilterOptions || []}
                onChange={(_e, data) => {
                  const newSearchCriteria = { ...searchCriteria };
                  newSearchCriteria.questionnaireDefinitionCodes = data.value;
                  updateSearchCriteria(newSearchCriteria);
                }}
                value={searchCriteria?.questionnaireDefinitionCodes || []}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                  id="hide-stopped"
                  label={t("HIDE_STOPPED")}
                  checked={searchCriteria?.hideStoppedSubjects}
                  onChange={(_e, data) => {
                    const newSearchCriteria = { ...searchCriteria };
                    newSearchCriteria.hideStoppedSubjects = data.checked;
                    updateSearchCriteria(newSearchCriteria);
                  }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button primary disabled={isLoading} type='submit'>
                {t('ATTACHMENTS_SEARCH_BUTTON', 'Search')}
              </Button>
              <Button secondary disabled={isLoading} onClick={handleResetSearchCriteria}>
                {t("ATTACHMENTS_RESET_BUTTON", "Reset search")}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  };

  useEffect(() => {
    if (!searchResults) {
      return;
    }
    const allAttachments = searchResults.map((attachment) => attachment.id);
    if (selectedItems.length === allAttachments.length) {
      setIsSelectAllChecked(true);
    } else {
      setIsSelectAllChecked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onAttachmentCheckChanged = (e, target, attachmentId) => {
    if (target.checked) {
      const newSelectedItems = [...selectedItems];
      if (!newSelectedItems.some((i) => attachmentId === i)) {
        newSelectedItems.push(attachmentId);
      }
      setSelectedItems(newSelectedItems);
    } else {
      const newSelectedItems = [...selectedItems].filter((i) => {
        return attachmentId !== i;
      });
      setSelectedItems(newSelectedItems);
    }
  };

  const onAttachmentCheckAllChanged = (e, target) => {
    setIsSelectAllChecked(target.checked);
    if (target.checked) {
      const allAttachments = searchResults.map((attachment) => attachment.id);
      setSelectedItems(allAttachments);
    } else {
      setSelectedItems([]);
    }
  };

  const handleUpdateWorkflowStatuses = async () => {
    if (!selectedNextWorkflowStatus) {
      return;
    }
    setIsLoading(true);

    for (const attachmentId of selectedItems) {
      await AttachmentService.updateWorkflowStatus(
        attachmentId,
        selectedNextWorkflowStatus
      );
    }

    setIsLoading(false);
    setIsUpdateWorkflowCompleteVisible(true);
  };

  const handleUpdateWorkflowCompleteDialogClick = async () => {
    setIsUpdateWorkflowCompleteVisible(false);
    performSearch();
  };

  const buildSearchResultsTable = () => {
    return (
      <Table selectable columns={12} compact>
        <Table.Header>
          <Table.Row key='header'>
            {isCheckboxColumnVisible && (
              <Table.HeaderCell width={1} key='header_checkbox'>
                <Checkbox
                  onChange={(e, target) =>
                    onAttachmentCheckAllChanged(e, target)}
                  checked={isSelectAllChecked}
                />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell width={2} key='header_subject'>
              {t('ATTACHMENTS_SEARCH_RESULTS_TABLE_HEADER_SUBJECT', 'Subject')}
            </Table.HeaderCell>
            {hasWorkflowStatuses && (
              <Table.HeaderCell
                width={2}
                key='header_attachment_workflowStatus'
              >
                {t(
                  'ATTACHMENTS_SEARCH_RESULTS_TABLE_HEADER_ATTACHMENT_WORKFLOW_STATUS',
                  'Attachment Workflow Status'
                )}
              </Table.HeaderCell>
            )}
            <Table.HeaderCell width={2} key='header_attachment'>
              {t(
                'ATTACHMENTS_SEARCH_RESULTS_TABLE_HEADER_ATTACHMENT',
                'Attachment'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell
              width={2}
              key='header_questionnaire_completionDate'
            >
              {t(
                'ATTACHMENTS_SEARCH_RESULTS_TABLE_HEADER_PRIMARY_QUESTIONNAIRE_COMPLETION',
                'Primary Questionnaire Completed Date'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={4} key='header_questionnaire'>
              {t(
                'ATTACHMENTS_SEARCH_RESULTS_TABLE_HEADER_PRIMARY_QUESTIONNAIRE',
                'Primary Questionnaire'
              )}
            </Table.HeaderCell>
            <Table.HeaderCell width={4} key='header_childQuestionnaires'>
              {t(
                'ATTACHMENTS_SEARCH_RESULTS_TABLE_HEADER_CHILD_QUESTIONNAIRES',
                'Related Questionnaires'
              )}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {searchResults &&
            searchResults.map((attachment) =>
              buildSearchResultsTableDataRow(attachment)
            )}
        </Table.Body>
      </Table>
    );
  };

  const getQuestionnaireWithAllChildren = (
    allQuestionnaires,
    parentQuestionnaire
  ) => {
    const all = [];
    const children = allQuestionnaires.filter(
      (q) => q.parentQuestionnaire === parentQuestionnaire.id
    );
    children.forEach((c) => {
      all.push(c);
      all.push(...getQuestionnaireWithAllChildren(allQuestionnaires, c));
    });
    return all;
  };

  const getQuestionnaireFromRawSearchResults = (
    rawResults,
    questionnaireId
  ) => {
    const questionnaire = rawResults.questionnaires.find(
      (q) => q.id === questionnaireId
    );
    return questionnaire;
  };

  const getQuestionnaireDefinition = (questionnaireDefinitionId) => {
    const questionnaireDefinition = allQuestionnaireDefinitions.find(
      (qd) => qd.id === questionnaireDefinitionId
    );
    return questionnaireDefinition;
  };

  const getAttachmentCount = () => {
    const count = searchResults?.length;
    return count;
  };

  /**
   * @private
   * @deprecated
   * Use RelatedQuestionnaireHelpers.buildQuestionnaireItemLink - caution to pass in t as first arg when moving to that one
   */
  const buildQuestionnaireItemLink = (
    questionnaire,
    questionnaireDefinition
  ) => {
    const isCompleted = questionnaire.completionDate != null;
    const submitOrViewUrlSegment = isCompleted ? '/view/' : '/submit/';
    const questionnaireLink = (
      <Link
        to={
          '/app/subject/' +
          questionnaire.subjectId +
          '/questionnaire-type/' +
          questionnaireDefinition.type +
          '/' +
          questionnaireDefinition.code +
          submitOrViewUrlSegment +
          questionnaire.id
        }
      >
        {t(
          'ATTACHMENTS_SEARCH_RESULTS_GOTO_QUESTIONNAIRE',
          'Go to Questionnaire'
        )}
      </Link>
    );
    return questionnaireLink;
  };

  const buildPrimaryQuestionnaireItem = (
    questionnaire,
    questionnaireDefinition
  ) => {
    const questionnaireLink = buildQuestionnaireItemLink(
      questionnaire,
      questionnaireDefinition
    );
    return (
      <div>
        {questionnaireDefinition.label}
        <br />
        {questionnaireLink}
        <br />
        {questionnaire.id}
      </div>
    );
  };

  /**
   * @private
   * @deprecated
   * Use RelatedQuestionnaireHelpers.buildChildQuestionnaireItem - caution to pass in t as first arg when moving to that one
   */
  const buildChildQuestionnaireItem = (
    questionnaire,
    questionnaireDefinition
  ) => {
    const questionnaireLink = buildQuestionnaireItemLink(
      questionnaire,
      questionnaireDefinition
    );
    const isCompleted = questionnaire.completionDate != null;
    const completedStatusText = isCompleted
      ? t('ATTACHMENTS_SEARCH_QUESTIONNAIRE_STATE_COMPLETED', 'Completed')
      : t('ATTACHMENTS_SEARCH_QUESTIONNAIRE_STATE_NOT_COMPLETED','Not completed'
    );
    const ariaLabelText = `${questionnaireDefinition.label}: ${completedStatusText}`;
    return (
      <li key={questionnaire.id}>
        <ChildQuestionnaire
          label={questionnaireDefinition.label}
          questionnaire={questionnaire}
          isCompleted={isCompleted}
          completedStatusText={completedStatusText}
          ariaLabelText={ariaLabelText}
          questionnaireLink={questionnaireLink}
        />
      </li>
    );
  };

  const buildSearchResultsTableDataRow = (attachment) => {
    const permissionsForSubject =
      searchResultSubjectPermissions[attachment.subject.Id];

    const childQuestionnaireItems = attachment.childQuestionnaires.map((c) => {
      const questionnaireDefinition = getQuestionnaireDefinition(
        c.definitionId
      );
      return buildChildQuestionnaireItem(c, questionnaireDefinition);
    });

    const questionDefinition = AttachmentService.extractAdditionalData(
      attachment,
      'questionDefinition'
    );

    return (
      <Table.Row key={attachment.reference}>
        {isCheckboxColumnVisible && (
          <Table.Cell
            width={2}
            key={attachment.reference + '_checkbox'}
            verticalAlign='top'
          >
            <Checkbox
              onChange={(e, target) =>
                onAttachmentCheckChanged(e, target, attachment.id)}
              checked={selectedItems.some((i) => i === attachment.id)}
            />
          </Table.Cell>
        )}
        <Table.Cell key={attachment.reference + '_subject'} verticalAlign='top'>
          <Link
            to={
              '/app/subject/' +
              attachment.subject.Id +
              '/tabs/subject-record/details'
            }
          >
            {attachment.subject.subjectCode}
          </Link>
        </Table.Cell>
        {hasWorkflowStatuses && (
          <Table.Cell
            key={attachment.reference + '_attachment_workflowStatus'}
            verticalAlign='top'
          >
            {AttachmentService.getWorkflowStatusText(t, attachment.workflowStatus)}
            <AttachmentWorkflowStatusOverride
              subjectId={attachment.subject.Id}
              attachmentId={attachment.id}
              reference={attachment.reference}
              workflowStatus={attachment.workflowStatus}
              onChange={performSearch}
            />
          </Table.Cell>
        )}
        <Table.Cell
          key={attachment.reference + '_attachment'}
          verticalAlign='top'
        >
          <DisplayMediaQuestion
            question={questionDefinition}
            answer={attachment.reference}
            subjectId={attachment.subject.Id}
            permissions={permissionsForSubject}
            showLabel={false}
            containerType='table'
            printable={false}
            allowInlneDialogSwitching={false}
            iconSize={'large'}
          />
          <div style={{marginTop: 4}}>
            {attachment.id}
          </div>
        </Table.Cell>
        <Table.Cell
          key={attachment.reference + '_questionnaire_completionDate'}
          verticalAlign='top'
        >
          {DateTimeService.build.asDisplayDateTime(
            attachment.questionnaire.completionDate
          )}
        </Table.Cell>

        <Table.Cell
          key={attachment.reference + '_questionnaire'}
          verticalAlign='top'
        >
          {buildPrimaryQuestionnaireItem(
            attachment.questionnaire,
            attachment.questionnaireDefinition
          )}
        </Table.Cell>

        <Table.Cell
          key={attachment.reference + '_childQuestionnaires'}
          verticalAlign='top'
        >
          {childQuestionnaireItems.length > 0 && (
            <ol style={{ margin: 0, paddingInlineStart: 0 }}>
              {childQuestionnaireItems}
            </ol>
          )}
        </Table.Cell>
      </Table.Row>
    );
  };

  const showWorkflowStatusUpdateInput =
    hasPermissionToUpdateAttachmentWorkflow &&
    selectedItems.length > 0 &&
    allowedNextWorkflowStatuses.length > 0;

  const primaryQuestionnaireAnswerValueSearchPossibleAnswersDataListOptions = primaryQuestionnaireAnswerValueSearchPossibleAnswers.map(possibleAnswer => <option key={possibleAnswer} value={possibleAnswer} />);
  
  if (hasPermission === false) {
    return <Redirect to='/' />;
  }

  return (
    <Page
      name='ATTACHMENTS_SEARCH_PAGE'
      header={t('ATTACHMENTS_SEARCH_TITLE', 'Attachment Search')}
    >
      {errorMessage && (
        <Message
          error
          header={t('GLOBAL_ERROR_TITLE', 'Error')}
          content={
            'Fatal error, if it persists contact support: ' + errorMessage
          }
        />
      )}
      {buildSearchCriteriaFilterInputs()}
      {searchResultsHasExceededLimit && (
        <Message
          warning
          header={t('GLOBAL_INFO_TITLE', 'Warning')}
          content={t(
            'ATTACHMENTS_SEARCH_RESULTS_EXCEEDED_LIMIT',
            'Your search returned a lot of results, it is advisable to add more filters to reduce the results.'
          )}
        />
      )}
      {getAttachmentCount() !== undefined && (
        <Segment>
          {t('ATTACHMENTS_SEARCH_RESULTS_ITEM_COUNT', 'Count')}:{' '}
          {getAttachmentCount()}
        </Segment>
      )}
      {buildSearchResultsTable()}
      {showWorkflowStatusUpdateInput && (
        <>
          <Segment>
            <h3>
              {t(
                'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_TITLE',
                'Update workflow status of selected items'
              )}
            </h3>
            <Dropdown
              placeholder={t(
                'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_DROPDOWN_PLACEHOLDER',
                'Select status'
              )}
              selection
              options={allowedNextWorkflowStatuses || []}
              onChange={(_e, data) => {
                setSelectedNextWorkflowStatus(data.value);
              }}
              value={selectedNextWorkflowStatus}
              style={{ marginRight: 20 }}
            />
            <ConfirmButton
              disabled={isLoading || !selectedNextWorkflowStatus}
              buttonText={t(
                'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_BUTTON',
                'Change Workflow Status'
              )}
              headerText={t(
                [
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_PROMPT_HEADER',
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_PROMPT_TEXT'
                ],
                'Change Workflow Status'
              )}
              contentText={t(
                'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_PROMPT_TEXT',
                'Please confirm that you want to update these items'
              )}
              confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
              cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
              onConfirm={handleUpdateWorkflowStatuses}
            />
            <Confirm
              open={isUpdateWorkflowCompleteVisible}
              onCancel={handleUpdateWorkflowCompleteDialogClick}
              onConfirm={handleUpdateWorkflowCompleteDialogClick}
              header={t(
                [
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_DONE_HEADER',
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_DONE_TEXT'
                ],
                'Complete'
              )}
              content={t(
                [
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_DONE_TEXT',
                  'ATTACHMENTS_WORKFLOW_CHANGE_STATUS_DONE_HEADER'
                ],
                'The requested changes have been made'
              )}
              confirmButton={t('GLOBAL_BUTTON_CONFIRM', 'OK')}
              cancelButton={null}
            />
          </Segment>
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    allQuestionnaireDefinitions: getDefinitions(state)
  };
};

const enhance = compose(withTranslation(), connect(mapStateToProps));

export default enhance(AttachmentSearch);
