import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import GroupService from '../../services/GroupService';
import GroupPermission from '../../GroupPermission';
import { useLocation } from 'react-router-dom';
import MenuItem from './MenuItem';
import ConfigContext from '../../context/ConfigContext';
import TypeHelper from '../../helpers/TypeHelper';
import { Icon } from 'semantic-ui-react';
import DashboardReportService from '../../services/DashboardReportService';
import StaffPermissionService from '../../services/StaffPermissionService';
import { typeHelper } from 'atom5-branching-questionnaire';

const StaffMenu = (props) => {
  const { user, groups, t } = props;
  const { profile } = user;

  const config = useContext(ConfigContext);
  const location = useLocation();

  const [isGroupsExpanded, setIsGroupsExpanded] = useState(true);
  const toggleIsGroupsExpanded = () => {
    setIsGroupsExpanded((oldValue) => !oldValue);
  };

  const [dashboardReportMenuList, setDashboardReportMenuList] = useState();
  const groupsWithDashboardReportPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_SITE_DASHBOARD_REPORTS
      ),
    [profile]
  );
  const canViewDashboardReports = profile.superAdmin || groupsWithDashboardReportPermission.length > 0;

  const groupsWithManageExportsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.MANAGE_EXPORTS
      ),
    [profile]
  );
  const canManageExports =
    profile.superAdmin || groupsWithManageExportsPermission.length > 0;

  const showHomeMenuItem = config?.ui?.showHomeMenuItem
    ? TypeHelper.parseBool(config?.ui?.showHomeMenuItem)
    : true;

  const groupsWithViewPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.VIEW_SUBJECT
      ),
    [profile]
  );

  const viewableGroups = groups
    .filter((group) =>
      groupsWithViewPermission.some(
        (viewableGroup) => group.code === viewableGroup.code
      )
    )
    .sort((a, b) => a?.label?.localeCompare(b.label))
    .map((group) => (
      <MenuItem
        className='indent-1'
        active={location.pathname === '/app/subjects/' + group.code}
        to={'/app/subjects/' + group.code}
        key={group.code}
        text={group.label}
      />
    ));

  // Search
  const searchMenuComponents = [];

  const groupsWithSearchAttachmentsPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.SEARCH_ATTACHMENTS
      ),
    [profile]
  );
  const canSearchAttachments = groupsWithSearchAttachmentsPermission.length > 0;
  if (canSearchAttachments) {
    searchMenuComponents.push(
      <MenuItem
        className='indent-1'
        active={location.pathname === '/app/search/attachments'}
        to="/app/search/attachments"
        key="/app/search/attachments"
        icon="file outline"
        text={t('MENU_SEARCH_ATTACHMENTS', 'Search Attachments')}
      />
    );
  }

  const groupsWithSearchAuditPermission = useMemo(
    () =>
      GroupService.getGroupsWithPermissionFromProfile(
        profile,
        GroupPermission.SEARCH_AUDIT
      ),
    [profile]
  );
  const canSearchAudit = groupsWithSearchAuditPermission.length > 0;
  if (canSearchAudit) {
    searchMenuComponents.push(
      <MenuItem
        className='indent-1'
        active={location.pathname === '/app/search/audit'}
        to="/app/search/audit"
        key="/app/search/audit"
        icon="database"
        text={t('MENU_SEARCH_AUDIT', 'Search Audit')}
      />
    );
  }

  const reportMenuComponents = useMemo(() => {
    const POSSIBLE_ITEMS = [
      {
        url: '/app/reports/manualdatachangereport',
        translationKey: 'MENU_REPORTS_MANUAL_DATA_CHANGE',
        fallbackText: 'Manual data change',
        permissionFunctionDelegate: StaffPermissionService.Reports.canAccessManualDataChangeReport
      },
      {
        url: '/app/reports/visitcompliancereport',
        translationKey: 'MENU_VISIT_COMPLIANCE_REPORT',
        fallbackText: 'Visit Compliance Report',
        permissionFunctionDelegate: StaffPermissionService.Reports.canAccessVisitComplianceReport
      }
    ];

    return POSSIBLE_ITEMS
      .map(item => {
        return { ...item, label: t(item.translationKey, item.fallbackText) };
      })
      .sort((a, b) => a?.label?.localeCompare(b.label))
      .filter(item => {
        if (item.permissionFunctionDelegate != null && typeof item.permissionFunctionDelegate === "function") {
          return typeHelper.parseBool(item.permissionFunctionDelegate(profile));
        }
        return false;
      })
      .map(item => {
        return <MenuItem className='indent-1'
          active={location.pathname === item.url}
          to={item.url}
          key={item.url}
          icon={item.iconName ?? 'clipboard'}
          text={item.label}
        />;

      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, location?.pathname]);

  const systemAdminMenuComponents = useMemo(() => {
    const POSSIBLE_ITEMS = [
      {
        url: '/app/staff',
        iconName: 'user',
        translationKey: 'MENU_STAFF',
        fallbackText: 'Staff',
        permissionFunctionDelegate: StaffPermissionService.canManageStaff
      },
      {
        url: '/app/rewards',
        iconName: 'gift',
        translationKey: 'MENU_REWARDS',
        fallbackText: 'Manage Rewards',
        permissionFunctionDelegate: StaffPermissionService.canManageRewards
      },
      {
        url: '/app/queries',
        iconName: 'search',
        translationKey: 'MENU_DATA_QUERIES',
        fallbackText: 'Data Queries',
        permissionFunctionDelegate: StaffPermissionService.canViewDataQueries
      },
      {
        url: '/app/drugs',
        iconName: 'syringe',
        translationKey: 'MENU_DRUG_MANAGEMENT',
        fallbackText: 'Manage Drugs',
        permissionFunctionDelegate: StaffPermissionService.canManageDrugs
      },
      {
        url: '/app/groups',
        iconName: 'address book',
        translationKey: 'MENU_GROUP_MANAGEMENT',
        fallbackText: 'Manage Sites',
        permissionFunctionDelegate: StaffPermissionService.canManageGroups
      },
      {
        url: '/app/roles',
        iconName: 'key',
        translationKey: 'MENU_ROLES_MANAGEMENT',
        fallbackText: 'Manage Roles',
        permissionFunctionDelegate: StaffPermissionService.canManageRoles
      },
      {
        url: '/app/translations-simplified',
        iconName: 'book',
        translationKey: 'MENU_ROLES_TRANSLATIONS',
        fallbackText: 'Manage Translations',
        permissionFunctionDelegate: StaffPermissionService.canManageTranslations
      },

      {
        url: '/app/notifications',
        iconName: 'bell',
        translationKey: 'MENU_VIEW_NOTIFICATIONS',
        fallbackText: 'Notifications',
        permissionFunctionDelegate: StaffPermissionService.canViewNotifications
      },
      {
        url: '/app/screenshots',
        iconName: 'mobile alternate',
        translationKey: 'MENU_SCREENSHOT_MANAGEMENT',
        fallbackText: 'Manage Screenshots',
        permissionFunctionDelegate: StaffPermissionService.canManageScreenshots
      },

      {
        url: '/app/attachments/batchupload',
        iconName: 'upload',
        translationKey: 'MENU_BATCHUPLOAD_ATTACHMENTS',
        fallbackText: 'Batch upload Attachments',
        permissionFunctionDelegate: StaffPermissionService.canViewBatchUploadAttachments
      },
      {
        url: '/app/visitDates/manage/trial',
        iconName: 'calendar',
        translationKey: 'MENU_TRIAL_VISITDATES_MANAGEMENT',
        fallbackText: 'Trial level visit dates',
        permissionFunctionDelegate: StaffPermissionService.canManageTrialVisitDates
      },
    ];

    return POSSIBLE_ITEMS
      .map(item => {
        return { ...item, label: t(item.translationKey, item.fallbackText) };
      })
      .filter(item => {
        if (item.permissionFunctionDelegate != null && typeof item.permissionFunctionDelegate === "function") {
          return typeHelper.parseBool(item.permissionFunctionDelegate(profile));
        }
        return false;
      })
      .map(item => {
        return <MenuItem className='indent-1'
          active={location.pathname === item.url}
          to={item.url}
          key={item.url}
          icon={item.iconName ?? 'clipboard'}
          text={item.label}
        />;

      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, location?.pathname]);

  useEffect(() => {
    const getDashboardMenuItems = async () => {
      const dashboardReports = await DashboardReportService.getDashboardReportList();
      // add all reports without parent to the menu list
      const filtered = dashboardReports.reports.filter(d => !d.parent);

      // add configured parents to menu list
      dashboardReports.reports.filter(d => d.parent).map(d => {
        if (filtered.filter(r => r.code === d.parent.code).length === 0) {
          filtered.push(d.parent);
        }
        return d;
      });
      setDashboardReportMenuList(filtered);
    };
    canViewDashboardReports && getDashboardMenuItems();
  }, [canViewDashboardReports]);

  const dashboardReportCode = (dr) => {
    return dr.parent && dr.parent !== null ? dr.parent.code : dr.code;
  };

  const dashboardReportCodeLabel = (dr) => {
    return dr.parent && dr.parent !== null ? dr.parent.label : dr.label;
  };
  return (
    <>
      {showHomeMenuItem && (
        <MenuItem
          active={location.pathname === '/app/home'}
          to="/app/home"
          key="/app/home"
          icon="desktop"
          text={t('MENU_HOME')}
        />
      )}
      {canViewDashboardReports && dashboardReportMenuList?.length > 0 && (
        dashboardReportMenuList.map((dR) =>
          <MenuItem
            to={'/app/dashboard-report/' + dashboardReportCode(dR)}
            key={'/app/dashboard-report/' + dashboardReportCode(dR)}
            text={<strong>{t(dashboardReportCodeLabel(dR))}</strong>}
          />
        )
      )}
      {viewableGroups.length > 0 && (
        <>
          <div
            onClick={toggleIsGroupsExpanded}
            aria-label={t('MENU_SHOW_HIDE_SUBJECT_GROUPS')}
          >
            <div style={styles.iconRightStyle}>
              <Icon name={isGroupsExpanded ? 'minus' : 'plus'} />
            </div>
            <MenuItem
              to='#'
              key='menu_subjects_home'
              icon="address book"
              text={<strong>{t('MENU_SUBJECTS')}</strong>}
            />
          </div>
          <div
            style={isGroupsExpanded ? styles.groupsOpen : styles.groupsClosed}
          >
            {viewableGroups}
          </div>
        </>
      )}
      {canManageExports && (
        <>
          <MenuItem to='#' key='menu_export_home' text={<strong>{t('MENU_EXPORT')}</strong>} />

          <MenuItem
            className='indent-1'
            active={location.pathname === '/app/exports'}
            to="/app/exports"
            key="/app/exports"
            icon="folder"
            text={t('MENU_EXPORT_LIST', 'Export List')}
          />

        </>
      )}

      {searchMenuComponents.length > 0 && (
        <>
          <MenuItem
            to='#'
            key='menu_search_home'
            text={<strong>{t('MENU_SEARCH', 'Search')}</strong>}
          />
          {searchMenuComponents}
        </>
      )}

      {reportMenuComponents.length > 0 && (
        <>
          <MenuItem
            to='#'
            key='menu_search_home'
            text={<strong>{t('MENU_REPORTS', 'Reports')}</strong>}
          />
          {reportMenuComponents}
        </>
      )}


    {systemAdminMenuComponents.length > 0 && (
        <>
          <MenuItem
            to='#'
            key='menu_admin_home'
            text={<strong>{t('MENU_ADMINISTRATION')}</strong>}
          />
          {systemAdminMenuComponents}
        </>
      )}
    </>
  );
};

const styles = {
  iconRightStyle: {
    float: 'right',
    marginTop: '14px',
    width: 30
  },
  groupsOpen: {},
  groupsClosed: {
    height: 0,
    overflow: 'hidden',
  }
};

const enhance = compose(withTranslation());

export default enhance(StaffMenu);
