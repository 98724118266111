import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import InternationalisationService from "../../InternationalisationService";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import ConfigContext from "../../context/ConfigContext";

class SubjectEditLanguagePage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      language: null,
      languages: [],
      subjectData: {},
      loading: true,
      error: false,
    };

    SubjectService.getSubjectData(this.props.match.params.subjectId)
      .then((subjectData) => {
        this.setState({
          language: subjectData?.language?.code,
          subjectData,
          loading: false,
        });
      })
      .catch(this.errorHandler);

    InternationalisationService.getMobileLanguages()
      .then((languages) => {
        this.setState({
          languages,
        });
      })
      .catch(this.errorHandler);
  }

  errorHandler = (_error) => {
    this.setState({ loading: false, error: true });
  };

  onSubmit = (feedback) => {
    return SubjectService.updateSubjectLanguage(
      this.props.match.params.subjectId,
      this.state.language,
      feedback
    )
      .then(this.onCancel)
      .catch(this.errorHandler);
  };

  languageChanged = (_event, item) => {
    this.setState({ language: item.value });
  };

  onCancel = () => {
    this.props.history.goBack();
  };

  handleSubmit = () => {
    this.onSubmit(undefined);
  };

  render() {
    const { t } = this.props;

    const languageOptions =
      this.state.languages &&
      this.state.languages.map((l) => {
        const key = `SUBJECT_LANGUAGE_${l.code.toUpperCase()}`;
        let text = t(key);
        if (text === key) {
          text = l.name;
        }
        return { key: l.code, text: l.name, value: l.code };
      });

      let buttonType = this.context.ui?.captureFeedbackOnSubjectChange ?
        <ConfirmButtonWithFeedback
          disabled={!this.state.language}
          buttonText={t("GLOBAL_BUTTON_SAVE")}
          headerText={t("STAFF_FORM_LANGUAGE_LABEL")}
          contentText={t("SUBJECT_EDIT_LANGUAGE_TITLE")}
          confirmButtonText={t("GLOBAL_BUTTON_CONFIRM")}
          cancelButtonText={t("GLOBAL_BUTTON_CANCEL")}
          onConfirm={this.onSubmit}
          placeholderText={t("SUBJECT_EDIT_LANGUAGE_PLACEHOLDER_TEXT")}
          mandatoryValidationText={t("SUBJECT_EDIT_LANGUAGE_VALIDATION_TEXT")}
        />
    :
        <Button
          onClick={this.handleSubmit}
          disabled={!this.state.language}
          primary={this.state.language}>
            {t("GLOBAL_BUTTON_SAVE")}
        </Button>;

    return (
      <Page
        header={() => (
          <Header as="h3">
            <Button
              color="orange"
              style={{ padding: "0.25rem 1rem " }}
              onClick={this.onCancel}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Icon name="arrow left" />
                <h3 style={{ margin: "0rem", marginLeft: "0.5rem" }}>
                  {this.state.subjectData.subjectCode}
                </h3>
              </div>
            </Button>
            {" " + t("SUBJECT_EDIT_LANGUAGE_TITLE")}
          </Header>
        )}
        loading={this.state.loading}
      >
        {this.state.error && (
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={t("GLOBAL_ERROR_GENERIC_MESSAGE")}
          />
        )}

        <Grid columns={"equal"}>
          <Grid.Column>
            <Form>
              <Form.Field>
                <label>{t("STAFF_FORM_LANGUAGE_LABEL")}</label>
                <Dropdown
                  placeholder={t("STAFF_FORM_LANGUAGE_DROPDOWN_PLACEHOLDER")}
                  fluid
                  selection
                  options={languageOptions}
                  onChange={this.languageChanged}
                  value={this.state.language}
                />
              </Form.Field>

              {buttonType}

              <Button color={"black"} basic type="button" onClick={this.onCancel}>
                {t("GLOBAL_BUTTON_CANCEL")}
              </Button>
           </Form>
          </Grid.Column>
        </Grid>
      </Page>
    );
  }
}

SubjectEditLanguagePage.contextType = ConfigContext;

export default withTranslation()(SubjectEditLanguagePage);
