import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Header,
  Message,
  Segment,
  Table,
} from "semantic-ui-react";
import DrugService from "../../DrugService";

class SubjectMedicationCreatePage extends Component {
  constructor(props, context) {
    super(props, context);
    const { t } = props;

    this.state = {
      drugOptions: [],
      error: false,
      loading: true,

      drug: undefined, // store and send to server as ID
      dosageAmount: 0, // double
      dosageUnit: undefined, // enum
      dosageRoute: undefined, // enum
      dosageFrequency: undefined, // enum
      slot1: false,
      slot2: false,
      slot3: false,
      slot4: false,
      baselineCompliance: 0, // double
      startDate: undefined, // date
      ongoing: true,
      endDate: undefined, // date
      daysOn: 1, // int
      daysOff: 0, // int
      allergiesConsidered: false,
      allergies: [],
    };

    this.dosageUnits = ["GRAMS", "MILLILITRES", "MILLIGRAMS", "MICROGRAMS", "UNIT", "TABLET"];
    this.dosageUnitOptions = this.dosageUnits.map((key) => {
      return {
        key: key,
        text: t("MEDICATION_DOSAGE_UNIT_" + key),
        value: key,
      };
    });

    this.dosageRoutes = [
      "ORAL",
      "CUTANEOUS",
      "SUBCUTANEOUS",
      "INTRAVENOUS",
      "OCULAR",
      "NASAL",
      "INHALATION",
      "OTHER",
    ];
    this.dosageRouteOptions = this.dosageRoutes.map((key) => {
      return {
        key: key,
        text: t("MEDICATION_DOSAGE_ROUTE_" + key),
        value: key,
      };
    });

    this.dosageFrequencyOptions = [
      {
        key: "AS_NEEDED",
        text: t("MEDICATION_DOSAGE_FREQUENCY_AS_NEEDED"),
        value: "AS_NEEDED",
      },
      {
        key: "REGULAR",
        text: t("MEDICATION_DOSAGE_FREQUENCY_REGULAR"),
        value: "REGULAR",
      },
    ];

    DrugService.listDrugs(1, 999999, "").then((drugs) => {
      const drugOptions = drugs.content
        .filter((drug) => drug.active)
        .map((drug) => {
          return {
            key: drug.id,
            value: drug.id,
            text: drug.name,
          };
        });
      this.setState({ drugOptions });
    });

    SubjectService.getAllergyList(this.props.match.params.subjectId)
      .then((allergies) => {
        this.setState({ allergies, loading: false });
      })
      .catch(this.errorHandler);
  }

  selectChanged = (event, option) => {
    this.setState({ [option.name]: option.value });
  };

  checkboxChanged = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  inputChanged = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  onSubmit = (event) => {
    //disable the save button to prevent multiply form submissions
    event.currentTarget.disabled = true;
    const payload = {
      drugId: this.state.drug,
      dosageAmount: this.state.dosageAmount,
      dosageUnit: this.state.dosageUnit,
      dosageRoute: this.state.dosageRoute,
      dosageFrequency: this.state.dosageFrequency,
      slot1: this.state.slot1,
      slot2: this.state.slot2,
      slot3: this.state.slot3,
      slot4: this.state.slot4,
      baselineCompliance: this.state.baselineCompliance,
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      daysOn: this.state.daysOn,
      daysOff: this.state.daysOff,
    };
    SubjectService.createMedication(this.props.match.params.subjectId, payload)
      .then(this.onCancel)
      .catch((error) => {
        console.error(error);
        this.setState({ error: true });
      })
      .finally(() => this.setState({ loading: false }));
  };

  onCancel = () => {
    window.location.href =
      window.location.origin +
      "/app/subject/" +
      this.props.match.params.subjectId +
      "/tabs/medication";
  };

  render() {
    const { t } = this.props;
    const errorMessage = this.state.error
      ? t("GLOBAL_ERROR_GENERIC_MESSAGE")
      : undefined;

    const slotCheckboxes = ["1", "2", "3", "4"].map((slotNum) => {
      return (
        <Form.Field key={slotNum}>
          <label>{t("MEDICATION_CREATE_SLOT_" + slotNum)}</label>
          <Form.Group inline>
            <Form.Input
              type="checkbox"
              id={"slot" + slotNum}
              name={"slot" + slotNum}
              onChange={this.checkboxChanged}
              value={this.state["slot" + slotNum]}
              checked={this.state["slot" + slotNum]}
            />
          </Form.Group>
        </Form.Field>
      );
    });

    let allergiesDisplay = (
      <Message>
        <p>{t("ALLERGIES_EMPTY_NOTICE")}</p>
      </Message>
    );

    if (this.state.allergies.length > 0) {
      allergiesDisplay = (
        <Table columns={1} compact>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                {t("ALLERGIES_TABLE_HEADER_NAME")}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.state.allergies.map((allergy) => (
              <Table.Row key={allergy.id}>
                <Table.Cell textAlign={"left"}>{allergy.name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      );
    }

    const submitDisabled =
      this.state.dosageUnit == null 
      || this.state.dosageRoute == null 
      || this.state.dosageFrequency == null 
      || this.state.drug === undefined
      || !this.state.allergiesConsidered;

    return (
      <Page
        name="MEDICATION_CREATE"
        header={t("MEDICATION_CREATE_HEADER")}
        subheader={t("MEDICATION_CREATE_SUBHEADER")}
        loading={this.state.loading}
      >
        <Form
          onCancel={this.onCancel}
          error={this.state.error}
        >
          <Message
            error
            header={t("GLOBAL_ERROR_TITLE")}
            content={errorMessage}
          />

          <Grid columns={"equal"}>
            <Grid.Column>
              <Segment>
                <Header>{t("MEDICATION_CREATE_DRUG_SECTION_HEADER")}</Header>

                <Form.Field>
                  <label>{t("MEDICATION_CREATE_DRUG_LABEL")}</label>
                  <Dropdown
                    name="drug"
                    required
                    placeholder={t("MEDICATION_CREATE_DRUG_BEGIN_TYPING")}
                    fluid
                    search
                    selection
                    options={this.state.drugOptions}
                    onChange={this.selectChanged}
                  />
                </Form.Field>
              </Segment>
              <Segment>
                <Header>
                  {t("MEDICATION_CREATE_TIME_PERIOD_SECTION_HEADER")}
                </Header>

                <Form.Input
                  label={t("MEDICATION_CREATE_START_DATE_LABEL")}
                  type="date"
                  name="startDate"
                  id="startDate"
                  onChange={this.inputChanged}
                  value={this.state.startDate}
                />

                <Form.Field>
                  <label>{t("MEDICATION_CREATE_ONGOING_LABEL")}</label>
                  <Form.Group inline>
                    <Form.Input
                      type="checkbox"
                      id="ongoing"
                      name="ongoing"
                      onChange={this.checkboxChanged}
                      value={this.state.ongoing}
                      checked={this.state.ongoing}
                    />
                  </Form.Group>
                </Form.Field>

                {!this.state.ongoing && (
                  <Form.Input
                    label={t("MEDICATION_CREATE_END_DATE_LABEL")}
                    type="date"
                    name="endDate"
                    id="endDate"
                    onChange={this.inputChanged}
                    value={this.state.endDate}
                  />
                )}
              </Segment>

              <Segment>
                <Header>{t("MEDICATION_CREATE_DOSAGE_SECTION_HEADER")}</Header>

                <Form.Input
                  label={t("MEDICATION_DOSAGE_AMOUNT")}
                  type="number"
                  step="0.01"
                  min="0.01"
                  max="1000"
                  name="dosageAmount"
                  id="dosageAmount"
                  onChange={this.inputChanged}
                  value={this.state.dosageAmount}
                />

                <Form.Field>
                  <label>{t("MEDICATION_DOSAGE_UNIT")}</label>
                  <Dropdown
                    name="dosageUnit"
                    fluid
                    selection
                    options={this.dosageUnitOptions}
                    onChange={this.selectChanged}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{t("MEDICATION_DOSAGE_ROUTE")}</label>
                  <Dropdown
                    name="dosageRoute"
                    fluid
                    selection
                    options={this.dosageRouteOptions}
                    onChange={this.selectChanged}
                  />
                </Form.Field>

                <Form.Field>
                  <label>{t("MEDICATION_DOSAGE_FREQUENCY")}</label>
                  <Dropdown
                    name="dosageFrequency"
                    fluid
                    selection
                    options={this.dosageFrequencyOptions}
                    onChange={this.selectChanged}
                  />
                </Form.Field>

                {this.state.dosageFrequency === "REGULAR" && slotCheckboxes}
              </Segment>

              {this.state.dosageFrequency === "REGULAR" && (
                <Segment>
                  <Header>{t("MEDICATION_CREATE_CYCLE_SECTION_HEADER")}</Header>

                  <Form.Input
                    label={t("MEDICATION_CREATE_DAYS_ON")}
                    type="number"
                    step="1"
                    min="1"
                    max="365"
                    name="daysOn"
                    id="daysOn"
                    onChange={this.inputChanged}
                    value={this.state.daysOn}
                  />

                  <Form.Input
                    label={t("MEDICATION_CREATE_DAYS_OFF")}
                    type="number"
                    step="1"
                    min="0"
                    max="365"
                    name="daysOff"
                    id="daysOff"
                    onChange={this.inputChanged}
                    value={this.state.daysOff}
                  />
                </Segment>
              )}

              <Segment>
                <Header>
                  {t("MEDICATION_CREATE_COMPLIANCE_SECTION_HEADER")}
                </Header>

                <Form.Input
                  label={t("MEDICATION_COMPLIANCE")}
                  type="number"
                  step="0.01"
                  min="0"
                  max="100"
                  name="baselineCompliance"
                  id="baselineCompliance"
                  onChange={this.inputChanged}
                  value={this.state.baselineCompliance}
                />
              </Segment>

              <Segment>
                <Header>
                  {t("MEDICATION_CREATE_ALLERGIES_SECTION_HEADER")}
                </Header>

                <Form.Field>
                  {allergiesDisplay}
                  <label>{t("MEDICATION_CREATE_ALLERGIES_CONSIDERED")}</label>
                  <Form.Group inline>
                    <Form.Input
                      type="checkbox"
                      id="allergiesConsidered"
                      name="allergiesConsidered"
                      onChange={this.checkboxChanged}
                      value={this.state.allergiesConsidered}
                      checked={this.state.allergiesConsidered}
                    />
                  </Form.Group>
                </Form.Field>
              </Segment>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column>
                <Button
                  type="submit"
                  loading={this.state.submitting}
                  disabled={submitDisabled}
                  onClick={(e) => this.onSubmit(e)}
                  primary
                >
                  {t("GLOBAL_BUTTON_SAVE")}
                </Button>
                {this.props.onCancel && (
                  <Button color={"black"} basic type="button" onClick={this.onCancel}>
                    {t("GLOBAL_BUTTON_CANCEL")}
                  </Button>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Page>
    );
  }
}

export default withTranslation()(SubjectMedicationCreatePage);
