import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import * as _ from "lodash";
import SubjectService from "../../SubjectService";
import {Button, Divider, Icon, Placeholder, Popup, Table} from "semantic-ui-react";
import { Link } from "react-router-dom";
import DeviceOnboardingModal from "./DeviceOnboardingModal";
import DateTimeService from "../../services/DateTimeService";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import TypeHelper from "../../helpers/TypeHelper";
import AuthService from "../../services/AuthService";
import ConfirmButton from "../../components/dashboard/ConfirmButton";
import DeviceVersionStatus from "./DeviceVersionStatus";
import ConfirmDropDownMenuWithFeedback from "../../components/dashboard/ConfirmDropDownMenuWithFeedback";
import ConfirmButtonWithFeedback from "../../components/dashboard/ConfirmButtonWithFeedback";
import { typeHelper } from "atom5-branching-questionnaire";

class SubjectViewDevicesTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      deviceData: [],
      onboarding: {},
      showOnboardingModal: false,
      loading: true,
      permissions: [],
      subjectData: null,
    };

    this.reload(false);
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId
    ) {
      this.reload();
    }
  };

  reload = (pageInitialised = true) => {
    if (pageInitialised) {
      this.setState({ loading: true });
      this.props.loadingState(true)
    }

    SubjectService.getSubjectDevices(this.props.match.params.subjectId)
      .then((deviceData) => {
        this.setState({ deviceData, loading: false });
        this.props.loadingState(false);
      })
      .catch(this.errorHandler);

    PermissionsService.hasPermissionForSubject(
      this.props.subjectData.groups,
      GroupPermission.MANAGE_SUBJECT_DEVICES
    ).then((hasManageDevicesPermission) => {
      this.setState({ hasManageDevicesPermission });
    });

    PermissionsService.hasPermissionForSubject(
        this.props.subjectData.groups,
        GroupPermission.MANAGE_SUBJECT_DEVICES_STATE
    ).then((hasManageDevicesStatePermission) => {
      this.setState({ hasManageDevicesStatePermission });
    });

    SubjectService.getSubjectData(this.props.match.params.subjectId).then(
      (subjectData) => {
        this.setState({ subjectData });
      }
    );
  };

  showQR = (device) =>
      this.setState({ onboarding: device, showOnboardingModal: true });

  createDeviceForSelfOnboarding = (feedback) => {
    // Auto-generating device name to simplify self onboarding process
    this.setState({ loading: true });
    return SubjectService.createDevice(
      this.props.match.params.subjectId,
      this.state.deviceData.length + 1, // Using numeric only description to be internationalisation-friendly
      feedback
    )
      .then((device) => {
        let deviceData = _.cloneDeep(this.state.deviceData);
        deviceData.push(device);
        this.setState({ deviceData });
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  changeDeviceState = async (deviceId, reason,newState) => {
    this.setState({ loading: true });
    const payload = { state: newState }
    try {
      await SubjectService.updateDeviceState(deviceId, payload,reason);
      const deviceData = await SubjectService.getSubjectDevices(this.props.match.params.subjectId) ;
      this.setState({ deviceData });
    } catch (error){
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  updateFirmwareUpgradeStatus = (device, payload) => {
    //the upgrade button has been pressed on the dashboard, send request to nucleus to approve update
    this.setState({ loading: true });
    device.pairedWearableFirmwareUpgradeStatus = payload.pairedWearableFirmwareUpgradeStatus;
    device.pairedWearableFirmwareVersionAtTimeOfUpgradeRequest = payload.pairedWearableFirmwareVersionAtTimeOfUpgradeRequest;
    return SubjectService.updateFirmwareUpgradeStatus(device.deviceId, payload)
      .catch((error) => {
        console.error(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  handleModalClose = () => {
    this.setState({ onboarding: {}, showOnboardingModal: false }, this.reload);
  };

  shouldShowNewDeviceDetailsScreen = () => {
    const showNewDeviceDetailsScreen =
      Window.configuration.ui?.devices?.showNewDeviceDetailsScreen !== undefined
        ? TypeHelper.parseBool(
            Window.configuration.ui?.devices?.showNewDeviceDetailsScreen
          )
        : false;
    return showNewDeviceDetailsScreen;
  };

  shouldShowConfirmNewDeviceAdditionButton = () => {
    const confirmNewDeviceAdditionButton =
        Window.configuration.ui?.devices?.confirmNewDeviceAddition !== undefined
            ? TypeHelper.parseBool(
                Window.configuration.ui?.devices?.confirmNewDeviceAddition
            )
            : false;
    return confirmNewDeviceAdditionButton;
  };

  bypassNewDeviceDetailsScreen = () => {
    return (
      AuthService.isSubject() === true ||
      this.shouldShowNewDeviceDetailsScreen() === false
    );
  };

  clearSecurityInfo = () => {
    this.setState({ loading: true });
    return SubjectService.clearSecurityInfo(this.props.match.params.subjectId)
      .then(() => {
        // noop
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  renderClearSecInfoButton = () => {
    const { t } = this.props;
    if (typeHelper.parseBool(Window.configuration?.onboardingSecurity?.enabled)) {
      return (
        <ConfirmButton
            buttonText={t("CLEAR_SECURITY_INFO", 'Clear Security Info')}
            headerText={t("CLEAR_SECURITY_INFO", 'Clear Security Info')}
            contentText={t(
              "CLEAR_SECURITY_INFO_CONFIRM",
              "Please confirm that you want to clear the security information for this subject, they will be asked to set this security information the next time they onboard to a device"
            )}
            confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
            cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
            onConfirm={this.clearSecurityInfo}
            color={"red"}
          />
        );
    }
  };

  render() {
    const { t } = this.props;
    let addDeviceButton = <></>;
    if (this.state.hasManageDevicesPermission) {
      if (this.bypassNewDeviceDetailsScreen()) {
        if (this.shouldShowConfirmNewDeviceAdditionButton()) {
          const participantDefaultText = "Please confirm you are creating an onboarding code for participant {0}";
          addDeviceButton = (
              <ConfirmButtonWithFeedback
                  onConfirm={this.createDeviceForSelfOnboarding}
                  buttonText={t(["SUBJECT_TAB_ADD_NEW_DEVICE", "GLOBAL_BUTTON_ADD_NEW"])}
                  headerText={t("NEW_DEVICE_HEADER_TITLE", "New device")}
                  contentText={t("NEW_DEVICE_DESCRIPTION", participantDefaultText).replace('{0}', this.state?.subjectData?.subjectCode)}
                  confirmButtonText={t("NEW_DEVICE_NEW_ONBOARDING_CODE", "Create onboarding code")}
                  cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                  mandatoryValidationText={t("NEW_DEVICE_VALIDATION_TEXT", "Please provide a reason for adding a new device!")}
                  labelPosition={"left"}
                >
                <Icon name="mobile" />
              </ConfirmButtonWithFeedback>
          )
        } else {
          addDeviceButton = (
              <Button
                  floated="right"
                  primary
                  icon
                  labelPosition="left"
                  onClick={() => this.createDeviceForSelfOnboarding()}
              >
                {t(["SUBJECT_TAB_ADD_NEW_DEVICE", "GLOBAL_BUTTON_ADD_NEW"])}{" "}
                <Icon name="mobile"/>
              </Button>
          );
        }
      } else {
        const isNew = new URLSearchParams(window.location.search).get("new");
        addDeviceButton = (
          <Button
            floated="right"
            primary
            icon
            as={Link}
            labelPosition="left"
            to={
              "/app/subject/" +
              this.props.match.params.subjectId +
              "/tabs/devices/new" +
              (isNew ? "?new=true" : "")
            }
          >
            {t(["SUBJECT_TAB_ADD_NEW_DEVICE", "GLOBAL_BUTTON_ADD_NEW"])}{" "}
            <Icon name="mobile" />
          </Button>
        );
      }
    }

    let tableColumnsConfig = [
      "consentedAt",
      "state",
      "platform",
      "deviceManufacturerAndModel",
      "firstInstalledVersion",
      "currentInstalledVersion"
    ];

    if (
      Window.configuration.ui.devices !== undefined &&
      Window.configuration.ui.devices.deviceTableColumns !== undefined
    ) {
      tableColumnsConfig = Window.configuration.ui.devices.deviceTableColumns;
    }
    const daysLastSeenColourBanding =
        Window.configuration.ui.devices !== undefined &&
        Window.configuration.ui.devices.daysLastSeenColourBanding !== undefined ? Window.configuration.ui.devices.daysLastSeenColourBanding : { warning: 30 ,critical: 90 }

    const headerCells = tableColumnsConfig.map((columnConfig) => {
      let headerText = "";
      if (columnConfig === "deviceId") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_DEVICEID", "Device Id");
      }else if (columnConfig === "deviceManufacturerAndModel") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_MODEL");
      } else if (columnConfig === "consentedAt") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_CREATED");
      } else if (columnConfig === "firstInstalledVersion") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_FIRST_INSTALLED_VERSION", "Initial Installed Version");
      } else if (columnConfig === "currentInstalledVersion") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_CURRENT_VERSION", "Current Installed Version");
      } else if (columnConfig === "latestAvailableVersion") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_LATEST_VERSION", "Latest Available Version");
      } else if (columnConfig === "lastAccessed") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_LAST_ACCESSED", "Last Seen (Days ago)");
      } else if (columnConfig === "pairedGarminFirmwareVersions") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_FIRMWARE_VERSION", "Wearable Firmware Version");
      } else if (columnConfig === "pairedGarminDisplayName") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_DISPLAY_NAME", "Wearable Display Name");
      } else if (columnConfig === "pairedGarminLastUploadDataTime") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_LAST_UPDATE", "Wearable Last Update");
      } else if (columnConfig === "pairedGarminConnected") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_CONNECTED", "Wearable Connected");
      } else if (columnConfig === "pairedGarminStatus") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_STATUS", "Wearable Status");
      } else if (columnConfig === "pairedWearableFirmwareUpgradeStatus") {
        headerText = t("DEVICE_LIST_TABLE_HEADER_WEARABLE_FIRMWARE_UPGRADE_STATUS", "Firmware Update");
      } else {
        const translationKey =
          "DEVICE_LIST_TABLE_HEADER_" + columnConfig.toUpperCase();
        headerText = t(translationKey);
      }
      return (
        <Table.HeaderCell key={columnConfig}>{headerText}</Table.HeaderCell>
      );
    });

    function fomatDaysLastSeenCell(daysLastSeen) {
      let cellStatusStyle = '';
      if (daysLastSeen) {
        if (daysLastSeen >= daysLastSeenColourBanding.warning && daysLastSeen < daysLastSeenColourBanding.critical) {
          cellStatusStyle = 'warning';
        }
        if (daysLastSeen >= daysLastSeenColourBanding.critical) {
          cellStatusStyle = 'negative';
        }
      }
      return cellStatusStyle;
    }

    const deviceRows = this.state.deviceData.map((device) => {
      const cells = tableColumnsConfig.map((columnConfig) => {
        let cellStatusStyle = null;
        let content = "";
        if (columnConfig === "deviceId") {
          content = device?.deviceId
              ? device.deviceId
              : "-";

        }else if (columnConfig === "deviceManufacturerAndModel") {
          const deviceManufacturerAndModel =
            (device.deviceManufacturer ? device.deviceManufacturer : "") +
            " " +
            (device.deviceModel ? device.deviceModel : "");
          content = deviceManufacturerAndModel.trim();
        } else if (columnConfig === "consentedAt") {
          content = device.consentedAt
            ? DateTimeService.build.asDisplayDateTime(device.consentedAt)
            : t("DEVICE_NOT_CONSENTED");
        }  else if (columnConfig === "firstInstalledVersion") {
          content = device?.firstInstalledVersion
            ? device.firstInstalledVersion
            : "-";
        } else if (columnConfig === "currentInstalledVersion") {

          let versionStatus = DeviceVersionStatus({
            currentInstalledVersionSupported: device?.currentInstalledVersionSupported,
            currentInstalledVersion: device?.currentInstalledVersion,
            t: t
          });
          cellStatusStyle = versionStatus.className;
          content = versionStatus.content;
        } else if (columnConfig === "latestAvailableVersion") {
          content = device?.latestAvailableVersion
              ? device.latestAvailableVersion
              : "-";
        } else if (columnConfig === "lastAccessed") {
          let daysLastSeen = null;
          if (device?.lastAccessed) {
                const now = new Date();
                daysLastSeen = Math.round((now.getTime() - new Date(device.lastAccessed).getTime()) / (1000 * 3600 * 24))
                content =  (<Popup trigger={<span>{daysLastSeen}</span>} content={DateTimeService.build.asDisplayDateTime(device.lastAccessed)} basic/>)
          }else{
              content = "-"
          }
          cellStatusStyle = fomatDaysLastSeenCell(daysLastSeen);
      } else if (columnConfig === "state") {
          const listOptions =  [
              {key: 'CONSENTED', value: 'CONSENTED', text:t("DEVICE_STATE_CONSENTED")},
              {key: 'DEAUTHORISED', value: 'DEAUTHORISED', text: t("DEVICE_STATE_DEAUTHORISED")}
          ]
          if (this.state.hasManageDevicesStatePermission && listOptions.some(stateOption => stateOption.key === device.state)) {
            content = (<ConfirmDropDownMenuWithFeedback
                listValue={device.state}
                listItems={listOptions}
                onConfirm={this.changeDeviceState}
                context={device.deviceId}
                headerText={t("SUBJECT_TAB_CHANGE_DEVICE_STATE", "WARNING: Updating device status.")}
                confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                contentText={t(
                    "SUBJECT_TAB_CHANGE_DEVICE_STATE_WARNING",
                    "Clicking OK will update the status of this device, please confirm and " +
                    "provide a reason for this change."
                )}

            />)
          } else {
            content =  t("DEVICE_STATE_" + device.state);
          }

        } else if (columnConfig === "platform") {
          content = device[columnConfig];
          if (device.platformVersion) {
            content = `${content} ${device.platformVersion}`;
          }
        } else if (columnConfig === "pairedGarminFirmwareVersions") {
          content = device?.pairedGarminFirmwareVersions
            ? device.pairedGarminFirmwareVersions
            : "-";
        } else if (columnConfig === "pairedGarminDisplayName") {
          content = device?.pairedGarminDisplayName
            ? device.pairedGarminDisplayName
            : "-";
        } else if (columnConfig === "pairedGarminConnected") {
          if (device?.pairedGarminConnected === true) {
            content = t("GENERIC_YES", "Yes")
          } else if (device?.pairedGarminConnected === false) {
            content = t("GENERIC_NO", "No");
          } else {
            content = "-";
          }
        }  else if (columnConfig === "pairedGarminStatus") {
          content = device?.pairedGarminStatus
            ? device.pairedGarminStatus
            : "-" ;
        } else if (columnConfig === "pairedGarminLastUploadDataTime") {
          content = device?.pairedGarminLastUploadDataTime
            ? DateTimeService.build.asDisplayDateTime(device.pairedGarminLastUploadDataTime)
            : "-";
        } else if (columnConfig === "pairedWearableFirmwareUpgradeStatus") {
          if (device?.pairedWearableFirmwareUpgradeStatus === "SCHEDULED") {
            content = (
              <Button
                color={"red"}
                icon
                labelPosition="left"
                onClick={() => this.updateFirmwareUpgradeStatus(device,
                {
                  pairedWearableFirmwareUpgradeStatus: 'CANCELLED',
                  pairedWearableFirmwareVersionAtTimeOfUpgradeRequest: null
                })}>
                  {t(["SUBJECT_TAB_UPGRADE_WEARABLE_FIRMWARE_BUTTON_CANCEL", "Scheduled Cancel Upgrade"])}
                  <Icon name="times" />
              </Button>);
          } else if (device?.pairedGarminFirmwareVersions != null
                      && (device?.pairedWearableFirmwareUpgradeStatus === null
                        || device?.pairedWearableFirmwareUpgradeStatus === "CANCELLED")) {
            content = (
              <ConfirmButton
                buttonText={t(["SUBJECT_TAB_UPGRADE_WEARABLE_FIRMWARE_BUTTON", "Upgrade when available"])}
                headerText={t("SUBJECT_TAB_UPGRADE_WEARABLE_FIRMWARE_REQUIRED", "WARNING: Confirm firmware upgrade is required")}
                contentText={t(
                  "SUBJECT_TAB_WEARABLE_FIRMWARE_UPGRADE_WARNING",
                  "If the wearable device is already on the latest version of the"
                    + " firmware this will lead to problems clearing the scheduled update"
                    + " please confirm this upgrade is required"
                )}
                confirmButtonText={t("GLOBAL_BUTTON_CONFIRM", "Confirm")}
                cancelButtonText={t("GLOBAL_BUTTON_CANCEL", "Cancel")}
                onConfirm={() => this.updateFirmwareUpgradeStatus(device,
                  {
                    pairedWearableFirmwareUpgradeStatus: 'SCHEDULED',
                    pairedWearableFirmwareVersionAtTimeOfUpgradeRequest: device.pairedGarminFirmwareVersions
                  })}
                primary/>);
          } else {
            content = "-";
          }
        } else {
          content = device[columnConfig];
        }
        return (
          <Table.Cell key={device.id + "-" + columnConfig} className={cellStatusStyle} >
            {content}
          </Table.Cell>
        );
      });

      return (
        <Table.Row key={device.id} style={{ cursor: "pointer" }}>
          {cells}
          <Table.Cell>
            {device.state === "CREATED" && (
              <Button
                primary
                type="button"
                floated="right"
                onClick={() => this.showQR(device)}
              >
                {t("DEVICE_BUTTON_ONBOARD")}
              </Button>
            )}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <>
        {this.state.loading && (
          <>
            <Placeholder fluid>
              <Placeholder.Paragraph>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder.Paragraph>
            </Placeholder>
            <Divider />
          </>
        )}

        {!this.state.loading && (
          <>
          <Table selectable columns={headerCells.length} compact>
            <Table.Header>
              <Table.Row>
                {headerCells}
                <Table.HeaderCell style={{ minHeight: "40px" }}>
                  {addDeviceButton}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{deviceRows}</Table.Body>
          </Table>
          {this.renderClearSecInfoButton()}
          </>
        )}
        {this.state.subjectData && (
          <DeviceOnboardingModal
            show={this.state.showOnboardingModal}
            onClose={this.handleModalClose}
            closeIcon
            deviceId={this.state.onboarding.deviceId}
            onboardingCode={this.state.onboarding.onboardingCode}
            subjectData={this.state.subjectData}
          />
        )}
      </>
    );
  }
}

export default withTranslation()(SubjectViewDevicesTab);
