import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import Page from '../../../components/page/Page';
import GroupPermission from '../../../GroupPermission';
import PermissionsService from '../../../services/PermissionsService';
import { Link, Redirect } from 'react-router-dom';
import { Button, Modal, Segment } from 'semantic-ui-react';
import BatchAttachmentService from '../../../services/BatchAttachmentService';
import { AgGridReact } from 'ag-grid-react';
import GridCell from './components/GridCell';
import BatchAttachmentHelpers from './BatchAttachmentHelpers';
import { typeHelper } from 'atom5-branching-questionnaire';
import ConfirmButtonWithFeedback from '../../../components/dashboard/ConfirmButtonWithFeedback';
import { VALIDATION_MESSAGES } from './constants/VALIDATION_MESSAGES';
import GeneralHelpers from '../../../helpers/GeneralHelpers';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';

const BatchUploadProcessData = (props) => {
  const { t } = props;

  const STATUS = {
    INIT: 'INIT',
    FILE_SELECTED: 'FILE_SELECTED',
    VALIDATING: 'VALIDATING',
    VALIDATION_PASSED: 'VALIDATION_PASSED',
    PROCESSSING: 'PROCESSSING',
    ERROR: 'ERROR',
    SUCCESS: 'SUCCESS'
  };

  const backgroundJobRefreshIntervalRef = useRef();

  const [permissions, setPermissions] = useState();
  const [backgroundJobs, setBackgroudJobs] = useState([]);

  const [status, setStatus] = useState(STATUS.INIT);

  const fileInputRef = useRef();
  const [csvFile, setCsvFile] = useState();

  const [errors, setErrors] = useState();

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    populatePermissions();

    populateBackgroundJobs();
    backgroundJobRefreshIntervalRef.current = setInterval(() => {
      populateBackgroundJobs();
    }, 10000);
    return () => clearInterval(backgroundJobRefreshIntervalRef.current);
  }, []);

  useEffect(() => {
    populateBackgroundJobs();
  }, [status])

  const populatePermissions = async () => {
    const requiredPermissionKeys = [
      GroupPermission.BATCH_ATTACHMENTS_VIEW,
      GroupPermission.BATCH_ATTACHMENTS_PROCESS_DATA,
    ];

    const result = {};
    for (const permissionKey of requiredPermissionKeys) {
      const hasPermission = await PermissionsService.hasPermissionInAnyGroup(permissionKey);
      result[permissionKey] = hasPermission;
    }

    setPermissions(result);
  };

  const populateBackgroundJobs = async () => {
    const response = await BatchAttachmentService.getBackgroundJobs();
    setBackgroudJobs(response);
  }

  if (permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_VIEW] !== true) {
    return <Redirect to='/' />;
  }

  const buildColumnDefinition = (headerRef, headerName) => { return { field: BatchAttachmentHelpers.getHeaderRef(headerRef) + '.value', headerName: headerName != null ? headerName : headerRef } };

  const onValidateClick = async () => {
    await uploadData(true, undefined);
  };

  const onProcessClick = async (feedbackReason) => {
    await uploadData(false, feedbackReason);
  };

  const uploadData = async (validateOnly = true, feedbackReason) => {
    setErrors();
    setStatus(validateOnly ? STATUS.VALIDATING : STATUS.PROCESSSING);
    setColumnDefs([]);
    setRowData([]);

    try {

      // Response and overall errors
      let response;
      try {
        response = await BatchAttachmentService.uploadData(validateOnly, csvFile, feedbackReason);

        setErrors(response?.errors); // get normal processing errors - from valid response
        const isSuccess = typeHelper.parseBool(response?.success);
        const successStatus = validateOnly ? STATUS.VALIDATION_PASSED : STATUS.SUCCESS;
        setStatus(isSuccess ? successStatus : STATUS.ERROR);
      } catch (error) {
        let errors = error.errors; // See if we have any errors on the process model response
        if (errors == null) {
          errors = [error?.message ? error.message : error];
        }
        setErrors(errors);
        setStatus(STATUS.ERROR);
        return;
      }

      // Column Defs
      const cd = response?.headers?.map(h => buildColumnDefinition(h));
      cd.unshift(buildColumnDefinition('__row_status', t('BATCHUPLOADEDFILELIST_GRID_ROW_STATUS_COLUMN_HEADER', 'Row Status')));
      setColumnDefs(cd);

      // Rows
      const rd = response?.rows?.map(row => {
        const obj = {};
        const rowStatusCell = {
          type: '__row_status',
          header: '__row_status',
          value: '',
          rowHasErrors: row.hasErrors,
          rowHasWarnings: row.hasWarnings
        };
        obj[BatchAttachmentHelpers.getHeaderRef('__row_status')] = rowStatusCell;
        for (const cell of row.cells) {
          obj[BatchAttachmentHelpers.getHeaderRef(cell.header)] = cell;
        }
        return obj;
      });
      setRowData(rd);

    } catch (error) {
      console.error(error);
      const message = GeneralHelpers.errorToString(error);
      setErrors([message]);
      setStatus(STATUS.ERROR);
    }
  };

  const handleInitClick = () => {
    fileInputRef.current.click();
  };

  const handleFileSelect = async (event) => {
    const file = event?.target?.files[0];
    setCsvFile(file);
    setStatus(STATUS.FILE_SELECTED);
  };

  const handlFileClear = async () => {
    setCsvFile();
    setStatus(STATUS.INIT);
  };

  const hasRunningBackgroundJobs = Array.isArray(backgroundJobs) && backgroundJobs.length > 0;

  const showSelectFileButton = [STATUS.INIT, STATUS.ERROR].includes(status);
  const showValidateFileButton = [STATUS.FILE_SELECTED].includes(status);
  const showProcessFileButton = [STATUS.VALIDATION_PASSED].includes(status);
  const showProcessCompletedMessage = [STATUS.SUCCESS].includes(status);
  const showValidatingModal = [STATUS.VALIDATING].includes(status);
  const showProcessingModal = [STATUS.PROCESSSING].includes(status);
  const showBackgroundJobsMessage = hasRunningBackgroundJobs && ![STATUS.PROCESSSING].includes(status)
  const showErrors = [STATUS.ERROR].includes(status);
  const showDataGrid = [STATUS.VALIDATION_PASSED, STATUS.ERROR].includes(status);

  return (
    <Page
      name='BATCHUPLOADPROCESSDATA'
      header={t('BATCHUPLOADPROCESSDATA_TITLE', 'Batch upload - Process data')}
    >
      {permissions && permissions[GroupPermission.BATCH_ATTACHMENTS_PROCESS_DATA] === true && (
        <>
          {showSelectFileButton && (
            <>
              <Button
                primary={true}
                content={t('GLOBAL_BUTTON_SELECT_FILE', 'Select file')}
                labelPosition='left'
                icon={'file'}
                onClick={handleInitClick}
              />
              <input
                ref={fileInputRef}
                type='file'
                hidden
                accept={['.csv', 'text/csv']}
                onChange={handleFileSelect}
              />
            </>
          )}

          {showValidateFileButton && (
            <>
              <Button
                primary
                onClick={onValidateClick}
              >
                {t('BATCHUPLOADPROCESSDATA_PROCESS_VALIDATE', 'Validate import file')}
              </Button>
              <Button onClick={handlFileClear}>
                {t('GLOBAL_BUTTON_SELECT_NEW_FILE', 'Select another file')}
              </Button>
            </>
          )}

          {showProcessFileButton && (
            <div>
              <Segment>
                {t('BATCHUPLOADPROCESSDATA_VALIDATION_PASSED',
                  'The validation has succeeded, you can now process the data by pressing the button below.')}
              </Segment>
              <ConfirmButtonWithFeedback
                disabled={!showProcessFileButton}
                buttonText={t('BATCHUPLOADPROCESSDATA_PROCESS_START', 'Start import')}
                headerText={t('BATCHUPLOADPROCESSDATA_PROCESS_PROMPT_TITLE', 'Batch import')}
                contentText={t('BATCHUPLOADPROCESSDATA_PROCESS_PROMPT_TITLE', 'Please enter the reason the the batch import')}
                confirmButtonText={t('GLOBAL_BUTTON_CONFIRM', 'Confirm')}
                cancelButtonText={t('GLOBAL_BUTTON_CANCEL', 'Cancel')}
                onConfirm={onProcessClick}
                placeholderText={t('BATCHUPLOADPROCESSDATA_PROCESS_PROMPT_TITLE', 'Batch import')}
                mandatoryValidationText={t('GLOBAL_REASON_PROMPT', 'Please enter a reason')}
              />
            </div>
          )}

          {showProcessCompletedMessage && (
            <div>
              <Segment inverted color='green'>
                {t('BATCHUPLOADPROCESSDATA_COMPLETE',
                  'The upload has completed. If files need processing, these will take time to be playable in the dashboard. They are procesed using a queue system.')}
              </Segment>
            </div>
          )}

          <Modal
            size={'fullscreen'}
            open={showProcessingModal || showValidatingModal}
            closeIcon={false}
            closeOnDimmerClick={false}
            closeOnDocumentClick={false}
          >
            <Modal.Header>
              <h3>{t('BATCHUPLOADPROCESSDATA_IS_PROCESSING', 'Processing')}</h3>
            </Modal.Header>
            <Modal.Content>
              {t('BATCHUPLOADPROCESSDATA_IS_PROCESSING',
                'The data is processing. Please wait for this to complete, and do not close this window. This message will disappear when the processing is complete.')}
            </Modal.Content>
          </Modal>

          {showBackgroundJobsMessage && (
            <Segment inverted>
              {t('BATCHUPLOADPROCESSDATA_JOB_ALREADY_RUNNING',
                'There is already a batch upload process running. Please wait for that to finish.')}
            </Segment>
          )}

          {showErrors && (
            <Segment inverted color='red'>
              {t('BATCHUPLOADPROCESSDATA_DATA_ERRORS', 'Errors')}
              {errors != null && errors.length > 0 && (
                <ul>
                  {errors.map((error, index) => {
                    let message = error;
                    if (Object.keys(VALIDATION_MESSAGES).includes(error)) {
                      const validationMessage = VALIDATION_MESSAGES[error];
                      message = t(validationMessage.translationKey, validationMessage.fallbackText);
                    }
                    return <li key={index}>{message}</li>;
                  })}
                </ul>
              )}
              {(errors == null || errors.length === 0) && (
                <div>
                  {t('BATCHUPLOADPROCESSDATA_DATA_ERRORS_IN_TABLE', 'Please check the table for row specific errors')}
                </div>
              )}
            </Segment>
          )}

          {/* Note the Grid has to have a height, or it doesn't render. This may be related to Semantic UI */}
          {showDataGrid && (
            <>
              <div style={{ marginTop: 5, marginBottom: 10 }}>
                <div><strong>{t('BATCHUPLOADPROCESSDATA_DATA_KEY', 'Key')}</strong></div>
                <table>
                  <tr>
                    <td style={{ margin: 3, padding: 3, backgroundColor: 'red', color: 'white' }}>
                      {t('BATCHUPLOADPROCESSDATA_DATA_KEY_ERROR', 'Error')}
                    </td>
                    <td>
                      {t('BATCHUPLOADPROCESSDATA_DATA_KEY_ERROR_DESCRIPTION', 'The cell/row has an error')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ margin: 3, padding: 3, backgroundColor: 'orange', color: 'black' }}>
                      {t('BATCHUPLOADPROCESSDATA_DATA_KEY_WARNING', 'Warnings / Information')}
                    </td>
                    <td>
                      {t('BATCHUPLOADPROCESSDATA_DATA_KEY_WARNING_DESCRIPTION', 'The cell/row has some warnings/information - the import will run, but it is worth checking the warnings/information')}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ margin: 3, padding: 3, backgroundColor: 'green', color: 'white' }}>
                      {t('BATCHUPLOADPROCESSDATA_DATA_KEY_OK', 'Valid')}
                    </td>
                    <td>
                      {t('BATCHUPLOADPROCESSDATA_DATA_KEY_OK_DESCRIPTION', 'The row is valid')}
                    </td>
                  </tr>
                </table>
              </div>
              <div className='ag-theme-balham' style={{ width: '100%', height: 500 }}>
                <AgGridReact
                  defaultColDef={{
                    cellRenderer: GridCell
                  }}
                  columnDefs={columnDefs}
                  rowData={rowData}
                ></AgGridReact>
              </div>
            </>
          )}
        </>
      )}
      <div style={{ marginTop: 50 }}>
        <Button
          as={Link}
          to={'/app/attachments/batchupload'}
        >
          {t('GLOBAL_BUTTON_BACK', 'Back')}
        </Button>
      </div>
    </Page>
  );
};

export default withTranslation()(BatchUploadProcessData);
