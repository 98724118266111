import React from "react";
import { Icon } from "semantic-ui-react";

const CollapseButton = (props) => {
  const { isExpanded, fn } = props;
  return (
      <button style={styles.button} onClick={fn}>
        <div style={styles.angleRight}>
          {isExpanded && <Icon name="angle left" color="black" size="large" />}
          {!isExpanded && (
            <Icon name="angle right" color="black" size="large" />
          )}
        </div>
      </button>
  );
};

const styles = {
  container: {
    height: "0px",
    width: "0px",
  },
  button: {
    height: "20px",
    width: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100%",
    backgroundColor: 'white',
    border: 'none',
    boxShadow: 'none',
    outline: 'none',
  },
  angleRight: {
    paddingLeft: "2px",
  },
};

export default CollapseButton;
