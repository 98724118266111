import React, { useRef, useState} from "react";
import VideoPlayer from "../../components/media/VideoPlayer/VideoPlayer";
import SkeletonView from "../../data-analysis/ui/skeleton/SkeletonView";
import {withTranslation} from "react-i18next";


const VideoPlayerWithTrackingOverlay = (videoPlayerProps) => {
    const  {skeleton } = videoPlayerProps;
    const videoPlayerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [skeletonOffset, setSkeletonOffset] = useState(0)

    const playerReady = () => {
        if (videoPlayerRef.current) {
            const internalPlayer = videoPlayerRef.current.getInternalPlayer()

            if(internalPlayer){
                const { clientWidth, clientHeight, videoWidth, videoHeight } = videoPlayerRef.current.getInternalPlayer();
                const aspectRatio = videoWidth / videoHeight;

                // Get the container's client dimensions (visible dimensions)
                const containerWidth = clientWidth;
                const containerHeight = clientHeight;

                // Now adjust the width/height to ensure the aspect ratio is maintained
                if (containerWidth / aspectRatio <= containerHeight) {
                    setDimensions({
                        width: containerWidth,
                        height: containerWidth / aspectRatio,
                    });
                   setSkeletonOffset(0);
                } else {
                    setDimensions({
                        width: containerHeight * aspectRatio,
                        height: containerHeight,
                    });
                    setSkeletonOffset(Math.abs(containerWidth - containerHeight * aspectRatio + 40) / 2)
                }
            }
        }
    }
    return (
        <div style={parentStyle}>
            <div >
                <VideoPlayer ref={videoPlayerRef} {...videoPlayerProps} onReady={playerReady} />

            </div>
            <div style={{...overlayContainerStyle, width: dimensions.width, height: dimensions.height, left: skeletonOffset + 'px' }}>
                    <SkeletonView width={dimensions.width} height={dimensions.height} skeleton={skeleton} scale={{x: 1, y: 1}} visible={true}
                                  lineColor="white" invertY={false}/>

            </div>
        </div>
    );
}


const parentStyle = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start', // Aligns content to the left
    alignItems: 'center', // Center vertically
    width: "100%",
    height: "800",

};

const overlayContainerStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',

    alignItems: 'center', // Keeps content vertically centered
    pointerEvents: 'none', // Allows clicks to pass through this overlay
};





export default withTranslation()(VideoPlayerWithTrackingOverlay);
